import $ from "jquery";
import NotificationService from "../Service/NotificationService";
import Widget from "./../../../Inlined/Widget";
import UserBiscuitEnum from "../VO/UserBiscuitEnum";
/* global document */
/* global Notification */
/* global window */
/* global __ */

export default class NotificationSubscribeWidget extends Widget {
	constructor(el, model, children, notificationService, userService, flashMessageService) {
		super(el, model, children);

		this.notificationService = notificationService;
		this.userService = userService;
		this.flashMessageService = flashMessageService;
	}

	bind() {
		super.bind();

		this.cancelBtn = $(".j-cancel", this.el);
		this.closeBtn = $(".j-close", this.el);
		this.subscribeBtn = $(".j-subscribe", this.el);
		this.spinner = $(".j-spinner", this.el);

		this.cancelBtn.click(ev => this.onCancelBtnClick(ev));
		this.closeBtn.click(ev => this.onCloseBtnClick(ev));
		this.subscribeBtn.on("click", ev => this.onSubscribeBtnClick(ev));

		this.onUserActivateFn = () => this.onUserActivate();
		$(document.body).one("mousemove touchstart scroll", this.onUserActivateFn);
	}

	unbind() {
		super.unbind();

		$(document.body).off("mousemove touchstart scroll", this.onUserActivateFn);
	}

	hide() {
		const height = this.el.outerHeight();

		this.el
			.stop()
			.animate(
				{
					top: `${-height - 30}px`,
				},
				300,
				() => {
					this.el.hide();
				},
			);
	}

	onCancelBtnClick(ev) {
		ev.preventDefault();

		this.hide();
		this.userService.setBiscuit(UserBiscuitEnum.NOTIFICATION_SUBCRIBE, 1, 365);
	}

	onCloseBtnClick(ev) {
		ev.preventDefault();

		this.hide();
		this.userService.setBiscuit(UserBiscuitEnum.NOTIFICATION_SUBCRIBE, 1, 365);
	}

	onSubscribeBtnClick(ev) {
		ev.preventDefault();
		this.spinner.show();

		this.notificationService.subscribe()
			.then(() => {
				this.spinner.hide();
				this.flashMessageService.showMessage(
					/* TRANSLATORS: Skrz Tip je název zvláštní kampaně na webu.
					Překlad nutno konzultovat s produktovým manažerem. */
					__("Odběr Skrz Tipů byl zapnut"),
					"success",
				);
				this.hide();
			})
			.catch((error) => {
				this.spinner.hide();

				if (Notification.permission === "denied") {
					// User denied notification permission which means we failed to subscribe and the user will
					// need to manually change the notification permission to subscribe to push messages
					// eslint-disable-next-line
					__DEV__ && console.log("NotificationService: Permission for Notifications was denied");
					this.hide();
					this.userService.setBiscuit(UserBiscuitEnum.NOTIFICATION_SUBCRIBE, 1, 365);
					// Notifications will be denied in browser incognito mode. So we display flash message.
					this.flashMessageService.showMessage(
						__("Zasílání upozornění bylo zakázáno nebo v tomto prohlížeči není podporováno."),
						"warning",
					);
				} else if (window.DOMException
					&& error instanceof window.DOMException && error.name === "NotAllowedError"
				) {
					// User temporarily denied notification permission (Chrome, Firefox). It means that until next page
					// load we can't ask for permissions. So do not show this dialog for 1 day.
					// eslint-disable-next-line
					__DEV__ && console.log("NotificationService: Permission for Notifications was temporarily blocked");
					this.hide();
					this.userService.setBiscuit(UserBiscuitEnum.NOTIFICATION_SUBCRIBE, 1, 1);
				} else {
					// A problem occurred with the subscription, this can often be down to an issue or lack of the
					// gcm_sender_id and / or gcm_user_visible_only
					// eslint-disable-next-line
					__DEV__ && console.log("NotificationService: Unable to subscribe to push.", error);
					this.flashMessageService.showMessage(
						__("Vyskytla se chyba, zkuste to prosím znovu"),
						"warning",
					);
				}
			});
	}

	onUserActivate() {
		if (this.model.userActive) {
			return;
		}

		this.model.userActive = true;

		setTimeout(
			() => {
				if (NotificationService.isSupported()) {
					NotificationService.getSubscription()
						.then((pushSubscription) => {
							// Show to unsubscribed users only
							if (!pushSubscription) {
								this.show();
							}
						});
				}
			},
			30000,
		);
	}

	show() {
		this.el
			.stop()
			.show()
			.animate(
				{
					top: 0,
				},
				300,
			);
	}
}
