import $ from "jquery";
import Widget from "./../../../../Inlined/Widget";
import * as dom from "../../../../../dry/dom";
import ExitReadyEvent from "../../Event/ExitReadyEvent";
import { getIconSpriteUrl } from "../../utils";
import checkIcon from "../../Styles/def/img/icons/check.svg";
import browser from "../../../../../dry/browser";

// Clipboard.js nepodporuje IE8
if (dry.browser.getIEVersion() == null || dry.browser.getIEVersion() >= 9) {
	var Clipboard = require("clipboard");
}

export default class ItemVoucherWidget extends Widget {

	constructor(el, model, children, app, flashMessageService) {
		super(el, model, children);

		this.app = app;
		this.flashMessageService = flashMessageService;
	}

	bind() {
		super.bind();

		this.btnCopy = $(".j-copy", this.el);
		this.btnExit = $(".j-exit", this.el);
		this.code = $(".j-code", this.el);

		this.btnCopy.click((ev) => { this.onBtnCopyClick(ev); });
		this.btnExit.click((ev) => { this.onBtnExitClick(ev); });

		if (typeof Clipboard !== "undefined") {
			this.btnCopy.each((i) => {
				var clipboard = new Clipboard(this.btnCopy[i]);
				clipboard.on("success", (ev) => this.onClipboardSuccess(ev));
				clipboard.on("error", (ev) => this.flashMessageService.showMessage("Stiskněte Ctrl + C pro zkopírování"));
			});
		}
		this.code.click((ev) => { this.onCodeClick(ev); });
	}

	exit() {
		this.el.hide();
		this.app.dispatch(new ExitReadyEvent());
	}

	onClipboardSuccess(ev) {
		this.btnCopy.html(`<svg class="icon mrq" aria-hidden="true">
			<use xlink:href="${getIconSpriteUrl(checkIcon)}"></use>
		</svg>Zkopírováno!`);
	}

	onBtnCopyClick(ev) {
		ev.preventDefault();

		if (typeof Clipboard === "undefined") {
			this.flashMessageService.showMessage("Stiskněte Ctrl + C pro zkopírování");
		}
	}

	onBtnExitClick(ev) {
		ev.preventDefault();

		this.exit();
	}

	onCodeClick(ev) {
		dry.dom.selectText(ev.currentTarget);
	}

}