import $ from "jquery";
import UserAuthMixin from "../../UserAuthMixin";
import ValidationMixin from "../ValidationMixin";
import FormStepperWidget from "../FormStepperWidget";
import UserAuthResumeEnum from "../../VO/UserAuthResumeEnum";
import suggestEmail from "./SuggestEmail";
import UserAuthStateEvent from "../../Event/UserAuthStateEvent";
import UserBiscuitEnum from "../../VO/UserBiscuitEnum";
import UserAuthStateEnum from "../../VO/UserAuthStateEnum";

/* global __ */
/* global dry */

export default class UserSubscribeWidget extends FormStepperWidget {
	constructor(
		el,
		model,
		children,
		app,
		userService,
		flashMessageService,
		validateSettings,
		emailProvidersSettings,
		flashMessagesWidget,
		fancyboxService,
	) {
		super(el, model, children, app);
		this.app = app;
		this.userService = userService;
		this.flashMessageService = flashMessageService;
		this.validateSettings = validateSettings;
		this.emailProvidersSettings = emailProvidersSettings;
		this.flashMessagesWidget = flashMessagesWidget;
		this.fancyboxService = fancyboxService;
		this.bindValidation = ValidationMixin.bindValidation;
		this.getEmailMessage = ValidationMixin.getEmailMessage;
		this.onInputFix = ValidationMixin.onInputFix;
		this.onValidationSuccess = ValidationMixin.onValidationSuccess;
		this.getShowLoginComplete = UserAuthMixin.getShowLoginComplete;
		this.setShowLoginComplete = UserAuthMixin.setShowLoginComplete;
	}


	bind() {
		super.bind();
		this.model.pwdFilled = false;
		this.btnClose = $(".j-close", this.el);
		this.btnChangeEmail = $(".j-change-email", this.el);
		this.btnFb = $(".j-btn-fb", this.el);
		this.btnForgottenPwd = $(".j-forgotten-pwd", this.el);
		this.btnGotoEmail = $(".j-goto-email", this.el);
		this.completeMessage = $(".j-complete-message", this.el);
		this.inputEmail = $(":input[name='email']", this.el);
		this.registeredEmailEl = $(".j-registered-email", this.el);
		this.btnShowRegisterVideo = $(".j-userregister-video-toggle", this.el);
		this.btnGotoEmail.hide();
		this.bindForm();
		this.bindValidation();
		this.btnClose.click(ev => this.onBtnCloseClick(ev));
		this.btnChangeEmail.click(ev => this.onBtnChangeEmailClick(ev));
		this.btnFb.click(ev => this.onBtnFbClick(ev));
		this.btnForgottenPwd.click(ev => this.onBtnForgottenPwdClick(ev));
		this.inputEmail.focus(ev => this.onInputEmailFocus(ev, this.inputEmail));
		this.userService.on(UserAuthStateEvent, ev => this.onUserAuth(ev));
		this.isWishlistLoginId = UserAuthResumeEnum.WISHLIST_SUBSCRIBE === this.model.loginId;
	}

	unbind() {
		super.unbind();
	}

	bindForm() {
		const formSettings = {
			rules: {
				email: {
					required: true,
					email: false,
					emailHtml5: true,
				},
				password: {
					required: true,
				},
			},
			messages: {
				email: {
					required: __("Bez zadání e-mailu to nepůjde"),
					emailHtml5: (...args) => this.getEmailMessage(...args),
				},
			},
			success: ($label, element) => {
				this.onValidationSuccess($label, element, true);
			},
			submitHandler: () => {
				const data = this.serializeArrayStep();
				if (data.email) {
					const suggestedEmailOutput = suggestEmail(data.email);
					if (suggestedEmailOutput) {
						data.email = suggestedEmailOutput;
					}
				}

				const step = this.getStep();
				const $spinner = $(".j-spinner", step.el);
				// Zde nesmi byt volani this.userService.setInternalTrackingParameters!!!
				// Tracking parametry pro UserService nastavuje widget nebo service, ktera spousti autentizaci
				$spinner.show();
				this.userService.doAuth(
					data,
					() => {
						$spinner.hide();
					},
					() => {
						$spinner.hide();
					},
				);
				return false;
			},
		};

		this.el.validate($.extend({}, this.validateSettings, formSettings));
	}

	end() {
		const step = this.getStep();

		// Dokoncit registraci pokud uzivatel zavre widget v kroku "email_confirm"
		// Dokonceni je dulezite kvuli spusteni callbacku navazanych na udalosti UserAuthStateEvent a UserLoginEvent
		if (step
			&& step.name === "email_confirm"
			&& this.userService.getState() === UserAuthStateEnum.EMAIL_CONFIRM_STATE
		) {
			this.userService.setState(UserAuthStateEnum.NEW_STATE);
			this.userService.doAuth();
		}
	}

	getCampaign() {
		return dry.str.undescorize(this.constructor.name);
	}

	getFancyboxSettings() {
		const fancyboxSettings = this.fancyboxSettings || {};
		return fancyboxSettings;
	}

	setFancyboxSettings(fancyboxSettings) {
		this.fancyboxSettings = fancyboxSettings;
	}

	onUserAuth(ev) {
		// Always show "login complete" step when:
		// - there is email video to show
		if (!this.isWishlistLoginId) {
			this.setShowLoginComplete(this.userService.isNewUser());
		}

		// distribute email
		if (this.userService.isLoggedIn() && !this.userService.isAnonymous()) {
			$(":input[name=\"email\"]", this.el).val(this.userService.getEmail());
		} else if (this.userService.getAuthData() && this.userService.getAuthData().email) {
			$(":input[name=\"email\"]", this.el).val(this.userService.getAuthData().email);
		}

		$(".j-spinner-fb", this.el).hide();
		const index = this.getStepIndex(ev.newState);
		if (index !== null) {
			this.goToStep(index, true);
		}
	}

	onBtnChangeEmailClick(ev) {
		ev.preventDefault();
		this.userService.setState(UserAuthStateEnum.EMAIL_STATE);
		this.goToStep(UserAuthStateEnum.EMAIL_STATE, true);
	}

	onBtnCloseClick(ev) {
		ev.preventDefault();
		if (this.fancyboxService.isOpened(this)) {
			this.fancyboxService.close();
		}
	}

	onBtnFbClick(ev) {
		ev.preventDefault();

		const step = this.getStep();
		let spinner = null;
		if (step) {
			spinner = $(".j-spinner-fb", step.el);
			spinner.show();
		}
		this.userService.doFacebookLogin((response) => {
			// Nechat spinner zobrazeny pri uspesne odpovedi FB, nasleduje auth.json request
			if (spinner && (!response || response.status !== "connected")) {
				spinner.hide();
			}
		});
	}

	onBtnForgottenPwdClick(ev) {
		ev.preventDefault();
		const data = this.serializeArrayStep();
		data.send = true;

		// Zde nesmi byt volani this.userService.setInternalTrackingParameters!!!
		// Tracking parametry pro UserService nastavuje widget nebo service, ktera spousti autentizaci
		this.userService.doAuth(data);
	}

	// eslint-disable-next-line class-methods-use-this
	onInputEmailFocus(ev, input) {
		// Nekteri BFU pry neumi napsat zavinac
		if (!$(input).val()) {
			$(input).val("@");
		}
		$(input).removeClass("text-center");
	}

	onStep(step, prevStep) {
		if (prevStep) {
			this.el.removeClass(`s-step-${prevStep.name}`);
		}
		this.el.addClass(`s-step-${step.name}`);
	}

	onStepCompleteIn(step) {
		this.showRegisteredEmail();
		if (this.userService.getEmail()) {
			$(".j-email", step.el).text(this.userService.getEmail());
		}
		const video = this.userService.getEmailVideo();
		if (!this.isWishlistLoginId) {
			this.showVideo(video);
		}
	}

	onStepForgottenPasswordIn(step) {
		const $email = $(":input[name=\"email\"]", step.el);
		const email = $email.val();

		if (email) {
			const $emailText = $(".j-email", step.el);
			$emailText.text(email || __("Váš e-mail"));
			const $emailProvider = $(".j-email-provider", step.el);

			const m = email.match(/@(.+)$/);
			this.btnGotoEmail.hide();

			if (m && this.emailProvidersSettings[m[1]]) {
				const provider = this.emailProvidersSettings[m[1]];
				$emailProvider.text(provider.name);
				this.btnGotoEmail.attr("href", provider.href);
				this.btnGotoEmail.show();
			}
		}
		return true;
	}


	showVideo(show) {
		if (show) {
			this.el.addClass("s-video");
			this.btnShowRegisterVideo.click(() => {
				$(".j-userregister-video-content").show();
				this.btnShowRegisterVideo.hide();
			});
			this.userService.setBiscuit(UserBiscuitEnum.EMAIL_VIDEO_BISCUIT, 1, 365);
			// Do not show flash messages during video playback
			this.flashMessagesWidget.doNotDisturb(5000);
		} else {
			this.el.removeClass("s-video");
		}
	}

	showRegisteredEmail() {
		if (this.userService.isLoggedIn() && !this.userService.isAnonymous()) {
			this.registeredEmailEl.text(this.userService.getEmail());
		}
	}
}
