import $ from "jquery";
import Widget from "../../../../Inlined/Widget";
import WishCreateEvent from "../../Event/WishCreateEvent";
import WishDeleteEvent from "../../Event/WishDeleteEvent";
import UserAuthStateEvent from "../../Event/UserAuthStateEvent";
import UserAuthStateEnum from "../../VO/UserAuthStateEnum";
import UserAuthResumeEnum from "../../VO/UserAuthResumeEnum";
import CaptchaCompleteEvent from "../../Event/CaptchaCompleteEvent";
import { trans } from "../../Service/I18NService";
/* global window */
/* global document */
/* global __ */
/* global dry */

export default class WishlistStickerWidget extends Widget {
	constructor(el, model, children, wishlistService, app, userService, flashMessageService, subscribeMessageService) {
		super(el, model, children);
		this.wishlistService = wishlistService;
		this.app = app;
		this.userService = userService;
		this.flashMessageService = flashMessageService;
		this.subscribeMessageService = subscribeMessageService;
		this.isDesktop = this.model.isDesktop;
	}

	bind() {
		super.bind();
		this.model.userAuthResume = UserAuthResumeEnum.WISHLIST_SUBSCRIBE;
		$.extend(this.model, this.parent.model);
		this.btn = $(".j-wishlist-btn", this.el);
		this.heartIcon = $(".j-heart", this.el);
		this.heartFullIcon = $(".j-heart-full", this.el);
		this.wishlistBtn = $(".j-wishlist-btn--bottom", this.el);
		this.wishlistBtnText = $(".j-wishlist-btn--bottom__text", this.wishlistBtn);
		this.refresh();

		if (!this.model.isInactive) {
			this.btn.click(ev => this.onBtnClick(ev));

			this.btn.tooltip({
				animation: false,
				html: true,
				placement: "bottom",
				template: `<div class="tooltip tooltip-large tooltip-danger tooltip-wishlist">
					<div class="tooltip-arrow"></div>
					<div class="tooltip-inner"></div>
				</div>`,
				title: () => {
					if (this.isFullHeart()) {
						return `
							<p class='strong m0'>${__("Odebrat z uložených")}</p>
							<p class='t-small m0'>${__("Kliknutím odeberete slevu z uložených")}</p>`;
					}
					return "";
				},
			});
		}

		this.onChangeFn = (ev) => {
			this.onChange(ev);
		};
		this.app.on(WishCreateEvent, this.onChangeFn);
		this.app.on(WishDeleteEvent, this.onChangeFn);
		this.model.userAuthResume = UserAuthResumeEnum.WISHLIST_SUBSCRIBE;
		this.model.waitingForAuth = false;
		this.userService.on(UserAuthStateEvent, ev => this.onUserAuth(ev));
		$(document.body).on("click", ".j-topicmailsubscribe-retry", this.onRetryFn = ev => this.onRetry(ev));
	}

	unbind() {
		super.unbind();
		this.app.off(WishCreateEvent, this.onChangeFn);
		this.app.off(WishDeleteEvent, this.onChangeFn);
	}

	onRetry(ev) {
		ev.preventDefault();
		this.setTopicMailing();
	}

	isFullHeart() {
		// Changing Heart state based on item occureance in concrete wishlist
		if (this.model.wishlistId || this.model.isWishlistDetailPosition) {
			return this.wishlistService.wishlistContainsItem(this.model.wishlistId, this.model.id, this.model.type);
		}
		return this.wishlistService.isInWishlist(this.model.id, this.model.type);
	}

	getOffset() {
		const offset = this.heartIcon.offset();
		return {
			left: offset.left,
			top: offset.top,
		};
	}

	onChange(ev) {
		if (ev.itemId === this.model.id && ev.itemType === this.model.type) {
			this.refresh();
		}
	}

	onBtnClick(ev) {
		ev.preventDefault();
		if (this.userService.isLoggedIn() && !this.userService.isAnonymous()) {
			this.addToWishlistModal();
		} else {
			this.onUnregistredAddToWishlist();
		}
	}

	addToWishlistModal() {
		const itemKey = this.model.type + this.model.id;
		const activeItemKeysValues = this.wishlistService.getActiveItemKeysValues();
		if (this.isFullHeart()) {
			if ((this.model.isWishlistDetailPosition && this.model.wishlistItemId)
				// if the item is only in one wishlist
				|| activeItemKeysValues.indexOf(itemKey) === activeItemKeysValues.lastIndexOf(itemKey)
			) {
				if (!this.model.wishlistItemId) {
					// lastWishlsitItemId should be the only one wishlistItemId because of the condition above
					this.model.wishlistItemId = this.wishlistService.getLastWishlistItemIdByKey(itemKey);
				}
				this.wishlistService.removeWishlistItem(
					this.model.wishlistItemId,
					this.model.id,
					this.model.type,
					() => {
						this.refresh();
					},
				);
			} else {
				this.wishlistService.openDialog(
					this.model.id,
					this.model.type,
					null,
					this,
					true,
				);
			}
		} else {
			this.wishlistService.openDialog(
				this.model.id,
				this.model.type,
				null,
				this,
			);
		}
	}

	refresh() {
		const wishlistBtnActiveClassName = "wishlist-btn--active";
		const wishlistBtnText = this.isDesktop ? __("Uložit na později") : __("Uložit");
		if (this.isFullHeart()) {
			this.wishlistBtn.addClass(wishlistBtnActiveClassName);
			this.wishlistBtnText.text(__("Uloženo"));
		} else {
			this.wishlistBtn.removeClass(wishlistBtnActiveClassName);
			this.wishlistBtnText.text(wishlistBtnText);
		}
	}

	onUserAuth(ev) {
		if (this.model.waitingForAuth && ev.newState === UserAuthStateEnum.COMPLETE_STATE) {
			this.model.waitingForAuth = false;
			this.setTopicMailing();
		}
	}

	onUserAuthResume() {
		this.model.waitingForAuth = false;
		this.setTopicMailing();
	}

	onUnregistredAddToWishlist() {
		this.userService.setLoginId(this.model.userAuthResume);
		this.userService.setInternalTrackingParameters({ s_campaign: this.getCampaign() });
		this.userService.setPayload({
			silentLogin: true,
		});
		this.userService.setInternalTrackingParameters(this.getInternalTrackingParameters());
		this.userService.doLogin();
		this.model.waitingForAuth = true;
		this.app.on(CaptchaCompleteEvent, () => this.addToWishlistModal());
	}

	setTopicMailing() {
		this.userService
			.setSettings({
				emailing_push_email_frequency: "medium",
				only_up: true,
			})
			.then(
				() => { this.addToWishlistModal(); },
				() => {
					const closeBtnStart = "<a class=\"j-topicmailsubscribe-retry j-close\" href=\"#\">";
					const closeBtnEnd = "</a>";
					this.flashMessageService.showMessage(
						trans(
							__("Došlo k chybě %closeBtnStart%Zkusit znovu%closeBtnEnd%"),
							{
								"%closeBtnStart%": closeBtnStart,
								"%closeBtnEnd%": closeBtnEnd,
							},
						),
						"warning",
						{
							modal: true,
						},
					);
				},
			);
	}

	getCampaign() {
		return dry.str.undescorize(this.constructor.name);
	}
}
