import $ from "jquery";
import Widget from "../../../Inlined/Widget";
import PageRedirectEvent from "../../FrontendBundle/Event/PageRedirectEvent";
/* global window */

export default class ListingOrderWidget extends Widget {
	constructor(el, model, children, app) {
		super(el, model, children, app);
		this.app = app;
	}
	bind() {
		super.bind();
		this.listingOrderSelect = $(".j-listing-order-select", this.el);
		this.listingOrderLink = $(".j-listing-order-link", this.el);
		this.listingOrderSelect.on("change", () => this.onListingOrderSelect());
	}

	onListingOrderSelect() {
		const optionValue = this.listingOrderSelect.val();

		if (optionValue) {
			this.app.dispatch(new PageRedirectEvent());
			window.location = optionValue;
		}
	}
}
