import $ from "jquery";
import Widget from "./../../../../Inlined/Widget";
import ReviewFilterChangeEvent from "../../Event/ReviewFilterChangeEvent";

export default class ReviewFilterWidget extends Widget {

	constructor(el, model, children, app) {
		super(el, model, children);

		this.app = app;
	}

	bind() {
		super.bind();

		this.orderSelect = $(".j-review-list-order", this.el);
		this.orderSelect.on("change", (ev) => this.onChange(ev));

		this.order = $(".j-order", this.el);
		this.orderTitle = $(".j-order-title", this.el);
		this.order.click((ev) => { this.onOrderClick(ev) });

		this.checkboxFilter = $(".j-review-list-filter", this.el);
		this.checkboxFilter.on("change", (ev) => this.onChange(ev));

		this.filter = $(".j-filter", this.el);
		this.activeFilters = $(".j-active-filters", this.el);

		this.resetFilters = $(".j-filter-reset", this.el);

		this.resetFilters.on("click", (ev) => this.onResetFilters(ev));

		this.filters = [];
		this.filterStrings = {
			"commentedOnly": "Jen hodnocení s textem",
			"withReactionOnly": "Jen s reakcemi"
		};
	}

	onChange(ev) {
		this.filters = [];
		var that = this;
		this.checkboxFilter.filter(":checked").each(function () {
			that.filters.push($(this).val());
		});

		if (this.filters.length > 0) {
			let filtersStrings = [];
			this.filters.forEach(((f) => {
				filtersStrings.push(this.filterStrings[f]);
			}));
			this.activeFilters.html("Seznam je omezen pomocí filtrů: <strong>" + filtersStrings.join(", ") + "</strong>");
			this.filter.show();
		} else {
			this.filter.hide();
		}

		this.app.dispatch(new ReviewFilterChangeEvent(this.filters, this.model.order));
	}

	onOrderClick(ev) {
		ev.preventDefault();

		var order = $(ev.currentTarget).data("order");
		var title = $(ev.currentTarget).text();

		this.orderTitle.text(title);
		this.model.order = order;

		this.onChange();
	}

	onResetFilters(ev) {
		ev.preventDefault();
		this.checkboxFilter.prop("checked", false);
		this.filter.hide();
		this.onChange();
	}

}