import $ from "jquery";
import Widget from "../../../../Inlined/Widget";

export default class ContentDemoWidget extends Widget {
	static GA_EVENT_LABEL = "Perex";

	bind() {
		super.bind();
		this.btnUndemo = $(".btn-undemo", this.el);
		this.visor = $(".contentdemo-visor", this.el);
		this.content = $(".contentdemo-content", this.el);
		this.contentLink = $("a", this.content);

		this.btnUndemo.click(ev => this.onBtnUndemoClick(ev));

		const contentHeight = this.content.height();
		const visorHeight = this.visor.height();

		const undemoHeight = 15;

		if ((contentHeight - undemoHeight) > visorHeight) {
			this.demo();
		} else {
			this.undemo();
		}
	}

	demo() {
		this.el
			.addClass("s-demo")
			.removeClass("s-undemo");
	}

	onBtnUndemoClick(ev) {
		ev.preventDefault();
		this.undemo();
	}

	undemo() {
		this.visor.css({
			"max-height": "none",
			height: "auto",
		});
		this.el
			.removeClass("s-demo")
			.addClass("s-undemo");
	}
}
