import $ from "jquery";
import Widget from "./../../../Inlined/Widget";

export default class TravelPromoBannerWidget extends Widget {
	constructor(el) {
		super(el);
		this.bannerLink = $(".j-banner-link", this.el);
		this.bannerBtn = $(".j-banner-btn", this.el);
	}

	bind() {
		super.bind();
	}
}
