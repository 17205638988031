import $ from "jquery";
import ResponsiveWidget from "../ResponsiveWidget";
import { lang } from "../../Locale";
import "../../../../../dry/str";
/* global __ */
/* global dry */

export default class AbstractFilterWidget extends ResponsiveWidget {
	constructor(el, model, children, app, filterService) {
		super(el, model, children);

		this.app = app;
		this.filterService = filterService;
	}

	bind() {
		super.bind();

		this.model.searchItems = null;
		this.model.searchQuery = null;

		this.lists = $(".j-lists", this.el);
		this.search = $(".j-search", this.el);
		this.searchResults = $(".j-search-results", this.el);

		this.search.on("keyup", ev => this.onSearch(ev));
	}

	filter() {
		this.form.filter();
	}

	getForm() {
		return this.form;
	}

	// Nazev widgetu
	getName() {
		return this.constructor.name.replace(/Widget$/, "");
	}

	// Nazev v URL
	getId() {
		return this.model.id;
	}

	onDropdownOpen() {
		this.search.focus();
	}

	onSearch(ev) {
		const query = $(ev.currentTarget).val();
		const cleanQuery = $.trim(dry.str.undiacritic(query.toLowerCase()));

		this.model.searchQuery = query;

		// Search items
		if (cleanQuery.length) {
			this.model.searchItems = this.model.items
				.filter((item) => {
					const value = item.title;
					const cleanValue = dry.str.undiacritic(value.toLowerCase());
					return cleanValue.indexOf(cleanQuery) >= 0;
				})
				.sort((itemA, itemB) => itemA.title.localeCompare(itemB.title, lang))
				.slice(0, 10);

			let searchResults;

			if (this.model.searchItems.length) {
				searchResults = `
					<div class="nav nav-links m0">
						<ul class="nav-listing unstyled m0">
							${this.model.searchItems.map(item => (`
								<li>
									<a 
										class="j-link"
										href="${item.url}"
										data-badge="${item.dealCount}" 
										data-val="${item.val}"
									>
										${item.title}
									</a>					
								</li>
							`)).join("")}
						</ul>
					</div>
				`;
			} else {
				searchResults = `
					<p class="m0">${__("Nic nenalezeno")}</p>
				`;
			}

			this.lists.hide();
			this.searchResults
				.show()
				.html(searchResults);

		// No search, show all items
		} else {
			this.model.searchItems = null;
			this.lists.show();
			this.searchResults.hide();
		}
	}

	// eslint-disable-next-line class-methods-use-this
	serialize() {
		return null;
	}
}
