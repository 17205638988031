import $ from "jquery";
import Widget from "../../../Inlined/Widget";

export default class MobileNavigationWidget extends Widget {
	bind() {
		super.bind();

		this.btnMenu = $(".j-menu", this.el);
		this.btnMenuIcon = $(".fi", this.btnMenu);
		this.barsIcon = $(".j-bars-icon", this.btnMenu);
		this.closeIcon = $(".j-close-icon", this.btnMenu);
		this.btnSearch = $(".j-search", this.el);
		this.menu = $(".f-menu-mobile");
		this.search = $(".f-search-mobile");

		this.model.menu = false;
		this.model.search = false;

		this.btnMenu.click(ev => this.onBtnMenuClick(ev));
		this.btnSearch.click(ev => this.onBtnSearchClick(ev));
	}

	onBtnMenuClick(ev) {
		ev.preventDefault();

		this.showMenu(!this.model.menu);
		this.showSearch(false);
	}

	onBtnSearchClick(ev) {
		ev.preventDefault();

		this.showMenu(false);
		this.showSearch(!this.model.search);
	}

	showMenu(show) {
		this.barsIcon.hide();
		this.closeIcon.hide();

		if (show) {
			this.menu.show();
			this.closeIcon.show();
		} else {
			this.menu.hide();
			this.barsIcon.show();
		}

		this.model.menu = show;
	}

	showSearch(show) {
		if (show) {
			this.search.show();
			$("input[name='q']", this.search).focus();
		} else {
			this.search.hide();
		}

		this.model.search = show;
	}
}
