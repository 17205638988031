import $ from "jquery";
import Widget from "../../../../Inlined/Widget";
import UserSubscribeWidget from "./UserSubscribeWidget";
import UserVerifiedEvent from "../../Event/UserVerifiedEvent";
import UserAuthStateEnum from "../../VO/UserAuthStateEnum";
/* global document */
/* global dry */

export default class UserSubscribeModalWidget extends Widget {
	constructor(el, model, children, app, userService) {
		super(el, model, children);

		this.app = app;
		this.userService = userService;
	}

	bind() {
		super.bind();

		this.userAuthResume = this.model.loginId;

		this.onUserVerifiedFn = ev => this.onUserVerified(ev);
		this.app.on(UserVerifiedEvent, this.onUserVerifiedFn);

		if (this.userService.isVerifiedHuman()) {
			this.onUserVerified();
		}

		setTimeout(
			() => this.open(),
			this.model.delay || 0,
		);
	}

	getCampaign() {
		return dry.str.undescorize(this.constructor.name);
	}

	onUserVerified() {
		if (this.userService.getUserId()) {
			return;
		}

		// Create anonymous user if no user exists
		this.userService.setState(UserAuthStateEnum.NEW_STATE);
		this.userService.setLoginId(null);
		this.userService.setPayload(null);
		this.userService.setInternalTrackingParameters(this.getInternalTrackingParameters());
		this.userService.doAuth();
	}

	open() {
		const authWidget = this.findByType(UserSubscribeWidget)[0] || null;

		if (authWidget && !this.userService.isRegistered()) {
			let fancyboxSettings = {
				baseClass: "s-overlay-black s-overlay-75",
				afterLoad: () => {
					// WARNING: Use scroll lock only on page load. It will reset scroll position after fancybox close.
					$(document.body).addClass("s-hideprices s-fancybox-overflow-scroll");
				},
				beforeClose: () => {
					$(document.body).removeClass("s-hideprices s-fancybox-overflow-scroll");
				},
				modal: true,
			};
			if (this.model.loginId === "user_subscribe_modal_vykupto") {
				// Make window modal-like but allow close button
				fancyboxSettings = {
					...fancyboxSettings,
					modal: false,
					// Disable keyboard navigation
					keyboard: false,
					// Disable click event handlers
					clickContent: false,
					clickSlide: false,
					clickOutside: false,
					dblclickContent: false,
					dblclickSlide: false,
					dblclickOutside: false,
					smallBtn: true,
				};
			}
			authWidget.setFancyboxSettings(fancyboxSettings);

			this.userService.setLoginId(this.userAuthResume);
			this.userService.setPayload(null);
			this.userService.setState(UserAuthStateEnum.EMAIL_STATE);
			this.userService.setInternalTrackingParameters(this.getInternalTrackingParameters());
			this.userService.setAuthWidget(this.userAuthResume, authWidget);
			this.userService.openAuthDialog();
		}
	}

	unbind() {
		super.unbind();

		this.app.off(UserVerifiedEvent, this.onUserVerifiedFn);
	}
}
