import $ from "jquery";
import Widget from "../../../Inlined/Widget";
import FixHeaderShowEvent from "../Event/FixHeaderShowEvent";
import FixHeaderHideEvent from "../Event/FixHeaderHideEvent";
import AbstractItemWidget from "./Item/AbstractItemWidget";
import ItemThumbWidget from "./Item/ItemThumbWidget";

export default class UserRetargetingWidget extends Widget {
	constructor(el, model, children, app, ajaxService, flashMessageService) {
		super(el, model, children);
		this.app = app;
		this.ajaxService = ajaxService;
		this.flashMessageService = flashMessageService;

		this.itemWidgetById = {};
		this.itemElementById = {};
		this.retargetingItemWidgetById = {};
		this.popoverClassById = {};
		this.loading = false;
		this.opened = null;
		this.openedClass = null;
		this.fixHeaderEl = 0;
	}

	bind() {
		super.bind();

		this.popover = $(".j-popover", this.el);
		this.popoverContent = $(".j-popover-content", this.el);

		this.app.on(FixHeaderShowEvent, ev => this.onFixHeaderShow(ev));
		this.app.on(FixHeaderHideEvent, ev => this.onFixHeaderHide(ev));
	}

	onFixHeaderShow(ev) {
		this.fixHeaderEl = ev.header.el;
	}

	onFixHeaderHide() {
		this.fixHeaderEl = null;
	}

	// eslint-disable-next-line class-methods-use-this
	getCampaign() {
		return "retargeting";
	}

	animateScroll(retargetingItemWidget, scrollTop) {
		const $htmlBody = $("html, body");
		$htmlBody.stop().animate({
			scrollTop: retargetingItemWidget.el.offset().top - (this.fixHeaderEl ? this.fixHeaderEl.height() : 0),
		}, {
			duration: 400,
			step: () => {
				const newScrollTop = retargetingItemWidget.el.offset().top
					- (this.fixHeaderEl ? this.fixHeaderEl.height() : 0);
				if (newScrollTop !== scrollTop) {
					$(this).stop();
					this.animateScroll(retargetingItemWidget, newScrollTop);
				}
			},
		});
	}

	open(retargetingItemWidget) {
		if (this.loading) {
			return;
		}

		this.animateScroll(retargetingItemWidget, this.fixHeaderEl ? this.fixHeaderEl.height() : 0);

		const id = retargetingItemWidget.getId();
		this.retargetingItemWidgetById[id] = retargetingItemWidget;

		if (!this.itemWidgetById[id]) {
			this.loading = true;
			this.ajaxService.ajax({ method: "GET", url: retargetingItemWidget.getPopoverUrl(), context: this })
				.done(data => this.onPopoverLoaded(id, data))
				.fail(() => this.onPopoverError(id));
		} else {
			this.toggle(id);
		}
	}

	onPopoverLoaded(id, data) {
		this.loading = false;
		if (!data.ok) {
			this.onPopoverError(id);
			return;
		}

		const renderedHtml = this.app.render(data.html, this);
		[this.itemElementById[id]] = renderedHtml.elements;
		[this.itemWidgetById[id]] = renderedHtml.widgets;
		this.popoverClassById[id] = data.class;

		this.children.push(this.itemWidgetById[id]);

		if (this.itemWidgetById[id] instanceof AbstractItemWidget) {
			this.itemWidgetById[id].setPreview(this.model.preview);
			this.itemWidgetById[id].setDelayedPreview(this.model.delayedPreview);
		}
		this.toggle(id);
	}


	onPopoverError() {
		this.loading = false;
		this.flashMessageService.showMessage("Nepodařilo se načíst nabídku. Zkuste prosím obnovit stránku.");
	}

	toggle(id) {
		if (id === this.opened) {
			this.opened = null;
			this.popover.hide();
		} else if (this.opened) {
			this.opened = null;
			this.popover.hide(() => this.toggle(id));
		} else {
			this.popover.removeClass("hide");
			if (this.openedClass) {
				this.popover.removeClass(this.openedClass);
			}
			this.openedClass = this.popoverClassById[id];
			this.popover.addClass(this.openedClass);

			this.popover.show();

			this.opened = id;
			this.popoverContent.children().detach();
			this.popoverContent.append(this.itemElementById[id]);
			const thumbs = this.findByType(ItemThumbWidget);
			thumbs.forEach(thumb => thumb.update());
		}
	}
}
