window.dry = window.dry || {};
dry.fn = dry.fn || {};

// A (possibly faster) way to get the current timestamp as an integer.
dry.fn.now = Date.now || function() { return new Date().getTime(); };

// Creates a version of the function that can only be called one time
dry.fn.once = function(func) {
	var memo;
	var times = 2;
	return function() {
		if (--times > 0) {
			memo = func.apply(this, arguments);
		}
		if (times <= 1) func = null;
		return memo;
	};
};

// Returns a function, that, when invoked, will only be triggered at most once
// during a given window of time. Normally, the throttled function will run
// as much as it can, without ever going more than once per `wait` duration;
// but if you'd like to disable the execution on the leading edge, pass
// `{leading: false}`. To disable execution on the trailing edge, ditto.
dry.fn.throttle = function(func, wait, options) {
	var context, args, result;
	var timeout = null;
	var previous = 0;
	options || (options = {});
	var later = function() {
		previous = options.leading === false ? 0 : dry.fn.now();
		timeout = null;
		result = func.apply(context, args);
		context = args = null;
	};
	return function() {
		var now = dry.fn.now();
		if (!previous && options.leading === false) previous = now;
		var remaining = wait - (now - previous);
		context = this;
		args = arguments;
		if (remaining <= 0) {
			clearTimeout(timeout);
			timeout = null;
			previous = now;
			result = func.apply(context, args);
			context = args = null;
		} else if (!timeout && options.trailing !== false) {
			timeout = setTimeout(later, remaining);
		}
		return result;
	};
};