import $ from "jquery";
import Widget from "./../../../../Inlined/Widget";

export default class UserDownloadDataWidget extends Widget {
	bind() {
		super.bind();

		this.submit = $(".j-submit", this.el);
		this.spinner = $(".j-spinner", this.el);
		this.el.on("submit", ev => this.onSubmit(ev));
	}

	onSubmit() {
		this.submit.prop("disabled", true);
		this.spinner.show();
		setTimeout(
			() => {
				this.submit.prop("disabled", false);
				this.spinner.hide();
			},
			3000,
		);
	}
}
