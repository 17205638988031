import $ from "jquery";
import { country } from "../../Locale";
import Widget from "./../../../../Inlined/Widget";
/* global google */
/* global window */
/* global __ */

export default class UserLocationDialogWidget extends Widget {
	constructor(
		el,
		model,
		children,
		mapService,
		validateSettings,
		flashMessageService,
		userService,
		userLocationService,
		fancyboxService,
	) {
		super(el, model, children);

		this.mapService = mapService;
		this.validateSettings = validateSettings;
		this.flashMessageService = flashMessageService;
		this.userService = userService;
		this.userLocationService = userLocationService;
		this.fancyboxService = fancyboxService;

		this.autocompleter = null;
		this.doRefresh = false;
		this.listeners = [];
		// Current address geocoded to lat/lng location
		this.location = null;
		this.locationsByAddress = {};
		this.geocoder = null;
		this.geocoderRequestsByAddress = {};
		this.validator = null;
	}

	bind() {
		super.bind();

		this.addControls = $(".j-add-controls", this.el);
		this.doneControls = $(".j-done-controls", this.el);
		this.address = $(".j-address", this.el);
		this.addressInputName = this.address.attr("name");
		this.btnAdd = $(".j-add", this.el);
		this.btnDone = $(".j-done", this.el);
		this.btnSave = $(".j-save", this.el);
		this.locations = $(".j-locations", this.el);
		this.spinner = $(".j-spinner", this.el);

		this.address.on("keyup change", ev => this.onAddressChange(ev));
		this.btnAdd.click(ev => this.onBtnAddClick(ev));
		this.btnDone.click(ev => this.onBtnDoneClick(ev));
		this.el.on("click", ".j-delete", ev => this.onBtnDeleteClick(ev));
		this.mapService
			.onLoad(() => this.onMapLoaded())
			.load();
		this.bindValidator();
	}

	bindAutocompleter() {
		this.autocompleter = new google.maps.places.Autocomplete(
			this.address[0],
			{
				componentRestrictions: {
					// two letter ISO 3166-1 country code
					// https://developers.google.com/maps/documentation/javascript/geocoding#ComponentFiltering
					// https://en.wikipedia.org/wiki/ISO_3166-1
					country,
				},
				types: ["geocode"],
			},
		);

		google.maps.event.addListener(this.autocompleter, "place_changed", () => this.onAutocompleterPlaceChange());

		this.geocoder = new google.maps.Geocoder();
	}

	bindValidator() {
		const formSettings = {
			ignore: "",
			messages: {
				address: {
					minlength: __("Vyplň adresu nebo PSČ"),
					required: __("Vyplň adresu nebo PSČ"),
				},
			},
			rules: {
				address: {
					minlength: 2,
					required: true,
				},
			},
			submitHandler: ev => this.onSubmit(ev),
		};
		const { validateSettings } = this;

		this.validator = this.el.validate({ ...validateSettings, ...formSettings });
	}

	end() {
		if (this.doRefresh) {
			// Reload without query to remove citySettings=true parameter that opens this modal
			window.location.replace(window.location.pathname);
		}
	}

	geocodeAddress(address) {
		let location;

		this.validator.showErrors({});

		// Do not geocode given address multiple times
		if (this.locationsByAddress[address]) {
			location = this.locationsByAddress[address];
			this.setLocation(location);
			this.save();
			return;
		}

		if (this.geocoder === null) {
			this.validator.showErrors({
				// TRANSLATORS: Chybová hláška při ukládání oblíbené lokality
				[this.addressInputName]: __("Nepodařilo se uložit adresu. Zkus obnovit stránku."),
			});
			return;
		}

		// Do not send multiple geocode requests at once
		if (this.geocoderRequestsByAddress[address]) {
			return;
		}

		this.geocoderRequestsByAddress[address] = this.geocoderRequestsByAddress[address] || 0;
		this.geocoderRequestsByAddress[address] += 1;

		this.setLoading(true);
		this.geocoder.geocode(
			{
				address: `${address},${country}`,
				region: country,
			},
			(geocoderResult, status) => {
				this.setLoading(false);

				if (status !== google.maps.GeocoderStatus.OK || !geocoderResult.length) {
					this.setLocation(null);
					// TRANSLATORS: Chybová hláška při ukládání oblíbené lokality
					this.validator.showErrors({ [this.addressInputName]: __("Neznámá adresa. Zkus ji změnit.") });
				} else {
					location = {
						address,
						lat: geocoderResult[0].geometry.location.lat(),
						lng: geocoderResult[0].geometry.location.lng(),
						geocoderResult: geocoderResult[0],
					};
					this.setLocation(location);
					this.save();
				}

				this.geocoderRequestsByAddress[address] = 0;
			},
		);
	}

	hide() {
		this.el.hide();
	}

	/**
	 * Update geocoded location on address input change
	 */
	onAddressChange() {
		const address = $.trim(this.address.val());

		// Current address match to current geocoded location
		if (this.location !== null && this.location.address === address) {
			return;
		// Set geocoded location by address from history
		} else if (this.locationsByAddress[address] !== undefined) {
			this.setLocation(this.locationsByAddress[address]);
		} else {
			// Remove current location
			this.setLocation(null);
		}

		if (address.length >= 2) {
			this.btnSave.removeClass("btn-info");
			this.btnSave.addClass("btn-success");
		} else {
			this.btnSave.removeClass("btn-success");
			this.btnSave.addClass("btn-info");
		}
	}

	onAutocompleterPlaceChange() {
		const place = this.autocompleter.getPlace();
		let location;

		// Autocompleter doesn't trigger change on input field when changing value
		this.address.change();
		this.el.validate().element(this.address);

		if (place && place.geometry && place.geometry.location) {
			location = {
				address: $.trim(this.address.val()),
				lat: place.geometry.location.lat(),
				lng: place.geometry.location.lng(),
				place,
			};
		} else {
			location = null;
		}

		this.setLocation(location);
	}

	onBtnAddClick(ev) {
		ev.preventDefault();

		this.addControls.removeClass("hide");
		$(ev.currentTarget).parent().hide();
		this.address.focus();
	}

	onBtnDoneClick(ev) {
		ev.preventDefault();
		this.listeners.forEach(listener => listener());

		if (this.model.widgetPosition === "modal") {
			this.fancyboxService.close();
		}
	}

	onBtnDeleteClick(ev) {
		ev.preventDefault();

		const $btn = $(ev.currentTarget);
		const id = $btn.data("id");
		const $spinner = $btn.prev(".j-location-spinner");

		$spinner.show();
		this.remove(
			id,
			() => $spinner.hide(),
		);
	}

	onMapLoaded() {
		this.bindAutocompleter();
	}

	onSubmit() {
		this.save();
		return false;
	}

	register(listener) {
		this.listeners.push(listener);
	}

	remove(userLocationId, onComplete) {
		this.userLocationService.remove(
			{
				id: userLocationId,
				widget_position: this.model.widgetPosition,
			},
			(response) => {
				onComplete();
				this.locations.html(response.html);
				this.doRefresh = true;

				if (this.userService.getLocationCount()) {
					this.doneControls.show();
				} else {
					this.doneControls.hide();
				}
			},
			(response) => {
				onComplete();
				this.flashMessageService.showMessage(
					response && response.message
						? response.message
						// TRANSLATORS: Chybová hláška při mazání oblíbené lokality
						: __("Nepodařilo se smazat lokalitu. Zkus to znovu."),
					"error",
				);
			},
		);
	}

	save() {
		let address;

		// No geocoded location, try to geocode current input address
		if (!this.location) {
			address = $.trim(this.address.val());
			this.geocodeAddress(address);
			return;
		}

		const trackingParameters = this.getInternalTrackingParameters();
		const data = {
			...trackingParameters,
			...this.location,
			geocoderResult: this.location.geocoderResult !== undefined
				? JSON.stringify(this.location.geocoderResult)
				: undefined,
			place: this.location.place !== undefined
				? JSON.stringify(this.location.place)
				: undefined,
			widget_position: this.model.widgetPosition,
		};

		this.validator.showErrors({});
		this.setLoading(true);

		this.userLocationService.add(
			data,
			(response) => {
				this.setLoading(false);
				this.doRefresh = true;

				// Update locations list
				this.locations.html(response.html);
				// Reset form
				this.address.val("");
				this.onAddressChange();

				if (this.userService.getLocationCount()) {
					this.doneControls.show();
				} else {
					this.doneControls.hide();
				}
			},
			(response) => {
				this.setLoading(false);
				this.validator.showErrors({
					[this.addressInputName]: response && response.message
						? response.message
						// TRANSLATORS: Chybová hláška při ukládání oblíbené lokality
						: __("Nepodařilo se uložit adresu. Zkus to znovu."),
				});
			},
		);
	}

	setLocation(location) {
		this.location = location;

		if (location !== null) {
			this.locationsByAddress[location.address] = location;
		}
	}

	setLoading(isLoading) {
		if (isLoading) {
			this.spinner.show();
			this.address.prop("disabled", true);
		} else {
			this.spinner.hide();
			this.address.prop("disabled", false);
		}
	}

	show() {
		this.el.show();
	}
}
