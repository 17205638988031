import $ from "jquery";
import Widget from "../../../../Inlined/Widget";
import CalendarWidget from "./CalendarWidget";
import CalendarSelectEvent from "../../Event/CalendarSelectEvent";
import RerenderMixin from "../RerenderMixin";
import ValidationMixin from "../ValidationMixin";
import UserBiscuitEnum from "../../VO/UserBiscuitEnum";
import "../../../../../dry/str";
/* global dry */
/* global window */

const DATE_FORMAT = "YYYYMMDD";
const REQUEST_DATE_FORMAT = "YYYY-MM-DD";
const BOOKING_COM_SERVER_ID = 6173;

const FORM_KEY = "form";
const BOOKING_TERM_CHECK = "bookingTermCheck";
const DONE_KEY = "done";
const CALENDAR_KEY = "calendar";
const HREF_KEY = "href";

export default class ItemReservationWidget extends Widget {
	static get BOOKING_COM_SERVER_ID() {
		return BOOKING_COM_SERVER_ID;
	}

	constructor(
		el,
		model,
		children,
		app,
		ajaxService,
		flashMessageService,
		hitService,
		userService,
		fancyboxService,
		validateSettings,
	) {
		super(el, model, children);

		this.app = app;
		this.ajaxService = ajaxService;
		this.flashMessageService = flashMessageService;
		this.hitService = hitService;
		this.rerender = RerenderMixin.rerender;
		this.userService = userService;
		this.fancyboxService = fancyboxService;
		this.validateSettings = validateSettings;

		this.bindValidation = ValidationMixin.bindValidation;
		this.getEmailMessage = ValidationMixin.getEmailMessage;
		this.onInputFix = ValidationMixin.onInputFix;
		this.onValidationSuccess = ValidationMixin.onValidationSuccess;
	}

	bind() {
		super.bind();

		this.model.step = CALENDAR_KEY;

		this.btnChangeTerm = $(".j-change-term", this.el);
		this.btnClose = $(".j-close", this.el);
		this.btnSubmit = $(".j-submit", this.el);
		this.btnUnselect = $(".j-unselect", this.el);
		this.btnVerifyTerm = $(".j-verify-term", this.el);
		if (!this.calendar) {
			this.calendar = this.app.findByType(CalendarWidget)[0] || null;
		}
		this.done = $(".j-done", this.el);
		this.booking = $(".j-booking", this.el);
		this.bookingLoader = $(".loader", this.booking);
		this.bookingSuccessResult = $(".checked-term-content.success", this.booking);
		this.bookingDateFrom = $(".date-from", this.bookingSuccessResult);
		this.bookingPrice = $(".price", this.bookingSuccessResult);
		this.bookingLink = $(".j-go-to-booking", this.bookingSuccessResult);
		this.bookingPhotos = $(".j-photos", this.bookingSuccessResult);
		this.bookingErrorResult = $(".checked-term-content.error", this.booking);
		this.bookingGoBackBtn = $(".j-go-back", this.bookingErrorResult);
		this.form = $(".j-form", this.el);
		this.arrival = $(".j-arrival", this.form);
		this.departure = $(".j-departure", this.form);
		this.noTerm = $(".j-no-term", this.el);
		this.selectedDescription = $(".j-selected-description", this.el);
		this.selectedFrom = $(".j-selected-from", this.el);
		this.selectedTerm = $(".j-selected-term", this.el);
		this.selectedTo = $(".j-selected-to", this.el);
		this.selectedDeal = $(".j-selected-deal", this.el);
		this.selectedVariant = $(".j-selected-variant", this.el);
		this.pageviewId = $(".j-pageview", this.el);
		this.variants = $(".j-variant", this.el);
		this.variantText = $(".j-vars-text", this.el);
		this.termPicker = $(".j-term-picker", this.el);

		this.pageviewId.val(this.hitService.getPageviewId() || "");

		this.btnChangeTerm.click(ev => this.onChangeTermClick(ev));
		this.onCalendarSelectFn = ev => this.onCalendarSelect(ev);
		this.app.on(CalendarSelectEvent, this.onCalendarSelectFn);
		this.btnClose.click(ev => this.onCloseClick(ev));
		this.btnVerifyTerm.click(ev => this.onVerifyTermClick(ev));
		this.btnUnselect.click(ev => this.onCalendarUnselectClick(ev));
		this.variants.click(ev => this.onVariantClick(ev));
		this.bookingGoBackBtn.click(ev => this.onBookingGoBackButtonClick(ev));
		this.bindForm();
		this.bindValidation();
	}

	unbind() {
		super.unbind();

		this.app.off(CalendarSelectEvent, this.onCalendarSelectFn);
	}

	bindForm() {
		const formSettings = {
			rules: {
				"term_request_form[buyerEmail]": {
					required: true,
					email: false,
					emailHtml5: true,
				},
			},
			messages: {
				"term_request_form[buyerEmail]": {
					required: "Bez e-mailu to nepůjde",
					emailHtml5: (...args) => this.getEmailMessage(...args),
				},
			},
			submitHandler: (form, ev) => this.onSubmit(ev),
			success: ($label, element) => {
				this.onValidationSuccess($label, element);
			},
		};

		this.form.validate($.extend({}, this.validateSettings, formSettings));
	}

	getCampaign() {
		return dry.str.undescorize(this.constructor.name);
	}

	goTo(step) {
		this.termPicker.hide();
		this.form.parent().hide();
		this.done.hide();
		this.booking.hide();

		switch (step) {
			case DONE_KEY:
				this.done.show();
				break;
			case FORM_KEY:
				this.form.parent().show();
				break;
			case BOOKING_TERM_CHECK:
				this.checkBookingComTermAvailability();
				break;
			default:
				this.termPicker.show();
		}
	}

	onCalendarSelect(ev) {
		const { selected } = ev;
		const variantCapacity = this.calendar
			? this.calendar.getSelectedVariantCapacity()
			: null;
		let selectedDate = null;

		this.selectedFrom.text(selected ? selected.dateFrom.format("D. M. YYYY") : "");
		this.selectedTo.text(selected ? selected.dateTo.format("D. M. YYYY") : "");
		this.selectedDeal.val(selected ? selected.dealId : "");
		this.selectedVariant.val(selected ? selected.variantId : "");
		this.arrival.val(selected ? selected.dateFrom.format("DD. MM. YYYY") : "");
		this.departure.val(selected ? selected.dateTo.format("DD. MM. YYYY") : "");

		if (selected) {
			this.selectedTerm.show();
			this.noTerm.hide();
			selectedDate = selected.dateFrom.format("YYYY-MM-DD");
		} else {
			this.selectedTerm.hide();
			this.noTerm.show();
		}

		if (variantCapacity
			&& selected
			&& selected.verified
			&& selectedDate
			&& variantCapacity.days[selectedDate]
			&& variantCapacity.days[selectedDate].status
		) {
			this.btnVerifyTerm.text("Rezervovat tento termín");
			this.selectedDescription
				.text("(termín je ověřený a je dostupný)")
				.show();
		} else {
			this.btnVerifyTerm.text("Ověřit dostupnost termínu");
			this.selectedDescription
				.text("")
				.hide();
		}
	}

	onBookingGoBackButtonClick(ev) {
		ev.preventDefault();
		this.bookingSuccessResult.hide();
		this.bookingErrorResult.hide();
		this.goTo();
	}

	onCloseClick(ev) {
		ev.preventDefault();
		this.fancyboxService.close();
		return this;
	}

	onVariantClick(ev) {
		ev.preventDefault();

		const dealId = $(ev.currentTarget).data("dealId") || null;
		const variantId = $(ev.currentTarget).data("variantId") || null;
		this.selectVariant(dealId, variantId);
	}

	checkBookingComTermAvailability() {
		this.booking.show();
		this.bookingLoader.show();
		const selected = this.calendar.getSelected();

		if (!selected) {
			return;
		}

		const deal = this.model.deals[selected.dealId];

		const btnText = this.btnSubmit.text();
		const url = this.model.ajaxBookingTermCheckUrl;

		this.btnSubmit
			.prop("disabled", true)
			.text("Odesílám...");

		const data = {
			...deal,
			...selected,
			dateFrom: selected.dateFrom.format(REQUEST_DATE_FORMAT),
			dateTo: selected.dateTo.format(REQUEST_DATE_FORMAT),

		};

		if (data.verified !== undefined) delete data.verified;

		this.ajaxService
			.ajax({
				data,
				method: "POST",
				url,
			})
			.then((response) => {
				if (response.ok) {
					if (response.result) {
						const {
							price,
							link,
							photos,
						} = response.result;

						this.bookingSuccessResult.show();
						// Fill date from wrapper
						this.bookingDateFrom.text(selected.dateFrom.format("D.M.YYYY"));
						// Fill price wrapper
						this.bookingPrice.text(price);
						// Add incoming link to btn
						this.bookingLink.attr(HREF_KEY, link);
						// Clean wrapper content
						this.bookingPhotos.html(null);
						if (photos && photos.length) {
							// eslint-disable-next-line no-restricted-syntax
							photos.forEach((photoLink) => {
								const img = $(`<img src="${photoLink}" alt="Fotografie pokoje" />`);
								this.bookingPhotos.append(img);
							});
						}
					} else {
						this.bookingErrorResult.show();
					}

					return $.Deferred().resolve();
				}

				return $.Deferred().reject();
			}).fail(() => {
				this.flashMessageService.showMessage("Nepodařilo se ověřit zvolený termín.");
			}).always(() => {
				this.btnSubmit
					.prop("disabled", false)
					.text(btnText);
				this.bookingLoader.hide();
			});
	}

	onSubmit(ev) {
		ev.preventDefault();

		const selected = this.calendar.getSelected();

		if (!selected) {
			return;
		}

		const deal = this.model.deals[selected.dealId];
		const btnText = this.btnSubmit.text();
		const url = this.model.ajaxUrlPattern.replace("{hash}", deal.hash);

		this.btnSubmit
			.prop("disabled", true)
			.text("Odesílám...");

		const formData = this.form.serializeObject();
		const trackingParameters = this.getInternalTrackingParameters();
		const data = {
			...formData,
			...trackingParameters,
		};

		this.ajaxService
			.ajax({
				data,
				method: "POST",
				url,
			})
			.then((response) => {
				if (response.ok) {
					this.rerender(response.html);
					if (response.saved) {
						this.goTo(DONE_KEY);
					} else {
						this.goTo(FORM_KEY);
					}
					// Vytvoren uzivatel
					if (response.shouldTrackRegistration) {
						this.hitService.trackRegistration();
					}

					return $.Deferred().resolve();
				}

				return $.Deferred().reject();
			}).fail(() => {
				this.flashMessageService.showMessage("Nepodařilo se odeslat rezervaci");
			}).always(() => {
				this.btnSubmit
					.prop("disabled", false)
					.text(btnText);
			});
	}

	onChangeTermClick(ev) {
		ev.preventDefault();
		this.goTo(CALENDAR_KEY);
	}

	onVerifyTermClick(ev) {
		ev.preventDefault();

		const selected = this.calendar.getSelected();

		if (!selected) {
			return;
		}

		this.calendar.logCapacityRequest(
			selected.dateFrom,
			selected.dealId,
			selected.variantId,
			this.model.clickedMerchantId,
			"date_confirmed",
		);

		const selectedDate = selected.dateFrom.format("YYYY-MM-DD");
		const deal = this.model.deals[selected.dealId];
		const variantCapacity = this.calendar
			? this.calendar.getSelectedVariantCapacity()
			: null;

		// VoucherFilterTypeEnum::EXACT
		this.userService.setBiscuit(
			UserBiscuitEnum.VOUCHER_EXPIRATION,
			`${selected.dateFrom.format("DD.MM.YYYY")}-${selected.dateTo.format("DD.MM.YYYY")}-exact`,
			30,
		);

		// Presmerovani na externi booking
		if (variantCapacity
			&& selected.verified
			&& selectedDate
			&& variantCapacity.days[selectedDate]
			&& variantCapacity.days[selectedDate].status
		) {
			const redirectUrl = this.model.reservationUrlPattern
				.replace("{from}", selected.dateFrom.format(DATE_FORMAT))
				.replace("{to}", selected.dateTo.format(DATE_FORMAT))
				.replace("{hash}", deal.hash)
				.replace("{variantId}", selected.variantId);
			window.open(redirectUrl, "_blank");
			this.fancyboxService.close();
			return;
		}

		// Different step handling for booking.com deals
		if (deal
			&& deal.serverId
			&& deal.serverId === BOOKING_COM_SERVER_ID
			&& deal.remoteHotelId
		) {
			this.goTo(BOOKING_TERM_CHECK);
		} else {
			// Booking si resime sami
			this.goTo(FORM_KEY);
		}
	}

	onCalendarUnselectClick(ev) {
		ev.preventDefault();

		if (this.calendar) {
			this.calendar.unselect();
			this.calendar.render();
		}
	}

	selectVariant(dealId, variantId) {
		const deal = dealId ? this.model.deals[dealId] : null;
		const variant = variantId ? this.model.variants[variantId] : null;

		if (variant) {
			this.variantText.text(variant.title);
		} else if (deal) {
			this.variantText.text(deal.title);
		} else {
			this.variantText.text("Všechny varianty");
		}

		if (this.calendar) {
			this.calendar.setVariant(dealId, variantId);
			this.calendar.render();
		}
	}
}
