import $ from "jquery";
import { lang } from "../Locale";
/* global grecaptcha */

const SITE_KEY = "6LeCNWoUAAAAAO069VTGw_w6t2eWBtEKKXMxBG36";

export default class CaptchaService {
	constructor(ajaxService) {
		this.ajaxService = ajaxService;
	}

	load() {
		this.loadingPromise = this.loadingPromise
			|| $.getScript(`https://www.google.com/recaptcha/api.js?render=explicit&hl=${lang}`);

		return this.loadingPromise;
	}

	render(container, parameters) {
		return this
			.load()
			.then(() => (
				$.Deferred((deferred) => {
					grecaptcha.ready(() => {
						const captchaId = grecaptcha.render(
							container,
							{
								sitekey: SITE_KEY,
								...parameters,
							},
						);
						deferred.resolve(captchaId);
					});
				})
			));
	}

	verify(token) {
		return this.ajaxService
			.ajax({
				data: {
					token,
				},
				method: "post",
				url: "/captcha/verify.json",
			})
			.then(response => response.isValid);
	}
}
