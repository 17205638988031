export default class FlashMessageService {
	static instance;

	constructor() {
		if (FlashMessageService.instance) return FlashMessageService.instance;

		this.listeners = [];
		FlashMessageService.instance = this;
	}

	register(listener) {
		this.listeners.push(listener);
	}

	showMessage(text, type = "info", options = {}) {
		this.listeners.forEach(listener => listener.onMessage(text, type, options));
	}
}
