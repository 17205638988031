import translations from "../../../../../locale/pl_PL/LC_MESSAGES/Skrz.javascript.json";

const lang = "pl";

// ISO_3166-1 country code
// (https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2)
const country = "PL";

const applicationName = "Sortado.pl";

const isLocationRequired = false;

const stellaDomain = "https://sortado.pl";
const pennyDomain = "https://my.sortado.pl";
const adminDomain = "https://admin.sortado.pl";
const datalogicDomain = "https://datalogic.sortado.pl";

export default lang;

export {
	country,
	isLocationRequired,
	lang,
	translations,
	applicationName,
	stellaDomain,
	pennyDomain,
	adminDomain,
	datalogicDomain,
};

