import $ from "jquery";
import Widget from "../../../Inlined/Widget";
import ListingWidget from "./ListingWidget";
import ListingLoadedEvent from "../Event/ListingLoadedEvent";

/* global window */
/* global document */

export default class BoxNavigationWidget extends Widget {
	constructor(el, model, children, app, bodyWidget) {
		super(el, model, children);
		this.app = app;
		this.bodyWidget = bodyWidget;
	}

	bind() {
		super.bind();

		this.body = $(document.body);
		this.boxLinks = $(".j-box-link", this.el);
		this.floatingContainer = $(".f-main").parent();
		this.listingWidget = this.bodyWidget.findByType(ListingWidget)[0] || null;
		this.marginTop = 20;

		this.app.on(ListingLoadedEvent, ev => this.onListingLoaded(ev));
		this.boxLinks.click(ev => this.onBoxLinkClick(ev));

		this.bindFloating();
	}

	bindFloating() {
		if (this.model.isFloating) {
			this.el.floating({
				// Kaslat na dynamickou vysku fixni hlavicky. Navigace pak pri skrolovani poskakuje.
				marginTop: 70,
				limit: () => this.floatingContainer.offset().top + this.floatingContainer.outerHeight(),
				zIndex: 30,
			});
		}

		// Bootstrap.scrollspy akceptuje pouze selector
		if (this.model.scrollSpyTarget) {
			this.body.scrollspy({ target: this.model.scrollSpyTarget });
		}
	}

	unbind() {
		super.unbind();
		$(window).off("scroll", this.onWindowScrollFn);
	}

	onBoxLinkClick(ev) {
		if (!this.listingWidget) {
			return;
		}
		const categoryId = Number.parseInt($(ev.currentTarget).data("category-id"), 10);
		const serverId = Number.parseInt($(ev.currentTarget).data("server-id"), 10);

		if (!Number.isNaN(categoryId)) {
			ev.preventDefault();
			this.listingWidget.scrollToCategoryBoxById(categoryId);
		} else if (!Number.isNaN(serverId)) {
			ev.preventDefault();
			this.listingWidget.scrollToServerBoxById(serverId);
		}
	}

	onListingLoaded() {
		if (this.model.scrollSpyTarget) {
			this.body.scrollspy("refresh");
		}
	}
}
