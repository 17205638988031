import $ from "jquery";
import ResponsiveWidget from "../ResponsiveWidget";
import ItemImageVO from "../../VO/ItemImageVO";

/* global Image */

export default class ItemThumbWidget extends ResponsiveWidget {
	bind() {
		super.bind();

		this.currentIndex = null;
		this.delay = 1500;
		// Prodleva prvni transition animace
		// (je kratsi, aby uzivatel zaznamenal zmenu obrazku co nejdrive)
		this.firstDelay = 150;
		this.playing = false;
		this.playT0 = null;
		this.playT1 = null;
		this.preloadCount = 1;
		this.thumbIndex = 0;

		this.btnNextThumb = $(".j-thumb-next", this.el);
		this.btnPrevThumb = $(".j-thumb-prev", this.el);
		this.pagination = $(".j-pagination", this.el);
		this.paginationItems = $("li", this.pagination);
		this.paginationImages = $("img", this.paginationItems);

		this.fetchImages();
		this.model.imageClassName = this.model.imageClassName || "";

		this.thumbImage0 = $(".j-img", this.el);
		if (this.isMultiImage()) {
			this.thumbImage1 = $(`<img class="${this.model.imageClassName}" src='' alt="">`)
				.hide()
				.insertAfter(this.thumbImage0);
		} else {
			this.thumbImage1 = null;
		}

		this.btnNextThumb.click(ev => this.onBtnNextClick(ev));
		this.btnPrevThumb.click(ev => this.onBtnPrevClick(ev));
		const widget = this;
		// eslint-disable-next-line func-names
		this.paginationItems.each(function () {
			const i = $(this).data("i");
			$("a", this).click(ev => widget.onPaginationBtnClick(ev, i));
		});
		this.pagination.mouseenter(ev => this.onPaginationMouseIn(ev));
		this.pagination.mouseover(ev => this.onPaginationMouseIn(ev));
	}

	// Spusti prvni iteraci rotace a zavola callback
	// Prvni iterace se spousti po uplynuti this.firstDelay
	firstCycle(cb = () => {}) {
		clearTimeout(this.playT0);
		clearInterval(this.playT1);

		this.playT0 = setTimeout(
			() => {
				this.next();
				cb.apply(this);
			},
			this.firstDelay,
		);
	}

	// Spusti rotaci obrazku
	// Dalsi iterace se spousti vzdy po uplynuti this.delay
	cycle() {
		clearTimeout(this.playT0);
		clearInterval(this.playT1);
		this.playT1 = setInterval(
			() => {
				this.next();
			},
			this.delay,
		);
	}

	// Vrati pole URL obrazku. Defaultne se URL berou z paginatoru (aby odkazy nebyly v HTML 2x)
	// Pokud neni paginator, vezme se URL z modelu
	fetchImages() {
		this.model.paginationImages = [];
		this.model.images = [];
		this.model.origImages = [];

		if (this.model.imageUrls) {
			this.model.images = this.model.imageUrls.map((url) => {
				const image = new ItemImageVO(url);
				image.tag = "slide";
				return image;
			});
		}
		if (this.model.origImageUrls) {
			this.model.origImages = this.model.origImageUrls.map((url) => {
				const image = new ItemImageVO(url);
				image.tag = "orig";
				return image;
			});
		}
		if (this.model.paginationImageUrls) {
			this.model.paginationImages = this.model.paginationImageUrls.map((url) => {
				const image = new ItemImageVO(url);
				image.tag = "pagination";
				return image;
			});
		}
	}

	getImage(tag = "slide", indexArg) {
		let index = indexArg;
		if (index === undefined) {
			index = this.currentIndex;
		}
		if (index === null) {
			return null;
		}
		const images = this.getImages(tag);
		return images[index] || null;
	}

	getImages(tag = "slide") {
		switch (tag) {
			case "orig":
				return this.model.origImages;
			case "pagination":
				return this.model.paginationImages;
			case "slide":
				return this.model.images;
			default:
				return [];
		}
	}

	getImageIndex(image) {
		const images = this.getImages(image.tag);
		const index = images.indexOf(image);
		return index !== -1 ? index : null;
	}

	getNextIndex(currentIndexArg) {
		let currentIndex = currentIndexArg;
		if (currentIndex === undefined) {
			// eslint-disable-next-line prefer-destructuring
			currentIndex = this.currentIndex;
		}
		if (currentIndex === null) {
			return 0;
		}
		const index = currentIndex + 1;
		return index >= this.model.images.length || index < 0 ? 0 : index;
	}

	getPrevIndex(currentIndexArg) {
		let currentIndex = currentIndexArg;
		if (currentIndex === undefined) {
			// eslint-disable-next-line prefer-destructuring
			currentIndex = this.currentIndex;
		}
		if (currentIndex === null) {
			return this.model.images.length - 1;
		}
		const index = currentIndex - 1;
		return index >= this.model.images.length || index < 0 ? this.model.images.length - 1 : index;
	}

	goTo(index) {
		const image = this.getImage("slide", index);

		// Obrazek nenalezen
		if (image === null) {
			return false;
		}

		// Obrazek jiz zobrazen a nacten
		if (index === this.currentIndex
			&& image.status === "complete"
			&& this[`thumbImage${this.thumbIndex}`].attr("src") === image.url
		) {
			return image;
		}

		this.currentIndex = index;
		this.paginationItems.removeClass("active");
		this.paginationItems.filter(`[data-i=${index}]`).addClass("active");

		this.setLoading(true);
		this.load(
			image,
			(img) => {
				if (this.currentIndex === index) {
					this.setLoading(false);
					if (this.isMultiImage()) {
						// Make <img> elements transition effect
						this.thumbIndex = +(!this.thumbIndex);
						this.thumbImage0.show().removeClass("active").addClass("inactive");
						this.thumbImage1.show().removeClass("active").addClass("inactive");
						this[`thumbImage${this.thumbIndex}`]
							.attr("src", img.url)
							.removeClass("inactive")
							.addClass("active");
					} else {
						this.thumbImage0.show().attr("src", img.url);
					}
				}
			},
			() => {
				if (this.currentIndex === index) {
					this.setLoading(false);
				}
			},
		);
		this.preload();
		return image;
	}

	isMultiImage() {
		return this.model.images.length >= 2;
	}

	next() {
		this.goTo(this.getNextIndex());
	}

	load(imageArg, onSuccess = () => {}, onError = () => {}) {
		const image = imageArg;
		if (image === null) {
			onError.apply(this, [image]);
			return false;
		}
		if (image.status === "complete") {
			// obrazek nacten jiz drive
			onSuccess.apply(this, [image]);
			return true;
		}

		if (image.status === "loading") {
			// obrazek se nacita => nedelat nic, cekat
			return true;
		}

		// obrazek nenacten nebo nacten s chybou => nacist obrazek
		image.entity = new Image();
		image.entity.onerror = () => {
			image.status = "error";
			onError.apply(this, [image]);
		};

		image.entity.onload = () => {
			image.status = "complete";
			image.width = image.entity.width;
			image.height = image.entity.height;
			onSuccess.apply(this, [image]);
			this.onImageLoaded(image);
		};

		image.status = "loading";
		image.entity.src = image.url;

		return true;
	}

	onImageLoaded(image) {
		const index = this.getImageIndex(image);

		if (index !== null && this.paginationImages[index] !== undefined && image.tag === "pagination") {
			this.paginationImages[index].src = image.url;
		}
	}

	onBtnNextClick(ev) {
		ev.preventDefault();
		this.next();
	}

	onBtnPrevClick(ev) {
		ev.preventDefault();
		this.prev();
	}

	onPaginationBtnClick(ev, i) {
		ev.preventDefault();
		this.goTo(i);
	}

	onPaginationMouseIn(ev) {
		// nesmi probublat k ItemWidget
		ev.stopPropagation();
		this.pause();
	}

	play() {
		if (this.playing) {
			return true;
		}
		if (!this.isMultiImage()) {
			return false;
		}

		this.playing = true;

		if (this.firstDelay !== null) {
			this.firstCycle(() => this.cycle());
		} else {
			this.cycle();
		}
		return true;
	}

	pause() {
		clearTimeout(this.playT0);
		clearInterval(this.playT1);

		this.playing = false;
	}

	preload(tag = "slide", preloadCountArg) {
		let preloadCount = preloadCountArg;
		if (preloadCount === undefined) {
			// eslint-disable-next-line prefer-destructuring
			preloadCount = this.preloadCount;
		}

		if (preloadCount < 1) {
			return false;
		}

		const index = this.currentIndex;

		for (let i = 0; i < preloadCount; i += 1) {
			const preloadIndex = this.getNextIndex(index + i);
			const preloadImage = this.getImage(tag, preloadIndex);
			this.load(preloadImage);
		}
		return true;
	}

	preloadAll(tag = "slide") {
		this.preload(tag, this.model.images.length);
	}

	prev() {
		this.goTo(this.getPrevIndex());
	}

	setLoading(loading) {
		if (loading) {
			this.el.addClass("loading");
		} else {
			this.el.removeClass("loading");
		}
	}
}
