import $ from "jquery";
import ItemThumbWidget from "./ItemThumbWidget";

export default class ItemPreviewThumbWidget extends ItemThumbWidget {
	bind() {
		super.bind();

		this.inner = $(".j-inner", this.el);

		if (!this.model.isDisabled) {
			setTimeout(() => this.bindSwipes(), 0);
			setTimeout(() => this.bindZoom(), 0);
		}
	}

	bindSwipes() {
		if (this.getDeviceSize() !== "xs" && this.getDeviceSize() !== "sm") {
			return;
		}

		this.inner.swipeleft(ev => this.onSwipe(ev));
		this.inner.swiperight(ev => this.onSwipe(ev));
	}

	bindZoom() {
		if (!this.model.origImages.length) {
			return;
		}

		// Zoomujeme jen na desktopu
		if (this.getDeviceSize() !== "md" && this.getDeviceSize() !== "lg") {
			return;
		}

		const placement = this.thumbImage1 ? this.thumbImage1 : this.thumbImage0;
		this.zoomImage = $(`<img class="${this.model.zoomImageClassName}' src=''>`)
			.css({
				maxHeight: "none",
				maxWidth: "none",
				position: "absolute",
			})
			.hide()
			.insertAfter(placement);

		this.inner.mouseenter(ev => this.onInnerMouseIn(ev));
		this.inner.mouseover(ev => this.onInnerMouseIn(ev));
		this.inner.mousemove(ev => this.onInnerMouseMove(ev));
		this.inner.mouseleave(ev => this.onInnerMouseOut(ev));
	}

	onInnerMouseIn(ev) {
		const origImage = this.getImage("orig");

		const position = this.inner.offset();
		this.model.thumbLeft = position.left;
		this.model.thumbTop = position.top;
		this.model.thumbHeight = this.inner.outerHeight();
		this.model.thumbWidth = this.inner.outerWidth();

		this.onMousePositionChange(ev);
		this.setLoading(true);
		this.load(
			origImage,
			(image) => {
				this.setLoading(false);
				this.zoomImage
					.attr("src", image.url)
					.height(image.height)
					.width(image.width);
				this.zoom(true);
			},
			() => {
				this.setLoading(false);
			},
		);
	}

	onInnerMouseMove(ev) {
		this.onMousePositionChange(ev);
		this.zoom(true);
	}

	onInnerMouseOut(ev) {
		this.onMousePositionChange(ev);
		this.zoom(false);
	}

	onSwipe(ev) {
		if (ev.type === "swiperight") {
			this.prev();
		} else {
			this.next();
		}
	}

	onMousePositionChange(ev) {
		const origImage = this.getImage("orig");

		if (!origImage || origImage.status !== "complete") {
			return;
		}

		const mouseX = ev.pageX - this.model.thumbLeft;
		const mouseY = ev.pageY - this.model.thumbTop;
		const normalizedMouseX = mouseX / this.model.thumbWidth;
		const normalizedMouseY = mouseY / this.model.thumbHeight;
		this.model.zoomImageX = -((origImage.width - this.model.thumbWidth) * normalizedMouseX);
		this.model.zoomImageY = -((origImage.height - this.model.thumbHeight) * normalizedMouseY);
	}

	zoom(enabled) {
		const origImage = this.getImage("orig");

		// Nenacteno nebo nemame pozici mysi
		if (!origImage
			|| origImage.status !== "complete"
			|| this.model.zoomImageX === undefined
			|| this.model.zoomImageY === undefined
		) {
			return;
		}

		// Smula, maly originalni obrazek
		if (origImage.height < this.model.thumbHeight || origImage.width < this.model.thumbWidth) {
			return;
		}

		if (!enabled) {
			this.zoomImage.hide();
			this.el.removeClass("zooming");
			return;
		}

		this.zoomImage
			.show()
			.css({
				left: `${this.model.zoomImageX}px`,
				top: `${this.model.zoomImageY}px`,
			});
		this.el.addClass("zooming");
	}
}
