class ContainerError extends Error {}

const ucfirst = s => (s.charAt(0).toUpperCase() + s.substring(1));

export default class Container {
	constructor(options = {}) {
		this.options = options;
		this.parameters = options;
		this.services = {};

		let prot = Object.getPrototypeOf(this);

		while (prot.constructor !== Object) {
			for (const methodName of Object.keys(prot)) {
				if (Object.prototype.hasOwnProperty.call(prot, methodName)) {
					if (methodName.substring(0, 3) === "get" && methodName.length > 3) {
						this[methodName] = (...args) => (this.get(...[methodName.substring(3)].concat(args)));
					} else if (methodName.substring(0, 6) === "create" && methodName.length > 6) {
						this[methodName] = (...args) => (this.create(...[methodName.substring(6)].concat(args)));
					}
				}
			}
			if (!prot.constructor.superclass) {
				break;
			}

			prot = prot.constructor.superclass.prototype;
		}
	}

	get(idArg) {
		const id = ucfirst(idArg);
		if (!this.services[id]) {
			const methodName = `get${id}`;
			if (!this.constructor.prototype[methodName]) {
				const msg = `Service '${id}' does not exist.`;
				throw new ContainerError(msg);
			}
			this.services[id] = this.constructor.prototype[methodName].call(this);
		}
		return this.services[id];
	}

	create(idArg, ...args) {
		const id = ucfirst(idArg);
		const methodName = `create${id}`;
		if (!this.constructor.prototype[methodName]) {
			const msg = `Factory '${id}' does not exist.`;
			throw new ContainerError(msg);
		}
		return this.constructor.prototype[methodName].apply(this, args);
	}

	set(idArg, service) {
		const id = ucfirst(idArg);
		this.services[id] = service;
		this[`get${id}`] = () => this.get(id);
	}
}
