import Widget from "../../../../Inlined/Widget";
import TopicDayLoginWidget from "./TopicDayLoginWidget";

export default class TopicDayRegistrationWidget extends Widget {
	userActive = false;

	constructor(el, model, children, app, fancyboxService, userService) {
		super(el, model, children);
		this.app = app;
		this.fancyboxService = fancyboxService;
		this.userService = userService;
	}

	bind() {
		super.bind();

		this.dialog = this.findByType(TopicDayLoginWidget)[0] || null;
		this.disabled = false;

		// Vypnuto, maily sbirame na vsech strankach pomoci UserSubscribeBoxWidget
		// $(document.body).one "mousemove touchstart scroll",  (ev) -> widget.onUserActivate(ev)
	}

	openDialog(force = false) {
		if (this.disabled) {
			return false;
		}

		if (this.dialog === null) {
			return false;
		}

		// Dialog nesmi premazat jiny otevreny dialog
		if (this.fancyboxService.getInstance() && !force) {
			return false;
		}

		return this.fancyboxService.openWidget(
			this.dialog,
			{
				clickOutside: false,
				clickSlide: false,
			},
		);
	}

	onUserActivate() {
		if (this.userActive) {
			return;
		}

		this.userActive = true;

		setTimeout(
			() => {
				if (!this.userService.isRegistered()) {
					this.openDialog();
					return;
				}

				this.userService.getSettings().then((userSettings) => {
				// Do not show registration when user has mailing enabled
					if (userSettings && userSettings.pushEmailFrequency !== "0") {
						return;
					}
					this.openDialog();
				});
			},
			30000,
		);
	}

	setDisabled(disabled) {
		this.disabled = disabled;
	}
}
