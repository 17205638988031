import $ from "jquery";
import Sortable from "sortablejs";
import Widget from "../../../../Inlined/Widget";

export default class WishlistListWidget extends Widget {
	constructor(el, model, children, wishlistService) {
		super(el, model, children);
		this.wishlistService = wishlistService;
	}

	bind() {
		super.bind();
		const self = this;

		if (typeof Sortable !== "undefined" && !this.model.isMobile) {
			// Sortable
			this.wishlistList = $(".j-wishlist-list", this.el);
			if (this.wishlistList.length > 0) {
				for (let i = 0; i < this.wishlistList.length; i += 1) {
					Sortable.create(this.wishlistList.get(i), {
						group: `wishlists${i}`,
						draggable: ".j-wishlist",
						onUpdate: (ev) => {
							self.onListUpdate(ev, self);
						},
						ghostClass: "ghost",
					});
				}
			}
		}

		this.el.on("click", ".j-create", () => {
			this.wishlistService.openDialog();
		});
	}

	onListUpdate(ev, self) {
		const wishlistOrder = [];
		const wishlists = $(".j-wishlist", self.el);
		$.each(wishlists, (index, element) => {
			wishlistOrder.push($(element).data("userWishlistId"));
		});
		if (wishlistOrder) {
			this.wishlistService.updateUserWishlistOrder(wishlistOrder);
		}
	}
}
