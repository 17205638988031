import $ from "jquery";
import Widget from "../../../Inlined/Widget";
import PageRedirectEvent from "../../FrontendBundle/Event/PageRedirectEvent";

export default class ActiveFiltersWidget extends Widget {
	constructor(el, model, children, app) {
		super(el, model, children, app);
		this.app = app;
		this.filterLink = $(".j-filter-link", this.el);
		this.filterLink.on("click", ev => this.onFilterClick(ev));
	}

	bind() {
		super.bind();
	}

	onFilterClick() {
		this.app.dispatch(new PageRedirectEvent());
	}
}
