import Widget from "./../../../Inlined/Widget";
import HeaderSlideinEvent from "../Event/HeaderSlideinEvent";
import HeaderSlideoutEvent from "../Event/HeaderSlideoutEvent";
import FixHeaderShowEvent from "../Event/FixHeaderShowEvent";
import FixHeaderHideEvent from "../Event/FixHeaderHideEvent";

export default class FixHeaderWidget extends Widget {
	constructor(el, model, children, app) {
		super(el, model, children);
		this.app = app;
	}

	bind() {
		super.bind();
		// sledujeme slidein/out udalosti na staticke hlavicce
		this.app.on(HeaderSlideinEvent, ev => this.onHeaderSlidein(ev));
		this.app.on(HeaderSlideoutEvent, ev => this.onHeaderSlideout(ev));
	}

	hide() {
		this.el.stop().removeClass("fixheader--visible");
		this.app.dispatch(new FixHeaderHideEvent(this));
	}

	onHeaderSlidein() {
		this.hide();
	}

	onHeaderSlideout() {
		this.show();
	}

	show() {
		this.el.addClass("fixheader--visible");
		this.app.dispatch(new FixHeaderShowEvent(this));
	}
}
