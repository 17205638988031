import Widget from '../../../Inlined/Widget';
import UserBiscuitEnum from '../VO/UserBiscuitEnum';
import { DOMEvalWithAsyncDefer } from '../utils';
/* global window */
/* global Sentry */

export default class ChatWidget extends Widget {
	constructor(el, model, children, userService) {
		super(el, model, children);

		this.userService = userService;
	}

	bind() {
		super.bind();

		this.model.loaded = false;

		if (this.model.start) {
			this.load();
		} else {
			setTimeout(() => { this.load(); }, this.model.delay * 1000);
		}
	}

	load() {
		if (this.model.loaded) {
			return;
		}

		try {
			const liveChatInitCode = 'window.__lc = window.__lc || {};'
				+ 'window.__lc.license = 12875460;'
				+ ';(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,'
				+ '_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},'
				+ 'off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("'
				+ '[LiveChatWidget] You can\'t use getters before load.");return i(["get",c.call(arguments)])},'
				+ 'call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");'
				+ 'n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",'
				+ 't.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget'
				+ '||e}(window,document,[].slice))';
			// LiveChatWidget initialization
			DOMEvalWithAsyncDefer(liveChatInitCode);

			// init open / close GA events handling
			if (window && window.LiveChatWidget) {
				this.setLoaded();
				window.LiveChatWidget.on('visibility_changed', this.onVisibilityChanged.bind(this));
			}
		} catch (e) {
			Sentry.captureException(e);
		}
	}

	onVisibilityChanged(data) {
		switch (data.visibility) {
			case 'maximized':
				this.onOpened();
				break;
			default:
				// Possible 'hidden / other' state -> skip
				break;
		}
	}

	setLoaded() {
		this.model.loaded = true;
	}

	isLoaded() {
		return this.model.loaded;
	}

	onOpened() {
		const biscuitDateExpired = new Date();
		biscuitDateExpired.setHours(biscuitDateExpired.getHours() + 8);
		this.userService.setBiscuit(UserBiscuitEnum.CHAT, 1, biscuitDateExpired);
	}
}
