export default () => new Promise((resolve) => {
	require.ensure(
		[],
		() => {
			/* eslint-disable global-require */
			const React = require("react");
			const ReactDOM = require("react-dom");

			// components
			const MobileTypeNumber = require("./ReactComponent/MobileNumberType").default;
			const ChildrenCountType = require("./ReactComponent/ChildrenCountType").default;

			// Terms table components
			const TermsTable = require("./ReactComponent/TermsTable/TermsTable").default;

			// Terms table components
			const ZonesFilter = require("./ReactComponent/ZonesFilter/ZonesFilter").default;

			// Terms table components
			const CalendarFilter = require("./ReactComponent/Calendar/CalendarFilter").default;

			resolve({
				React,
				ReactDOM,
				MobileTypeNumber,
				ChildrenCountType,
				TermsTable,
				ZonesFilter,
				CalendarFilter,
			});
			/* eslint-enable */
		},
		"bundle/react-components",
	);
});
