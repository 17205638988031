import $ from "jquery";
import moment from "moment";
import AbstractItemWidget from "./AbstractItemWidget";
import MapWidget from "../MapWidget";
import UserBiscuitEnum from "../../VO/UserBiscuitEnum";
import ItemShareWidget from "./ItemShareWidget";
import LeadgenTermSelectEvent from "../../Event/LeadgenTermSelectEvent";
/* global window */
/* global __ */

const STEP_DEAL_DETAIL = "dealDetail";
const STEP_LEADGEN_FORM = "leadgenForm";
const STEP_LEADGEN_DONE = "leadgenDone";

const DATE_FORMAT_TEXT = "D. M. YYYY";
const DATE_FORMAT_VALUE = "DD. MM. YYYY";

export default class ItemPreviewWidget extends AbstractItemWidget {
	static MODAL_WIDGET_POSITION = "modal";

	static DETAIL_WIDGET_POSITION = "detail";

	constructor(
		el,
		model,
		children,
		app,
		hitService,
		dealService,
		itemWatchdogService,
		membersService,
		ajaxService,
		itemPreviewService,
		mobileDetectService,
		flashMessageService,
		itemShareService,
		reservationService,
		userService,
		scrollService,
		filterService
	) {
		super(
			el,
			model,
			children,
			app,
			hitService,
			dealService,
			itemWatchdogService,
			membersService,
			ajaxService,
			itemPreviewService,
			mobileDetectService,
			flashMessageService,
			itemShareService,
			reservationService,
			filterService,
		);
		this.userService = userService;
		this.scrollService = scrollService;
	}

	bind() {
		// Musi byt false, jinak budou prokliky smerovat opet na deal preview!
		this.model.preview = false;

		super.bind();

		this.address = $(".j-address", this.el);
		this.btnExpand = $(".j-btn-expand", this.el);
		this.btnMap = $(".j-btn-map", this.el);
		this.btnVariantsText = $(".j-btn-vars-text", this.el);
		this.btnDealVariant = $(".j-btn-deal-var", this.el);
		this.btnVariant = $(".j-btn-var", this.el);
		this.gps = $(".j-gps", this.el);
		this.gpsShow = $(".j-gps-show", this.el);
		this.hiddenTags = $(".j-tag.bye", this.el);
		this.map = this.findByType(MapWidget)[0] || null;
		this.mapContainer = $(".j-map", this.el);
		this.merchantWeb = $(".j-merchant-web", this.el);
		this.price = $(".j-price", this.el);
		this.priceOrig = $(".j-priceorig", this.el);
		this.share = this.findByType(ItemShareWidget)[0] || null;
		this.variantsDropdown = $(".j-vars-dropdown", this.el);
		this.variantsTables = $(".j-var-table", this.el);
		this.wishlistBtn = $(".j-wishlist", this.el);

		if (this.address.hasClass("hide")) {
			this.address.removeClass("hide").hide();
		}
		this.variantsDropdown
			.tooltip({
				placement: "top",
				trigger: "manual",
			});

		this.btnExpand.click(ev => this.btnExpandClick(ev));
		this.gpsShow.click(ev => this.btnGpsShowClick(ev));
		this.btnMap.click(ev => this.onBtnMapClick(ev));
		this.btnDealVariant.click(ev => this.onBtnDealVariantClick(ev));
		this.btnVariant.click(ev => this.onBtnVariantClick(ev));
		this.variantsDropdown.click(() => this.variantsDropdown.tooltip("hide"));
		this.wishlistBtn.click(ev => this.onWishlistClick(ev));
		this.el.mouseenter(ev => this.onMouseIn(ev));
		this.el.mouseover(ev => this.onMouseIn(ev));
		this.el.mouseleave(ev => this.onMouseOut(ev));

		this.app.on(LeadgenTermSelectEvent, ev => this.onLeadgenTermSelect(ev));

		if (this.model.position === ItemPreviewWidget.DETAIL_WIDGET_POSITION) {
			this.showVariantTooltip();
		}

		if (this.model.isLeadgen) {
			this.leadgenBtn = $(".j-leadgen-btn", this.el);
			this.dealDetail = $(".j-preview-detail", this.el);

			this.leadgenForm = $(".j-leadgen-form", this.el);
			this.selectedFrom = $(".j-selected-from", this.el);
			this.selectedTo = $(".j-selected-to", this.el);
			this.selectedDeal = $(".j-selected-deal", this.el);
			this.arrival = $(".j-arrival", this.form);
			this.departure = $(".j-departure", this.form);
			this.priceFinal = $(".j-leadgen-deal-price", this.el);

			this.leadgenDone = $(".j-leadgen-done", this.el);
			this.leadgen = $(".j-leadgen", this.el);
			this.leadgenChangeTerm = $(".j-change-term", this.el);
			this.leadgenChangeTerm.click(ev => this.onLeadgenChangeTermClick(ev, true));

			this.leadgenBackToDetail = $(".j-back-to-detail", this.el);
			this.leadgenBackToDetail.click(ev => this.onLeadgenChangeTermClick(ev, false));

			this.leadgenBtn.click(ev => this.onLeadgenCTABtnClick(ev));
			this.goTo(STEP_DEAL_DETAIL, false);
		}
	}

	onLeadgenCTABtnClick(ev) {
		ev.preventDefault();
		this.onLeadgenTermSelect(new LeadgenTermSelectEvent({
			start: this.model.termDateFrom,
			end: this.model.termDateTo,
			dealId: this.model.id,
			priceFinalFormatted: this.model.priceFinalFormatted,
		}));
	}

	onLeadgenTermSelect(ev) {
		const dateFrom = ev.term.start ? moment(ev.term.start) : null;
		const dateTo = ev.term.end ? moment(ev.term.end) : null;

		const priceFinalFormatted = ev.term.priceFinalFormatted ? ev.term.priceFinalFormatted : null;

		this.selectedFrom.text(dateFrom ? dateFrom.format(DATE_FORMAT_TEXT) : "");
		this.selectedTo.text(dateTo ? dateTo.format(DATE_FORMAT_TEXT) : "");
		this.selectedDeal.val(ev.term.dealId ? ev.term.dealId : this.model.id);
		this.arrival.val(dateFrom ? dateFrom.format(DATE_FORMAT_VALUE) : "");
		this.departure.val(dateTo ? dateTo.format(DATE_FORMAT_VALUE) : "");

		if (priceFinalFormatted) {
			this.priceFinal.text(priceFinalFormatted);
		}

		this.goTo(STEP_LEADGEN_FORM);
	}

	onLeadgenChangeTermClick(ev, scroll) {
		ev.preventDefault();
		this.goTo(STEP_DEAL_DETAIL, scroll);
	}

	goTo(step, scroll = true) {
		// Steps are only used when itempreview is used as leadgen form
		if (!this.model.isLeadgen) {
			return;
		}
		this.leadgenForm.hide();
		this.leadgenDone.hide();
		this.leadgen.hide();

		switch (step) {
			case STEP_LEADGEN_DONE:
				this.leadgen.show();
				this.leadgenDone.show();
				break;
			case STEP_LEADGEN_FORM:
				this.leadgen.show();
				this.leadgenForm.show();
				this.scrollService.scrollToElement(this.leadgenForm);
				break;
			default:
				this.dealDetail.show();
				if (scroll) {
					this.scrollService.scrollToElement($("#terms-table", this.el));
				}
		}
	}

	begin() {
		// Zobrazit tooltip s timeoutem, protoze metoda begin je volana jeste pred zobrazenim Fancyboxu
		setTimeout(() => this.showVariantTooltip(), 0);
	}

	getPageTitle() {
		return this.model.pageTitle;
	}

	btnExpandClick(ev) {
		ev.preventDefault();
		this.el.removeClass("s-collapsed");
	}

	onBeforeGo(ev) {
		super.onBeforeGo(ev);
	}

	btnGpsShowClick(ev) {
		ev.preventDefault();
		this.gps.removeClass("hide");
		$(ev.currentTarget).parent().hide();
	}

	onBtnMapClick(ev) {
		ev.preventDefault();

		this.mapContainer.show();
		this.address.show();

		const y = this.mapContainer.offset().top - 100;
		$("body,html").animate({ scrollTop: y }, 100);

		if (this.map) {
			// Zobrazit mapu a odskrolovat na dany marker
			const mapMarkerIndex = $(ev.currentTarget).data("marker-index");
			this.map.show();
			this.map.panTo(mapMarkerIndex);
		}
	}

	onBtnTagsHideClick(btn, ev) {
		super.onBtnTagsHideClick(btn, ev);
		this.hiddenTags.addClass("bye");
	}

	onBtnTagsShowClick(btn, ev) {
		super.onBtnTagsShowClick(btn, ev);
		this.hiddenTags.removeClass("bye");
	}

	onBtnDealVariantClick(ev) {
		ev.preventDefault();

		const $variantDeal = $(ev.currentTarget);
		const hash = $variantDeal.data("hash");
		const $spinner = $(".j-spinner", this.btnVariantsText);

		if (this.model.position === ItemPreviewWidget.MODAL_WIDGET_POSITION) {
			// On deal modal use deal preview feature (with history push state)
			this.itemPreviewService.preview(hash, true);
		} else {
			// On deal detail use widget replace (with history replace state)
			// Cannot make history push because current widget will be unbinded
			this.parent.model.ajaxReloadUrl = this.model.ajaxDealPreviewUrl.replace("{current}", hash);
			this.parent.reload().then(
				(widget) => {
					$spinner.hide();
					window.history.replaceState(
						undefined,
						widget.getPageTitle(),
						this.itemPreviewService.createUrl(widget.getSlug(), widget.getHash()),
					);
				},
				() => {
					$spinner.hide();
					this.flashMessageService.showMessage(__("Při načítání došlo k chybě"), "error");
				},
			);
			$spinner.show();
		}
	}

	onBtnVariantClick(ev) {
		ev.preventDefault();

		const $variant = $(ev.currentTarget);
		const variantId = $variant.data("variant");
		const title = $variant.data("title");
		const price = $variant.data("price");
		const priceOrig = $variant.data("priceorig");

		this.model.currentVariantId = variantId;

		this.btnVariantsText.text(title);
		this.price.text(price);
		this.priceOrig.text(priceOrig);
		if (priceOrig) {
			this.priceOrig.show();
		} else {
			this.priceOrig.hide();
		}
		const variantTable = this.variantsTables.filter("[data-variant='#{variantId}']");
		this.variantsTables.hide();
		variantTable.show();

		// Probliknout zmenu obsahu
		clearTimeout(this.variantTableFlashT1);
		clearTimeout(this.variantTableFlashT2);
		$(".warning", this.variantsTables).removeClass("warning");
		this.price.removeClass("highlight-warning");
		this.priceOrig.removeClass("highlight-warning");

		this.variantTableFlashT1 = setTimeout(() => {
			$("tr", variantTable).addClass("warning");
			this.price.addClass("highlight-warning");
			this.priceOrig.addClass("highlight-warning");
		}, 150);
		this.variantTableFlashT2 = setTimeout(() => {
			$(".warning", variantTable).removeClass("warning");
			this.price.removeClass("highlight-warning");
			this.priceOrig.removeClass("highlight-warning");
		}, 800);

		// Scrolovat na varianty (dropdown a tabulka), pokud jsou mimo viewport
		setTimeout(() => {
			const variantsDropdownRect = this.variantsDropdown[0].getBoundingClientRect();
			const variantTableRect = variantTable[0].getBoundingClientRect();
			if (variantsDropdownRect.top < 0 || variantTableRect.bottom > $(window).height()) {
				const variantsDropdownTop = this.variantsDropdown.offset().top;
				$(window).scrollTop(variantsDropdownTop - 20);
			}
		}, 0);
	}

	onMouseIn() {
		this.attachHitParams();
	}

	// eslint-disable-next-line
	onMouseOut() {
	}

	onShareButtonClick(btn, ev) {
		ev.preventDefault();

		if (this.share && this.share.show) {
			this.share.show();
		}
	}

	onWishlistClick(ev) {
		ev.preventDefault();
		$(".j-wishlist-well", this.el).slideDown();
	}

	showVariantTooltip() {
		if (!this.model.showVariantTooltip) {
			return;
		}

		if (!this.variantsDropdown.length) {
			return;
		}

		this.variantsDropdown.tooltip("show");
		const $tooltip = this.variantsDropdown.data("tooltip") ? this.variantsDropdown.data("tooltip").$tip : null;
		if ($tooltip) {
			$tooltip.addClass("animated bounce tooltip-warning strong nowrap");
		}

		// Zobrazit tooltip pouze 1x denne
		this.userService.setBiscuit(UserBiscuitEnum.ITEM_VARIANT_TOOLTIP_BISCUIT, 1, 1);
	}
}
