import $ from "jquery";
import Widget from "../../../../Inlined/Widget";
import ValidationMixin from "../ValidationMixin";
import UserLoginEvent from "../../Event/UserLoginEvent";
import UserAuthStateEvent from "../../Event/UserAuthStateEvent";
import UserAuthStateEnum from "../../VO/UserAuthStateEnum";
import suggestEmail from "./SuggestEmail";

/* global dry */
/* global window */

export default class UserLoginWidget extends Widget {
	constructor(el, model, children, userService, flashMessageService, facebookService, validateSettings) {
		super(el, model, children);
		this.userService = userService;
		this.flashMessageService = flashMessageService;
		this.facebookService = facebookService;
		this.validateSettings = validateSettings;

		this.bindValidation = ValidationMixin.bindValidation;
		this.getEmailMessage = ValidationMixin.getEmailMessage;
		this.onInputFix = ValidationMixin.onInputFix;
		this.onValidationSuccess = ValidationMixin.onValidationSuccess;
	}

	bind() {
		super.bind();

		this.btnSubmit = $(":submit", this.el);
		this.spinnerSubmit = $(".j-spinner", this.btnSubmit);
		this.btnFacebook = $(".j-btn-fb", this.el);
		this.spinnerFacebook = $(".j-spinner", this.btnFacebook);
		this.emailInput = $(".j-email", this.el);
		this.passwordInput = $(".j-password", this.el);

		this.btnFacebook.click(ev => this.onBtnFacebookClick(ev));
		this.bindForm();
		this.bindValidation();

		this.userService.on(UserLoginEvent, ev => this.onUserLogin(ev));
		this.userService.on(UserAuthStateEvent, ev => this.onUserAuthState(ev));
	}

	bindForm() {
		const formSettings = {
			rules: {
				email: {
					required: true,
					email: false,
					emailHtml5: true,
				},
				password: {
					required: true,
				},
			},
			messages: {
				email: {
					required: "Bez zadání emailu tě nepřihlásíme.",
					emailHtml5: (...args) => this.getEmailMessage(...args),
				},
				password: {
					required: "Je nutné zadat heslo. Neznáš ho? Řešení je níže.",
				},
			},
			submitHandler: (form, ev) => {
				this.onSubmit(ev);
			},
			success: ($label, element) => {
				this.onValidationSuccess($label, element);
			},
		};
		this.el.validate($.extend({}, this.validateSettings, formSettings));
	}

	getCampaign() {
		return dry.str.undescorize(this.constructor.name);
	}

	onUserLogin(ev) {
		if (!ev.anonymous) {
			window.location = this.model.redirectUrl;
		}
	}

	onUserAuthState(ev) {
		if (ev.newState === UserAuthStateEnum.WRONG_PASSWORD_STATE) {
			this.flashMessageService.showMessage("Zadané heslo je bohužel špatně, zkus to ještě jednou.", "error");
		} else if (ev.newState === UserAuthStateEnum.FORGOTTEN_PASSWORD_STATE) {
			this.flashMessageService.showMessage(
				// eslint-disable-next-line max-len
				`Zadané heslo bylo bohužel opět špatně. Na email ${this.emailInput.val()} byly zaslány informace k obnovení hesla.`,
				"error",
			);
			this.userService.setState(UserAuthStateEnum.WRONG_PASSWORD_STATE);
		}
	}

	onBtnFacebookClick(ev) {
		ev.preventDefault();

		this.spinnerFacebook.show();
		setTimeout(() => this.spinnerFacebook.hide(), 10000);

		this.userService.setLoginId(null);
		this.userService.setPayload(null);
		this.userService.setInternalTrackingParameters(this.getInternalTrackingParameters());
		this.userService.doFacebookLogin(() => this.spinnerFacebook.hide());
	}

	onSubmit(ev) {
		ev.preventDefault();

		let email = this.emailInput.val();
		const password = this.passwordInput.val();

		if (email) {
			const suggestedEmail = suggestEmail(email);

			if (suggestedEmail) {
				// eslint-disable-next-line max-len, no-alert
				const replace = window.confirm(`Zdá se nám, že v emailové adrese je překlep. Není adresa správně takto? ${suggestedEmail}`);
				if (replace) {
					email = suggestedEmail;
					this.emailInput.val(email);
				}
			}
		}

		this.spinnerSubmit.show();
		this.userService.setLoginId(null);
		this.userService.setPayload(null);
		this.userService.setInternalTrackingParameters(this.getInternalTrackingParameters());
		this.userService.doAuth({ email, password }, () => this.spinnerSubmit.hide(), () => this.spinnerSubmit.hide());
	}
}
