import $ from "jquery";
import ResponsiveWidget from "../ResponsiveWidget";
/* global document */
/* global window */

export default class MerchantDetailWidget extends ResponsiveWidget {
	constructor(el, model, children, fancyboxService, reservationService) {
		super(el, model, children);

		this.fancyboxService = fancyboxService;
		this.reservationService = reservationService;
	}

	bind() {
		super.bind();

		this.model.images = this.model.images || [];
		this.model.images = this.model.images.map(url => (
			{
				src: url,
				type: "image",
			}
		));

		this.anchors = $(".j-anchor", this.el);
		this.btnExpand = $(".j-btn-expand", this.el);
		this.tabs = $(".j-tabs", this.el);
		this.termCheck = $(".j-merchant-term-check", this.el);
		this.thumbnails = $(".j-thumbnail", this.el);
		this.tooltips = $("[data-toggle='tooltip']", this.el);
		this.web = $(".j-web", this.el);

		this.anchors.click(ev => MerchantDetailWidget.onAnchorClick(ev));
		this.btnExpand.click(ev => this.onExpandClick(ev));
		this.termCheck.click(ev => this.onTermCheckClick(ev));
		this.tooltips.tooltip();
		this.thumbnails.each((index, thumbnail) => (
			$(thumbnail).click(ev => this.onThumbnailClick(ev, index))
		));
		this.web.click(ev => MerchantDetailWidget.onWebClick(ev));
		setTimeout(() => this.bindTabs(), 0);
	}

	bindTabs() {
		if (this.isDesktop()) {
			this.tabs.floating({
				marginTop: 0,
				limit: () => (
					this.el.offset().top + this.el.outerHeight()
				),
				zIndex: 30,
			});
			$(document.body).scrollspy({
				target: ".j-scrollspy",
			});
		}
	}

	onExpandClick(ev) {
		ev.preventDefault();
		this.el.removeClass("s-collapsed");
	}

	onTermCheckClick(ev) {
		ev.preventDefault();

		const date = $(ev.currentTarget).data("date");
		this.reservationService.createTermRequestForMerchant(this.model.slug, date);
	}

	onThumbnailClick(ev, index) {
		ev.preventDefault();

		this.fancyboxService.open(
			this.model.images,
			{
				mobile: {
					thumbs: {
						autoStart: false,
					},
				},
				thumbs: {
					autoStart: true,
				},
			},
			index,
		);
	}
}

MerchantDetailWidget.onAnchorClick = function onAnchorClick(ev) {
	const { hash } = ev.currentTarget;
	const $target = $(hash);

	if ($target.length) {
		ev.preventDefault();
		$("html,body").animate(
			{
				scrollTop: $target.offset().top - 60,
			},
			400,
		);
	}
};

MerchantDetailWidget.onWebClick = function onWebClick(ev) {
	const link = $(ev.currentTarget).data("href");
	window.open(link, "_blank");
};
