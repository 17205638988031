import $ from "jquery";
import Widget from "../../../../../Inlined/Widget";
import "../../../../../../dry/num";
/* global dry */

export default class CarsContestAnswerWidget extends Widget {
	constructor(el, model, children, ajaxService, flashMessageService) {
		super(el, model, children);

		this.ajaxService = ajaxService;
		this.flashMessageService = flashMessageService;
	}

	bind() {
		super.bind();

		this.after = $(".j-after", this.el);
		this.answer = $(".j-answer", this.el);
		this.before = $(".j-before", this.el);
		this.spinner = $(".j-spinner", this.el);

		this.el.on("submit", ev => this.onSubmit(ev));
	}

	onSubmit(ev) {
		ev.preventDefault();

		const answer = this.answer.val().replace(/ /g, "");
		const $controlGroup = this.answer.closest(".control-group");

		if (!answer) {
			$controlGroup.addClass("error");
			this.flashMessageService.showMessage("Vyplňte odpověď", "warning");
			return;
		}
		// TODO Dá se nahradit ES6 isInteger()
		if (!dry.num.isNumeric(answer)) {
			$controlGroup.addClass("error");
			this.flashMessageService.showMessage("Zadejte prosím číslici, např. 123", "warning");
			return;
		}
		$controlGroup.removeClass("error");

		this.spinner.show();
		this.ajaxService.ajax({
			method: "POST",
			url: this.model.ajaxSendAnswer,
			data: {
				answer,
				question_id: this.model.questionId,
			},
		})
			.done((response) => {
				if (!response.ok) {
					this.flashMessageService.showMessage(
						response.message || "Při komunikaci se serverem došlo k chybě.",
						"error",
					);
				}
				this.spinner.hide();
				this.before.hide();
				this.after.show();
			})
			.fail(() => {
				this.spinner.hide();
				this.flashMessageService.showMessage("Při komunikaci se serverem došlo k chybě.", "error");
			});
	}
}
