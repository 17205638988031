import $ from "jquery";
import AbstractFilterFormWidget from "./AbstractFilterFormWidget";
import AbstractFilterWidget from "./AbstractFilterWidget";

export default class TravelPriceIncludesFilterWidget extends AbstractFilterWidget {
	bind() {
		super.bind();

		this.form = this.findParentByType(AbstractFilterFormWidget) || null;

		if (!this.form) {
			throw new Error("I need AbstractFilterFormWidget parent!");
		}

		this.btnSubmit = $(".j-price-includes-submit", this.el);
		this.links = $(".j-link", this.el);
		this.checkboxes = $(".j-check", this.el);

		this.btnSubmit.click(ev => this.onBtnSubmitClick(ev));
		this.checkboxes.change(() => this.onChange());
		this.links.click(ev => this.onLinkClick(ev));
	}

	onBtnSubmitClick(ev) {
		ev.preventDefault();
		this.filter();
	}

	onChange() {
		this.model.priceIncludesSlugs = this.checkboxes.filter(":checked")
			.get()
			.map(checkbox => $(checkbox).val())
			.sort();

		if (this.model.priceIncludesSlugs.length > 0) {
			this.btnSubmit
				.removeClass("btn-info")
				.addClass("btn-success");
		} else {
			this.btnSubmit
				.addClass("btn-info")
				.removeClass("btn-success");
		}
		this.filterService.setFilter(this.getId(), this.getName(), this.serialize());
	}

	// eslint-disable-next-line class-methods-use-this
	onLinkClick(ev) {
		ev.preventDefault();
		const $this = $(ev.currentTarget);

		// Zaskrtnuti, protoze nepouzivame <label> element kvuli zjednoduseni markupu a stylu
		const checked = $this.prevAll(":checkbox").prop("checked");
		$this.prevAll(":checkbox")
			.prop("checked", !checked)
			.change();
		$this.blur();
	}

	serialize = () => (this.model.priceIncludesSlugs ? this.model.priceIncludesSlugs.join(",") : null);
}
