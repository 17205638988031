import $ from "jquery";
import Widget from "../../../Inlined/Widget";
import TopicDayListingBoxWidget from "./Topicday/TopicDayListingBoxWidget";
import ListingLoadedEvent from "../Event/ListingLoadedEvent";
import MerchantWidget from "./Merchant/MerchantWidget";
import BoxWidget from "./BoxWidget";
import RestBoxWidget from "./RestBoxWidget";

/* global dry */
/* global window */
/* global __ */

export default class AbstractListingWidget extends Widget {
	constructor(
		el,
		model,
		children,
		userService,
		flashMessageService,
		app,
		ajaxService,
		itemClass,
		scrollService,
		hitService,
		bodyWidget,
		lazyLoadingService,
		topCategory,
	) {
		super(el, model, children);
		this.userService = userService;
		this.flashMessageService = flashMessageService;
		this.app = app;
		this.ajaxService = ajaxService;
		this.itemClass = itemClass;
		this.scrollService = scrollService;
		this.hitService = hitService;
		this.bodyWidget = bodyWidget;
		this.topCategory = topCategory;
		this.lazyLoadingService = lazyLoadingService;
	}

	bind() {
		super.bind();

		this.barForce = $(".j-bar-force", this.el);
		this.btnForce = $(".j-btn-force", this.el);
		this.items = $(".j-items", this.el);
		this.pagination = $(".j-pagination", this.el);
		this.pages = $(".j-pages", this.el);
		this.loader = $(".j-loader", this.el);

		this.btnForce.click(ev => this.onBtnForceClick(ev));
		this.onWindowScrollFn = dry.fn.throttle((...args) => this.onScroll(...args), 150);
		$(window).scroll(this.onWindowScrollFn);

		this.pages.removeClass("hide").hide();
		this.loader.removeClass("hide").hide();
		this.isLoading = false;
		this.onReady();

		// on scroll vyhazujeme s timeoutem, aby na nej mohly reagovat
		// vsechny poslouchajici widgety ihned po nacteni stranky
		setTimeout((...args) => this.onScroll(...args), 0);
		setTimeout(() => this.app.dispatch(new ListingLoadedEvent(this)), 0);

		// Doplnit vzdalenosti dealu do sablony Javascriptem (kvuli HTML cache)
		if (this.model.distancesById) {
			this.showDistances(this.model.distancesById);
		}

		if (this.hitService) {
			this.hitService.setListingCount(this.model.listingCount);
		}
	}

	unbind() {
		super.unbind();
		$(window).off("scroll", this.onWindowScrollFn);
	}

	getItemIndex(item) {
		const items = this.getItems();
		const index = items.indexOf(item);

		if (index === -1) {
			return null;
		}
		return index;
	}

	getItems() {
		return this.findByType(this.itemClass);
	}

	getItemByHash(hash) {
		const items = this.getItems();
		const itemsWithHash = items.filter(item => (item.getHash() === hash));
		return itemsWithHash.length ? itemsWithHash[0] : null;
	}

	getNextItem(item) {
		const items = this.getItems();
		const index = items.indexOf(item);
		if (index === -1) {
			return null;
		}
		const nextItem = items[index + 1];
		return nextItem || null;
	}

	// Get last item on row where given item is placed
	getLastElementOnRow(itemElement) {
		const nextItemElement = itemElement.next("li");

		// Given item is the last one
		if (!nextItemElement.length) {
			return itemElement;
		}

		const itemRect = itemElement[0].getBoundingClientRect();
		const nextItemRect = nextItemElement[0].getBoundingClientRect();

		// When next item is placed completely under item then item is last on row.
		if (nextItemRect.top >= itemRect.bottom) {
			return itemElement;
		}

		return this.getLastElementOnRow(nextItemElement);
	}

	getNextUniqueDeal(item) {
		const items = this.getItems();
		const index = items.indexOf(item);

		if (index === -1) {
			return null;
		}

		const nextItems = items.slice(index + 1);
		const nextItemOfType = nextItems.find(nextItem => (
			nextItem.getType() === "deal"
				&& nextItem.getHash() !== item.getHash()
		));
		return nextItemOfType || null;
	}

	getPrevItem(item) {
		const items = this.getItems();
		const index = items.indexOf(item);

		if (index === -1) {
			return null;
		}

		const prevItem = items[index - 1];
		return prevItem || null;
	}

	getPrevUniqueDeal(item) {
		const items = this.getItems();
		const index = items.indexOf(item);

		if (index === -1) {
			return null;
		}

		const prevItems = items.slice(0, index);
		let prevItemOfType = null;

		for (let i = prevItems.length - 1; i >= 0; i -= 1) {
			if (prevItems[i].getType() === "deal" && prevItems[i].getHash() !== item.getHash()) {
				prevItemOfType = prevItems[i];
				break;
			}
		}
		return prevItemOfType;
	}

	getBoxes() {
		return this.findByType(BoxWidget);
	}

	getCategoryBoxById(id) {
		const boxes = this.getBoxes().filter(box => (box.getCategoryId() === id));
		return boxes.length ? boxes[0] : null;
	}

	getRestBoxes() {
		return this.getBoxes().filter(box => (box instanceof RestBoxWidget));
	}

	getPage() {
		return this.model.page;
	}

	getTerm() {
		return this.model.term;
	}

	getTopicDayBoxes() {
		return this.getBoxes().filter(box => (box instanceof TopicDayListingBoxWidget));
	}

	getServerBoxById(id) {
		const boxes = this.getBoxes().filter(box => (box.getServerId() === id));
		return boxes.length ? boxes[0] : null;
	}

	getTopicDayBoxById(id) {
		const topicDayBoxes = this.getTopicDayBoxes();
		const boxes = topicDayBoxes.filter(box => (box.getTopicDayBoxId() === id));
		return boxes.length ? boxes[0] : null;
	}

	getVisibleBoxes() {
		const boxes = this.getBoxes();
		return boxes.filter(box => (box.getIsVisible()));
	}

	hasMore() {
		return !!this.model.hasMore;
	}

	// eslint-disable-next-line class-methods-use-this
	isEnabled() {
		return true;
	}

	onBtnForceClick(ev) {
		ev.preventDefault();
		this.load();
	}

	isDelayedPreview() {
		return !!this.model.delayedPreview;
	}

	isPreview() {
		return !!this.model.preview;
	}

	onScroll() {
		if (this.isEnabled() === false) {
			return;
		}

		this.show();

		if (this.shouldMakeInfiniteLoad()) {
			this.load();
		}
	}

	getAjaxPageUrl() {
		return this.model.ajaxPageUrlPattern;
	}

	hide() {
		return this.el.hide();
	}

	show() {
		return this.el.show();
	}

	load(onLoaded = () => {}) {
		if (this.isEnabled() === false) {
			return;
		}
		if (!this.model.hasMore) {
			return;
		}
		if (this.isLoading) {
			return;
		}

		this.show();
		this.barForce.hide();
		this.loader.show();
		this.isLoading = true;

		const url = this.getAjaxPageUrl().replace("{page}", this.model.page + 1);
		this.ajaxService.ajax({ method: "GET", url, context: this })
			.done((data) => {
				this.onLoaded(data);
				onLoaded.apply(this, [data]);
				this.onReady();
				this.lazyLoadingService.update();
			})
			.fail(() => {
				this.onError();
				this.onReady();
			});
	}

	loadWithScroll(onLoaded = () => {}) {
		if (!this.model.hasMore) {
			return;
		}

		const scrollPosition = this.el.offset().top + this.el.outerHeight();

		$("html, body").stop().animate({
			scrollTop: scrollPosition,
		}, {
			duration: 400,
		});

		this.load(onLoaded);
	}

	onLoaded(data) {
		this.loader.hide();
		this.isLoading = false;
		this.model.page += 1;
		this.model.hasMore = data.hasMore;

		if (this.model.page === 1) {
			this.items.empty();
			this.children = [];
		}

		const renderedHtml = this.app.render(data.html, this);
		renderedHtml.elements.each((index, el) => this.items.append(el));
		renderedHtml.widgets.forEach(child => this.children.push(child));

		// Zobrazit GPS vzdalenosti (pridano Javascriptem kvuli HTML cache)
		if (data.distancesById) {
			this.showDistances(data.distancesById);
		}

		this.track();
		this.app.dispatch(new ListingLoadedEvent(this));
	}

	onError() {
		this.loader.hide();
		this.isLoading = false;
		this.flashMessageService.showMessage(__("Nepodařilo se načíst další nabídky. Zkuste prosím obnovit stránku."));
	}

	onReady() {
		if (this.model.hasMore && (!this.model.infinite || this.model.page === 2)) {
			this.barForce.show();
		} else {
			this.barForce.hide();
		}
		this.loader.hide();

		if (this.isEnabled()) {
			this.show();
		} else {
			this.hide();
		}
	}

	showDistances(distancesById) {
		const entities = {
			deal: this.getItems().filter(item => item.getType() === "deal"),
			merchant: this.findByType(MerchantWidget),
		};
		Object.keys(entities).forEach((entityName) => {
			const items = entities[entityName];
			if (distancesById[entityName] === undefined) {
				return;
			}
			items.forEach((item) => {
				const id = item.getId();
				const distance = distancesById[entityName][id];

				if (distance === undefined) {
					return;
				}

				item.setDistance(distance);
			});
		});
	}

	scrollToBox(box) {
		this.scrollService.scrollToElement(box.el, -30);
	}

	scrollToCategoryBoxById(id) {
		const box = this.getCategoryBoxById(id);
		if (box) {
			this.scrollToBox(box);
		} else {
			this.loadWithScroll(() => this.scrollToCategoryBoxById(id));
		}
	}

	scrollToServerBoxById(id) {
		const box = this.getServerBoxById(id);
		if (box) {
			this.scrollToBox(box);
		} else {
			this.loadWithScroll(() => this.scrollToServerBoxById(id));
		}
	}

	scrollToFirstRestBox() {
		const boxes = this.getRestBoxes();
		const box = boxes.length ? boxes[0] : null;
		if (box) {
			this.scrollToBox(box);
		} else {
			this.loadWithScroll(() => this.scrollToFirstRestBox());
		}
	}

	scrollToTopicDayBoxById(id) {
		const box = this.getTopicDayBoxById(id);
		if (box) {
			this.scrollToBox(box);
		} else {
			this.loadWithScroll(() => this.scrollToTopicDayBoxById(id));
		}
	}

	shouldMakeInfiniteLoad() {
		if (!this.model.infinite) {
			return false;
		}

		if (this.model.page === 2 && this.model.scrollStop !== false) {
			return false;
		}

		const rect = this.el[0].getBoundingClientRect();
		return rect.bottom < $(window).height() + 500;
	}

	onNavigatedBack(itemWidget) {
		// Deals subscribe box
		if (this.model.showDealsSubscribe !== false && this.model.ajaxDealsSubscribe) {
			if (!this.userService.isRegistered()) {
				this.showDealsSubscribe(itemWidget);
			} else {
				this.userService.getSettings().then((userSettings) => {
					// Do not show registration when user has mailing enabled
					if (userSettings && userSettings.pushEmailFrequency !== "0") {
						return;
					}
					this.showDealsSubscribe(itemWidget);
				});
			}
		}
	}

	showDealsSubscribe(itemWidget) {
		this.ajaxService
			.ajax({
				url: this.model.ajaxDealsSubscribe,
			})
			.then((response) => {
				if (response.ok) {
					// Show only once
					this.model.showDealsSubscribe = false;
					if (response.html) {
						const renderedHtml = this.app.render(response.html, this);
						const insertAfterEl = this.getLastElementOnRow(itemWidget.el);
						insertAfterEl.after(renderedHtml.elements);
					}
				}
			})
			.fail(() => {
				// do nothing
			});
	}

	// eslint-disable-next-line class-methods-use-this
	track() {
		// do nothing
	}
}
