import $ from "jquery";
import AbstractListingWidget from "./AbstractListingWidget";
import PageRedirectEvent from "../Event/PageRedirectEvent";


export default class VerifiedTermListingWidget extends AbstractListingWidget {
	// eslint-disable-next-line max-len
	constructor(el, model, children, userService, flashMessageService, app, ajaxService, itemClass, scrollService, hitService, bodyWidget, lazyLoadingService, topCategory) {
		// eslint-disable-next-line max-len
		super(el, model, children, userService, flashMessageService, app, ajaxService, itemClass, scrollService, hitService, bodyWidget, lazyLoadingService, topCategory);
		this.loadingOverlay = $(".j-loading-overlay", this.el);
		this.scrollService = scrollService;
	}

	bind() {
		super.bind();
		this.app.on(PageRedirectEvent, () => this.onPageRedirect());
	}

	onPageRedirect() {
		this.loadingOverlay.show();
		this.scrollService.bindFloatingInnerElement(this.loadingOverlay, this.el);
	}
}
