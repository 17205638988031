import $ from "jquery";
import AbstractWatchdogSubscribeWidget from "./AbstractWatchdogSubscribeWidget";
import WatchdogResumeEnum from "../../VO/WatchdogResumeEnum";

/* global window */

export default class ListingEndWatchdogSubscribeWidget extends AbstractWatchdogSubscribeWidget {
	constructor(
		el,
		model,
		children,
		flashMessageService,
		watchdogService,
		userService,
		app,
		ajaxService,
	) {
		super(el, model, children, flashMessageService, watchdogService, userService);
		this.app = app;
		this.ajaxService = ajaxService;
		this.watchdogResume = WatchdogResumeEnum.LISTING_END_RESUME;
	}

	bind() {
		super.bind();

		if (this.model.recommendTeleportClass) {
			this.recommendTeleport = $(`.${this.model.recommendTeleportClass}`); // intentionally no this.el
			this.recommendTeleport.slideUp(0);
			this.recommendTeleportShown = false;
		}
		this.done = $(".j-done", this.el).removeClass("bye");
		this.prepare = $(".j-prepare", this.el);

		this.model.modal = false;
		this.model.tooltipContent = null;
		this.model.tooltipType = "hover";

		this.el.on("click", ".j-btn-closetooltip", ev => this.onCloseTooltipBtnClick(ev));

		this.btn.tooltip({
			animation: false,
			html: true,
			placement: "bottom",
			// eslint-disable-next-line max-len
			template: "<div class='tooltip tooltip-dropdown tooltip-large tooltip-light' role='tooltip'><div class='tooltip-arrow'></div><div class='tooltip-inner'></div></div>",
			title: () => this.model.tooltipContent,
			trigger: "manual",
		});

		this.refresh();
		this.showWatchdogCountTooltip();
	}

	logBtnClick() {

	}

	logWatchdogSet(interaction) {

	}

	onCloseTooltipBtnClick(ev) {
		ev.preventDefault();

		this.model.modal = false;
		this.refresh();
	}

	onWatchdogSet(ev) {
		if (ev.watchdogId !== WatchdogResumeEnum.LISTING_RESUME
			&& ev.watchdogId !== WatchdogResumeEnum.LISTING_END_RESUME
		) {
			return;
		}

		super.onWatchdogSet(ev);

		// Skryt watchdoga, pokud byl zapnut. Nechceme, aby si ho nekdo vypnul.
		// Neskryt, pokud udalost pochazi z tohoto widgetu => musime zobrazit "hotovo".
		if (ev.active && ev.watchdogId !== this.watchdogResume) {
			this.el.hide();
		} else {
			this.el.show();
		}

		// Pokud zapnuti watchdoga bylo vyvolano timto widgetem
		if (ev.watchdogId === this.watchdogResume) {
			this.showRecommendations(ev);
		}
	}

	refresh() {
		super.refresh();

		if (this.model.active) {
			this.prepare.hide();
			this.done
				.text(this.getAlertText())
				.show();
		} else {
			this.prepare.show();
			this.done
				.text(this.getAlertText())
				.hide();
		}

		// Bublina: tyto slevy si hlida X lidi
		if (this.model.modal && !this.model.active && this.model.isWatchdogCountTooltipShown) {
			this.model.tooltipName = "watchdogCount";
			this.model.tooltipContent = this.model.watchdogCountText;
			this.model.tooltipType = "modal";
		} else {
			this.model.tooltipContent = null;
		}


		if (!this.model.modal && this.model.tooltipType === "modal") {
			// Skryti modal tooltipu
			this.model.tooltipContent = null;
		} else if (!this.model.dropdownHover && this.model.tooltipType === "dropdown") {
			// Skryti dropdown tooltipu pri sjeti mysi z dropdownu
			this.model.tooltipContent = null;
		} else if (!this.model.hover && this.model.tooltipType === "hover") {
			// Skryti tooltipu pri sjeti mysi z tlacitka
			this.model.tooltipContent = null;
		}

		if (this.model.tooltipContent) {
			this.btn.tooltip("show");
		} else {
			this.btn.tooltip("hide");
		}
	}

	showRecommendations(ev) {
		if (ev.watchdogId === this.watchdogResume
			&& ev.active
			&& ev.filterId
			&& this.model.recommendTeleportClass
			&& !this.recommendTeleportShown
		) {
			this.recommendTeleportShown = true;

			this.ajaxService.ajax({
				type: "GET",
				url: this.model.ajaxRecommendUrl,
				data: { filterId: ev.filterId },
			})
				.done((response) => {
					if (response.ok && response.html) {
						this.recommendTeleport.empty();
						const renderedHtml = this.app.render(response.html, this);
						this.setChildren((this.children || []).concat(renderedHtml.widgets));
						this.recommendTeleport.append(renderedHtml.elements);
						this.recommendTeleport.slideDown("slow");
					}
				})
				.fail(() => {
					// do nothing
				});
		}
	}

	showWatchdogCountTooltip() {
		if (!this.model.isWatchdogCountTooltip) {
			return;
		}

		this.model.isWatchdogCountTooltipShown = true;
		this.model.modal = true;
		this.refresh();
	}
}
