/* global Cookies */

import UserAuthStateEvent from "../Event/UserAuthStateEvent";
import UserAuthStateEnum from "../VO/UserAuthStateEnum";

export default class DeadEmailService {
	constructor(app, ajaxService, userService, fancyboxService, bodyWidget, isDeadEmail, ajaxDeadEmailUrl, domain) {
		this.app = app;
		this.ajaxService = ajaxService;
		this.userService = userService;
		this.fancyboxService = fancyboxService;
		this.bodyWidget = bodyWidget;
		this.isDeadEmail = isDeadEmail;
		this.ajaxDeadEmailUrl = ajaxDeadEmailUrl;
		this.domain = domain;
		this.cookieName = "deadEmailDialog";

		this.userService.on(UserAuthStateEvent, ev => this.onUserAuthState(ev));

		// Dead e-mail? Otevrit fancybox!
		if (this.isDeadEmail) {
			this.check();
		}
	}

	check() {
		if (typeof Cookies.get(this.cookieName) === "string") {
			return false;
		}

		const url = this.ajaxDeadEmailUrl;
		this.ajaxService.ajax({ method: "GET", url })
			.done((response) => {
				if (response.isDeadEmail && response.html) {
					const renderedHtml = this.app.render(response.html, this.bodyWidget);
					if (!renderedHtml.widgets.length) {
						return;
					}

					const dialog = renderedHtml.widgets[0];
					this.openDialog(dialog);
				}
			});

		return true;
	}

	disableDialog() {
		Cookies.get(this.cookieName, "0", {
			domain: `.${this.domain}`,
			path: "/",
			secure: true,
			expires: 365,
		});
	}

	openDialog(dialog) {
		if (!dialog) {
			return false;
		}

		this.fancyboxService.openWidget(dialog);
		return true;
	}

	onUserAuthState(ev) {
		// Je-li otevren jiny fancybox, neotevreme dead email fancybox
		if (this.fancyboxService.isOpened()) {
			return;
		}

		// Uzivatel prihlasen: overit e-mail a otevrit fancybox!
		if (ev.newState === UserAuthStateEnum.COMPLETE_STATE) {
			this.check();
		}
	}
}
