import $ from "jquery";
import Widget from "./../../../../Inlined/Widget";
import RerenderMixin from "../RerenderMixin";

/* global location */

export default class ReviewItemWidget extends Widget {
	constructor(el, model, children, app, flashMessageService, ajaxService) {
		super(el, model, children);
		this.app = app;
		this.flashMessageService = flashMessageService;
		this.ajaxService = ajaxService;
		this.rerender = RerenderMixin.rerender.bind(this);
	}
	bind() {
		super.bind();
		this.addReactionButton = $(".j-reaction-add", this.el);
		this.addReactionButton.click(ev => this.onAddReactionClick(ev));
		this.save = $(".j-reaction-save", this.el);
		this.save.click(ev => this.onSaveClick(ev));
		this.cancel = $(".j-reaction-cancel", this.el);
		this.cancel.click(ev => this.onCancelClick(ev));
		this.reactionContainer = $(".j-reaction-container", this.el);
		this.reactionInputText = $(".j-reaction-textarea", this.el);
		this.reactionInput = $(".j-reaction-input", this.el);
		this.loader = $(".j-loader", this.el);
	}

	onAddReactionClick() {
		this.reactionInput.show();
	}

	onSaveClick() {
		const reactionText = this.reactionInputText.val();
		if (reactionText.length > 0) {
			this.loader.show();
			this.ajaxService.ajax({
				url: this.model.ajaxReviewReactionUrl,
				method: "POST",
				data: {
					reactionText,
				},
			}).done((response) => {
				if (response.ok) {
					this.loader.hide();
					if (this.model.forceReload) {
						this.reactionContainer.hide();
						/* eslint-disable-next-line no-restricted-globals */
						location.reload();
					} else {
						this.rerender(response.html);
					}
				}
				this.flashMessageService.showMessage(response.msg, response.ok ? "success" : "danger");
			}).fail(() => {
				this.flashMessageService.showMessage("Při komunikaci se serverem došlo k chybě.");
			});
		} else {
			this.flashMessageService.showMessage("Prosím vložte neprázdnou reakci.");
		}
	}

	onCancelClick() {
		this.reactionInput.hide();
	}
}
