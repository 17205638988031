import WishDeleteEvent from "../Event/WishDeleteEvent";
/* global fbq */
/* global __ */
/* global __LOCALE__ */

export default class WishlistService {
	static instance;

	constructor(
		app,
		bodyWidget,
		ajaxService,
		flashMessageService,
		fancyboxService,
		wishlists,
		wishlistAjaxUrls,
	) {
		if (WishlistService.instance) {
			WishlistService.instance.wishlists = wishlists;
			WishlistService.instance.setupActiveItemKeys();
			return WishlistService.instance;
		}
		this.app = app;
		this.bodyWidget = bodyWidget;
		this.ajaxService = ajaxService;
		this.flashMessageService = flashMessageService;
		this.fancyboxService = fancyboxService;
		this.wishlists = wishlists;
		this.wishlistAjaxUrls = wishlistAjaxUrls;
		this.setupActiveItemKeys();
		if (__LOCALE__ === "pl_PL") {
			this.currency = "PLN";
		} else {
			this.currency = "CZK";
		}
		WishlistService.instance = this;
	}

	getWishlists() {
		return this.wishlists;
	}

	setWishlists(wishlists) {
		this.wishlists = wishlists;
		this.setupActiveItemKeys();
	}

	isInWishlist(itemId, itemType) {
		return this.activeItemKeyValues.indexOf(itemType + itemId) > -1;
	}

	setupActiveItemKeys() {
		this.activeItemKeys = {};
		this.activeItemKeyValues = [];
		if (this.wishlists) {
			this.wishlists.forEach((wishlist) => {
				if (wishlist.isEditable && wishlist.activeWishlistItemKeys) {
					Object.keys(wishlist.activeWishlistItemKeys).forEach((itemId) => {
						this.activeItemKeys[itemId] = wishlist.activeWishlistItemKeys[itemId];
						this.activeItemKeyValues.push(wishlist.activeWishlistItemKeys[itemId]);
					});
				}
			});
		}
	}

	getActiveItemKeysValues() {
		return this.activeItemKeyValues;
	}

	getLastWishlistItemIdByKey(itemKey) {
		const lastWishlistItemId = Object
			.keys(this.activeItemKeys)
			.filter(wishlistItemId => this.activeItemKeys[wishlistItemId] === itemKey)
			.pop();

		return lastWishlistItemId
			? parseInt(lastWishlistItemId, 10)
			: null;
	}

	wishlistContainsItem(wishlistId, itemId, itemType) {
		if (!this.wishlists) {
			return false;
		}
		return this.wishlists.some((wishlist) => {
			if (wishlist.wishlist.id === wishlistId) {
				// Object.keys().map() instead of ES7 Object.values()
				const itemKeys = Object
					.keys(wishlist.activeWishlistItemKeys)
					.map(key => wishlist.activeWishlistItemKeys[key]);
				if (itemKeys.length > 0) {
					return itemKeys.indexOf(itemType + itemId) !== -1;
				}
			}
			return false;
		});
	}

	/** Modal */
	openDialog(itemId, itemType, shareUrl, sourceWidget, isRemoveDialog) {
		const url = this.wishlistAjaxUrls.ajaxWishlistModalUrl;

		this.fancyboxService.openAjax(
			url,
			{
				ajax: {
					settings: {
						data: {
							itemType,
							itemId,
							shareUrl,
							isRemoveDialog,
						},
						type: "POST",
					},
				},
				slideClass: "s-edge-to-edge",
			},
		);
	}


	/** AJAX Requests */

	/* Wishlists */

	createWishlist(wishlistTitle, successCallback, errorCallback, item) {
		const data = {
			wishlistTitle,
		};
		if (item && item.id && item.type) {
			data.itemId = item.id;
			data.itemType = item.type;
		}
		this.ajaxService.ajax({
			method: "POST",
			url: this.wishlistAjaxUrls.ajaxWishlistsUrlPattern.replace("/wishlistId", ""),
			data,
		}).done((response) => {
			if (response.ok && response.wishlistArray) {
				this.setWishlists(response.wishlistArray);
				if (successCallback) {
					successCallback(response);
				}
			} else {
				this.flashMessageService.showMessage(response.errorMessage);
				if (errorCallback) {
					errorCallback(response);
				}
			}
		}).fail(() => {
			this.flashMessageService.showMessage(__("Při komunikaci se serverem došlo k chybě."), "danger");
			if (errorCallback) {
				errorCallback();
			}
		});
	}

	updateAccessRights(wishlistId, accessRight, successCallback) {
		this.ajaxService.ajax({
			method: "PATCH",
			url: this.wishlistAjaxUrls.ajaxWishlistsUrlPattern.replace("wishlistId", wishlistId),
			data: {
				accessRight,
			},
		}).done((response) => {
			this.flashMessageService.showMessage(__("Nastavení seznamu bylo upraveno."), "success");
			if (successCallback) {
				successCallback(response);
			}
		}).fail(() => {
			this.flashMessageService.showMessage(__("Při komunikaci se serverem došlo k chybě."), "danger");
		});
	}

	/* UsersWishlists */

	updateUserWishlistOrder(wishlistIdsOrder, callback) {
		const self = this;
		this.ajaxService.ajax({
			method: "PATCH",
			url: this.wishlistAjaxUrls.ajaxUsersWishlistsUrlPattern.replace("/userWishlistId", ""),
			data: {
				userWishlistIdsOrder: wishlistIdsOrder,
			},
		}).done((response) => {
			if (!response.ok) {
				self.flashMessageService.showMessage(response.errorMessage);
			}
		}).fail(() => {
			self.flashMessageService.showMessage(__("Při komunikaci se serverem došlo k chybě."), "danger");
		}).always(() => {
			if (callback) {
				callback();
			}
		});
	}

	deleteUserWishlist(userWishlistId, callback) {
		const url = this.wishlistAjaxUrls.ajaxUsersWishlistsUrlPattern.replace("userWishlistId", userWishlistId);
		this.ajaxService.ajax({
			method: "DELETE",
			url,
		}).done((response) => {
			if (response.ok && response.wishlistArray) {
				this.setWishlists(response.wishlistArray);
			} else {
				this.flashMessageService.showMessage(response.errorMessage);
			}
		}).fail(() => {
			this.flashMessageService.showMessage(__("Při komunikaci se serverem došlo k chybě."), "danger");
		}).always(() => {
			if (callback) {
				callback();
			}
		});
	}

	/* WishlistsItems */

	createWishlistItem(item, wishlistId, success, error) {
		this.ajaxService.ajax({
			method: "POST",
			url: this.wishlistAjaxUrls.ajaxWishlistsItemsUrlPattern.replace("/wishlistItemId", ""),
			data: {
				itemId: item.id,
				itemType: item.type,
				wishlistId,
			},
		}).done((response) => {
			if (response.ok && response.wishlistArray) {
				this.setWishlists(response.wishlistArray);
				if (success) {
					success(response);
				}
			} else {
				this.flashMessageService.showMessage(response.errorMessage);
				if (error) {
					error(response);
				}
			}
		}).fail(() => {
			this.flashMessageService.showMessage(__("Při komunikaci se serverem došlo k chybě."), "danger");
			if (error) {
				error();
			}
		});
	}

	updateWishlistItemOrder(wishlistItemIdsOrder) {
		this.updateWishlistItem("", { wishlistItemIdsOrder });
	}

	updateWishlistItemNote(note, wishlistItemId, alwaysCallback) {
		this.updateWishlistItem(wishlistItemId, { note }, null, alwaysCallback);
	}

	updateWishlistItemState(wishlistItemId, targetState, sucessCallback) {
		this.updateWishlistItem(
			wishlistItemId,
			{ targetState },
			(response) => {
				if (response.wishlistArray) {
					this.setWishlists(response.wishlistArray);
				}
				if (sucessCallback) {
					sucessCallback(response);
				}
			},
		);
	}

	updateWishlistItem(wishlistItemId, properties, sucessCallback, alwaysCallback) {
		const replaceString = wishlistItemId ? "wishlistItemId" : "/wishlistItemId";
		const self = this;
		this.ajaxService.ajax({
			method: "PATCH",
			url: this.wishlistAjaxUrls.ajaxWishlistsItemsUrlPattern.replace(replaceString, wishlistItemId),
			data: properties,
		}).done((response) => {
			if (sucessCallback) {
				sucessCallback(response);
			}
		}).fail((jqXHR) => {
			const jsonReponse = JSON.parse(jqXHR.responseText);

			if (jsonReponse.message) {
				self.flashMessageService.showMessage(jsonReponse.message, "danger");
			} else {
				self.flashMessageService.showMessage(__("Při komunikaci se serverem došlo k chybě."), "danger");
			}
		}).always(() => {
			if (alwaysCallback) {
				alwaysCallback();
			}
		});
	}

	removeWishlistItem(wishlistItemId, itemId, itemType, successCallback, errorCallback) {
		const self = this;
		this.ajaxService.ajax({
			method: "DELETE",
			url: this.wishlistAjaxUrls.ajaxWishlistsItemsUrlPattern.replace("wishlistItemId", wishlistItemId),
		}).done((response) => {
			this.flashMessageService.showMessage(__("Nabídka byla úspěšně odebrána ze seznamu."), "success");
			if (response.ok) {
				// Removing wishlistItem from this.wishlists
				self.wishlists.some((wishlist) => {
					if (wishlist.activeWishlistItemKeys[wishlistItemId] !== undefined) {
						// eslint-disable-next-line no-param-reassign
						delete wishlist.activeWishlistItemKeys[wishlistItemId];
						return true;
					}
					return false;
				});
				self.setupActiveItemKeys();

				if (successCallback) {
					successCallback(response);
				}
				this.app.dispatch(new WishDeleteEvent(itemId, itemType));
			} else {
				this.flashMessageService.showMessage(response.errorMessage);
				if (errorCallback) {
					errorCallback(response);
				}
			}
		}).fail(() => {
			this.flashMessageService.showMessage(__("Při komunikaci se serverem došlo k chybě."), "danger");
			if (errorCallback) {
				errorCallback();
			}
		});
	}
}
