window.dry = window.dry || {};
dry.net = dry.net || {};

dry.net.getRelativeUri = function (url) {
	var a = document.createElement("a");
	a.href = url;
	return (a.pathname.indexOf("/") === 0 ? "" : "/")
		+ a.pathname
		+ a.search
		+ a.hash;
};

dry.net.parseUri = function(str) {
	var	o   = this.parseUri.options,
		m   = o.parser[o.strictMode ? "strict" : "loose"].exec(str),
		uri = {},
		i   = 14;

	while (i--) uri[o.key[i]] = m[i] || "";

	uri[o.q.name] = {};
	uri[o.key[12]].replace(o.q.parser, function ($0, $1, $2) {
		if ($1) uri[o.q.name][$1] = $2;
	});

	return uri;
};

dry.net.parseUri.options = {
	// Striktni rezim:
	// - spravne parsuje relativni cesty, ktere nezacinaji root adresarem (napr. dir/file.html)
	// - adresar nekoncici lomitkem je povazovan za soubor
	// Loose rezim
	// - adresare nemusi byt ukonceny lomitkem
	// - URI muze zacinat autoritou bez "//" na zacatku (napr. yahoo.com/search/)
	// (http://blog.stevenlevithan.com/archives/parseuri)
	strictMode: true,
	key: ["source","protocol","authority","userInfo","user","password","host","port","relative","path","directory","file","query","anchor"],
	q:   {
		name:   "queryKey",
		parser: /(?:^|&)([^&=]*)=?([^&]*)/g
	},
	parser: {
		strict: /^(?:([^:\/?#]+):)?(?:\/\/((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?))?((((?:[^?#\/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
		loose:  /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/
	}
};