import $ from "jquery";
import { lang } from "../Locale";
/* global document */
/* global __ */

export default class FancyboxService {
	constructor(app, ajaxService, bodyWidget) {
		this.app = app;
		this.ajaxService = ajaxService;
		this.bodyWidget = bodyWidget;

		this.converters = {
			...$.ajaxSettings.converters,
			"text json": response => this.jsonResponseConverter(response),
		};
		this.lastThunk = null;

		$(document).on("click", ".j-btn-fancybox-refresh", ev => this.onBtnRefreshClick(ev));
		// Remove default AJAX param
		if ($.fancybox
			&& $.fancybox.defaults
			&& $.fancybox.defaults.ajax
			&& $.fancybox.defaults.ajax.settings
			&& $.fancybox.defaults.ajax.settings.data
			&& $.fancybox.defaults.ajax.settings.data.fancybox
		) {
			delete $.fancybox.defaults.ajax.settings.data.fancybox;
		}
	}

	/**
	 * Show Fancybox using thunk. So we can repeat it's execution if necessary.
	 * @param {function} thunk
	 * @returns {*}
	 */
	create(thunk) {
		this.lastThunk = thunk;
		return thunk();
	}

	/**
	 * Close current / all Fancybox instance(s).
	 * @param {boolean} all
	 */
	// eslint-disable-next-line class-methods-use-this
	close(all = false) {
		$.fancybox.close(all);
	}

	/**
	 * JSON response middleware.
	 * Converts JSON to jQuery node / HTML markup so Fancybox can display the result.
	 * @param {string} response
	 * @returns {jQuery|string}
	 */
	jsonResponseConverter(response) {
		try {
			const jsonResponse = JSON.parse(response);

			if (jsonResponse.ok) {
				const renderedHtml = this.app.render(jsonResponse.html, this.bodyWidget);

				// Return widget element if widget was rendered successfully
				if (renderedHtml.widgets.length) {
					const widget = renderedHtml.widgets[0];
					widget.el.data({ widget });
					return widget.el;
				}

				// Return plain HTML if markup contains no widget.
				return jsonResponse.html;
			}

			return FancyboxService.errorTemplate;
		} catch (e) {
			return FancyboxService.errorTemplate;
		}
	}

	/**
	 * Get current Fancybox instance
	 * @returns {FancyBox|null}
	 */
	// eslint-disable-next-line class-methods-use-this
	getInstance() {
		return $.fancybox.getInstance() || null;
	}

	/**
	 * Get widget currently displayed in Fancybox.
	 * @returns {Widget|null}
	 */
	getWidget() {
		const instance = this.getInstance();

		if (instance && instance.current && instance.current.widget) {
			return instance.current.widget;
		}

		return null;
	}

	/**
	 * Check if Fancybox is opened and (optionally) if it contains given widget.
	 * @param {Widget|null} widget
	 * @returns {boolean}
	 */
	isOpened(widget = null) {
		if (widget) {
			const instanceWidget = this.getWidget();
			return instanceWidget === widget;
		}

		return !!this.getInstance();
	}

	onBtnRefreshClick(ev) {
		ev.preventDefault();

		this.refresh();
	}

	open(items, settings = {}, index) {
		const fancyboxSettings = $.extend(
			true,
			{},
			FancyboxService.getSettings(),
			settings,
		);

		return this.openRaw(items, fancyboxSettings, index);
	}

	openAjax(url, settings = {}) {
		const sameDomainUrl = this.ajaxService.getSameDomainUrl(url);
		const fancyboxSettings = $.extend(
			true,
			{},
			FancyboxService.getSettings(),
			settings,
		);

		return this.openRaw(
			{
				ajax: {
					settings: {
						// Vlastni JSON response processing, protoze Fancybox neumi zpracovat JSON
						converters: this.converters,
						dataType: "json",
					},
				},
				src: sameDomainUrl,
				// Zakazat dragovani nad HTML obsahem
				touch: false,
				type: "ajax",
				afterLoad: (instance, current) => (
					FancyboxService.onAfterLoad(instance, current, fancyboxSettings)
				),
				beforeClose: (instance, current) => (
					FancyboxService.onBeforeClose(instance, current, fancyboxSettings)
				),
			},
			fancyboxSettings,
		);
	}

	openRaw(items, settings, index) {
		return this.create(() => {
			// Zakazat vice instanci Fancyboxu. Vznika problem, pokud se pokusime otevrit vice instanci
			// se stejnym DOM uzlem.
			$.fancybox.close(true);
			return $.fancybox.open(
				items,
				settings,
				index,
			);
		});
	}

	openWidget(widget, settings = {}) {
		const fancyboxSettings = $.extend(
			true,
			{},
			FancyboxService.getSettings(),
			settings,
		);

		widget.el
			.data({ widget })
			.show();

		return this.openRaw(
			{
				src: widget.el.show(),
				// Zakazat dragovani nad HTML obsahem
				touch: false,
				type: "html",
				afterLoad: (instance, current) => (
					FancyboxService.onAfterLoad(instance, current, fancyboxSettings)
				),
				beforeClose: (instance, current) => (
					FancyboxService.onBeforeClose(instance, current, fancyboxSettings)
				),
			},
			fancyboxSettings,
		);
	}

	refresh() {
		if (!this.lastThunk) {
			return false;
		}

		const { lastThunk } = this;
		return lastThunk();
	}
}

/**
 * @returns {Object}
 */
FancyboxService.getSettings = function getSettings() {
	return {
		animationEffect: false,
		errorTpl: FancyboxService.errorTemplate,
		lang,
		i18n: {
			[lang]: {
				CLOSE: __("Zavřít"),
				NEXT: __("Další"),
				PREV: __("Předchozí"),
				ERROR: `${__("Při komunikaci se serverem došlo k chybě.")}<br>${__("Zkuste to znovu.")}`,
				PLAY_START: __("Spustit přehrávání"),
				PLAY_STOP: __("Zastavit přehrávání"),
				FULL_SCREEN: "Celá obrazovka",
				THUMBS: __("Náhledy"),
				DOWNLOAD: __("Stáhnout"),
				SHARE: __("Sdílet"),
				ZOOM: __("Zvětšit"),
			},
		},
	};
};

FancyboxService.onBeforeClose = function onBeforeClose(instance, current, settings) {
	const { widget } = current;

	if (widget && widget.end) {
		widget.end();
	}
	if (settings.beforeClose) {
		settings.beforeClose(instance, current);
	}

	// Fancybox will not put content without placeholder back to the DOM. Instead it will be removed and will lost
	// all events, data etc. This is okay as it prevents memory leaks (see https://api.jquery.com/empty/).
	// Sometimes we need to detach element instead of remove so we can keep reference to element with binded events.
	// (see https://api.jquery.com/detach/)
	const currentDetachOnClose = current.opts.detachOnClose ? current.opts.detachOnClose : false;
	if (!current.$placeholder && currentDetachOnClose && current.$content) {
		current.$content.detach();
	}
};

FancyboxService.onAfterLoad = function onAfterLoad(instance, current, settings) {
	// Reference na widget uvnitr fancyboxu
	const { widget } = current.$content.data();
	// eslint-disable-next-line no-param-reassign
	current.widget = widget;

	if (settings.afterLoad) {
		settings.afterLoad(instance, current);
	}

	if (widget && widget.begin) {
		widget.begin();
	}
};

FancyboxService.errorTemplate = `
	<div class="fancybox-error">
		<div class="alert m0">
			${__("Při komunikaci se serverem došlo k chybě.")}
			<a class="fancybox-btn-refresh j-btn-fancybox-refresh" href="#">${__("Zkusit znovu")}</a>
		</div>
	</div>`;
