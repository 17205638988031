import $ from 'jquery';
import Widget from '../../../../Inlined/Widget';

export default class ReviewRequestItemWidget extends Widget {
	constructor(el, model, children, ajaxService) {
		super(el, model, children);

		this.ajaxService = ajaxService;
	}

	bind() {
		super.bind();

		this.deleteBtn = $('.j-delete', this.el);
		this.deleteBtn.click((ev) => { this.onBtnDeleteClick(ev); });

		setTimeout(() => { this.show(); }, 2000);
	}

	deleteRequest() {
		this.ajaxService.ajax({
			url: this.model.ajaxUrl,
			method: 'DELETE',
		});
	}

	hide() {
		this.el.slideUp();
	}

	onBtnDeleteClick(ev) {
		ev.preventDefault();

		this.deleteRequest();
		this.hide();
	}

	show() {
		this.el
			.removeClass('hide')
			.slideDown();
	}
}
