import $ from "jquery";
import Clipboard from "clipboard";
import Widget from "../../../../Inlined/Widget";
import "../../../../../dry/dom";
import WishCreateEvent from "../../Event/WishCreateEvent";
import { getIconSpriteUrl } from "../../utils";
import checkIcon from "../../Styles/def/img/icons/check.svg";
/* global dry */
/* global __ */

class WishlistModalWidget extends Widget {
	constructor(el, model, children, app, wishlistService, flashMessageService, fancyboxService) {
		super(el, model, children);

		this.app = app;
		this.wishlistService = wishlistService;
		this.flashMessageService = flashMessageService;
		this.fancyboxService = fancyboxService;
	}

	bind() {
		super.bind();

		this.spinner = $(".j-spinner", this.el);
		this.spinner.removeClass("hide").hide();
		this.createContainer = $(".j-wishlist-container", this.el);
		this.createGroup = $(".j-create-group", this.el);
		this.createInput = $(".j-wishlist-create-input", this.el);
		this.btnCreateList = $(".j-create-list", this.el);
		this.btnCopy = $(".j-copy", this.el);
		this.shareContainer = $(".j-share-container", this.el);
		this.shareUrlOutput = $(".j-shareurl", this.el);
		this.shareUrlButton = $(".j-shareurl-button", this.el);

		this.btnCopy.click((ev) => { this.onBtnCopyClick(ev); });
		this.btnCreateList.click((ev) => { this.onBtnCreateListClick(ev); });
		this.el.on("click", ".j-add-to-list", (ev) => { this.onBtnAddToListClick(ev); });
		this.el.on("click", ".j-remove-from-list", (ev) => { this.onBtnRemoveFromListClick(ev); });
		this.el.submit((ev) => { this.onSubmit(ev); });

		this.model.wishCreated = false;

		this.btnCopy.each((i) => {
			const clipboard = new Clipboard(this.btnCopy[i]);
			clipboard.on("success", ev => this.onClipboardSuccess(ev));
			clipboard.on("error", () => this.flashMessageService.showMessage(__("Stiskněte Ctrl + C pro zkopírování")));
		});

		this.el.on("click", ".j-shareurl", ev => WishlistModalWidget.onShareUrlClick(ev));
	}

	begin() {
		// iOS scroll position bug workaround (https://remysharp.com/2012/05/24/issues-with-position-fixed-scrolling-on-ios)
		// (zamknuti scrollovani sice v iOS nefunguje, ale po jejim "odemceni" se obnovi scroll pozice do stavu
		// pred chybou)
		$.scrollLock(true);

		if (this.model.shareUrl) {
			dry.dom.selectText(this.shareUrlOutput);
		}
	}

	end() {
		// iOS scroll position bug workaround (https://remysharp.com/2012/05/24/issues-with-position-fixed-scrolling-on-ios)
		// (zamknuti scrollovani sice v iOS nefunguje, ale po jejim "odemceni" se obnovi scroll pozice do stavu
		// pred chybou)
		$.scrollLock(false);

		// Ve fancyboxu se create callback vola az po zavreni fancyboxu
		if (this.model.wishCreated) {
			setTimeout(() => {
				this.onWishCreated();
			}, 300);
			this.model.wishCreated = false;
		}
	}

	onClipboardSuccess() {
		this.btnCopy.html(`
			<svg class="icon mrq" aria-hidden="true">
				<use xlink:href="${getIconSpriteUrl(checkIcon)}"></use>
			</svg>${__("Zkopírováno!")}
		`);
	}

	onBtnCopyClick(ev) {
		ev.preventDefault();

		if (typeof Clipboard === "undefined") {
			this.flashMessageService.showMessage(__("Stiskněte Ctrl + C pro zkopírování"));
		}
	}

	onBtnCreateListClick(ev) {
		ev.preventDefault();
		this.btnCreateList.hide();
		this.createGroup.show();
		this.createInput.focus();
	}

	onBtnAddToListClick(ev) {
		ev.preventDefault();

		const wishlistId = $(ev.currentTarget).data("wishlist-id");
		const wishlistTitle = $(ev.currentTarget).data("wishlist-title");

		if (wishlistId) {
			this.spinner.show();
			this.model.submitted = true;
			this.wishlistService.createWishlistItem(
				this.model.item,
				wishlistId,
				(response) => {
					this.spinner.hide();
					this.onCreated(response);
				},
				() => {
					this.spinner.hide();
					this.onError();
				},
			);
		} else if (wishlistTitle) {
			this.spinner.show();
			this.model.submitted = true;
			if (this.model.item && this.model.item.id && this.model.item.type) {
				this.wishlistService.createWishlist(
					wishlistTitle,
					(response) => {
						this.spinner.hide();
						this.onCreated(response);
					},
					() => {
						this.spinner.hide();
						this.onError();
					},
					this.model.item,
				);
			} else {
				this.wishlistService.createWishlist(
					wishlistTitle,
					(response) => {
						this.spinner.hide();
						this.onCreated(response);
					},
					() => {
						this.spinner.hide();
						this.onError();
					},
				);
			}
		}
	}

	onBtnRemoveFromListClick(ev) {
		ev.preventDefault();
		let wishlistItemId = $(ev.currentTarget).data("wishlist-item-id");
		if (wishlistItemId) {
			wishlistItemId = parseInt(wishlistItemId, 10);
			this.spinner.show();
			this.model.submitted = true;
			this.wishlistService.removeWishlistItem(
				wishlistItemId,
				this.model.item.id,
				this.model.item.type,
				() => {
					this.spinner.hide();
					this.model.wishlistItemIds.splice(this.model.wishlistItemIds.indexOf(wishlistItemId), 1);
					if (this.model.wishlistItemIds.length === 0) {
						this.fancyboxService.close();
					}
					$(ev.currentTarget).slideUp();
				},
				() => {
					this.spinner.hide();
					this.onError();
				},
			);
		}
	}

	onCreated(response) {
		this.createContainer.hide();
		this.shareContainer.show();
		this.setShareUrl(response.shareUrl);
		dry.dom.selectText(this.shareUrlOutput);

		if (this.model.item && this.model.item.id) {
			// Ve fancyboxu se wish create callback vola az po zavreni fancyboxu
			if (this.model.position === "modal") {
				this.model.wishCreated = true;
			} else {
				this.onWishCreated();
			}
		}
	}

	onError() {
		this.model.submitted = false;
	}

	onWishCreated() {
		const offset = this.sourceWidget ? this.sourceWidget.getOffset() : null;
		this.app.dispatch(new WishCreateEvent(this.model.item.id, this.model.item.type, offset));
	}

	onSubmit(ev) {
		ev.preventDefault();

		if (this.model.submitted) {
			return;
		}

		const wishlistTitle = $.trim(this.createInput.val());
		if (wishlistTitle) {
			if (this.model.item && this.model.item.id && this.model.item.type) {
				this.model.submitted = true;
				this.wishlistService.createWishlist(
					wishlistTitle,
					(response) => {
						this.onCreated(response);
					},
					() => {
						this.onError();
					},
					this.model.item,
				);
			} else {
				this.model.submitted = true;
				this.wishlistService.createWishlist(
					wishlistTitle,
					(response) => {
						this.onCreated(response);
					},
					() => {
						this.onError();
					},
				);
			}
		}
	}

	setShareUrl(shareUrl) {
		this.model.shareUrl = shareUrl;
		this.btnCopy.attr("data-clipboard-text", this.model.shareUrl);
		this.shareUrlOutput.val(this.model.shareUrl);
		this.shareUrlButton.attr("href", this.model.shareUrl);
	}
}

WishlistModalWidget.onShareUrlClick = (ev) => {
	dry.dom.selectText(ev.currentTarget);
};

export default WishlistModalWidget;
