import $ from "jquery";
import Widget from "./../../../../Inlined/Widget";

export default class ReservationFormWidget extends Widget {

	constructor(el, model, children) {
		super(el, model, children);
	}

	bind() {
		super.bind();

		this.terms = $(".j-terms", this.el);
		this.voucher = $(".j-voucher", this.el);
		this.voucherAttachment = $(".j-voucherattachment", this.el);
		this.voucherNo = $(".j-voucher-no", this.el)
		this.voucherType = $(".j-voucher-type :input", this.el);
		this.voucherYes = $(".j-voucher-yes", this.el);
		this.someoneElse = $(".j-someone-else", this.el);
		this.visitorName = $(".j-visitor-name", this.el);
		this.inputs = $(":input", this.el);
		this.formSecondPart = $(".j-form-second", this.el);
		this.continueButton = $(".j-continue", this.el);

		this.terms.children(".j-term").each((term => this.bindTerm(term)));

		this.voucherNo.on("click", (ev) => this.onChangeVoucher(ev, false));
		this.voucherType.on("change", (ev) => this.onChangeVoucherType(ev));
		this.voucherYes.on("click", (ev) => this.onChangeVoucher(ev, true));
		this.onChangeVoucherType();

		this.someoneElse.on("change", (ev) => this.onChangeSomeoneElse(ev));
		this.onChangeSomeoneElse();

		if (this.model.reservationType === "reservation") {
			this.inputs.on("change", (ev) => this.onFormChange(ev));
		}

		this.continueButton.on("click", (ev) => this.onContinueClick(ev));
		if (this.model.showSecondPart) {
			this.onContinueClick();
		} else {
			this.formSecondPart.hide();
		}
		this.el.submit((ev) => this.onSubmit(ev));

		if (!$.fn.datepicker) {
			require.ensure(
				[],
				() => {
					require("bootstrap-datepicker");
					require("bootstrap-datepicker/dist/locales/bootstrap-datepicker.cs.min.js");
					return this.bindDatepicker();
				},
				"bundle/bootstrap-datepicker"
			);
		} else {
			this.bindDatepicker();
		}
	}

	bindDatepicker() {
		$(".j-arrival", this.el)
			.datepicker({
				autoclose: true,
				format: "dd. mm. yyyy",
				language: "cs",
				startDate: "0d"
			})
			.on("changeDate", (ev) => {
				// Datum odjezdu pozdejsi nez datum prijezdu
				$(ev.currentTarget)
					.closest(".j-term")
					.find(".j-departure")
					.datepicker("setStartDate", ev.date);
			});
		$(".j-departure", this.el).datepicker({
			autoclose: true,
			format: "dd. mm. yyyy",
			language: "cs",
			startDate: "0d"
		});
	}

	onContinueClick(ev) {
		if (ev) {
			ev.preventDefault();
		}
		this.formSecondPart.show();
		this.continueButton.hide();
	}

	onFormChange() {
		$.ajax({
			type: "POST",
			url: this.model.ajaxSaveFormUrl,
			data: this.el.serialize(),
		});
	}

	bindTerm(term) {
		$(".j-add-term", term).on("click", (ev) => this.onAddTerm(ev));
		$(".j-remove-term", term).on("click", (ev) => this.onRemoveTerm(ev));
		$(".j-arrival", term).on("change", (ev) => this.recountNights(ev));
		$(".j-departure", term).on("change", (ev) => this.recountNights(ev));
		$(".j-term .j-remove-term", this.el).show();
		$(".j-term:first .j-remove-term", this.el).hide();
		this.recountNights();
	}

	onChangeVoucher(ev, hasVoucher) {
		ev.preventDefault();
		$(".j-voucher-help", this.el).remove();
		this.voucherNo.parent(".control-group").removeClass("error");
		this.voucherYes.removeClass("active");
		this.voucherNo.removeClass("active");
		$(ev.currentTarget).addClass("active");

		if (hasVoucher) {
			this.voucher.show();
		} else {
			this.voucher.hide();
		}

		this.model.hasVoucher = hasVoucher;
	}

	onChangeVoucherType() {
		let selectedVoucherType = this.voucherType.filter(":checked");
		let selectedVoucherRadio = selectedVoucherType.parent(".radio");
		$(".j-voucher-attachment", this.el).hide();
		$(".j-voucher-attachment-" + selectedVoucherType.val(), this.el).show();

		if (selectedVoucherRadio.length) {
			this.voucherAttachment.insertAfter(selectedVoucherRadio);
		}
	}

	onChangeSomeoneElse() {
		if ($(".j-someone-else input", this.el).is(":checked")) {
			this.visitorName.show();
		} else {
			this.visitorName.hide();
		}
	}

	onAddTerm(ev) {
		if (ev) {
			ev.preventDefault();
		}
		let lastRow = this.terms.children(".j-term:last");

		let newRow = lastRow.clone();
		lastRow.after(newRow);
		let lastIteration = Number(lastRow.attr("id").split("-")[1]),
			newIteration = lastIteration + 1;

		newRow.attr("id", "term-" + newIteration);
		newRow.find("input").each(function () {
			let replaceRegex = new RegExp(lastIteration.toString(), "g");
			$(this).attr("name", $(this).attr("name").replace(replaceRegex, newIteration));
			$(this).attr("id", $(this).attr("id").replace(replaceRegex, newIteration));
		});
		newRow.children(".j-remove-term").removeClass("hidden");
		this.bindTerm(newRow);
		this.bindDatepicker();
	}

	onSubmit(ev) {
		if (typeof this.model.hasVoucher !== "boolean") {
			ev.preventDefault();
			$(".j-voucher-help", this.el).remove();
			$(`<span class="help-block text-error j-voucher-help">Máte již voucher?</span>`).insertAfter(this.voucherNo);
			this.voucherNo.parent(".control-group").addClass("error");
		}
	}

	onRemoveTerm(ev) {
		if (ev) {
			ev.preventDefault();
		}
		if (this.terms.find(".j-term").length > 1) {
			$(ev.target).parents(".j-term").remove()
		}
	}

	recountNights() {
		this.terms.children(".j-term").each(function () {
			let arrivalParts = $(this).find(".j-arrival").val().replace("/ /g", "").split("."),
				departureParts = $(this).find(".j-departure").val().replace("/ /g", "").split(".");
			let arrivalDate = new Date(arrivalParts[2], arrivalParts[1] - 1, arrivalParts[0]),
				departureDate = new Date(departureParts[2], departureParts[1] - 1, departureParts[0]);
			let nights = parseInt((departureDate.getTime() - arrivalDate.getTime()) / (24 * 3600 * 1000)),
				nightsText = "-";

			if (Number(nights) > 0) {
				nightsText = dry.str.formatCzechInflection(
					nights,
					["1 noc", `${nights} noci`, `${nights} nocí`]
				);
				$(this).find(".j-night-count-label").show();
			} else {
				$(this).find(".j-night-count-label").hide();
			}
			$(this).find(".j-night-count").html(nightsText);
		});
	}

}