import $ from "jquery";
import AbstractFilterFormWidget from "./AbstractFilterFormWidget";
import AbstractFilterWidget from "./AbstractFilterWidget";

export default class SearchLocalZoneFilterWidget extends AbstractFilterWidget {
	bind() {
		super.bind();

		this.form = this.findParentByType(AbstractFilterFormWidget) || null;

		if (!this.form) {
			throw new Error("I need AbstractFilterFormWidget parent!");
		}
		this.el.on("click", ".j-more", ev => SearchLocalZoneFilterWidget.onMoreClick(ev));
	}

	// eslint-disable-next-line class-methods-use-this
	serialize() {
		// Nepridava se do URL jako filtr, ale jako slug trail
		return null;
	}
}

SearchLocalZoneFilterWidget.onMoreClick = function onMoreClick(ev) {
	ev.preventDefault();
	ev.stopPropagation();

	const $button = $(ev.currentTarget);
	const $list = $button.closest(".j-list");

	$(".hide", $list).removeClass("hide");
	$button.hide();
};
