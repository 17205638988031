import $ from "jquery";
import ResponsiveWidget from "../ResponsiveWidget";
import ResponsiveMenuMixin from "../Navigation/ResponsiveMenuMixin";
import UserAuthStateEvent from "../../Event/UserAuthStateEvent";

export default class UserWidget extends ResponsiveWidget {
	static HEADER_POSITION = "header";

	static FIXHEADER_POSITION = "fixheader";

	constructor(el, model, children, app, ajaxService, userService) {
		super(el, model, children);
		this.app = app;
		this.ajaxService = ajaxService;
		this.userService = userService;

		this.bindResponsiveMenu = ResponsiveMenuMixin.bindResponsiveMenu;
		this.placeResponsiveMenu = ResponsiveMenuMixin.placeResponsiveMenu;

		this.userMenuDesktop = $(".j-usermenu-desktop", this.el);
		this.userMenuMobile = $(".j-usermenu-mobile", this.el);
	}

	bind() {
		super.bind();
		this.dropdownMenus = $(".dropdown-menu", this.el);
		this.isBtnToolbar = this.el.hasClass("btn-toolbar");
		this.onUserAuthFn = ev => this.onUserAuth(ev);
		this.userService.on(UserAuthStateEvent, this.onUserAuthFn);
		if (this.model.position === UserWidget.HEADER_POSITION) {
			this.bindResponsiveMenu($(".f-menu-mobile"));
		}
	}

	unbind() {
		super.unbind();
		this.userService.off(UserAuthStateEvent, this.onUserAuthFn);
	}

	onDeviceSizeChange(ev) {
		const mobile = "mobile";
		const desktop = "desktop";
		if (this.model.position === UserWidget.HEADER_POSITION) {
			const placement = ev.deviceSize === "xs" || ev.deviceSize === "sm" ? mobile : desktop;
			this.placeResponsiveMenu(placement);

			if (placement === mobile) {
				this.userMenuDesktop.hide();
				this.userMenuMobile.css("display", "flex");
			} else {
				this.userMenuMobile.hide();
				this.userMenuDesktop.show();
			}
		}
	}

	onUserAuth(ev) {
		if (ev.newState === "complete") {
			this.reload();
		}
	}
}
