import $ from "jquery";
import Widget from "../../../../Inlined/Widget";
import WishCreateEvent from "../../Event/WishCreateEvent";
import WishDeleteEvent from "../../Event/WishDeleteEvent";
/* global __ */
/* global window */
/* global dry */

export default class WishlistItemAddonWidget extends Widget {
	constructor(el, model, children, wishlistService, app) {
		super(el, model, children);

		this.wishlistService = wishlistService;
		this.app = app;
	}

	bind() {
		super.bind();

		// For remove/reactivate animations
		this.addon = $(".j-wish-addon", this.el);
		this.removedAlert = $(".j-wish-removed-alert", this.el);

		// Note handling
		this.btnAddNote = $(".j-wish-note-add", this.el);
		this.btnSaveNote = $(".j-wish-note-save", this.el);
		this.note = $(".j-wish-note", this.el);
		this.noteForm = $(".j-wish-note-form", this.el);
		this.noteText = $(".j-wish-note-text", this.el);
		this.noteDate = $(".j-wish-note-date", this.el);
		this.noteInput = $("textarea[name ='note']", this.el);

		this.app.on(WishCreateEvent, this.onWishCreateFn = (ev) => { this.onWishCreate(ev); });
		this.app.on(WishDeleteEvent, this.onWishDeleteFn = (ev) => { this.onWishDelete(ev); });
		this.el.submit((ev) => { this.onSubmit(ev); });
		this.btnAddNote.click((ev) => { this.onBtnAddNoteClick(ev); });

		// WishlistItem Remove/reactivate
		this.el.on("click", ".j-wish-remove", (ev) => { this.onRemoveClick(ev); });
		this.el.on("click", ".j-wish-reactivate", (ev) => { this.onReactivateClick(ev); });
		this.el.on("click", ".j-wish-reactivate-reload", (ev) => { this.onReactivateClick(ev, true); });

		// Add to another wishlist
		this.el.on("click", ".j-wish-copy", (ev) => {
			ev.preventDefault();
			this.wishlistService.openDialog(this.model.itemId, this.model.itemType);
		});
	}

	unbind() {
		super.unbind();

		this.app.off(WishCreateEvent, this.onWishCreateFn);
		this.app.off(WishDeleteEvent, this.onWishDeleteFn);
	}

	onSubmit(ev) {
		ev.preventDefault();
		const note = this.noteInput.val();

		if (note) {
			this.btnSaveNote.text(__("Ukládám..."));
			this.wishlistService.updateWishlistItemNote(
				note,
				this.model.wishlistItemId,
				() => {
					this.btnSaveNote.text(__("Uložit"));
					this.noteForm.hide();
					this.onNoteSaved(note);
				},
			);
		} else {
			this.noteForm.hide();
			this.btnAddNote.show();
			this.note.show();
		}
	}

	onNoteSaved(note) {
		let noteHtml = dry.str.escapeHtml(note);
		noteHtml = dry.str.nl2Br(noteHtml);

		this.model.note = note;

		// if noteText div exists
		if (this.noteText.length) {
			this.noteText.html(noteHtml);
			this.noteDate.text(__("Upraveno před chvílí"));
		} else {
			this.noteText = $(`<p class='j-wish-note-text'>${noteHtml}</p>`);
			this.note.prepend(this.noteText);
		}

		this.btnAddNote
			.text(__("Upravit poznámku"))
			.show();
		this.note.show();
	}

	onBtnAddNoteClick(ev) {
		ev.preventDefault();

		this.noteInput.val(this.model.note);
		this.btnAddNote.hide();
		this.noteForm.show();
		this.note.hide();
		this.noteInput.focus();
	}

	onRemoveClick(ev) {
		ev.preventDefault();
		this.wishlistService.updateWishlistItemState(
			this.model.wishlistItemId,
			"removed",
			() => {
				this.app.dispatch(new WishDeleteEvent(this.model.itemId, this.model.itemType));
			},
		);
	}

	onReactivateClick(ev, forceReload) {
		ev.preventDefault(ev);
		this.wishlistService.updateWishlistItemState(
			this.model.wishlistItemId,
			"active",
			() => {
				if (forceReload) {
					window.location.reload();
				} else {
					this.app.dispatch(new WishCreateEvent(this.model.itemId, this.model.itemType));
				}
			},
		);
	}

	onWishCreate(ev) {
		if (ev.itemId === this.model.itemId && ev.itemType === this.model.itemType) {
			this.removedAlert.hide();
			this.addon.show();
		}
	}

	onWishDelete(ev) {
		if (ev.itemId === this.model.itemId && ev.itemType === this.model.itemType) {
			this.removedAlert.show();
			this.addon.hide();
		}
	}
}
