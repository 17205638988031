import $ from "jquery";
import AbstractFilterFormWidget from "./AbstractFilterFormWidget";
import TopFilterFormWidget from "./TopFilterFormWidget";
import AbstractFilterWidget from "./AbstractFilterWidget";

export default class FlagFilterWidget extends AbstractFilterWidget {
	bind() {
		super.bind();

		this.form = this.findParentByType(AbstractFilterFormWidget) || null;

		if (!this.form) {
			throw new Error("I need AbstractFilterFormWidget parent!");
		}

		this.flags = $(".j-flag", this.el);
		this.tooltips = $("[data-toggle='tooltip']", this.el);

		this.flags.on("change", () => {
			this.onChange();
		});
		this.tooltips.tooltip();
	}

	// eslint-disable-next-line class-methods-use-this
	isVisible = () => true;

	onChange() {
		this.model.flag = [].slice.call(this.flags.filter(":checked").map((i, el) => ($(el).val()))).sort();
		this.filterService.setFilter(this.getId(), this.getName(), this.serialize());

		// Submit on change when filter is in top position
		if (this.form instanceof TopFilterFormWidget) {
			this.form.filter();
		}
	}

	serialize() {
		return this.model.flag ? this.model.flag.join() : null;
	}
}
