import $ from 'jquery';
import formurldecoded from 'form-urldecoded';
import Widget from '../../../../Inlined/Widget';

const REVIEW_COMPLETED_ERROR = 'review-already-completed';

const REVIEW_NOT_FOUND_ERROR = 'review-not-found';

export default class ReviewFormWidget extends Widget {
	bind() {
		super.bind();

		this.updateReviewUrl = this.model.updateReviewUrl;
		this.autosaveSended = false;

		this.form = this.el;
		this.invalidInput = $('.error', this.el);

		this.starRatingBar = $('.j-stars-rating-bar', this.el);
		this.starRatingBar.click(ev => this.onFormChange(ev));

		this.form.change(ev => this.onFormChange(ev));

		setTimeout(
			() => {
				if (this.invalidInput.length > 0) {
					this.scrollToInvalidInput();
				}
			},
			0,
		);
	}

	onFormChange(ev) {
		if (this.autosaveSended === false) {
			this.autosaveSended = true;
			let savedBadge = null;
			const parentFieldset = ev.target.closest('.j-item-wrapper');

			if ($(parentFieldset).length > 0) {
				savedBadge = $(parentFieldset).find('.saved-badge').get(0);
			}

			const formData = formurldecoded($(this.form).serialize()).review_form;
			// need index items array from 0
			formData.items = formData.items.filter(() => true);

			$.ajax({
				url: this.updateReviewUrl,
				data: JSON.stringify(formData),
				dataType: 'json',
				type: 'post',
				error: (response) => {
					const httpCode = response.status;

					if (httpCode === 422) {
						const { error } = response.responseJSON;

						if (error === REVIEW_NOT_FOUND_ERROR) {
							window.location.href = this.model.notFoundPageUrl;
						} else if (error === REVIEW_COMPLETED_ERROR) {
							window.location.href = this.model.reviewCompletedUrl;
						}
					} else {
						window.location.href = this.model.errorPageUrl;
					}
				},
				complete: () => {
					this.autosaveSended = false;

					if (savedBadge && $(savedBadge).length > 0) {
						$(savedBadge).removeClass('hide');
					}
				},
			});
		}
	}

	scrollToInvalidInput() {
		$('html,body').animate({ scrollTop: this.invalidInput.first().offset().top - 10 }, 1000);
	}
}
