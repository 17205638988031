/* eslint-disable global-require */
/* global __LOCALE__ */

const locales = require("../../Locale/LocaleEnum").default;

if (__LOCALE__ === locales.csCz) {
	module.exports = require("./FrontendContainer.cs_CZ").default;
} else if (__LOCALE__ === locales.plPl) {
	module.exports = require("./FrontendContainer.pl_PL").default;
} else {
	throw new Error(`Unsupported locale "${__LOCALE__}".`);
}
