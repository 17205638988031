import $ from "jquery";
import Widget from "../../../../Inlined/Widget";
import BeforeExitEvent from "../../Event/BeforeExitEvent";

export default class MenuUserLocationWidget extends Widget {
	constructor(el, model, children, app, userLocationService) {
		super(el, model, children);
		this.app = app;
		this.userLocationService = userLocationService;
	}

	bind() {
		super.bind();
		this.btnLocation = $(".j-location", this.el);
		this.btnGo = $(".j-go", this.el);

		this.btnLocation.click(ev => this.onBtnLocationClick(ev));
		this.btnGo.click(ev => this.onBtnGoClick(ev));
	}

	onBtnLocationClick(ev) {
		ev.preventDefault();
		this.userLocationService.openDialog();
	}

	onBtnGoClick(ev) {
		// Prejit na detail zony
		if (!this.model.requireLocation) {
			return;
		}

		const exitUrl = $(ev.currentTarget).attr("href");
		const zoneId = $(ev.currentTarget).data("id");
		const zone = this.model.zones[zoneId];

		// K ulozeni zony k uzivateli je potreba locationId
		if (!zone.locationId) {
			return;
		}

		// Vyzadat si nastaveni mest uzivatele
		ev.preventDefault();
		this.app.dispatch(new BeforeExitEvent(exitUrl, zone));
	}
}
