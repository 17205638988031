import $ from "jquery";
import Widget from "./../../../Inlined/Widget";
import CountdownEndEvent from "../Event/CountdownEndEvent";

export default class CountdownWidget extends Widget {
	constructor(el, model, children, app, dateService) {
		super(el, model, children);

		this.app = app;
		this.dateService = dateService;
	}

	bind() {
		super.bind();

		this.hours = $(".j-hours", this.el);
		this.minutes = $(".j-minutes", this.el);
		this.seconds = $(".j-seconds", this.el);

		this.model.ended = false;

		if (this.model.remaining >= 0 || this.model.to >= 0) {
			this.model.start = new Date();
			this.tick();
			setInterval((() => { this.tick(); }), 500);

			// Fade-in efekt
			$(this.el).addClass("in");
			// Zviditelnit cislice, pokud byly na zacatku neviditelne
			// (pouziva se u cachovaneho obsahu)
			this.hours.removeClass("invisible");
			this.minutes.removeClass("invisible");
			this.seconds.removeClass("invisible");
		}
	}

	tick() {
		let elapsed;
		let counter;
		let hours;
		let minutes;
		let now;
		let seconds;

		counter = null;

		// Odpocitavani na zaklade zbyvajiciho casu
		if (this.model.remaining >= 0) {
			now = new Date();
			elapsed = (now.getTime() - this.model.start.getTime()) / 1000;
			counter = Math.max(0, this.model.remaining - elapsed);

		// Odpocitavani na zaklade ciloveho casu
		} else if (this.model.to >= 0) {
			now = this.dateService.getDate();
			counter = Math.max(0, this.model.to - (now.getTime() / 1000));
		}

		if (counter === null) {
			return;
		}

		if (counter === 0 && !this.model.ended) {
			this.app.dispatch(new CountdownEndEvent(this));
			this.model.ended = true;
		}

		hours = Math.floor(counter / 3600);
		minutes = Math.floor((counter / 60) % 60);
		seconds = Math.floor(counter % 60);

		hours = (hours < 10 ? "0" : "") + hours;
		minutes = (minutes < 10 ? "0" : "") + minutes;
		seconds = (seconds < 10 ? "0" : "") + seconds;

		this.hours[0].innerText = hours;
		this.hours[0].textContent = hours;
		this.minutes[0].innerText = minutes;
		this.minutes[0].textContent = minutes;
		this.seconds[0].innerText = seconds;
		this.seconds[0].textContent = seconds;
	}
}
