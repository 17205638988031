import $ from "jquery";
import Widget from "./../../../Inlined/Widget";
import BeforeExitEvent from "../Event/BeforeExitEvent";

export default class LocalBoxWidget extends Widget {

	constructor(el, model, children, app, userLocationService) {
		super(el, model, children);

		this.app = app;
		this.userLocationService = userLocationService;
	}

	bind() {
		super.bind();

		this.btnZone = $(".j-zone-go", this.el);
		this.btnLocation = $(".j-location", this.el);

		this.btnZone.click(ev => this.onBtnZoneClick(ev));
		this.btnLocation.click(ev => this.onBtnLocationClick(ev));
	}

	onBtnZoneClick(ev) {

		// Prejit na detail zony
		if (!this.model.requireLocation) {
			return;
		}

		let exitUrl = $(ev.currentTarget).attr("href");
		let zoneId = $(ev.currentTarget).data("id");
		let zone = this.model.zones[zoneId];

		// K ulozeni zony k uzivateli je potreba locationId
		if (!zone.locationId) {
			return;
		}

		// Vyzadat si nastaveni mest uzivatele
		ev.preventDefault();
		this.app.dispatch(new BeforeExitEvent(exitUrl, zone));
	}

	onBtnLocationClick(ev) {
		ev.preventDefault();
		this.userLocationService.openDialog();
	}

}
