import $ from "jquery";
import ResponsiveWidget from "../ResponsiveWidget";
import ItemPreviewWidget from "./ItemPreviewWidget";
/* global window */

export default class ItemDetailWidget extends ResponsiveWidget {
	constructor(el, model, children, app, ajaxService, scrollService) {
		super(el, model, children);
		this.app = app;
		this.ajaxService = ajaxService;
		this.scrollService = scrollService;
		this.termsBtn = $(".j-scroll-to-terms", this.el);
		this.window = $(window);
	}

	bind() {
		super.bind();
		this.item = this.findByType(ItemPreviewWidget)[0] || null;
		// Malo vysky na mobilu. Odskrolovat primo na detail.
		setTimeout(
			() => {
				if (this.getDeviceSize() === "xs") {
					this.scrollIntoView();
				}
			},
			0,
		);
		this.termsBtn.click(() => this.onTermsBtnClick());
	}

	getHash() {
		return this.item ? this.item.getHash() : null;
	}

	getSlug() {
		return this.item ? this.item.getSlug() : null;
	}

	getPageTitle() {
		return this.item ? this.item.getPageTitle() : null;
	}

	scrollIntoView() {
		const { top } = this.el.offset();
		$(window).scrollTop(top);
	}

	onTermsBtnClick() {
		const termsTable = $(".deal-terms-table", this.el);
		const termsTableOffset = termsTable.offset().top;
		if (termsTable) {
			$("html, body").stop().animate({ scrollTop: termsTableOffset }, 300);
		}
	}
}
