/* global window */
/* global dry */
/* global navigator */

window.dry = window.dry || {};
dry.browser = dry.browser || {};

dry.browser.isAndroid = () => {
	const ua = navigator.userAgent.toLowerCase();
	return ua.indexOf("android") > -1;
};

// Returns the version of Internet Explorer or a null
// (indicating the use of another browser).
dry.browser.getIEVersion = () => {
	let rv = null; // Return value assumes failure.
	let ua;
	let re;
	if (navigator.appName === "Microsoft Internet Explorer") {
		ua = navigator.userAgent;
		/* eslint-disable */
		re = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
		/* eslint-enable */
		if (re.exec(ua) !== null) {
			rv = parseFloat(RegExp.$1);
		}
	} else if (navigator.appName === "Netscape") {
		// in IE 11 the navigator.appVersion says "Trident"
		// in Edge the navigator.appVersion does not say trident
		if (navigator.appVersion.indexOf("Trident") === -1) {
			rv = 12;
		} else {
			rv = 11;
		}
	}
	return rv;
};
