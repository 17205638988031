const Cookies = require("js-cookie"); // custom library

/* global __DEV__ */
/* global skrz */
/* global window */
/* global document */

const svg4everybody = require("svg4everybody");

// basics
const $ = require("jquery");

window.jQuery = $;
window.$ = $;
global.$ = $;
// application
const Application = require("../../Inlined/Application").default;
const UserVerifiedEvent = require("./Event/UserVerifiedEvent").default;
const { decodeString } = require("./utils");

// Vendors
// conditional import, will not require both modules
// eslint-disable-next-line import/no-dynamic-require
require(__DEV__ ? "jquery-migrate" : "jquery-migrate/dist/jquery-migrate.min.js");
// Mute all deprecation messages
window.jQuery.migrateMute = true;

svg4everybody();

// Intersection observer polyfill for IE 11 support
// https://www.npmjs.com/package/intersection-observer
// https://bundlephobia.com/result?p=intersection-observer@0.11.0
require("intersection-observer");
// eslint-disable-next-line import/no-dynamic-require
require("vanilla-lazyload");

module.exports = (FrontendContainer) => {
	const hvrName = [104, 118, 114].map(it => (String.fromCharCode(it))).join("");
	let hvrChecked = false;

	const options = JSON.parse(document.getElementById("js-options").textContent
		|| document.getElementById("js-options").innerHTML);
	options.hvrName = hvrName;

	const container = new FrontendContainer(options);
	const application = new Application(container);
	container.set("Application", application);
	application.processQueue(skrz.queue);

	// human verification
	const ajaxService = container.getAjaxService();

	if (!Cookies.get(hvrName)) {
		const hvrCheck = () => {
			if (hvrChecked) {
				return;
			}

			hvrChecked = true;
			Cookies.set(
				`pre_${hvrName}`,
				`${[73, 32, 97, 109, 32, 104, 117, 109, 97, 110].map(it => (String.fromCharCode(it))).join("")}.`,
				{
					domain: `.${options.domain}`,
					path: "/",
					secure: true,
					expires: new Date(new Date().getTime() + 3600),
				},
			);
			ajaxService.ajax({ type: "GET", url: `/${hvrName}` }).done((response) => {
				if (response.ok) {
					application.dispatch(new UserVerifiedEvent(response.userId, response.visitId));
				}
			});
		};

		$(document.body).one("mousemove touchstart", hvrCheck);
		$(window).one("scroll", hvrCheck);
	}


	// services
	skrz.service = skrz.service || {};
	skrz.service.deal = container.getDealService();
	skrz.service.importItem = container.getImportItemService();
	skrz.service.user = container.getUserService();
	skrz.service.watchdog = container.getWatchdogService();
	skrz.service.itemPreview = container.getItemPreviewService();
	skrz.service.itemWatchdog = container.getItemWatchdogService();
	skrz.service.facebook = container.getFacebookService();
	skrz.service.fancybox = container.getFancyboxService();
	skrz.service.mobileDetect = container.getMobileDetectService();
	skrz.service.hit = container.getHitService();
	skrz.service.notification = container.getNotificationService(); // Spustit notification API
	skrz.service.lazyloading = container.getLazyLoadingService();

	if (options.loginId) {
		skrz.service.user.resume(options.loginId, options.payload);
	}

	$(window).on("load", () => {
		$(".j-load-css").each(() => {
			const el = $(this);
			const url = el.data("css-href");
			el.attr("href", url);
			const a = document.createElement("a");
			a.href = url;
			// V IE9- chybi pocatecni "/"
			const relativeUrl = (a.pathname.charAt(0) === "/" ? a.pathname : `/${a.pathname}`) + a.search;

			Cookies.set(
				"assets",
				relativeUrl,
				{
					domain: `.${options.domain}`,
					secure: true,
					path: "/",
					expires: 30,
				},
			);
		});
	});

	// Some links have target that we dont want to get indexed.
	// So it's encoded in code (reversed) and replaced before click
	$(document).on("mousedown", "[data-link]", (ev) => {
		const link = $(ev.currentTarget).attr("data-link");

		if (!link) {
			return;
		}
		// eslint-disable-next-line no-param-reassign
		ev.currentTarget.href = decodeString(link);
		$(ev.currentTarget).removeAttr("data-link");
	});
};
