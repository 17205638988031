import $ from "jquery";
import AbstractFilterFormWidget from "./AbstractFilterFormWidget";
import AbstractFilterWidget from "./AbstractFilterWidget";

export default class TravelPersonsFilterWidget extends AbstractFilterWidget {
	bind() {
		super.bind();

		this.form = this.findParentByType(AbstractFilterFormWidget) || null;

		if (!this.form) {
			throw new Error("I need AbstractFilterFormWidget parent!");
		}

		this.btnSubmit = $(".j-persons-submit", this.el);
		this.links = $(".j-link", this.el);
		this.persons = $(".j-persons", this.el);

		this.btnSubmit.click(ev => this.onBtnSubmitClick(ev));
		this.persons.change(() => this.onChange());
		this.persons.keyup(ev => this.onChange(ev));
	}

	onBtnSubmitClick(ev) {
		ev.preventDefault();
		this.filter();
	}

	onChange() {
		const emptyValue = "emptyValue";
		if (this.persons.val() === emptyValue) {
			this.model.persons = null;
		} else {
			this.model.persons = this.persons.val();
		}
		this.filterService.setFilter(this.getId(), this.getName(), this.serialize());
	}

	serialize = () => this.model.persons || null;
}
