import $ from "jquery";
import Widget from "../../../../Inlined/Widget";
import WishCreateEvent from "../../Event/WishCreateEvent";
import WishDeleteEvent from "../../Event/WishDeleteEvent";
import FixHeaderWidget from "../FixHeaderWidget";
import FixHeaderShowEvent from "../../Event/FixHeaderShowEvent";
import FixHeaderHideEvent from "../../Event/FixHeaderHideEvent";
import { getIconSpriteUrl } from "../../utils";
import heartFullIcon from "../../Styles/def/img/icons/heart-full.svg";

export default class WishlistGroupListWidget extends Widget {
	constructor(el, model, children, app, ajaxService, bodyWidget) {
		super(el, model, children);

		this.app = app;
		this.ajaxService = ajaxService;
		this.bodyWidget = bodyWidget;
	}

	animateGhost(offset, cb) {
		const $body = $("body");

		const $ghost = $(`
			<svg class='icon ghost-heart' aria-hidden='true'>
				<use xlink:href='${getIconSpriteUrl(heartFullIcon)}'></use>
			</svg>
		`);
		$ghost.css({
			position: "absolute",
			top: offset.top,
			left: offset.left,
		});
		$body.append($ghost);

		const userHistoryOffset = this.heart.offset();

		$ghost.animate(
			{
				left: userHistoryOffset.left,
				top: userHistoryOffset.top,
			},
			700,
			() => {
				this.heart.addClass("s-beat");

				// Navysit pocet jeste pred AJAX refreshem
				const currentCount = Number.parseInt(this.count.text(), 10);
				this.count
					.text(currentCount + 1)
					.removeClass("bye");
				$ghost.remove();

				setTimeout(
					() => {
						this.heart.removeClass("s-beat");
						if (cb) {
							cb();
						}
					},
					1000,
				);
			},
		);
	}

	bind() {
		super.bind();

		this.count = $(".j-count", this.el);
		this.fixHeader = this.bodyWidget.findByType(FixHeaderWidget)[0] || null;
		this.heart = $(".j-heart", this.el);

		this.app.on(WishCreateEvent, this.onWishCreateFn = (ev) => { this.onCreate(ev); });
		this.app.on(WishDeleteEvent, this.onWishDeleteFn = (ev) => { this.onDelete(ev); });

		if (this.findParentByType(FixHeaderWidget)) {
			this.model.isGhost = this.fixHeader && this.fixHeader.el.visible();
			this.app.on(FixHeaderShowEvent, this.onFixHeaderShowFn = () => { this.model.isGhost = true; });
			this.app.on(FixHeaderHideEvent, this.onFixHeaderHideFn = () => { this.model.isGhost = false; });
		} else {
			this.model.isGhost = !this.fixHeader || !this.fixHeader.el.visible();
			this.app.on(FixHeaderShowEvent, this.onFixHeaderShowFn = () => { this.model.isGhost = false; });
			this.app.on(FixHeaderHideEvent, this.onFixHeaderHideFn = () => { this.model.isGhost = true; });
		}
	}

	unbind() {
		super.unbind();

		this.app.off(WishCreateEvent, this.onWishCreateFn);
		this.app.off(WishDeleteEvent, this.onWishDeleteFn);
		this.app.off(FixHeaderShowEvent, this.onFixHeaderShowFn);
		this.app.off(FixHeaderHideEvent, this.onFixHeaderHideFn);
	}

	onCreate(ev) {
		// Je-li zapnut "ghost effekt" a udalost WishCreateEvent ma zadany offset (nutne k animaci srdicka)
		if (this.model.isGhost && ev.offset) {
			setTimeout(
				() => {
					this.animateGhost(ev.offset, () => {
						this.reload();
					});
				},
				100,
			);
		} else {
			this.reload();
		}
	}

	onDelete() {
		this.reload();
	}
}
