import $ from "jquery";
import Widget from "../../../../Inlined/Widget";
import UserAuthResumeEnum from "../../VO/UserAuthResumeEnum";
import UserAuthStateEnum from "../../VO/UserAuthStateEnum";
import UserBiscuitEnum from "../../VO/UserBiscuitEnum";
import UserAuthStateEvent from "../../Event/UserAuthStateEvent";

export default class TopicDayLoginWidget extends Widget {
	constructor(el, model, children, userService, fancyboxService) {
		super(el, model, children);
		this.userService = userService;
		this.fancyboxService = fancyboxService;
		this.userAuthResume = UserAuthResumeEnum.TOPICDAY_LOGIN;
	}

	bind() {
		super.bind();
		this.btnClose = $(".j-close", this.el);
		this.btnRegister = $(".j-register", this.el);
		this.login = $(".j-login", this.el);
		this.success = $(".j-success", this.el);

		this.btnClose.click(this.onBtnCloseClick);
		this.btnRegister.click(this.onBtnRegisterClick);
		this.userService.register(this.userAuthResume, ev => this.onUserAuthResume(ev));
		this.userService.on(UserAuthStateEvent, ev => this.onUserAuth(ev));
	}

	begin() {
		this.goToLogin();
	}

	goToLogin() {
		this.login.show();
		this.success.hide();
		this.el.removeClass("s-success");
	}

	goToSuccess() {
		this.login.hide();
		this.success.show();
		this.el.addClass("s-success");
	}

	onLoginSuccess() {
		this.login.hide();
		this.success.show();
		this.el.css({ minHeight: 0 });
	}

	end() {
		this.userService.setBiscuit(UserBiscuitEnum.TOPIC_DAY_LOGIN, 1, 30);
	}

	onBtnCloseClick(ev) {
		ev.preventDefault();
		this.fancyboxService.close();
	}

	onBtnRegisterClick(ev) {
		ev.preventDefault();
		this.userService.setLoginId(this.userAuthResume);
		this.userService.setPayload({});
		this.userService.setInternalTrackingParameters(this.getInternalTrackingParameters());

		if (this.userService.isLoggedIn() && !this.userService.isAnonymous()) {
			this.setTopicMailing();
			this.goToSuccess();
		} else {
			this.userService.doLogin();
		}

		this.model.waitingForAuth = true;
	}

	onUserAuth(ev) {
		if (this.model.waitingForAuth && ev.newState === UserAuthStateEnum.COMPLETE_STATE) {
			this.model.waitingForAuth = false;
			// Turn on topicday mailing on login / registration success
			this.setTopicMailing();
		}
	}

	onUserAuthResume() {
		// Turn on topicday mailing on login / registration success
		this.setTopicMailing();
	}

	setTopicMailing() {
		this.userService.setSettings({
			emailing_push_email_frequency: "medium",
			only_up: true,
		});
	}
}
