import $ from "jquery";
import Widget from "./../../../../Inlined/Widget";
import TopicDayCountdownWidget from "./TopicDayCountdownWidget";
import CountdownEndEvent from "../../Event/CountdownEndEvent";
import TopicDayRegistrationWidget from "./TopicDayRegistrationWidget";

/* global window */

export default class TopicDayDetailWidget extends Widget {
	constructor(el, model, children, app) {
		super(el, model, children);

		this.app = app;
	}

	bind() {
		super.bind();

		this.btnReload = $(".j-reload", this.el);
		this.countdown = this.findByType(TopicDayCountdownWidget)[0] || null;
		this.overlay = $(".j-overlay", this.el);
		this.registrationWidget = this.app.findByType(TopicDayRegistrationWidget)[0] || null;

		this.btnReload.click((ev) => { this.onBtnReloadClick(ev); });
		this.onCountdownEndFn = ((ev) => { this.onCountdownEnd(ev); });
		this.overlay.click((ev) => { this.onBtnReloadClick(ev); });
		this.app.on(CountdownEndEvent, this.onCountdownEndFn);
	}

	unbind() {
		this.app.off(CountdownEndEvent, this.onCountdownEndFn);
	}

	onCountdownEnd(ev) {
		// Kdyz skonci topicday pocitadlo
		if (this.countdown && ev.countdown === this.countdown) {
			this.overlay
				.removeClass("bye")
				.show();
			$.scrollLock(true);
			// Zakazat registracni fancybox
			if (this.registrationWidget) {
				this.registrationWidget.setDisabled(true);
			}
		}
	}

	// eslint-disable-next-line class-methods-use-this
	onBtnReloadClick(ev) {
		ev.preventDefault();
		ev.stopPropagation();

		window.location.reload();
	}
}
