import $ from "jquery";
import Base64 from "Base64";
import EventDispatcherMixin from "../../../Inlined/EventDispatcherMixin";
import UserLoginEvent from "../Event/UserLoginEvent";
import UserAuthStateEvent from "../Event/UserAuthStateEvent";
import UserAuthStateEnum from "../VO/UserAuthStateEnum";
import UserVerifiedEvent from "../Event/UserVerifiedEvent";
import UserBiscuitVO from "../VO/UserBiscuitVO";
// eslint-disable-next-line import/named
import { isLocationRequired } from "../Locale";
// eslint-disable-next-line import/named

/* global FB */
/* global __DEV__ */
/* global dry */
/* global __ */
/* global Cookies */

export default class UserService {
	static FORCE_LOGOUT_COOKIE = "forceLogout";

	static instance;

	constructor(
		app,
		domain,
		ajaxService,
		flashMessageService,
		fancyboxService,
		bodyWidget,
		facebookService,
		authUrl,
		userId,
		visitId,
		facebookId,
		uid,
		sid,
		anonymous,
		email,
		registrationType,
		registrationFrom,
		zoneTitle,
		internalTrackingParameters,
		setUserBiscuitUrl,
		getUserBiscuitUrl,
		hvrName,
		shouldTrackRegistration,
		ajaxUserSettingsUrl,
		newUser,
	) {
		if (UserService.instance) {
			this.setBasics(newUser);

			return UserService.instance;
		}
		this.app = app;
		this.domain = domain;
		this.ajaxService = ajaxService;
		this.flashMessageService = flashMessageService;
		this.fancyboxService = fancyboxService;
		this.bodyWidget = bodyWidget;
		this.facebookService = facebookService;
		this.authUrl = authUrl;
		this.userId = userId;
		this.visitId = visitId;
		this.facebookId = facebookId;
		this.uid = uid;
		this.sid = sid;
		this.anonymous = anonymous;
		this.email = email;
		this.registrationType = registrationType;
		this.registrationFrom = registrationFrom;
		this.zoneTitle = zoneTitle;
		this.internalTrackingParameters = internalTrackingParameters;
		this.setUserBiscuitUrl = setUserBiscuitUrl;
		this.getUserBiscuitUrl = getUserBiscuitUrl;
		this.hvrName = hvrName;
		this.shouldTrackRegistration = shouldTrackRegistration;
		this.ajaxUserSettingsUrl = ajaxUserSettingsUrl;

		this.on = EventDispatcherMixin.on;
		this.off = EventDispatcherMixin.off;
		this.dispatch = EventDispatcherMixin.dispatch;

		this.state = UserAuthStateEnum.NEW_STATE;
		if (this.userId && !this.anonymous) {
			this.state = UserAuthStateEnum.COMPLETE_STATE;
		}

		this.app.on(UserVerifiedEvent, ev => this.onUserVerified(ev));

		this.setBasics(newUser);

		this.facebookAutoLogin();

		UserService.instance = this;
	}

	setBasics(newUser) {
		if (!UserService.instance) {
			this.authData = {};
			this.authWidgets = {};
			this.loginId = null;
			this.payload = null;
			this.dialogOpened = false;
			this.resumes = {};
			this.newUser = newUser;
		} else {
			UserService.instance.authData = UserService.instance.authData || {};
			UserService.instance.authWidgets = UserService.instance.authWidgets || {};
			UserService.instance.loginId = UserService.instance.loginId || null;
			UserService.instance.payload = UserService.instance.payload || null;
			UserService.instance.dialogOpened = UserService.instance.dialogOpened || false;
			UserService.instance.resumes = {};
			UserService.instance.newUser = newUser || UserService.instance.newUser;
		}
	}

	closeAuthDialog() {
		const fancyboxWidget = this.fancyboxService.getWidget();

		if (fancyboxWidget && this.getAuthWidgetLoginId(fancyboxWidget)) {
			this.fancyboxService.close();
		}
	}

	isAuthDialogOpened() {
		return this.dialogOpened;
	}

	facebookAutoLogin() {
		// Uzivatel jiz prihlasen
		if (this.isLoggedIn() && !this.isAnonymous()) {
			return false;
		}

		// Po odhlaseni uzivatele kratkodobe zakazeme Facebook autologin
		if (typeof Cookies.get(UserService.FORCE_LOGOUT_COOKIE) === "string") {
			return false;
		}

		this.facebookService.onLoad(() => {
			FB.getLoginStatus((response) => {
				if (response.status === "connected") {
					this.doAuth();
				}
			});
		});
		return true;
	}

	getZoneTitle() {
		return this.zoneTitle;
	}

	getLocationCount() {
		return this.locationCount;
	}

	getRegistrationType() {
		return this.registrationType;
	}

	getRegistrationFrom() {
		return this.registrationFrom;
	}

	getState() {
		return this.state;
	}

	getAuthWidget(loginId) {
		return this.authWidgets[loginId] || null;
	}

	getAuthWidgetLoginId(authWidget) {
		let loginId = null;

		// eslint-disable-next-line no-restricted-syntax
		for (const i in this.authWidgets) {
			if (Object.prototype.hasOwnProperty.call(this.authWidgets, i)) {
				const widget = this.authWidgets[i];
				if (widget === authWidget) {
					loginId = i;
					break;
				}
			}
		}
		return loginId;
	}

	getEmail() {
		return this.email;
	}

	getEmailVideo() {
		return this.emailVideo;
	}

	getLoginId() {
		return this.loginId;
	}

	getPayload() {
		return this.payload;
	}

	getUserId() {
		return this.userId;
	}

	getVisitId() {
		return this.visitId;
	}

	getFacebookId() {
		return this.facebookId;
	}

	// Type string due to number precision
	getUid() {
		return this.uid;
	}

	// Type string due to number precision
	getSid() {
		return this.sid;
	}

	setUid(uid) {
		this.uid = uid;
	}

	setSid(sid) {
		this.sid = sid;
	}

	isCaptchaChallenge() {
		return !!this.captchaChallenge;
	}

	setCaptchaChallenge(captchaChallenge) {
		this.captchaChallenge = captchaChallenge;
	}

	// Returns whether user is challenged to enter a location
	// There is location challenge when user location for given locale is required and user hasn't any
	isLocationChallenge() {
		return isLocationRequired && !this.getLocationCount();
	}

	isLoggedIn() {
		return !!this.userId;
	}

	isAnonymous() {
		return !!this.anonymous;
	}

	isVerifiedHuman() {
		return !!Cookies.get(this.hvrName);
	}

	isNewUser() {
		return !!this.newUser;
	}

	isRegistered() {
		return Boolean(this.isLoggedIn() && !this.isAnonymous() && this.getEmail());
	}

	logUser() {

	}

	setAuthWidget(loginId, widget) {
		this.authWidgets[loginId] = widget;
		return this;
	}

	getBiscuit(name, callback = () => {}) {
		if (this.isLoggedIn()) {
			this.ajaxService.ajax({ method: "POST", url: this.getUserBiscuitUrl, data: { name } })
				.done((response) => {
					let biscuit = null;
					if (response.biscuit !== null) {
						biscuit = UserBiscuitVO.fromArray(response.biscuit);
					}
					callback.apply(this, [biscuit]);
				});
		} else {
			const cookieContent = Cookies.get(name);
			let biscuit = null;
			if (typeof cookieContent === "string") {
				try {
					const jsonString = Base64.atob(cookieContent);
					const jsonArray = JSON.parse(jsonString);
					if (typeof jsonArray === "object" && jsonArray !== null && jsonArray.c) {
						// Detekce UserBiscuit cookie
						biscuit = UserBiscuitVO.fromCookieArray(jsonArray);
					} else {
						// Zpetna kompatibilita s JSON-value cookies
						biscuit = new UserBiscuitVO();
						biscuit.setContent(cookieContent);
					}
				} catch (e) {
					// Zpetna kompatiblita s klasickymi cookies
					biscuit = new UserBiscuitVO();
					biscuit.setContent(cookieContent);
				}
			}
			callback.apply(this, [biscuit]);
		}
	}

	setAnonymous(anonymous) {
		this.anonymous = anonymous;
	}


	setBiscuit(name, content, datetimeExpired = null) {
		if (datetimeExpired === null) {
			// eslint-disable-next-line no-param-reassign
			datetimeExpired = new Date();
			const year = datetimeExpired.getFullYear();
			datetimeExpired.setFullYear(year + 10);
		} else if (typeof datetimeExpired === "number") {
			const datetimeExpiredDays = datetimeExpired;
			// eslint-disable-next-line no-param-reassign
			datetimeExpired = new Date();
			const daysOfMonth = datetimeExpired.getDate();
			datetimeExpired.setDate(daysOfMonth + datetimeExpiredDays);
		}

		if (this.isLoggedIn()) {
			this.ajaxService.ajax({
				method: "POST",
				url: this.setUserBiscuitUrl,
				data: {
					name,
					content,
					datetimeExpired: dry.date.getMySqlString(datetimeExpired),
				},
			});
		} else {
			const biscuit = new UserBiscuitVO();
			biscuit.setName(name);
			biscuit.setContent(content);
			biscuit.setDatetimeExpired(datetimeExpired);

			const jsonArray = UserBiscuitVO.toCookieArray(biscuit);
			const jsonString = JSON.stringify(jsonArray);
			const base64String = Base64.btoa(jsonString);

			Cookies.set(name, base64String, {
				domain: `.${this.domain}`,
				path: "/",
				secure: true,
				expires: datetimeExpired,
			});
		}
		return this;
	}

	setInternalTrackingParameters(internalTrackingParameters) {
		this.internalTrackingParameters = $.extend({}, this.internalTrackingParameters, internalTrackingParameters);
		return this;
	}

	setLocationCount(locationCount) {
		this.locationCount = locationCount;
	}

	setLoginId(loginId) {
		this.loginId = loginId;
		return this;
	}

	setPayload(payload) {
		this.payload = payload;
		return this;
	}

	setState(state) {
		this.state = state;
		return this;
	}

	setUserId(userId) {
		this.userId = userId;
	}

	register(loginId, cb) {
		if (!loginId) {
			throw new Error("Resume auth ID not specified.");
		}
		if (this.resumes[loginId]) {
			throw new Error(`Resume auth '${loginId}' already registered.`);
		}

		this.resumes[loginId] = cb;
	}

	resume(loginId, payload) {
		this.loginId = loginId;
		this.payload = payload;

		if (!this.resumes[loginId]) {
			throw new Error(`Could not resume '${loginId}' auth.`);
		}
		this.resumes[loginId](payload);
	}

	doFacebookLogin(onResponse) {
		// eslint-disable-next-line no-unused-expressions,no-console
		__DEV__ && console.log("UserService: doFacebookLogin");
		// eslint-disable-next-line no-unused-expressions,no-console
		__DEV__ && console.log("UserService: setState", UserAuthStateEnum.NEW_STATE);

		this.setState(UserAuthStateEnum.NEW_STATE);
		// Nelze spolehat na Facebook API (napr. anonymni Firefox FB API blokuje)
		if (this.facebookService.isLoaded()) {
			FB.login(
				(response, ...args) => {
					if (onResponse) {
						onResponse(...args);
					}
					this.onFacebookResponse(response);
				},
				{ scope: "email" },
			);
		} else {
			this.doAuth({}, onResponse, onResponse);
		}
	}

	doLogin(onResponse) {
		this.setState(UserAuthStateEnum.NEW_STATE);
		this.doAuth({}, onResponse, onResponse);
	}

	getAuthData() {
		return this.authData;
	}

	doAuth(data = {}, onSuccess, onError) {
		this.sendAuth(this.state, data, onSuccess, onError);
	}

	sendAuth(state, data, onSuccess, onError) {
		// eslint-disable-next-line no-unused-expressions,no-console
		__DEV__ && console.log("UserService: sendAuth", state, data);

		// eslint-disable-next-line no-param-reassign
		data.state = state;
		// eslint-disable-next-line no-param-reassign
		data.loginId = this.loginId;
		// eslint-disable-next-line no-param-reassign
		data.payload = this.payload;
		let { authUrl } = this;
		let join = authUrl.indexOf("?") >= 0 ? "&" : "?";

		Object.keys(this.internalTrackingParameters).forEach((i) => {
			authUrl = `${authUrl}${join}${i}=${this.internalTrackingParameters[i]}`;
			join = "&";
		});

		// Ulozit odesilana data, aby se mohla propsat do vsech auth widgetu
		$.extend(this.authData, data);
		delete (this.authData.password);

		this.ajaxService.ajax({
			method: "POST",
			url: authUrl,
			data,
			success: ((response, textStatus, jqXHR) => {
				const args = [data, response, textStatus, jqXHR];
				if (response.ok) {
					this.onAuthSuccess(...args);
					if (onSuccess) {
						onSuccess.apply(this, args);
					}
				} else {
					this.onAuthError(...args);
					if (onError) {
						onError.apply(this, args);
					}
				}
			}),
			error: ((jqXHR, textStatus, errorThrown) => {
				const response = null;
				const args = [data, response, textStatus, jqXHR, errorThrown];
				this.onAuthError(...args);
				if (onError) {
					onError.apply(this, args);
				}
			}),
		});
	}

	getSettings() {
		return this.ajaxService
			.ajax({
				method: "GET",
				url: this.ajaxUserSettingsUrl,
			})
			.then(response => response.userEmailingSettings);
	}

	setSettings(settings = {}) {
		return this.ajaxService.ajax({
			method: "POST",
			url: this.ajaxUserSettingsUrl,
			data: settings,
		});
	}

	onFacebookResponse(response) {
		this.doAuth();
	}

	onAuthSuccess(data, response) {
		// eslint-disable-next-line no-unused-expressions,no-console
		__DEV__ && console.log("UserService: newState", response.nextState);
		// eslint-disable-next-line no-unused-expressions,no-console
		__DEV__ && console.log("UserService: oldState", this.state);

		if (data.loginId) {
			const authWidget = this.getAuthWidget(data.loginId);

			if (!authWidget && response.userSubscribeHtml) {
				const renderedHtml = this.app.render(response.userSubscribeHtml, this.bodyWidget);
				if (!renderedHtml.widgets.length) {
					throw new Error("No widget in returned HTML.");
				}
				this.setAuthWidget(data.loginId, renderedHtml.widgets[0]);
			}
		}
		let newState;
		let oldState;

		if (response.nextState) {
			newState = response.nextState;
			oldState = this.state;
			this.state = response.nextState;
		} else {
			newState = this.state;
			oldState = this.state;
		}

		if (response.email !== undefined) {
			this.email = response.email;
		}

		if (response.newUser !== undefined) {
			this.newUser = response.newUser;
		}

		if (response.emailVideo !== undefined) {
			this.emailVideo = response.emailVideo;
		}

		if (response.zoneTitle !== undefined) {
			this.zoneTitle = response.zoneTitle;
		}

		if (response.locationCount !== undefined) {
			this.locationCount = response.locationCount;
		}

		if (response.registrationType !== undefined) {
			this.registrationType = response.registrationType;
		}

		if (response.shouldTrackRegistration !== undefined) {
			this.shouldTrackRegistration = response.shouldTrackRegistration;
			// Make captcha challenge when new registration was created
			this.captchaChallenge = response.shouldTrackRegistration;
		}

		const { shouldTrackRegistration } = this;

		if (response.ok && response.loggedIn) {
			this.userId = response.userId;
			this.visitId = response.visitId;
			this.facebookId = response.facebookId;
			this.anonymous = response.anonymous;
			const ev = new UserLoginEvent(this.userId, this.visitId, this.anonymous, this);
			this.dispatch(ev);
			this.onLogin(ev);
		}

		if (oldState !== newState) {
			const ev = new UserAuthStateEvent(
				newState,
				oldState,
				this.userId,
				this.visitId,
				this.isLoggedIn(),
				this.anonymous,
				data.loginId,
				shouldTrackRegistration,
			);
			this.dispatch(ev);
			this.onAuthState(ev);
		}
	}

	onAuthError(data, response) {
		if (response && response.message) {
			this.flashMessageService.showMessage(response.message, "error");
		} else if (!response || !response.validationMessages) {
			this.flashMessageService.showMessage(__("Při komunikaci se serverem došlo k chybě."), "error");
		}
	}

	onAuthState() {
		this.logUser();
		this.openAuthDialog();
	}

	openAuthDialog() {
		if (!this.loginId) {
			return;
		}

		const authWidget = this.getAuthWidget(this.loginId);

		if (!authWidget) {
			return;
		}

		if (this.state === UserAuthStateEnum.COMPLETE_STATE
			&& this.payload
			&& this.payload.silentLogin
			&& !authWidget.getShowLoginComplete()
		) {
			return;
		}

		let fancyboxSettings = {};
		if (authWidget.getFancyboxSettings && authWidget.getFancyboxSettings()) {
			fancyboxSettings = authWidget.getFancyboxSettings();
		}

		this.fancyboxService.openWidget(
			authWidget,
			$.extend(
				{},
				fancyboxSettings,
				{
					afterLoad: (instance, current) => {
						if (fancyboxSettings.afterLoad) {
							fancyboxSettings.afterLoad(instance, current);
						}
						this.dialogOpened = true;
					},
					beforeClose: (instance, current) => {
						if (fancyboxSettings.beforeClose) {
							fancyboxSettings.beforeClose(instance, current);
						}
						this.dialogOpened = false;
					},
					detachOnClose: true,
				},
			),
		);
	}

	onLogin() {
		const authWidget = this.getAuthWidget(this.loginId);

		if (!authWidget) {
			return;
		}

		if (this.payload && this.payload.silentLogin && !authWidget.getShowLoginComplete()) {
			this.closeAuthDialog();
		}
	}

	onUserVerified(ev) {
		// eslint-disable-next-line no-unused-expressions,no-console
		__DEV__ && console.log(`UserService: UserVerifiedEvent(userId=${ev.userId}, visitId=${ev.visitId})`);

		if (ev.userId) {
			this.userId = ev.userId;
		}
		if (ev.visitId) {
			this.visitId = ev.visitId;
		}
		this.logUser();
	}
}
