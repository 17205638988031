window.dry = window.dry || {};
dry.dom = dry.dom || {};

// https://github.com/accursoft/caret/blob/master/jquery.caret.js
dry.dom.getCaretPosition = function(target) {
	var isContentEditable = target.contentEditable === 'true';

	//HTML5
	if (window.getSelection) {
		//contenteditable
		if (isContentEditable) {
			target.focus();
			var range1 = window.getSelection().getRangeAt(0),
				range2 = range1.cloneRange();
			range2.selectNodeContents(target);
			range2.setEnd(range1.endContainer, range1.endOffset);
			return range2.toString().length;
		}
		//textarea
		return target.selectionStart;
	}
	//IE<9
	if (document.selection) {
		target.focus();
		//contenteditable
		if (isContentEditable) {
				var range1 = document.selection.createRange(),
					range2 = document.body.createTextRange();
				range2.moveToElementText(target);
				range2.setEndPoint('EndToEnd', range1);
				return range2.text.length;
		}
		//textarea
		var pos = 0,
				range = target.createTextRange(),
				range2 = document.selection.createRange().duplicate(),
				bookmark = range2.getBookmark();
		range.moveToBookmark(bookmark);
		while (range.moveStart('character', -1) !== 0) pos++;
		return pos;
	}
	//not supported
	return 0;
};


dry.dom.selectText = function(elem) {
	var range, selection;
	// A jQuery selector should pass through too
	elem = (elem.jquery && elem.length) ? elem[0] : elem;
	if ( !elem ) {
		return;
	} else if ( elem.nodeName.match(/^(INPUT|TEXTAREA)$/i) ) {
		elem.focus();
		elem.select();
		// Select input content on mobile Safari
		elem.setSelectionRange(0, 9999);
	} else if ( typeof document.body.createTextRange === "function" ) {
		// IE or Opera <10.5
		range = document.body.createTextRange();
		range.moveToElementText(elem);
		range.select();
	} else if ( typeof window.getSelection === "function" ) {
		selection = window.getSelection();
		if ( typeof selection.setBaseAndExtent === "function" ) {
			// Safari
			selection.setBaseAndExtent(elem, 0, elem, 1);
		} else if ( typeof selection.addRange === "function"
			&& typeof selection.removeAllRanges === "function"
			&& typeof document.createRange === "function" ) {
			// Mozilla or Opera 10.5+
			range = document.createRange();
			range.selectNodeContents(elem);
			selection.removeAllRanges();
			selection.addRange(range);
		}
	}
};