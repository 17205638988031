import $ from "jquery";
import AbstractListingWidget from "../AbstractListingWidget";
import ReviewFilterChangeEvent from "../../Event/ReviewFilterChangeEvent";

export default class ReviewListingWidget extends AbstractListingWidget {
	bind() {
		super.bind();
		this.app.on(ReviewFilterChangeEvent, (ev) => {
			this.onFilterChange(ev);
		});
	}
	getAjaxPageUrl() {
		// eslint-disable-next-line max-len
		return `${this.model.ajaxPageUrlPattern.replace("{order}", this.model.order).replace("{page}", this.model.page + 1)}?${$.param({ filters: this.model.filters })}`;
	}
	onFilterChange(ev) {
		this.model.filters = ev.filters;
		this.model.order = ev.order;
		this.model.page = 0;
		this.load();
	}
}
