import $ from "jquery";
import Widget from "./../../../../Inlined/Widget";

export default class StarsRatingWidget extends Widget {

	constructor(el, model, children, mobileDetectService) {
		super(el, model, children);

		this.mobileDetectService = mobileDetectService;

	}

	bind() {
		super.bind();

		this.starFormInput = $(".j-stars-rating-form-input", this.el);
		this.starRatingBar = $(".j-stars-rating-bar", this.el);
		this.starRating = $(".j-stars-rating", this.el);
		this.starRatingText = $(".j-stars-rating-text", this.el);

		this.ratingRangeFrom = this.model.ratingRangeFrom ? this.model.ratingRangeFrom : 0;
		this.ratingRangeTo = this.model.ratingRangeTo ? this.model.ratingRangeFrom : 5;
		this.ratingRange = this.ratingRangeTo - this.ratingRangeFrom;
		// ratingStep can not be zero, because it will be used for division.
		this.ratingStep = this.model.ratingStep && this.model.ratingStep != 0 ? this.model.ratingStep : 0.5;
		this.roundUnit = 100 * this.ratingStep / this.ratingRange;

		this.starRatingBar.mouseenter((ev) => this.onMouseEnter(ev));
		this.starRatingBar.mouseleave((ev) => this.onMouseLeave(ev));
		this.starRatingBar.mousemove((ev) => this.onMouseMove(ev));
		this.starRatingBar.click((ev) => this.onClick(ev));
	}

	customRound(value, unit) {
		return Math.ceil(value / unit) * unit;
	}

	onMouseEnter(ev) {
		this.offsetLeft = this.starRatingBar.offset().left;
		this.width = this.starRatingBar.width();
	}

	onMouseLeave(ev) {
		// Zadny hover na mobilu a tabletu
		if (this.mobileDetectService.isDesktop()) {
			this.setStars(this.model.percent || 0);
		}
	}

	onMouseMove(ev) {
		// Zadny hover na mobilu a tabletu
		if (this.mobileDetectService.isDesktop()) {
			this.setStarsByMousePosition(ev);
		}
	}

	setRatingText(percent) {
		var textRating;
		if (percent === 0) {
			textRating = "";
		} else if (percent > 0 && percent <= 20) {
			textRating = "nepoužitelná (" + percent + "\u200a%)";
		} else if (percent > 20 && percent <= 40) {
			textRating = "špatná (" + percent + "\u200a%)";
		} else if (percent > 40 && percent <= 60) {
			textRating = "průměrná (" + percent + "\u200a%)";
		} else if (percent > 60 && percent <= 80) {
			textRating = "velmi dobrá (" + percent + "\u200a%)";
		} else if (percent > 80 && percent <= 100) {
			textRating = "perfektní (" + percent + "\u200a%)";
		}
		this.starRatingText.text(textRating);
	}

	setStars(percent) {
		this.starRating.width(percent + "%");
		this.setRatingText(percent);
	}

	setStarsByMousePosition(ev) {
		var percent = this.customRound(100 * (ev.pageX - this.offsetLeft) / this.width - 0.5, this.roundUnit); //-0.5 is offset of starBar
		this.setStars(percent);
		return percent;
	}

	onClick(ev) {
		ev.preventDefault();
		this.model.percent = this.setStarsByMousePosition(ev);
		this.starFormInput.val(this.model.percent);

		// Odkaz presmerovat asynchronne, jinak mobil nezobrazi spravne hvezdicky pred proklikem
		if (ev.currentTarget.tagName === "A") {
			setTimeout(
				() => {
					window.location.href = ev.currentTarget.href;
				},
				0
			)
		}
	}
}