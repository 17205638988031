import SingletonWidget from "../../../Inlined/SingletonWidget";

export default class BodyWidget extends SingletonWidget {
	getSource() {
		return this.model.source;
	}

	getCampaign() {
		return this.model.campaign;
	}

	getMedium() {
		return this.model.medium;
	}

	getTerm() {
		return this.model.term;
	}

	getContent() {
		return this.model.content;
	}

	getPosition() {
		return this.model.position;
	}

	getRank() {
		return this.model.rank;
	}

	getVariant() {
		return this.model.variant;
	}

	getHandler() {
		return this.model.handler;
	}
}
