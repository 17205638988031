const emailSuggestDomains = ["atlas.cz", "centrum.cz", "email.cz", "gmail.com", "hotmail.com", "post.cz", "seznam.cz",
	"tiscali.cz", "volny.cz",
];

const emailDomainsTypos = {
	"gmail.com": ["gmail.cz", "google.com", "google.cz", "gmail.sk", "gemail.cz", "gmai.cz", "gmeil.cz"],
	"seznam.cz": ["emailseznam.cz", "seynam"],
};

/**
 * Compute ‘Levenshtein distance‘.
 * Informally, the Levenshtein distance between two words is equal to the number of single-character edits
 * required to change one word into the other.
 * @param {string} a
 * @param {string} b
 * @returns {int}
 */
const levenshtein = function levenshtein(a, b) {
	if (!a || !b) return (a || b).length;
	const m = [];
	for (let i = 0; i <= b.length; i += 1) {
		m[i] = [i];
		if (i !== 0) {
			for (let j = 0; j <= a.length; j += 1) {
				m[0][j] = j;
				if (j !== 0) {
					m[i][j] = b.charAt(i - 1) === a.charAt(j - 1) ? m[i - 1][j - 1] : Math.min(
						m[i - 1][j - 1] + 1,
						m[i][j - 1] + 1,
						m[i - 1][j] + 1,
					);
				}
			}
		}
	}
	return m[b.length][a.length];
};

const suggestedEmails = {};

/**
 * Finds email suggestion based on given domain typos dictionary.
 * @param {string} email
 * @returns {string|null}
 */
const suggestEmailByDictionary = function suggestByDictionary(email) {
	let suggestedEmail = null;

	// Check given email only once
	if (suggestedEmails[email] !== undefined) {
		return suggestedEmails[email];
	}

	const emailParts = email.split("@");
	const domain = emailParts[1] ? emailParts[1] : null;

	// Invalid email
	if (domain === null) {
		suggestedEmails[email] = suggestedEmail;
		return suggestedEmail;
	}

	// Email contain no typo
	if (emailDomainsTypos[domain] !== undefined) {
		suggestedEmails[email] = suggestedEmail;
		return suggestedEmail;
	}

	const suggestedDomain = Object
		.keys(emailDomainsTypos)
		.find((correctDomain) => {
			const typos = emailDomainsTypos[correctDomain];
			return typos.indexOf(domain) >= 0;
		});

	// Typo replacement not found. Email is correct or cannot suggest.
	if (suggestedDomain === undefined) {
		suggestedEmails[email] = suggestedEmail;
		return suggestedEmail;
	}

	suggestedEmail = `${emailParts[0]}@${suggestedDomain}`;
	suggestedEmails[email] = suggestedEmail;

	return suggestedEmail;
};

/**
 * Finds email suggestion based on list of correct domain names and allowed ‘Levenshtein distance‘.
 * @param {string} email
 * @param {int} maxDistance
 * @returns {string|null}
 */
const suggestEmailByDistance = function suggestEmailByDistance(email, maxDistance) {
	const normalizedEmail = email
		.toLowerCase()
		.trim();
	const atIndex = normalizedEmail.search("@");
	if (atIndex === -1) {
		return null;
	}
	const domain = normalizedEmail.substring(atIndex + 1);
	let minDist = -1;
	let minDomain = null;
	for (let i = 0; i < emailSuggestDomains.length; i += 1) {
		const dist = levenshtein(domain, emailSuggestDomains[i]);
		if (dist === 0) {
			return null; // email already uses correct domain
		}
		if (dist <= maxDistance && (minDist === -1 || minDist > dist)) {
			minDist = dist;
			minDomain = emailSuggestDomains[i];
		}
	}
	if (minDomain == null) {
		return null;
	}

	return email.substring(0, atIndex + 1) + minDomain;
};

/**
 * Finds email suggestion based on two methods (in given order):
 * - domain typos dictionary
 * - list of correct domain names and allowed ‘Levenshtein distance‘.
 * Doesn't suggest anything when email is correct (returns null).
 *
 * @param {string} email
 * @param {int} maxDistance
 * @returns {string|null}
 */
const suggestEmail = function suggestEmail(email, maxDistance = 3) {
	let suggestedEmail;

	suggestedEmail = suggestEmailByDictionary(email);

	if (suggestedEmail) {
		return suggestedEmail;
	}

	suggestedEmail = suggestEmailByDistance(email, maxDistance);
	return suggestedEmail;
};

export default suggestEmail;
