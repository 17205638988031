import WatchdogSubscribeWidget from "../Watchdog/WatchdogSubscribeWidget";
import WatchdogResumeEnum from "../../VO/WatchdogResumeEnum";

export default class TopicDayWatchdogSubscribeWidget extends WatchdogSubscribeWidget {
	constructor(...args) {
		super(...args);

		this.watchdogResume = WatchdogResumeEnum.TOPICDAY_PROMO_RESUME;
	}

	// eslint-disable-next-line class-methods-use-this
	checkWatchdogId(watchdogId) {
		return watchdogId === WatchdogResumeEnum.TOPICDAY_PROMO_RESUME;
	}
}
