import FrontendContainer from "./FrontendContainer";
/* global __DEV__ */

/**
 * This is localized container.
 * Modules imported here will be included in given locale only.
 */
export default class FrontendCsContainer extends FrontendContainer {
	constructor(options) {
		super(options);

		/* eslint-disable-next-line no-console */
		__DEV__ && console.log("cs_CZ container loaded");
	}
}

// Container depends on superclass property
FrontendCsContainer.superclass = FrontendContainer;
