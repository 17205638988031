import $ from "jquery";
import Widget from "./../../../../Inlined/Widget";
import UserAuthResumeEnum from "../../VO/UserAuthResumeEnum";
import UserAuthStateEnum from "../../VO/UserAuthStateEnum";
import UserAuthStateEvent from "../../Event/UserAuthStateEvent";
import { getIconSpriteUrl } from "../../utils";
import checkIcon from "../../Styles/def/img/icons/check.svg";
/* global __ */
/* global document */

export default class DealsSubscribeWidget extends Widget {
	constructor(el, model, children, userService, flashMessageService, subscribeMessageService) {
		super(el, model, children);

		this.userService = userService;
		this.flashMessageService = flashMessageService;
		this.subscribeMessageService = subscribeMessageService;
	}

	bind() {
		super.bind();

		this.model.userAuthResume = UserAuthResumeEnum.DEALS_SUBSCRIBE_RESUME;
		this.model.waitingForAuth = false;

		this.spinner = $(".j-spinner", this.el);
		this.subscribe = $(".j-subscribe", this.el);
		this.subscribe.click(ev => this.onSubscribeClick(ev));
		this.userService.register(this.model.userAuthResume, payload => this.onUserAuthResume(payload));
		this.userService.on(UserAuthStateEvent, ev => this.onUserAuth(ev));
		$(document.body).on("click", ".j-dealssubscribe-retry", this.onRetryFn = ev => this.onRetry(ev));
	}

	unbind() {
		super.unbind();
		$(document.body).off(".j-dealssubscribe-retry", this.onRetryFn);
	}

	onRetry(ev) {
		ev.preventDefault();
		this.setTopicMailing();
	}

	onSubscribeClick(ev) {
		ev.preventDefault();

		this.userService.setLoginId(this.model.userAuthResume);
		this.userService.setPayload({
			silentLogin: true,
		});
		this.userService.setInternalTrackingParameters(this.getInternalTrackingParameters());
		this.userService.doLogin();
		this.model.waitingForAuth = true;
	}

	onUserAuth(ev) {
		if (this.model.waitingForAuth && ev.newState === UserAuthStateEnum.COMPLETE_STATE) {
			this.model.waitingForAuth = false;

			this.setTopicMailing();
		}
	}

	onUserAuthResume() {
		this.model.waitingForAuth = false;
		this.setTopicMailing();
	}

	setTopicMailing() {
		this.userService
			.setSettings({
				emailing_push_email_frequency: "medium",
			})
			.then(
				() => {
					this.subscribeMessageService.showExistingUserSuccessMessage(this.userService.getEmail());

					this.subscribe
						.html(`
							<svg class="icon" aria-hidden="true">
								<use xlink:href='${getIconSpriteUrl(checkIcon)}'></use>
							</svg>
							${__("Odebírání bylo zapnuto")}
						`)
						.prop("disabled", true);
				},
				() => {
					this.flashMessageService.showMessage(
						`${__("Došlo k chybě.")}
						<a class="j-dealssubscribe-retry j-close" href="#">
							${__("Zkusit znovu")}
						</a>`,
						"warning",
						{
							modal: true,
						},
					);
				},
			);
	}
}
