import $ from "jquery";
import Widget from "./../../../../Inlined/Widget";
import ExitReadyEvent from "../../Event/ExitReadyEvent";

export default class ItemBannerWidget extends Widget {

	constructor(el, model, children, app) {
		super(el, model, children);

		this.app = app;
	}

	bind() {
		super.bind();

		this.model.exitDelay = this.model.exitDelay ? this.model.exitDelay : 4000;

		this.btnExit = $(".j-exit", this.el);
		this.spinner = $(".j-banner-spinner", this.el);

		this.btnExit.click((ev) => { this.onBtnExitClick(ev); });

		// Neprerusovat exit
		if (!this.model.interruptExit) {
			setTimeout(() => { this.exit(); }, this.model.exitDelay);
		}
	}

	onBtnExitClick(ev) {
		ev.preventDefault();

		this.exit();
	}

	exit() {
		// Pokud banner prerusuje exit, nezobrazi se spinner
		// Pri exit skryjeme banner a zobrazi se ExitWidget spinner
		if (this.model.interruptExit) {
			this.el.hide();
		}
		this.spinner.hide();
		this.app.dispatch(new ExitReadyEvent());
	}

}