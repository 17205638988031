const AdminnableMixin = {
	hasAdmin() {
		return !!this.admin;
	},

	getAdmin() {
		return this.admin;
	},

	setAdmin(admin) {
		this.children.push(admin);
		this.admin = admin;
	},
};

export default AdminnableMixin;
