import translations from "../../../../../locale/cs_CZ/LC_MESSAGES/Skrz.javascript.json";

const lang = "cs";

// ISO_3166-1 country code
// (https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2)
const country = "CZ";

const applicationName = "Skrz.cz";

const isLocationRequired = false;

const stellaDomain = "https://skrz.cz";
const pennyDomain = "https://muj.skrz.cz";
const adminDomain = "https://admin.skrz.cz";
const datalogicDomain = "https://datalogic.skrz.cz";

export default lang;

export {
	country,
	isLocationRequired,
	lang,
	translations,
	applicationName,
	stellaDomain,
	pennyDomain,
	adminDomain,
	datalogicDomain,
};
