import Widget from "./Widget";

export default class SingletonWidget extends Widget {
	setEl(el) {
		this.el = el;
		// eslint-disable-next-line no-underscore-dangle
		this.__ready__ = true;
	}

	setModel(model) {
		this.model = model;
		// eslint-disable-next-line no-underscore-dangle
		this.__ready__ = true;
	}

	setChildren(children) {
		this.children = children;
		// eslint-disable-next-line no-underscore-dangle
		this.__ready__ = true;
		this.children.map(child => (child.setParent(this)));
	}

	bind() {
		const widgetName = this.constructor.name;
		// eslint-disable-next-line no-underscore-dangle
		if (!this.__ready__) {
			throw new Error(`${widgetName} is not ready to be bound. setEl, setModel, setChildren not called.`);
		}
		// eslint-disable-next-line no-underscore-dangle
		if (this.__bound__) {
			throw new Error(`${widgetName} is singleton, can be bound only once.`);
		}

		super.bind();
	}
}
