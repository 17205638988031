/* global dry */

export default class UserBiscuitVO {
	static fromArray = (arr) => {
		const biscuit = new UserBiscuitVO();
		Object.keys(arr).forEach((k) => {
			if (k === "datetimeCreated" || k === "datetimeUpdated" || k === "datetimeExpired") {
				biscuit[k] = arr[k] ? new Date(arr[k]) : arr[k];
			} else {
				biscuit[k] = arr[k];
			}
		});
		return biscuit;
	};

	static toArray = (biscuit) => {
		const arr = {};
		Object.keys(biscuit).forEach((k) => {
			if (k === "datetimeCreated" || k === "datetimeUpdated" || k === "datetimeExpired") {
				arr[k] = dry.date.getMySqlString(biscuit[k]);
			} else {
				arr[k] = biscuit[k];
			}
		});
		return arr;
	};

	static fromCookieArray = (arr) => {
		const biscuit = new UserBiscuitVO();

		biscuit.content = arr.c;
		biscuit.datetimeExpired = arr.de ? new Date(arr.de) : arr.de;
		return biscuit;
	};

	static toCookieArray = biscuit => ({
		c: biscuit.content,
		de: dry.date.getMySqlString(biscuit.datetimeExpired),
	});

	constructor() {
		this.id = null;
		this.name = null;
		this.content = null;
		this.datetimeCreated = null;
		this.datetimeUpdated = null;
		this.datetimeExpired = null;
		this.userId = null;
	}

	getId() {
		return this.id;
	}

	setId(id) {
		this.id = id;
	}

	getName() {
		return this.name;
	}

	setName(name) {
		this.name = name;
	}

	getContent() {
		return this.content;
	}

	setContent(content) {
		this.content = content;
	}

	getDatetimeCreated() {
		return this.datetimeCreated;
	}

	setDatetimeCreated(datetimeCreated) {
		this.datetimeCreated = datetimeCreated;
	}


	getDatetimeUpdated() {
		return this.datetimeUpdated;
	}

	setDatetimeUpdated(datetimeUpdated) {
		this.datetimeUpdated = datetimeUpdated;
	}

	getDatetimeExpired() {
		return this.datetimeExpired;
	}

	setDatetimeExpired(datetimeExpired) {
		this.datetimeExpired = datetimeExpired;
	}

	getUserId() {
		return this.userId;
	}

	setUserId(userId) {
		this.userId = userId;
	}
}
