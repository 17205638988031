import $ from "jquery";
import AbstractFilterFormWidget from "./AbstractFilterFormWidget";
import AbstractFilterWidget from "./AbstractFilterWidget";

export default class ServerFilterWidget extends AbstractFilterWidget {
	bind() {
		super.bind();

		this.form = this.findParentByType(AbstractFilterFormWidget) || null;

		if (!this.form) {
			throw new Error("I need AbstractFilterFormWidget parent!");
		}

		this.el.on("click", ".j-link", (ev) => { this.onLinkClick(ev); });
		this.el.on("click", ".j-more", (ev) => { this.onMoreClick(ev); });
		this.el.on("change", ".j-check", () => this.onChange());
	}

	// eslint-disable-next-line class-methods-use-this
	onLinkClick(ev) {
		const $this = $(ev.currentTarget);

		// Special handling for filter with radio buttons
		const previousRadios = $this.prevAll(":radio");
		if (previousRadios.length) {
			ev.preventDefault();

			// Zaskrtnuti, protoze nepouzivame <label> element kvuli zjednoduseni markupu a stylu
			const checked = previousRadios.prop("checked");
			previousRadios
				.prop("checked", !checked)
				.change();
			$this.blur();
		}
	}

	// eslint-disable-next-line class-methods-use-this
	onMoreClick(ev) {
		ev.preventDefault();
		ev.stopPropagation();

		const $button = $(ev.currentTarget);
		const $list = $button.closest(".j-list");

		$(".hide", $list).removeClass("hide");
		$button.hide();
	}

	onChange() {
		const inputs = $(".j-check", this.el);
		const emptyValue = "emptyValue";
		const val = inputs.filter(":checked").val();

		if (val === emptyValue) {
			this.model.serverSlug = null;
		} else {
			this.model.serverSlug = val;
		}

		this.filterService.setFilter(this.getId(), this.getName(), this.serialize());
	}

	serialize = () => (this.model.serverSlug || null);
}
