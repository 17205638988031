import $ from "jquery";
import Widget from "./../../../../Inlined/Widget";

export default class PromoWidget extends Widget {

	bind() {
		super.bind();

		this.go = $(".j-go", this.el)
			.add(this.el.filter(".j-go"));
	}
}
