import $ from "jquery";
import Clipboard from "clipboard";
import "../../../../../dry/dom";
import "../../../../../dry/browser";
import Widget from "../../../../Inlined/Widget";
import { decodeString, getIconSpriteUrl } from "../../utils";
import checkIcon from "../../Styles/def/img/icons/check.svg";
/* global __ */
/* global dry */
/* global FB */
/* global window */

export default class ItemShareWidget extends Widget {
	constructor(el, model, children, itemShareService, flashMessageService) {
		super(el, model, children);

		this.itemShareService = itemShareService;
		this.flashMessageService = flashMessageService;
	}

	bind() {
		super.bind();

		this.btnCopy = $(".j-copy", this.el);
		this.btnFacebook = $(".j-facebook", this.el);
		this.btnTwitter = $(".j-twitter", this.el);
		this.shareUrl = $(".j-shareurl", this.el);

		this.btnCopy.click(ev => this.onBtnCopyClick(ev));
		this.btnFacebook.click(ev => this.onBtnFacebookClick(ev));
		this.btnTwitter.click(ev => this.onBtnTwitterClick(ev));
		this.shareUrl.click(ev => this.onShareUrlClick(ev));

		if (this.itemShareService && this.itemShareService.getUrlPattern()) {
			const urlPattern = this.itemShareService.getUrlPattern();
			this.model.shareUrl = urlPattern.replace("{hash}", this.model.hash);
			this.refresh();
		} else if (this.model.hiddenShareUrl) {
			this.model.shareUrl = decodeString(this.model.hiddenShareUrl);
			this.refresh();
		}

		this.btnCopy.each((i) => {
			const clipboard = new Clipboard(
				this.btnCopy[i],
				{
					// Solves issue with clipboard not working due to copy helper element existing outside of focused
					// modal
					// (https://github.com/zenorocha/clipboard.js/issues/155#issuecomment-273124130)
					container: this.el[0],
				},
			);
			clipboard.on("success", ev => this.onClipboardSuccess(ev));
			clipboard.on("error", () => this.flashMessageService.showMessage(__("Stiskněte Ctrl + C pro zkopírování")));
		});
	}

	// eslint-disable-next-line class-methods-use-this
	begin() {
		// iOS scroll position bug workaround (https://remysharp.com/2012/05/24/issues-with-position-fixed-scrolling-on-ios)
		// (zamknuti scrollovani sice v iOS nefunguje, ale po jejim "odemceni" se obnovi scroll pozice
		// do stavu pred chybou)
		$.scrollLock(true);
	}

	// eslint-disable-next-line class-methods-use-this
	end() {
		// iOS scroll position bug workaround (https://remysharp.com/2012/05/24/issues-with-position-fixed-scrolling-on-ios)
		// (zamknuti scrollovani sice v iOS nefunguje, ale po jejim "odemceni" se obnovi scroll pozice
		// do stavu pred chybou)
		$.scrollLock(false);
	}

	onClipboardSuccess() {
		this.btnCopy.html(`
			<svg class="icon mrq" aria-hidden="true">
				<use xlink:href="${getIconSpriteUrl(checkIcon)}"></use>
			</svg>${__("Zkopírováno!")}
		`);
	}

	onBtnCopyClick(ev) {
		ev.preventDefault();
	}

	onBtnFacebookClick(ev) {
		ev.preventDefault();

		FB.ui({
			// https://developers.facebook.com/docs/sharing/reference/share-dialog
			display: "popup",
			method: "share",
			href: this.model.shareUrl,
		});
	}

	onBtnTwitterClick(ev) {
		ev.preventDefault();

		const win = window.open(ev.currentTarget.href, "twitter_share", "left=200,height=300,top=200,width=550");
		win.focus();
	}

	onShareUrlClick(ev) {
		dry.dom.selectText(ev.currentTarget);
	}

	refresh() {
		const tweet = `${this.model.tweetTitle} ${this.model.shareUrl}`;
		const tweetButtonUrl = this.model.tweetButtonUrlPattern.replace("{text}", encodeURIComponent(tweet));

		this.btnCopy.attr("data-clipboard-text", this.model.shareUrl);
		this.shareUrl.val(this.model.shareUrl);
		this.btnTwitter.attr("href", tweetButtonUrl);
	}

	show() {
		this.el.slideDown();
	}
}
