import ReactLoader from "../ReactLoader";
import AbstractFilterWidget from "../../Widget/Filter/AbstractFilterWidget";

export default class CalendarFilterWidget extends AbstractFilterWidget {
	constructor(el, model, children, filterService, app) {
		super(el, model, children);
		this.filterService = filterService;
		this.app = app;
	}

	bind() {
		super.bind();
		this.render();
	}

	render() {
		ReactLoader().then(({ React, ReactDOM, CalendarFilter }) => {
			ReactDOM.render(
				<CalendarFilter
					filterService={this.filterService}
					app={this.app}
					{...this.model}
				/>,
				this.el[0],
			);
		});
	}
}
