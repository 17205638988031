import $ from "jquery";
import Widget from "../../../Inlined/Widget";

/* global document */

export default class BirthdayCoverPromoWidget extends Widget {
	constructor(el, model, children, domain) {
		super(el, model, children);
		this.domain = domain;
	}

	bind() {
		super.bind();
		this.model.pageCounter = null;
		$(document.body).one("mousemove touchstart scroll", () => this.pushPageView());
	}

	getPageCounter() {
		if (this.model.pageCounter === null) {
			this.model.pageCounter = Number(Cookies.get(this.model.cookieName));
			if (Number.isNaN(this.model.pageCounter)) {
				this.model.pageCounter = 0;
			}
		}
		return this.model.pageCounter;
	}

	pushPageView() {
		this.setPageCounter(this.getPageCounter() + 1);
	}

	setPageCounter(count) {
		Cookies.set(
			this.model.cookieName,
			count,
			{
				domain: `.${this.domain}`,
				path: "/",
				secure: true,
				expires: 365,
			},
		);
		this.model.pageCounter = count;
	}
}
