import $ from "jquery";
import Widget from "../../../../Inlined/Widget";

/* global window */

export default class WishlistGroupWidget extends Widget {
	constructor(el, model, children, wishlistService) {
		super(el, model, children);
		this.wishlistService = wishlistService;
	}

	bind() {
		super.bind();

		this.btnDelete = $(".j-delete", this.el);
		this.btnGo = $(".j-go", this.el);

		this.btnDelete.click(() => {
			this.wishlistService.deleteUserWishlist(this.model.userWishlistId, () => window.location.reload());
		});
	}
}
