import $ from "jquery";
import Widget from "./../../../../Inlined/Widget";
import UserBiscuitEnum from "../../VO/UserBiscuitEnum";

export default class KristalovkaPromoWidget extends Widget {

	constructor(el, model, children, userService) {
		super(el, model, children);

		this.userService = userService;
	}

	bind() {
		super.bind();

		this.close = $(".j-close", this.el);
		this.go = $(".j-go", this.el);
		this.close.click((ev) => this.onCloseClick(ev));
	}

	onCloseClick(ev) {
		ev.preventDefault();

		this.userService.setBiscuit(UserBiscuitEnum.KRISTALOVKA_PROMO, 2, 60);
		this.el.slideUp();
	}

}
