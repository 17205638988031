import $ from 'jquery';
import moment from 'moment';
import Widget from '../../../../Inlined/Widget';

const INPUT_DATE_FORMAT = 'dd. mm. yyyy';
const MOMENT_INPUT_DATE_FORMAT = 'DD. MM. YYYY';
const MOMENT_POSTPONE_URL_DATE_FORMAT = 'YYYY-MM-DD';
const POSTPONE_DATE_INPUT_NAME = 'postpone-date';
/* global window */

export default class ReviewPostponeFormWidget extends Widget {
	constructor(el, model, children, ajaxService, flashMessageService) {
		super(el, model, children);

		this.ajaxService = ajaxService;
		this.flashMessageService = flashMessageService;
		this.postponeToDateUrl = this.model.postponeToDateUrl;
		this.postponeToDateUrlPlaceholder = this.model.postponeToDateUrlPlaceholder;
		this.submitInNewTab = this.model.submitInNewTab;
	}

	bind() {
		super.bind();

		this.postponeForm = $('form[name=postpone-review-request]', this.el);
		this.postponeDateInput = $('input[name=postpone-date]', this.el);

		this.postponeForm.submit((ev) => { this.onPostponeFormSubmit(ev); });

		if (!$.fn.datepicker) {
			require.ensure(
				[],
				() => {
					// eslint-disable-next-line
					require('bootstrap-datepicker');
					// eslint-disable-next-line
					require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.cs.min.js');
					return this.bindDatepicker();
				},
				'bundle/bootstrap-datepicker',
			);
		} else {
			this.bindDatepicker();
		}
	}

	bindDatepicker() {
		this.postponeDateInput.datepicker({
			autoclose: true,
			format: INPUT_DATE_FORMAT,
			language: 'cs',
			startDate: '+1d',
			orientation: 'bottom auto',
		});
	}

	onPostponeFormSubmit(ev) {
		ev.preventDefault();
		let postponeDate = null;

		if (typeof ev.target.elements[POSTPONE_DATE_INPUT_NAME] !== 'undefined') {
			postponeDate = moment(
				ev.target.elements[POSTPONE_DATE_INPUT_NAME].value,
				MOMENT_INPUT_DATE_FORMAT,
			);
		}

		if (postponeDate === null || postponeDate.isValid() === false) {
			this.flashMessageService.showMessage('Datum není vyplněno, nebo není validní.', 'warning');

			return false;
		}

		const url = this.postponeToDateUrl.replace(
			this.postponeToDateUrlPlaceholder,
			postponeDate.format(MOMENT_POSTPONE_URL_DATE_FORMAT),
		);

		window.open(url, this.submitInNewTab === true ? '_blank' : '_self');

		return true;
	}
}
