import $ from "jquery";
import Widget from "../../../../Inlined/Widget";
import PageRedirectEvent from "../../Event/PageRedirectEvent";

// eslint-disable-next-line
export default class CategoryWidget extends Widget {
	constructor(el, model, children, app, scrollService) {
		super(el, model, children);
		this.app = app;
		this.scrollService = scrollService;
		this.loadingOverlay = $(".j-loading-overlay", this.el);
		this.isTravelCategory = this.model.isTravelCategory || false;
	}

	bind() {
		super.bind();
		this.app.on(PageRedirectEvent, () => this.onPageRedirect());
	}

	onPageRedirect() {
		this.loadingOverlay.show();
		this.scrollService.bindFloatingInnerElement(this.loadingOverlay, this.el);
	}
}
