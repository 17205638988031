import $ from 'jquery';
import Widget from '../../../Inlined/Widget';
import ConsentManagementLogService from '../Service/ConsentManagementLogService';

export default class FooterWidget extends Widget {
	constructor(el, model, children, consentManagementLogService) {
		super(el, model, children);
		this.consentManagementLogService = consentManagementLogService;
	}

	bind() {
		super.bind();
		this.link = $('.j-link', this.el);
		this.consentManagementLink = $('.j-consent-management');
		this.consentManagementDetail = $('.consent-management-detail');

		this.consentManagementLink.on('click', () => {
			this.consentManagementDetail.removeClass('hidden');
			this.consentManagementLogService.logEvent(ConsentManagementLogService.FOOTER_PRIVACY_SETTINGS);
		});
	}

	static getLinkCategoryTitle(ev) {
		const targetParent = $(ev.target).closest('div') || null;
		const targetData = targetParent ? targetParent.data('ga') : null;
		if (targetData) {
			return $(ev.target).closest('div').data('ga');
		}
		return null;
	}
}
