import $ from "jquery";
import Widget from "./../../../../Inlined/Widget";

/* global window */
/* global dry */

export default class SirensHelpWidget extends Widget {
	bind() {
		super.bind();

		$(window).scroll(this.onWindowScrollFn = dry.fn.throttle((() => this.onScroll()), 150));
		this.onScroll();
	}

	unbind() {
		$(window).off("scroll", this.onWindowScrollFn);
	}

	onScroll() {
		if (this.model.shown) {
			return;
		}

		if ($(window).scrollTop() > 150) {
			this.show();
		}
	}

	show() {
		this.model.shown = true;
		this.el.slideDown();
	}
}
