import * as Sentry from "@sentry/browser";
import "../../../../dry/str";
/* global __DEV__ */
/* global dry */
/* global window */

export default class NotificationService {
	constructor(publicKey, notificationIdentUrl, ajaxService, userService) {
		this.applicationServerPublicKey = publicKey;
		this.notificationIdentUrl = notificationIdentUrl;
		this.ajaxService = ajaxService;
		this.userService = userService;
		this.subscriptionChecksumName = "pushSubscription";

		if (!("serviceWorker" in window.navigator)) {
			// eslint-disable-next-line no-console, no-unused-expressions
			__DEV__ && console.log("[NotificationService] Service workers aren't supported in this browser.");

		// Web push subscriptions are separated by domain/protocol. So allow skrz.cz domain only
		} else if (window.location.hostname.match(/^letaky\./)) {
			// eslint-disable-next-line no-console, no-unused-expressions
			__DEV__ && console.log("[NotificationService] Notifications are supported on skrz.cz domain only.");
		} else {
			try {
				NotificationService.unregisterOneSignal().then(() => {
					// register service worker within "/" scope
					window.navigator.serviceWorker.register(
						"/service-worker.js",
						{
							scope: "/",
						},
					)
						.then(swRegistration => this.onServiceWorkerRegistration(swRegistration))
						.catch(error => Sentry.captureException(error));
				});
			} catch (error) {
				Sentry.captureException(error);
			}
		}
	}

	logCommandForSending(pushSubscription) {
		if (!__DEV__) {
			return;
		}

		const pushSubscriptionJson = pushSubscription.toJSON();

		// Chrome 51 and older doesn't support Web Push Protocol. Send it with HTTP request.
		if (!pushSubscriptionJson.keys) {
			const endpointParts = pushSubscriptionJson.endpoint.split("/");
			const registrationId = endpointParts[endpointParts.length - 1];
			const endpoint = "https://android.googleapis.com/gcm/send";

			// eslint-disable-next-line no-unused-expressions,no-console
			__DEV__ && console.log(`curl \\
--header 'Authorization: key=XXX' \\
--header 'Content-Type: application/json' \\
${endpoint} \\
-d '{"registration_ids":["${registrationId}"]}'`);
		// Sending using web push protocol
		} else {
			// eslint-disable-next-line no-unused-expressions,no-console
			__DEV__ && console.log(`web-push send-notification \\
--endpoint=${pushSubscriptionJson.endpoint} \\
--key=${pushSubscriptionJson.keys.p256dh} \\
--auth=${pushSubscriptionJson.keys.auth} \\
--vapid-subject=mailto:dev@skrz.cz \\
--vapid-pubkey=${this.applicationServerPublicKey} \\
--vapid-pvtkey=XXX \\
--payload='{"title":"Nadpis notifikace","body":"Text notifikace","url":"https://skrz.cz"}' \\
--gcm-api-key=XXX`);
		}
	}

	getSubscriptionChecksum(pushSubscription) {
		const pushSubscriptionJson = pushSubscription.toJSON();

		// Tie push subscription with userId and update if anything changed
		return JSON.stringify({
			pushSubscription: pushSubscriptionJson,
			userId: this.userService.getUserId(),
		});
	}

	onServiceWorkerRegistration(swRegistration) {
		// eslint-disable-next-line no-console, no-unused-expressions
		__DEV__ && console.log("[NotificationService] Service worker is active:", swRegistration.active);

		try {
			NotificationService.getSubscription()
				.then((pushSubscription) => {
					if (pushSubscription) {
						// eslint-disable-next-line no-console, no-unused-expressions
						__DEV__ && console.log("[NotificationService] User HAVE push subscription.", pushSubscription);
					} else {
						// eslint-disable-next-line no-console, no-unused-expressions
						__DEV__ && console.log("[NotificationService] User DOESN'T HAVE push subscription.");
					}

					// We aren’t subscribed to push
					if (!pushSubscription) {
						return;
					}

					// Keep our server in sync with the latest subscription
					this.syncSubscriptionToServer(pushSubscription);
				})
				.catch((err) => {
					// eslint-disable-next-line no-console, no-unused-expressions
					__DEV__ && console.log("[NotificationService] Error during getSubscription()", err);
				});
		} catch (error) {
			Sentry.captureException(error);
		}
	}

	subscribe() {
		try {
			if (!NotificationService.isSupported()) {
				return Promise.reject(new Error("Push notifications are not supported or permission was denied"));
			}

			const applicationServerKey = dry.str.urlB64ToUint8Array(this.applicationServerPublicKey);

			return window.navigator.serviceWorker.ready.then(swRegistration => swRegistration.pushManager.subscribe({
				userVisibleOnly: true,
				// Push service will tie the application's public key to the user's PushSubscription
				applicationServerKey,
			})
				.then((pushSubscription) => {
					// The subscription was successful
					this.syncSubscriptionToServer(pushSubscription);

					return pushSubscription;
				})
				.catch((error) => {
					if (window.Notification.permission === "denied") {
						// eslint-disable-next-line no-unused-expressions, no-console
						__DEV__ && console.log("Permission for Notifications was denied");
					} else {
						Sentry.captureException(error);
					}
				}));
		} catch (error) {
			// intentionally empty
		}
		return null;
	}

	syncSubscriptionToServer(pushSubscription) {
		this.logCommandForSending(pushSubscription);

		const checksum = this.getSubscriptionChecksum(pushSubscription);
		const lastChecksum = window.localStorage.getItem(this.subscriptionChecksumName);
		const inSync = lastChecksum && lastChecksum === checksum;
		const pushSubscriptionJson = pushSubscription.toJSON();

		if (inSync) {
			// eslint-disable-next-line no-console, no-unused-expressions
			__DEV__ && console.log("[NotificationService] subscription synced", pushSubscriptionJson);
			return;
		}

		// eslint-disable-next-line no-console, no-unused-expressions
		__DEV__ && console.log("[NotificationService] sending subscription to server", pushSubscription);

		let data = {
			endpoint: pushSubscriptionJson.endpoint,
		};
		// Chrome 51 and older doesn't support Web Push Protocol. Subscription has endpoint only.
		if (pushSubscriptionJson.keys) {
			data = {
				...data,
				auth: pushSubscriptionJson.keys.auth,
				pushKey: pushSubscriptionJson.keys.p256dh,
			};
		}

		try {
			this.ajaxService
				.ajax({
					url: this.notificationIdentUrl,
					method: "PUT",
					data,
				})
				.done((response) => {
					if (response.ok) {
						window.localStorage.setItem(this.subscriptionChecksumName, checksum);
						// eslint-disable-next-line no-console, no-unused-expressions
						__DEV__ && console.log("[NotificationService] subscription sent", response.id);
					} else {
						// eslint-disable-next-line no-console, no-unused-expressions
						__DEV__ && console.log("[NotificationService] subscription send failed");
					}
				})
				.fail(() => {
					// eslint-disable-next-line no-console, no-unused-expressions
					__DEV__ && console.log("[NotificationService] subscription send failed");
				});
		} catch (error) {
			Sentry.captureException(error);
		}
	}
}

NotificationService.getSubscription = function getSubscription() {
	try {
		if (!NotificationService.isSupported()) {
			return Promise.reject(new Error("Push notifications are not supported or permission was denied"));
		}

		// We need the service worker registration to check for a subscription
		return window.navigator.serviceWorker.ready.then(swRegistration => (
			// Do we already have a push message subscription?
			swRegistration.pushManager.getSubscription()
		));
	} catch (error) {
		Sentry.captureException(error);
		return Promise.reject(new Error("Unknown notification error was sent to Sentry"));
	}
};

NotificationService.isSupported = function isSupported() {
	try {
		if (!("Notification" in window) || !("serviceWorker" in window.navigator)) {
			return false;
		}

		// Are notifications supported in the service worker?
		if (!("showNotification" in window.ServiceWorkerRegistration.prototype)) {
			// eslint-disable-next-line no-console, no-unused-expressions
			__DEV__ && console.log("[NotificationService] Notifications aren't supported.");
			return false;
		}

		// Check the current Notification permission.
		// If its denied, it's a permanent block until the user changes the permission.
		if (window.Notification.permission === "denied") {
			// eslint-disable-next-line no-console, no-unused-expressions
			__DEV__ && console.log("[NotificationService] The user has blocked notifications.");
			return false;
		}

		// Check if push messaging is supported
		if (!("PushManager" in window)) {
			// eslint-disable-next-line no-console, no-unused-expressions
			__DEV__ && console.log("[NotificationService] Push messaging isn't supported.");
			return false;
		}

		return true;
	} catch (error) {
		Sentry.captureException(error);
		return false;
	}
};

NotificationService.unregisterOneSignal = function unregisterOneSignal() {
	try {
		if (!("serviceWorker" in window.navigator)) {
			return Promise.reject(new Error("Service workers are not supported"));
		}

		if (!(typeof window.navigator.serviceWorker.getRegistrations === "function")) {
			return Promise.reject(new Error("There is no registration getter in ServiceWorker"));
		}

		return window.navigator.serviceWorker.getRegistrations()
			.then((registrations) => {
				const unregistrations = [];

				registrations.forEach((registration) => {
					if (registration.active
						&& (
							registration.active.scriptURL.indexOf("OneSignalSDKWorker.js") !== -1
							|| registration.active.scriptURL.indexOf("OneSignalSDKUpdaterWorker.js") !== -1
						)
					) {
						unregistrations.push(registration.unregister());
					}
				});

				return unregistrations.length
					? Promise.all(unregistrations)
					: Promise.resolve([]);
			})
			.catch((error) => {
				Sentry.captureException(error);
				return Promise.resolve([]);
			});
	} catch (error) {
		Sentry.captureException(error);
		return Promise.resolve([]);
	}
};
