import $ from "jquery";
import AbstractMenuWidget from "../AbstractMenuWidget";
import WatchdogSubscribeWidget from "../Watchdog/WatchdogSubscribeWidget";

/* global window */
/* global dry */

export default class FixUserMenuWidget extends AbstractMenuWidget {
	bind() {
		super.bind();

		this.fixWatchdog = this.findByType(WatchdogSubscribeWidget)[0] || null;
		this.fixHeaderWidth = 54;
		const watchdogs = this.app.findByType(WatchdogSubscribeWidget).filter(wd => (!wd.model.inFixedHeader));
		this.usermenu = $(".j-usermenu", this.el);
		this.watchdog = watchdogs[0] || null;

		$(window).scroll(this.onWindowScrollFn = dry.fn.throttle(() => this.onScroll(), 150));
	}

	unbind() {
		super.unbind();
		$(window).off("scroll", this.onWindowScrollFn);
	}

	onScroll() {
		if (!this.fixWatchdog || !this.watchdog) {
			return;
		}

		const rect = this.watchdog.el[0].getBoundingClientRect();

		// Show fixheader watchdog when non-fixed watchdog is out of viewport
		if (rect.bottom <= this.fixHeaderWidth) {
			this.fixWatchdog.show();
			this.usermenu.addClass("s-collapsed");
		} else {
			this.fixWatchdog.hide();
			this.usermenu.removeClass("s-collapsed");
		}
	}
}
