import $ from "jquery";
import Widget from "../../../Inlined/Widget";

/* global window */
/* global matchMedia */

export default class ResponsiveWidget extends Widget {
	constructor(el, model, children) {
		super(el, model, children);
		this.deviceSize = null;
	}

	bind() {
		super.bind();
		this.onWindowResizeFn = ev => this.onWindowResize(ev);
		$(window).on("resize", this.onWindowResizeFn);
		setTimeout(() => this.onWindowResizeFn(), 0);
	}

	unbind() {
		super.unbind();
		$(window).off("resize", this.onWindowResizeFn);
	}

	getDeviceSize() {
		return this.deviceSize;
	}

	isDesktop() {
		return this.deviceSize === "md" || this.deviceSize === "lg";
	}

	// eslint-disable-next-line class-methods-use-this
	onDeviceSizeChange() {

	}

	onWindowResize() {
		let deviceSize = "xs";
		if (matchMedia("(min-width: 1245px)").matches) {
			deviceSize = "lg";
		} else if (matchMedia("(min-width: 1024px) and (max-width: 1244px)").matches) {
			deviceSize = "md";
		} else if (matchMedia("(min-width: 768px) and (max-width: 1023px)").matches) {
			deviceSize = "sm";
		}

		if (deviceSize !== this.deviceSize) {
			this.deviceSize = deviceSize;
			this.onDeviceSizeChange({ deviceSize });
		}
	}
}
