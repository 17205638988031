import $ from "jquery";
import ResponsiveWidget from "./ResponsiveWidget";
import ResponsiveMenuMixin from "./Navigation/ResponsiveMenuMixin";

export default class CategoryNavigationWidget extends ResponsiveWidget {
	constructor(el, model, children) {
		super(el, model, children);
		this.bindResponsiveMenu = ResponsiveMenuMixin.bindResponsiveMenu;
		this.placeResponsiveMenu = ResponsiveMenuMixin.placeResponsiveMenu;
	}

	bind() {
		super.bind();
		const widget = this;
		$(".j-list", this.el).each(function () {
			const list = $(this);
			const button = $(".j-more", list);
			button.click(ev => widget.onMoreClick(ev, list, button));
		});
		this.bindResponsiveMenu($(".f-sidemenu-mobile"));
	}

	onDeviceSizeChange(ev) {
		const placement = ev.deviceSize === "xs" || ev.deviceSize === "sm" ? "mobile" : "desktop";
		this.placeResponsiveMenu(placement);
	}

	// eslint-disable-next-line class-methods-use-this
	onMoreClick(ev, list, button) {
		ev.preventDefault();
		$(".hide", list).removeClass("hide");
		button.hide();
	}
}
