import $ from "jquery";
import Widget from "../../../../../Inlined/Widget";
/* global document */

export default class CarsContestPromoWidget extends Widget {
	constructor(el, model, children, fancyboxService, domain) {
		super(el, model, children);
		this.fancyboxService = fancyboxService;
		this.domain = domain;
	}

	bind() {
		super.bind();

		this.$dialog = $(".j-dialog", this.el);
		this.$btnGo = $(".j-go", this.el);
		this.sequence = [1, 3, 6, 10];

		this.$btnGo.click(ev => this.onBtnGoClick(ev));
		$(document.body).one("mousemove touchstart scroll", () => this.pushPageView());
	}

	getPageCounter() {
		let pageCounter = Number(Cookies.get(this.model.cookieName));

		if (Number.isNaN(pageCounter)) {
			pageCounter = null;
		}
		return pageCounter;
	}

	go() {
		// Jiz nezobrazit, nanejvys 1x za 10 let ;-);
		this.setPageCounter(0, 10 * 365);
	}

	isElementInSequence(x) {
		return this.sequence.indexOf(x) !== -1;
	}

	onBtnGoClick() {
		this.go();
	}

	onClose() {

	}

	openDialog() {
		if (!this.$dialog.length) {
			return false;
		}

		// Dialog nesmi premazat jiny otevreny dialog
		if (this.fancyboxService.getInstance()) {
			return false;
		}

		this.fancyboxService.open({
			src: this.$dialog,
			type: "html",
			beforeClose: () => this.onClose(),
			clickOutside: false,
			clickSlide: false,
		});
		return true;
	}

	pushPageView() {
		let pageCounter = this.getPageCounter();
		// 0 = nezobrazovat promo
		if (pageCounter === 0) {
			return;
		}

		// Prvni zobrazeni
		pageCounter = pageCounter === null
			? 1
			: pageCounter + 1;

		if (this.isElementInSequence(pageCounter)) {
			setTimeout(() => this.openDialog(), 1000);
		}

		// Posledni zobrazeni - vypnout promo
		pageCounter = pageCounter >= this.sequence[this.sequence.length - 1] ? 0 : pageCounter;
		this.setPageCounter(pageCounter, 10 * 365);
	}

	setPageCounter(count, expires) {
		if (count < 0) {
			return;
		}

		Cookies.set(
			this.model.cookieName,
			count,
			{
				domain: `.${this.domain}`,
				path: "/",
				secure: true,
				expires,
			},
		);
	}
}
