import $ from "jquery";
import throttle from "lodash/throttle";
import debounce from "lodash/debounce";
import FixHeaderShowEvent from "../Event/FixHeaderShowEvent";
import FixHeaderHideEvent from "../Event/FixHeaderHideEvent";

/* global window */

export default class ScrollService {
	constructor(app) {
		this.app = app;
		this.window = $(window);
		this.html = $("html, body");
		this.fixHeaderEl = null;
		this.app.on(FixHeaderShowEvent, ev => this.onFixHeaderShow(ev));
		this.app.on(FixHeaderHideEvent, ev => this.onFixHeaderHide(ev));
	}

	getTopOffset() {
		if (this.fixHeaderEl) {
			return this.fixHeaderEl.outerHeight();
		}
		return 0;
	}

	onFixHeaderShow(ev) { this.fixHeaderEl = ev.header.el; }

	onFixHeaderHide() { this.fixHeaderEl = null; }

	scrollToElement(el, offset = 0, cb = () => {}) {
		let scrollTop = (el.offset().top - this.getTopOffset()) + offset;
		this.html.stop().animate({
			scrollTop,
		}, {
			duration: 400,
			done: () => {
				// Korekce, pokud se mezitim cilova scroll pozice zmenila
				scrollTop = ($(el).offset().top - this.getTopOffset()) + offset;

				if (scrollTop !== $(window).scrollTop()) {
					$(window).scrollTop(scrollTop);
				}
				return cb();
			},
		});
	}

	bindOverflowIndicator(el, wrapper, indicatorClassName) {
		ScrollService.setOverflowIndicator(el, wrapper, indicatorClassName);
		this.window.on("resize", () => {
			ScrollService.setOverflowIndicator(el, wrapper, indicatorClassName);
		});
	}

	static setOverflowIndicator(el, wrapper, indicatorClassName) {
		if (!el[0]) {
			return;
		}
		const elScrollWidth = el[0].scrollWidth;
		const elWidth = el.width();
		const elEndWidth = elScrollWidth - elWidth;

		if (elScrollWidth > elWidth) {
			wrapper.addClass(indicatorClassName);
		} else if (wrapper.hasClass(indicatorClassName)) {
			wrapper.removeClass(indicatorClassName);
		}

		el.on("scroll", debounce(() => {
			const elScroll = el.scrollLeft();
			if (elScroll >= elEndWidth) {
				wrapper.removeClass(indicatorClassName);
			} else {
				wrapper.addClass(indicatorClassName);
			}
		}, 100));
	}

	bindFloatingInnerElement(el, parent) {
		ScrollService.setFloatingElementPosition(el, parent);
		this.window.on("scroll", throttle(() => {
			ScrollService.setFloatingElementPosition(el, parent);
		}, 100));
	}

	static setFloatingElementPosition(el, parent) {
		const innerElement = el.children();
		const scrollTop = $(window).scrollTop();
		const parentOffset = parent.offset().top;
		const parentHeight = parent.outerHeight();
		const distance = (parentOffset - scrollTop) - 32;
		const parentBottom = (parentHeight + parentOffset);
		if (distance < 0 && scrollTop < parentBottom) {
			innerElement.addClass("loading-overlay__box--is-floating");
		} else {
			innerElement.removeClass("loading-overlay__box--is-floating");
		}
	}
}
