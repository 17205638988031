import $ from "jquery";
import Widget from "../../../Inlined/Widget";
import markerPng from "../Styles/def/img/map/marker.png";
import "../../../../dry/browser";
import { lang, country } from "../Locale";

const Leaflet = require("leaflet");
require("leaflet.markercluster");

/* global __ */
/* global window */
/* global document */
/* global IntersectionObserver */

export default class MapWidget extends Widget {
	constructor(el, model, children) {
		super(el, model, children);
		super.bind();
	}

	bind() {
		this.map = null;
		this.mapEl = this.model.mapEl
			? $(this.model.mapEl, this.el)
			: this.el;
		this.markers = [];
		this.openedPopupMarker = null;
		this.visiblePoints = [];
		this.mapCloseBtn = $(".j-map-close-btn", this.el);
		this.mapCloseBtn.click(() => this.el.parent().hide());
		this.points = this.model.points && this.model.points.length
			? this.model.points
			: null;
		this.Leaflet = Leaflet;
		this.bindMap();
	}

	bindMap() {
		if (!this.model.intersectionObserver) {
			this.placeMap();
		} else {
			this.placeMap = this.placeMap.bind(this);
			this.observer = new IntersectionObserver(this.placeMap);
			this.observer.observe(this.el[0]);
		}
	}

	// eslint-disable-next-line class-methods-use-this
	async loadStyleAndScript() {
		try {
			const linkElements = $("link[href*='leaflet-js']");
			const scriptElements = $("script[href*='leaflet-js']");

			if (linkElements && linkElements.length === 0 && this.model.linkPath) {
				const cssMain = document.createElement("link");
				cssMain.href = `${this.model.linkPath}`;
				cssMain.rel = "stylesheet";
				cssMain.type = "text/css";
				cssMain.media = "all";
				document.querySelector("head").appendChild(cssMain);
			}

			if (scriptElements && scriptElements.length === 0 && this.model.scriptPath) {
				const scriptMain = document.createElement("script");
				scriptMain.href = `${this.model.scriptPath}`;
				scriptMain.async = null;
				scriptMain.defer = null;
				document.body.appendChild(scriptMain);
			}
		} catch (e) {
			this.hide();
		}
	}

	async placeMap(element = null) {
		const isIntersecting = element && element[0] && element[0].isIntersecting && element[0].isIntersecting === true;
		if (!this.map && (!element || isIntersecting)) {
			await this.loadStyleAndScript();
			if (Leaflet) {
				this.Leaflet = Leaflet;
				this.refresh();
				if (this.observer) this.observer.unobserve(this.el[0]);
			} else {
				throw new Error("There is no map");
			}
		}
	}

	createClusterer() {
		if (!this.map) {
			this.map = this.createMap();
		}
		if (!this.markerCluster) {
			this.markerCluster = this.Leaflet.markerClusterGroup({
				showCoverageOnHover: false,
			});
			this.markerCluster.addTo(this.map);
		}

		return this.markerCluster;
	}

	createMap() {
		if (!this.map) {
			this.map = this.Leaflet.map(
				this.mapEl[0],
				{
					center: MapWidget.getCenter(country),
					closePopupOnClick: false,
					scrollWheelZoom: false,
					zoom: 15,
					maxZoom: 18,
				},
			);

			if (window.dry.browser.getIEVersion() === null) {
				// Other browser then IE - show vector tiles
				this.Leaflet
					.mapboxGL({
						attribution: `<a href="https://www.maptiler.com/license/maps/" target="_blank">
											© MapTiler
										  </a>
										  <a href="https://www.openstreetmap.org/copyright" target="_blank">
										  	© OpenStreetMap contributors
										  </a>`,
						crossOrigin: true,
						accessToken: "not-needed",
						style: MapWidget.getMapStyle(lang, true),
					}).addTo(this.map);
			} else {
				// IE browser - we need to use raster tiles
				this.Leaflet
					.tileLayer(
						MapWidget.getMapStyle(lang, false),
						{
							attribution: `<a href="https://www.maptiler.com/license/maps/" target="_blank">
											© MapTiler
										  </a> 
										  <a href="https://www.openstreetmap.org/copyright" target="_blank">
										  	© OpenStreetMap contributors
										  </a>`,
							crossOrigin: true,
						},
					)
					.addTo(this.map);
			}
		}

		return this.map;
	}

	createMarker(point) {
		const icon = this.Leaflet.icon({
			iconUrl: markerPng,
			iconSize: [24, 35],
			iconAnchor: [12, 35],
		});
		const marker = this.Leaflet.marker(
			[point.lat, point.lng],
			{
				icon,
			},
		);

		marker.point = point;

		// Add popup to marker
		if (this.hasPopup(marker)) {
			const popup = this.Leaflet
				.popup({
					maxWidth: 320,
					offset: this.Leaflet.point(0, -35),
				})
				.setContent(`<span class="spinner"/> ${__("Načítám")}...`);
			marker.bindPopup(popup);
			marker.on("click", () => this.openPopup(marker));
		}

		return marker;
	}

	fitBounds() {
		if (!this.markers.length) {
			return;
		}

		if (this.markers.length > 1) {
			let limitedMarkers;

			// Fit to markers but do not exceed given bounds
			if (this.model.boundsLimit) {
				const { northEast, southWest } = this.model.boundsLimit;
				const limitedBounds = this.Leaflet.latLngBounds(
					this.Leaflet.latLng(northEast.lat, northEast.lng),
					this.Leaflet.latLng(southWest.lat, southWest.lng),
				);
				limitedMarkers = this.markers.filter(marker => limitedBounds.contains(marker.getLatLng()));
			// Fit to markers with no bounds limit
			} else {
				limitedMarkers = this.markers;
			}

			const latLngs = limitedMarkers.map(marker => marker.getLatLng());
			const bounds = this.Leaflet.latLngBounds(latLngs);
			this.map.fitBounds(bounds, { animate: false });
		} else {
			this.map.panTo(this.markers[0].getLatLng(), { animate: false });
		}
	}

	// eslint-disable-next-line class-methods-use-this
	getPopupContent(marker) {
		const { point } = marker;

		if (!point) {
			return $.Deferred().reject();
		}

		return $.Deferred().resolve(`
			<div style="max-width:270px;">
				${point.title ? `<p class="t-larger m0">${point.title}</p>` : ""}
				${point.address ? `<p class="m0">${point.address}</p>` : ""}
				<p class="t-small m0">
					<a href="https://maps.google.com?q=${point.lat},${point.lng}" rel="noreferrer noopener" target="_blank">
						${__("Otevřít velkou mapu")}
					</a> 
				</p>
			</div>
		`);
	}

	// eslint-disable-next-line class-methods-use-this
	hasPopup(marker) {
		const { point } = marker;
		return point && (point.title || point.address);
	}

	hide() {
		this.el.hide();
	}

	openPopup(marker) {
		if (!marker.getPopup()) {
			return false;
		}

		this.markers.forEach(m => m.closePopup());

		// Open popup with sync content
		marker.openPopup();
		this.openedPopupMarker = marker;

		// Open popup with async content
		const asyncContent = this.getPopupContent(marker);
		asyncContent.then(
			(content) => {
				if (this.openedPopupMarker === marker) {
					marker.setPopupContent(content);
					marker.openPopup();
				}
			},
			() => {
				marker.closePopup();
				this.openedPopupMarker = null;
			},
		);

		return true;
	}

	panTo(markerIndex) {
		if (!this.map) {
			return false;
		}

		const marker = this.markers[markerIndex];

		if (!marker) {
			return false;
		}

		this.map.setView(marker.getLatLng());
		this.openPopup(marker);

		return true;
	}


	refresh() {
		if (this.map) {
			this.map.invalidateSize();
		} else {
			this.map = this.createMap();
		}
		if (this.points) {
			this.showPointsOnMap(this.points);
		}
	}

	removeMarkers() {
		this.markers.forEach((marker) => {
			this.markerCluster.removeLayer(marker);
		});

		this.markers = [];
		this.model.visiblePoints = [];
	}

	show() {
		this.el.show();
		this.refresh();
	}

	showPointsOnMap(points) {
		this.createMap();

		if (!this.map) {
			return;
		}

		// Do not rerender markers when not needed
		if (this.model.visiblePoints !== points) {
			this.removeMarkers();
			this.createClusterer();

			// Create markers, add them to cluster
			const markers = points.map(point => this.createMarker(point));
			markers.forEach(marker => this.markerCluster.addLayer(marker));

			this.markers = markers;
			this.model.visiblePoints = points;
		}

		this.fitBounds();
	}
}

MapWidget.getCenter = function getCenter(countryCode) {
	switch (countryCode) {
		case "PL":
			// Warsaw
			return [52.216667, 21.033333];
		default:
			// Prague
			return [50.083333, 14.416667];
	}
};

MapWidget.getMapStyle = function getMapStyle(languageCode, vectors) {
	switch (languageCode) {
		case "pl":
			// Map with Polish names
			return vectors
				? "https://api.maptiler.com/maps/8b4560ba-abfa-422d-9ed1-aa24617931df/style.json"
					+ "?key=HkrAHun6Uzvt529LgMDe"
				: "https://api.maptiler.com/maps/8b4560ba-abfa-422d-9ed1-aa24617931df/256/{z}/{x}/{y}@2x.png"
					+ "?key=HkrAHun6Uzvt529LgMDe";
		default:
			// Map with Czech names
			return vectors
				? "https://api.maptiler.com/maps/67f953cf-ff0f-4952-be44-4f2f1195320e/style.json"
					+ "?key=HkrAHun6Uzvt529LgMDe"
				: "https://api.maptiler.com/maps/67f953cf-ff0f-4952-be44-4f2f1195320e/256/{z}/{x}/{y}@2x.png"
					+ "?key=HkrAHun6Uzvt529LgMDe";
	}
};
