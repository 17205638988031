import "../../../../dry/str";

import moment from "moment";
import {
	EXACT_KEY,
	INTERVAL_KEY,
	VOUCHER_EXPIRATION_FILTER,
	TRAVEL_DAYS_FILTER,
} from "../Widget/Filter/FiltersEnum";

const INPUT_DATE_FORMAT = "DD.M.YYYY";
const OUTPUT_DATE_FORMAT = "YYYY-MM-DD";

/* global dry */
/* global window */

export const FILTER_SEPARATOR = "/";

export const ID_DATA_SEPARATOR = ":";

export const FILTER_DATA_SEPARATOR = ",";

export default class FilterService {
	constructor(filters, topCategoryId) {
		this.filters = filters;
		this.topCategoryId = topCategoryId;
	}

	filter(filterUrlPattern) {
		const activeFilters = this.getActiveFilters();

		// category and server is not part of {filter} placeholder (it uses __CAT__ and __SER__ placeholder instead)
		const categorySlug = activeFilters.kategorie ? activeFilters.kategorie.value : "";
		const serverSlug = activeFilters.server ? activeFilters.server.value : "";

		delete activeFilters.kategorie;
		delete activeFilters.server;

		const filterSlugs = Object
			.keys(activeFilters)
			.map((id) => {
				const filter = activeFilters[id];
				return id + ID_DATA_SEPARATOR + filter.value;
			})
			.sort()
			.join(FILTER_SEPARATOR);

		window.location.href = filterUrlPattern
			.replace("{filter}", filterSlugs)
			.replace("__CAT__", categorySlug)
			.replace("__SER__", serverSlug)
			.replace(/\/+$/, "")
			.replace(/\/{2,}/, "/");
	}

	getActiveFilters() {
		const activeFilters = {};

		Object
			.keys(this.filters)
			.forEach((id) => {
				const filter = this.filters[id];
				if (filter.value) {
					activeFilters[id] = this.filters[id];
				}
			});

		return activeFilters;
	}

	getTermFilterTypeAndValues() {
		const termFilterValues = {
			type: null,
			dateFrom: null,
			dateTo: null,
		};
		let daysDifference = null;
		const filters = this.getActiveFilters();
		if (!filters) {
			return termFilterValues;
		}
		let dateFrom = null;
		let dateTo = null;
		// https://regex101.com/r/Q22oSf/1
		if (filters && filters[VOUCHER_EXPIRATION_FILTER] && filters[VOUCHER_EXPIRATION_FILTER].value) {
			const expirationMatches = filters[VOUCHER_EXPIRATION_FILTER].value.match(
				/(\d{2}\.\d{1,2}\.\d{4})-(\d{2}\.\d{1,2}\.\d{4})/,
			);
			if (expirationMatches && expirationMatches[1] && expirationMatches[2]) {
				dateFrom = moment(expirationMatches[1], INPUT_DATE_FORMAT);
				dateTo = moment(expirationMatches[2], INPUT_DATE_FORMAT);
				daysDifference = Math.round(dateTo.clone().endOf("day")
					.diff(
						dateFrom.clone().startOf("day"),
						"days",
						true,
					));
			}
		}

		if (!dateFrom || !dateTo) {
			return termFilterValues;
		}

		termFilterValues.dateFrom = dateFrom.format(OUTPUT_DATE_FORMAT);
		termFilterValues.dateTo = dateTo.format(OUTPUT_DATE_FORMAT);

		let daysFrom = null;
		let daysTo = null;
		// https://regex101.com/r/amwWEA/1
		if (filters && filters[TRAVEL_DAYS_FILTER] && filters[TRAVEL_DAYS_FILTER].value) {
			const daysMatches = filters[TRAVEL_DAYS_FILTER].value.match(
				/(\d{1,2})-(\d{1,2})/,
			);
			if (daysMatches && daysMatches[1] && daysMatches[2]) {
				daysFrom = parseInt(daysMatches[1], 10);
				daysTo = parseInt(daysMatches[2], 10);
			}
		}

		// 1. check for needed variables existence
		// 2. check for same days length
		// 3. check for exact term
		if (daysDifference && daysFrom && daysTo
			&& daysFrom === daysTo
			&& daysFrom === daysDifference
		) {
			termFilterValues.type = EXACT_KEY;
		} else {
			termFilterValues.type = INTERVAL_KEY;
		}

		return termFilterValues;
	}

	getFilter(id) {
		return this.filters[id] !== undefined
			? this.filters[id]
			: null;
	}

	setFilter(id, name, value) {
		this.filters[id] = {
			name,
			value,
		};
		return this.filters;
	}

	/**
	 * Makes category-aware filter name for event tracking. E.g. PriceFilter -> TravelPriceFilter
	 * @param {string} name
	 * @returns {string}
	 */
	makeFilterTrackingName(name) {
		if (this.topCategoryId === 2 && !name.match(/^(Travel|Tours)/)) {
			return `Travel${name}`;
		}

		return name;
	}
}
