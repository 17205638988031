import $ from "jquery";
import AbstractFilterFormWidget from "./AbstractFilterFormWidget";
import AbstractFilterWidget from "./AbstractFilterWidget";

export default class TravelClassFilterWidget extends AbstractFilterWidget {
	bind() {
		super.bind();

		this.form = this.findParentByType(AbstractFilterFormWidget) || null;

		if (!this.form) {
			throw new Error("I need AbstractFilterFormWidget parent!");
		}

		this.btnSubmit = $(".j-class-submit", this.el);
		this.btnSubmit.click(ev => this.onBtnSubmitClick(ev));

		this.el.on("click", ".j-link", ev => this.onLinkClick(ev));
		this.el.on("change", ".j-check", () => this.onChange());
		this.el.on("click", ".j-more", ev => this.onMoreClick(ev));
	}

	onBtnSubmitClick(ev) {
		ev.preventDefault();
		this.filter();
	}

	onChange() {
		const checkboxes = $(".j-check", this.el);

		this.model.dealClassSlugs = checkboxes.filter(":checked")
			.get()
			.map(checkbox => ($(checkbox).val()))
			.sort();

		if (this.model.dealClassSlugs.length) {
			this.btnSubmit
				.removeClass("btn-info")
				.addClass("btn-success");
		} else {
			this.btnSubmit
				.addClass("btn-info")
				.removeClass("btn-success");
		}

		this.filterService.setFilter(this.getId(), this.getName(), this.serialize());
	}

	// eslint-disable-next-line class-methods-use-this
	onLinkClick(ev) {
		ev.preventDefault();
		const $this = $(ev.currentTarget);

		// Zaskrtnuti, protoze nepouzivame <label> element kvuli zjednoduseni markupu a stylu
		const checked = $this.prevAll(":checkbox").prop("checked");
		$this.prevAll(":checkbox")
			.prop("checked", !checked)
			.change();
		$this.blur();
	}

	// eslint-disable-next-line class-methods-use-this
	onMoreClick(ev) {
		ev.preventDefault();
		ev.stopPropagation();

		const $button = $(ev.currentTarget);
		const $list = $button.closest(".j-list");

		$(".hide", $list).removeClass("hide");
		$button.hide();
	}

	serialize = () => (this.model.dealClassSlugs ? this.model.dealClassSlugs.join(",") : null);
}
