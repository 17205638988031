import $ from "jquery";
import AbstractFilterFormWidget from "./AbstractFilterFormWidget";
import AbstractFilterWidget from "./AbstractFilterWidget";
/* global __ */

export default class PriceFilterWidget extends AbstractFilterWidget {
	bind() {
		super.bind();

		this.form = this.findParentByType(AbstractFilterFormWidget) || null;

		if (!this.form) {
			throw new Error("I need AbstractFilterFormWidget parent!");
		}
		this.fromInput = $(".j-days-from", this.el);
		this.toInput = $(".j-days-to", this.el);
		this.inputsSubmitBtn = $(".j-days-submit", this.el);
		this.shortcuts = $(".j-shortcuts", this.el);
		this.type = $(".j-type", this.el);
		this.typeSelect = $(".j-type-select", this.el);
		this.typeLabel = $(".j-type-label", this.el);

		this.fromInput.on("change keyup", ev => this.onChange(ev));
		this.toInput.on("change keyup", ev => this.onChange(ev));
		this.type.on("click", "a", ev => this.onTypeClick(ev));
		this.typeSelect.on("change", ev => this.onTypeChange(ev));
		this.inputsSubmitBtn.on("click", () => this.filter());
	}

	onChange() {
		const emptyValue = "emptyValue";
		this.model.from = this.fromInput.val() || null;
		if (this.toInput.val() === emptyValue) {
			this.model.to = null;
		} else {
			this.model.to = this.toInput.val();
		}

		this.filterService.setFilter(this.getId(), this.getName(), this.serialize());
	}

	onTypeClick(ev) {
		ev.preventDefault();

		const $anchor = $(ev.currentTarget);
		const $tab = $anchor.parent();
		const type = $tab.data("val");

		this.type.removeClass("active");
		$tab.addClass("active");

		// Show shortcuts for empty type only (price per deal)
		if (!type) {
			this.shortcuts.show();
		} else {
			this.shortcuts.hide();
		}

		// @TODO localize slug
		if (type === "noc") {
			this.typeLabel.text(`${__("Cena za noc")}:`);
		} else {
			this.typeLabel.text(`${__("Cena za pobyt")}:`);
		}

		this.model.type = type;
		this.onChange();
	}

	onTypeChange(ev) {
		this.model.type = $(ev.currentTarget).val();
		this.onChange();
	}

	serialize() {
		if (this.model.from != null || this.model.to != null) {
			return [this.model.from, this.model.to, this.model.type]
				.join("-")
				.replace(/-+$/, "");
		}

		return null;
	}
}
