import ReactLoader from "../ReactLoader";
import Widget from "../../../../Inlined/Widget";

export default class ZonesFilterWidget extends Widget {
	constructor(el, model, children, ajaxService, filterService, app) {
		super(el, model, children);
		this.ajaxService = ajaxService;
		this.filterService = filterService;
		this.app = app;
	}

	bind() {
		super.bind();
		this.render();
	}

	render() {
		ReactLoader().then(({ React, ReactDOM, ZonesFilter }) => {
			ReactDOM.render(
				<ZonesFilter
					ajaxService={this.ajaxService}
					filterService={this.filterService}
					app={this.app}
					{...this.model}
				/>,
				this.el[0],
			);
		});
	}
}
