import $ from "jquery";
import Widget from "./../../../../Inlined/Widget";

export default class LeaveContactFormWidget extends Widget {

	constructor(el, model, children, app) {
		super(el, model, children);

		this.app = app;
	}

	bind() {
		super.bind();

		this.onShowContactRadioChange = this.onShowContactRadioChange.bind(this);
		this.showContactRadio = $(".j-show-contact input[type='radio']", this.el);
		this.showContactRadio.change(ev => this.onShowContactRadioChange(ev));
		this.contactControls = $(".j-contact-controls", this.el);
		this.showContactRadio.trigger("change");
	}

	onShowContactRadioChange(ev) {
		var selectedContactRadio = this.showContactRadio.filter(":checked").val();
		// only double equals because radio return string "0" or "1"
		if (selectedContactRadio == true) {
			this.contactControls.show();
		} else {
			this.contactControls.hide();
		}
	}

}