import $ from "jquery";
import Widget from "./../../../Inlined/Widget";
import happyVideo from "../Styles/def/img/user/happy.mp4";
import okayVideo from "../Styles/def/img/user/okay.mp4";
import sadVideo from "../Styles/def/img/user/sad.mp4";
/* global Image */
/* global window */

const HAPPY = "happy";

const OKAY = "okay";

const SAD = "sad";

export default class EmailingWidget extends Widget {
	constructor(el, model, children, flashMessageService) {
		super(el, model, children);

		this.flashMessageService = flashMessageService;
	}

	bind() {
		super.bind();
		this.frequencyWarning = $(".j-frequency-warning", this.el);
		this.frequencyWarningConfirm = $(".j-frequency-warning-confirm", this.el);
		this.frequencyWarningConfirmCheckbox = $(".j-frequency-warning-confirm-checkbox", this.el);
		this.pushEmailFrequency = $(".j-push-email-frequency", this.el);
		this.pushEmailFrequencyInput = $(":input[name='emailing_push_email_frequency']", this.el);
		this.video = $(".j-video", this.el);
		this.pause = $(".j-pause", this.el);
		this.pauseInput = $(":input[name='emailing_pause']", this.el);

		this.model.previousPushEmailFrequency = this.model.pushEmailFrequency;
		this.model.nextPushEmailFrequency = this.model.pushEmailFrequency;
		this.model.videoIndex = 0;

		this.pauseInput.on("change", ev => this.onPauseInputChange(ev));
		this.pushEmailFrequency.one("mouseenter", ev => this.onPushEmailFrequencyIntent(ev));
		this.pushEmailFrequencyInput.on("change", ev => this.onPushEmailFrequencyChange(ev));
		// Video play on Android can be initiated by a user interaction only
		this.el.on("click", ".j-video", ev => ev.currentTarget.play());
		this.el.on("submit", ev => this.onSubmit(ev));
	}

	getEmotionFromFrequencyChange(previousPushEmailFrequency, nextPushEmailFrequency) {
		const previousIndex = this.model.pushEmailFrequencyValues.indexOf(previousPushEmailFrequency);
		const nextIndex = this.model.pushEmailFrequencyValues.indexOf(nextPushEmailFrequency);

		// User increased email frequency
		if (nextIndex > previousIndex) {
			return HAPPY;
			// User decreased email frequency
		} else if (nextIndex < previousIndex) {
			if (nextPushEmailFrequency !== "0") {
				return OKAY;
			}

			return SAD;
		}

		return null;
	}

	/**
	 * User intent to change push frequency value. Preload graphics.
	 * @param {$.event} ev
	 */
	onPushEmailFrequencyIntent(ev) {
		const pushEmailFrequency = $(ev.currentTarget).attr("data-value");
		const emotion = this.getEmotionFromFrequencyChange(
			this.model.nextPushEmailFrequency,
			pushEmailFrequency,
		);

		switch (emotion) {
			case HAPPY:
				EmailingWidget.preloadVideo(happyVideo);
				break;
			case OKAY:
				EmailingWidget.preloadVideo(okayVideo);
				break;
			case SAD:
				EmailingWidget.preloadVideo(sadVideo);
				break;
			default:
		}
	}

	/**
	 * User changed push frequency value
	 * @param {$.event} ev
	 */
	onPushEmailFrequencyChange(ev) {
		const $input = $(ev.currentTarget);
		const nextPushEmailFrequency = $input.val();

		this.pushEmailFrequencyInput
			.parent()
			.removeClass("well-success")
			.addClass("well-default");
		$input
			.parent()
			.addClass("well-success");

		this.model.previousPushEmailFrequency = this.model.nextPushEmailFrequency;
		this.model.nextPushEmailFrequency = nextPushEmailFrequency;

		// Show graphics
		const emotion = this.getEmotionFromFrequencyChange(
			this.model.previousPushEmailFrequency,
			this.model.nextPushEmailFrequency,
		);
		switch (emotion) {
			case HAPPY:
				this.playVideo(happyVideo);
				break;
			case OKAY:
				this.playVideo(okayVideo);
				break;
			case SAD:
				this.playVideo(sadVideo);
				break;
			default:
		}

		// Show warning
		if (nextPushEmailFrequency === "high") {
			this.frequencyWarning.hide();
		} else {
			this.frequencyWarning.show();
		}

		if (nextPushEmailFrequency === "0") {
			this.pause.show();
			// Select the closest datetime paused
			this.pauseInput.eq(0)
				.prop("checked", true)
				.change();
		} else {
			this.pause.hide();
			// Select the latest datetime paused
			this.pauseInput.last()
				.prop("checked", true)
				.change();
		}

		if (this.model.pushEmailFrequency === "high" && nextPushEmailFrequency !== "high") {
			this.frequencyWarningConfirm.show();
		} else {
			this.frequencyWarningConfirm.hide();
		}
	}

	onPauseInputChange(ev) {
		const $input = $(ev.currentTarget);

		this.pauseInput
			.parent()
			.removeClass("well-success")
			.addClass("well-default");
		$input
			.parent()
			.addClass("well-success");
	}

	onSubmit(ev) {
		// Changing push frequency from high to anything else requires confirmation
		if (this.model.pushEmailFrequency === "high"
			&& (this.pushEmailFrequencyInput.length && this.pushEmailFrequencyInput.filter(":checked").val() !== "high")
			&& (this.frequencyWarningConfirmCheckbox.length && !this.frequencyWarningConfirmCheckbox.prop("checked"))
		) {
			ev.preventDefault();

			this.frequencyWarningConfirm.addClass("text-danger");
			const y = this.frequencyWarningConfirm.offset().top - 100;
			$(window).scrollTop(y);

			this.flashMessageService.showMessage("Potvrďte změnu nastavení kliknutím na \"Souhlasím\"", "warning");
		} else {
			this.frequencyWarningConfirm.removeClass("text-danger");
		}
	}

	playVideo(videoUrl) {
		if (!this.video.length) {
			return;
		}

		this.video.prop("loop", false);
		if (!this.video1) {
			this.video1 = $("<video class='fade in j-video' autoplay muted></video>").insertAfter(this.video);
		}

		this.model.videoIndex = Number(!this.model.videoIndex);
		this.video.removeClass("active inactive");
		this.video1.removeClass("active inactive");

		if (this.model.videoIndex === 1) {
			this.video.addClass("inactive");
			this.video1
				.addClass("active")
				.attr("src", videoUrl);
			this.video1[0].load();
			this.video1[0].play();
		} else {
			this.video1.addClass("inactive");
			this.video
				.addClass("active")
				.attr("src", videoUrl);
			this.video[0].load();
			this.video[0].play();
		}
	}
}

EmailingWidget.preloadImage = function preloadImage(imageUrl) {
	const image = new Image();
	image.src = imageUrl;
};

EmailingWidget.preloadVideo = function preloadVideo(videoUrl) {
	const $video = $("<video>");
	$video.attr("src", videoUrl);
	$video[0].load();
};
