import $ from "jquery";
import debounce from "lodash/debounce";
import Widget from "../../../Inlined/Widget";
import isInViewport from "../../../../dry/isInViewport";

export default class BestZoneWidget extends Widget {
	constructor(el, model, children, scrollService) {
		super(el, model, children, scrollService);
		this.scrollService = scrollService;
		this.link = $(".j-link", this.el);
		this.bestZonesWrapper = $(".j-best-zones__inner");
		this.visibleItem = 1;
	}

	bind() {
		super.bind();
		this.trackBestZonesScroll();
	}

	trackBestZonesScroll() {
		if (matchMedia("(min-width: 500px)").matches) {
			return;
		}
		const bestZonesEl = document.querySelectorAll(".j-best-zones-list");

		this.bestZonesWrapper.on("scroll", debounce(() => {
			bestZonesEl.forEach((item, index) => {
				if (index + 1 > this.visibleItem && isInViewport(item)) {
					this.visibleItem = index + 1;
				}
			});
			$(document).on("scroll", debounce(() => {
				this.bestZonesWrapper.off("scroll");
			}, 100));
		}, 100));
	}
}
