import $ from "jquery";
import Widget from "../../../Inlined/Widget";

/* global dry */
/* global window */
const anchorMenuLinkKey = ".anchor-menu__link";
const anchorMenuLinkActiveKey = "anchor-menu__link--active";
const anchorMenuDropdownKey = ".anchor-menu--dropdown";

export default class ActiveAnchorIndicatorWidget extends Widget {
	constructor(el, model, children, app, scrollService) {
		super(el, model, children);
		this.app = app;
		this.scrollService = scrollService;

		this.navbarHeight = $(".fixheader").height();
		this.sections = $(".j-items .boxlist").first().children(".box");
		this.wrapper = $(".j-items");
		this.fixedMenu = $(".anchor-menu__wrapper");
		this.breadcrumbBarHeight = $(".breadcrumb-bar").outerHeight();
		this.wrapperHeight = this.wrapper.height();
		this.wrapperOffset = (this.wrapper.offset().top) + (this.navbarHeight);
		this.wrapperBottom = (this.wrapperHeight + this.wrapperOffset) - (this.fixedMenu.height());
		this.sec = [];
		/* eslint-disable */
		for (const section of Array.from(this.sections)) {
			this.sec.push({	id: section.id, offset: $(section).offset().top - this.breadcrumbBarHeight });
		}
		/* eslint-enable */
		this.fixedMenu.find(anchorMenuLinkKey).first().addClass(anchorMenuLinkActiveKey);
		this.lastItemId = this.sections[0].id;

		this.links = this.fixedMenu.find(anchorMenuLinkKey);
		this.links.on("click", ev => this.moveToClickedContent(ev));
	}

	moveToClickedContent(ev) {
		ev.preventDefault();
		const link = $(ev.currentTarget);
		if (!link) return;

		const anchor = link.attr("data-target");
		if (!anchor) return;

		const boxElement = $(`#${anchor}`);
		if (!boxElement) return;

		this.scrollService.scrollToElement(boxElement, -30);
	}

	bind() {
		super.bind();

		this.activeMenuItem();

		this.onWindowScrollFn = dry.fn.throttle(() => this.activeMenuItem(), 50);
		$(window).scroll(this.onWindowScrollFn);
		$(window).on("resize", () => {
			this.wrapperHeight = this.wrapper.height();
			this.wrapperOffset = (this.wrapper.offset().top) + (this.navbarHeight);
			this.wrapperBottom = (this.wrapperHeight + this.wrapperOffset) - (this.fixedMenu.height());
		});
		ActiveAnchorIndicatorWidget.anchorMenuDropdown();
	}

	activeMenuItem() {
		if (window.matchMedia("(min-width: 768px)").matches) {
			const scrollResult = this.scroll();
			let currentItem = null;
			if (!scrollResult) {
				currentItem = $(`[data-target=${this.sec[0].id}]`);
			} else {
				currentItem = $(`[data-target=${scrollResult[0].id}]`);
				if (this.lastItemId !== scrollResult[0].id) {
					$(`[data-target=${this.lastItemId}]`)
						.removeClass(anchorMenuLinkActiveKey);
					this.lastItemId = scrollResult[0].id;
				}
			}
			currentItem.addClass(anchorMenuLinkActiveKey);
		}
	}

	scroll() {
		const currentScroll = $(window).scrollTop() + this.navbarHeight;
		const newArray = this.sec.filter(el => el.offset <= currentScroll);
		const currentId = newArray && newArray[newArray.length - 1] ? newArray[newArray.length - 1] : null;


		if (!currentId) {
			return null;
		}

		return [currentId, currentScroll];
	}

	static anchorMenuDropdown() {
		$(anchorMenuDropdownKey).find(".anchor-menu__dropdown").on("click", () => {
			$(anchorMenuDropdownKey).toggleClass("anchor-menu--open");
		});
	}
}
