export default class WatchdogResumeEnum {
	static ITEM_RESUME = "item";

	static LISTING_RESUME = "listing";

	static LISTING_END_RESUME = "listing_end";

	static TOPICDAY_RESUME = "topicday";

	static TOPICDAY_PROMO_RESUME = "topicday_promo";

	static RECOMMEND = "recommend";
}
