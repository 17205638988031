import $ from "jquery";
import StepperWidget from "./StepperWidget";

export default class FormStepperWidget extends StepperWidget {
	constructor(el, model, children, app) {
		super(el, model, children);
		this.app = app;
	}

	// Vrati objekt s parametry AJAX requestu pro dany krok
	getStepAjax(indexOrName = this.stepIndex) {
		const step = this.getStep(indexOrName);
		if (step === null) {
			return null;
		}
		return this.model.ajax[step.name] || null;
	}

	// serializuje formularove prvky z daneho kroku
	serializeStep(indexOrName = this.stepIndex) {
		const step = this.getStep(indexOrName);
		if (step === null) {
			return null;
		}

		const inputs = $(":input", step.el);
		return inputs.serialize();
	}

	serializeArrayStep(indexOrName = this.stepIndex) {
		const step = this.getStep(indexOrName);
		if (step === null) {
			return null;
		}
		const inputs = $(":input", step.el);
		return inputs.serializeObject();
	}
}
