import $ from "jquery";
import Sortable from "sortablejs";
import Widget from "../../../../Inlined/Widget";
import AbstractItemWidget from "../Item/AbstractItemWidget";
/* global window */

export default class WishlistDetailWidget extends Widget {
	constructor(el, model, children, wishlistService, fancyboxService) {
		super(el, model, children);
		this.wishlistService = wishlistService;
		this.fancyboxService = fancyboxService;
	}

	bind() {
		super.bind();

		this.el.on("click", ".j-show-removed", (ev) => {
			this.onShowRemovedClick(ev);
		});

		this.el.on("click", ".j-create", () => {
			this.wishlistService.openDialog();
		});

		this.el.on("click", ".j-share", () => {
			this.wishlistService.openDialog(null, null, this.model.shareUrl);
		});

		this.el.on("click", ".j-delete", () => {
			this.wishlistService.deleteUserWishlist(this.model.userWishlistId, () => {
				window.location.replace(this.model.redirUrl);
			});
		});

		if (typeof Sortable !== "undefined" && !this.model.isMobile) {
			// Sortable
			const list = $(".j-wishlist-list", this.el);

			if (list.length) {
				Sortable.create(list.get(0), {
					group: "wishlistDeals",
					onUpdate: (ev) => {
						this.saveItemOrder(ev);
					},
					ghostClass: "ghost",
				});
			}
		}

		this.el.on("click", ".j-wishlist-access-level", (ev) => {
			this.onAccessLevelClick(ev);
		});


		this.accessLevelTitle = $(".j-wishlist-access-title", this.el);
	}

	saveItemOrder(ev) {
		this.moveChildren.apply(this, [ev.oldIndex, ev.newIndex]);
		const items = this.findByType(AbstractItemWidget);
		const itemOrder = [];
		for (let i = 0; i < items.length; i += 1) {
			itemOrder.push(items[i].model.wishlistItemId);
		}
		this.wishlistService.updateWishlistItemOrder(itemOrder);
	}

	moveChildren(oldIndex, newIndex) {
		const moveItem = this.children[oldIndex];
		this.children.splice(oldIndex, 1);
		this.children.splice(newIndex, 0, moveItem);
	}

	onShowRemovedClick(ev) {
		ev.preventDefault();
		$(".j-alert-removed", this.el).hide();
		$(".j-wishlist-removed-list", this.el).show();
	}

	onAccessLevelClick(ev) {
		const { access } = ev.target.dataset;
		ev.preventDefault();
		this.wishlistService.updateAccessRights(
			this.model.wishlistId,
			access,
			() => {
				this.accessLevelTitle.text(this.model.accessLevels[access]);
			},
		);
	}
}
