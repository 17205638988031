module.exports = {
	VOUCHER_EXPIRATION_FILTER: "platnost",
	TRAVEL_DAYS_FILTER: "pocet-dni",
	TRAVEL_PERSONS_FILTER: "osob",
	EXACT_KEY: "exact",
	INTERVAL_KEY: "interval",
	AVAILABLE_KEY: "available",
	UNAVAILABLE_KEY: "unavailable",
	ON_DEMAND_KEY: "on_demand",
};
