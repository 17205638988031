import $ from "jquery";
import Widget from "./../../../../Inlined/Widget";
import UserAuthStateEnum from "../../VO/UserAuthStateEnum";
import UserAuthStateEvent from "../../Event/UserAuthStateEvent";
import UserVerifiedEvent from "../../Event/UserVerifiedEvent";
import WatchdogSetEvent from "../../Event/WatchdogSetEvent";
import ValidationMixin from "../ValidationMixin";
import suggestEmail from "./SuggestEmail";
/* global document */
/* global window */

export default class UserSubscribeModal4Widget extends Widget {
	constructor(
		el,
		model,
		children,
		app,
		userService,
		fancyboxService,
		validateSettings,
		watchdogService,
		itemWatchdogService,
		flashMessageService,
		flashMessagesWidget,
	) {
		super(el, model, children);

		this.app = app;
		this.userService = userService;
		this.fancyboxService = fancyboxService;
		this.validateSettings = validateSettings;
		this.watchdogService = watchdogService;
		this.itemWatchdogService = itemWatchdogService;
		this.flashMessageService = flashMessageService;
		this.flashMessagesWidget = flashMessagesWidget;

		this.bindValidation = ValidationMixin.bindValidation;
		this.getEmailMessage = ValidationMixin.getEmailMessage;
		this.onInputFix = ValidationMixin.onInputFix;
		this.onValidationSuccess = ValidationMixin.onValidationSuccess;
	}

	bind() {
		super.bind();

		this.emailInput = $(".j-email", this.el);
		this.emailGroup = $(".j-email-group", this.el);
		this.spinner = $(".j-spinner", this.el);

		this.app.on(UserVerifiedEvent, this.onUserVerifiedFn = ev => this.onUserVerified(ev));
		this.bindForm();
		this.bindValidation();
		this.userService.register(this.model.loginId, () => this.onUserAuthResume());
		this.userService.on(UserAuthStateEvent, ev => this.onUserAuth(ev));
		this.watchdogService.on(WatchdogSetEvent, ev => this.onWatchdogSet(ev));
		$(document.body).one("mousemove touchstart scroll", this.onUserInteractionFn = () => this.onUserInteraction());

		if (this.userService.isVerifiedHuman()) {
			this.onUserVerified();
		}
	}

	unbind() {
		super.unbind();

		this.app.off(UserVerifiedEvent, this.onUserVerifiedFn);
		$(document.body).off("mousemove touchstart scroll", this.onUserInteractionFn);
	}

	bindForm() {
		const formSettings = {
			rules: {
				email: {
					required: () => this.isEmailRequired(),
					emailHtml5: () => this.isEmailRequired(),
				},
			},
			messages: {
				email: {
					required: "Bez e-mailu to nepůjde",
					emailHtml5: (...args) => this.getEmailMessage(...args),
				},
			},
			submitHandler: (form, ev) => this.onSubmit(ev),
			success: ($label, element) => {
				this.onValidationSuccess($label, element);
			},
		};

		this.el.validate($.extend({}, this.validateSettings, formSettings));
	}

	close() {
		if (this.fancyboxService.isOpened(this)) {
			this.fancyboxService.close();
		}
	}

	isEmailRequired() {
		return !this.userService.isRegistered();
	}

	onSubmit() {
		let email = this.emailInput.val();

		if (this.userService.isRegistered()) {
			this.setMailing();
		} else {
			const suggestedEmail = suggestEmail(email);

			if (suggestedEmail) {
				// eslint-disable-next-line no-alert
				const replace = window.confirm(`Zdá se nám, že v emailové adrese je překlep. 
Není adresa správně takto? ${suggestedEmail}`);
				if (replace) {
					email = suggestedEmail;
				}
			}

			this.spinner.show();
			this.userService.setState(UserAuthStateEnum.EMAIL_STATE);
			this.userService.setLoginId(this.model.loginId);
			this.userService.setPayload({
				silentLogin: true,
			});
			this.userService.setInternalTrackingParameters(this.getInternalTrackingParameters());
			this.userService.doAuth(
				{
					email,
				},
				() => {
					this.spinner.hide();
				},
				() => {
					this.spinner.hide();
				},
			);
			this.model.waitingForAuth = true;
		}

		return false;
	}

	/**
	 * Open modal on user's first interaction
	 */
	onUserInteraction() {
		if (this.model.userActive) {
			return;
		}

		this.model.userActive = true;
		this.open();
	}

	/**
	 * Subscribe to watchdog after user is authorized
	 * @param {$.Event} ev
	 */
	onUserAuth(ev) {
		if (this.model.waitingForAuth && ev.newState === UserAuthStateEnum.COMPLETE_STATE) {
			this.model.waitingForAuth = false;

			this.setMailing();
		}
	}

	/**
	 * Subscribe to watchdog after user's authorization is finished (after password recovery etc.)
	 */
	onUserAuthResume() {
		this.model.waitingForAuth = false;
		this.setMailing();
	}

	/**
	 * Create anonymous user if no user exists. Wait after user is verified (cannot create user before).
	 */
	onUserVerified() {
		if (this.userService.getUserId()) {
			return;
		}

		this.userService.setState(UserAuthStateEnum.NEW_STATE);
		this.userService.setLoginId(null);
		this.userService.setPayload(null);
		this.userService.setInternalTrackingParameters(this.getInternalTrackingParameters());
		this.userService.doAuth();
	}

	/**
	 * Success state after watchdog is set
	 * @param {WatchdogSetEvent} ev
	 */
	onWatchdogSet(ev) {
		if (ev.watchdogId !== this.itemWatchdogService.watchdogResume) {
			return;
		}

		this.spinner.hide();
		this.close();
		this.flashMessageService.showMessage(
			`Hotovo, tipy budete dostávat na váš e-mail ${this.userService.getEmail()}`,
			"success",
		);
	}

	setMailing() {
		const watchdogData = {
			...this.model.watchdog,
			active: 1,
		};

		// Set watchdog
		this.spinner.show();
		this.itemWatchdogService.setInternalTrackingParameters(this.getInternalTrackingParameters());
		this.itemWatchdogService.setSourceWidget(this);
		this.itemWatchdogService.setItemWatchdog(watchdogData, true);

		// Turn on push mailing
		this.userService.getSettings().then((userSettings) => {
			if (userSettings && userSettings.pushEmailFrequency !== "0") {
				return;
			}

			this.userService.setSettings({ emailing_push_email_frequency: "low" });
		});
	}

	open() {
		// Require email when user is not registered. Hide otherwise.
		if (!this.isEmailRequired()) {
			this.emailGroup.hide();
		} else {
			this.emailGroup.show();
		}
		this.fancyboxService.openWidget(
			this,
			{
				baseClass: "s-overlay-black s-overlay-75",
				afterLoad: () => {
					// WARNING: Use scroll lock only on page load.
					// It will reset scroll position after fancybox is closed.
					$(document.body).addClass("s-fancybox-overflow-scroll");
				},
				beforeClose: () => {
					$(document.body).removeClass("s-fancybox-overflow-scroll");
				},
				// Make window modal-like but allow close button
				modal: false,
				// Disable keyboard navigation
				keyboard: false,
				// Disable click event handlers
				clickContent: false,
				clickSlide: false,
				clickOutside: false,
				dblclickContent: false,
				dblclickSlide: false,
				dblclickOutside: false,
				smallBtn: true,
			},
		);
	}
}
