import $ from "jquery";
import EventDispatcherMixin from "./EventDispatcherMixin";
import AdminnableMixin from "./AdminnableMixin";

export default class Widget {
	constructor(el, model = {}, children = []) {
		this.on = EventDispatcherMixin.on;
		this.off = EventDispatcherMixin.off;
		this.dispatch = EventDispatcherMixin.dispatch;

		this.hasAdmin = AdminnableMixin.hasAdmin;
		this.getAdmin = AdminnableMixin.getAdmin;
		this.setAdmin = AdminnableMixin.setAdmin;

		this.el = $(el);
		this.model = model;
		this.children = children;
		this.parent = null;
		this.children.map(child => (child.setParent(this)));
	}

	setEl(el) {
		this.el = el;
	}

	setModel(model) {
		this.model = model;
	}

	setParent(parent) {
		this.parent = parent;
	}

	setChildren(children) {
		this.children = children;
		this.children.map(child => (child.setParent(this)));
	}

	bind() {
		// eslint-disable-next-line no-underscore-dangle
		this.__bound__ = true;
		this.children.map((child) => {
			child.bind();
			// eslint-disable-next-line no-underscore-dangle
			if (!child.__bound__) {
				throw new Error("You have to call parent's bind!");
			}
			return child;
		});
	}

	unbind() {
		// eslint-disable-next-line no-underscore-dangle
		this.__bound__ = false;
		this.children.map(child => (child.unbind()));
	}

	findByType(clazz) {
		let byType = this.children
			.map(child => (child.findByType(clazz)))
			.reduce((total, currentValue) => (total.concat(currentValue)), []);

		if (this instanceof clazz) {
			byType = [this].concat(byType);
		}

		return byType;
	}

	findParentByType(clazz) {
		let w = this;
		let byType = null;

		while (w.parent) {
			if (w.parent instanceof clazz) {
				byType = w.parent;
				break;
			}
			w = w.parent;
		}

		return byType;
	}

	getKey() {
		return this.constructor.name;
	}

	getSource() {
		if (typeof this.model.source === "undefined") {
			return this.parent ? this.parent.getSource() : "";
		}
		return this.model.source;
	}

	getCampaign() {
		if (typeof this.model.campaign === "undefined") {
			return this.parent ? this.parent.getCampaign() : "";
		}
		return this.model.campaign;
	}

	getMedium() {
		if (typeof this.model.medium === "undefined") {
			return this.parent ? this.parent.getMedium() : "";
		}
		return this.model.medium;
	}

	getTerm() {
		if (typeof this.model.term === "undefined") {
			return this.parent ? this.parent.getTerm() : "";
		}
		return this.model.term;
	}

	getContent() {
		if (typeof this.model.content === "undefined") {
			return this.parent ? this.parent.getContent() : "";
		}
		return this.model.content;
	}

	getPosition() {
		if (typeof this.model.position === "undefined") {
			return 	this.parent ? this.parent.getPosition() : "";
		}
		return this.model.position;
	}

	getRank() {
		if (typeof this.model.rank === "undefined") {
			return this.parent ? this.parent.getRank() : "";
		}
		return this.model.rank;
	}

	getVariant() {
		if (typeof this.model.variant === "undefined") {
			return this.parent ? this.parent.getVariant() : "";
		}
		return this.model.variant;
	}

	getHandler() {
		if (typeof this.model.handler === "undefined") {
			return this.parent ? this.parent.getHandler() : "";
		}
		return this.model.handler;
	}

	getInternalTrackingParameters() {
		return {
			s_source: this.getSource(),
			s_campaign: this.getCampaign(),
			s_medium: this.getMedium(),
			s_term: this.getTerm(),
			s_content: this.getContent(),
			s_position: this.getPosition(),
			s_rank: this.getRank(),
			s_variant: this.getVariant(),
		};
	}

	createInternalTrackingParametersQuery(setParams = {}) {
		const widgetParams = this.getInternalTrackingParameters();
		const params = $.extend({}, widgetParams, setParams);
		const queryArr = [];

		if (params.s_source) {
			queryArr.push(`s_source=${params.s_source}`);
		}
		if (params.s_medium) {
			queryArr.push(`s_medium=${params.s_medium}`);
		}
		if (params.s_campaign) {
			queryArr.push(`s_campaign=${params.s_campaign}`);
		}
		if (params.s_content) {
			queryArr.push(`s_content=${params.s_content}`);
		}
		if (params.s_term) {
			queryArr.push(`s_term=${params.s_term}`);
		}
		if (params.s_position) {
			queryArr.push(`s_position=${params.s_position}`);
		}
		if (params.s_variant) {
			queryArr.push(`s_variant=${params.s_variant}`);
		}
		if (params.s_rank) {
			queryArr.push(`s_rank=${params.s_rank}`);
		}

		return queryArr.join("&");
	}

	moveChildren(oldIndex, newIndex) {
		const moveItem = this.children[oldIndex];
		this.children.splice(oldIndex, 1);
		this.children.splice(newIndex, 0, moveItem);
	}

	reload() {
		if (!this.model.ajaxReloadUrl) {
			return $.Deferred().reject();
		}

		return this.ajaxService
			.ajax({
				method: "GET",
				url: this.model.ajaxReloadUrl,
				data: {
					widget_position: this.model.position,
				},
			})
			.then((response) => {
				const renderedHtml = this.app.render(response.html, this.parent);
				const widget = renderedHtml.widgets.length ? renderedHtml.widgets[0] : null;

				if (widget) {
					widget.el.insertAfter(this.el);
					const index = this.parent.children.indexOf(this);
					// Replace old widget with new one in widget tree
					if (index !== -1) {
						this.parent.children.splice(index, 1, widget);
					}

					this.unbind();
					this.el.remove();
				}

				return widget;
			});
	}

	replaceWith(widget = null) {
		if (widget) {
			widget.setParent(this.parent);
			widget.el.insertAfter(this.el);
		}

		// Replace current widget with new one in widget tree
		const index = this.parent.children.indexOf(this);
		if (index !== -1) {
			if (widget) {
				this.parent.children.splice(index, 1, widget);
			} else {
				this.parent.children.splice(index, 1);
			}
		}

		this.unbind();
		this.el.remove();
	}

	remove() {
		this.replaceWith(null);
	}
}
