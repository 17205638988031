/* eslint-disable global-require */
/* global __LOCALE__ */

const locales = require("./LocaleEnum").default;

if (__LOCALE__ === locales.csCz) {
	module.exports = require("./Locale.cs_CZ");
} else if (__LOCALE__ === locales.plPl) {
	module.exports = require("./Locale.pl_PL");
} else {
	throw new Error(`Unsupported locale "${__LOCALE__}".`);
}
