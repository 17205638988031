import $ from "jquery";
import WatchdogResumeEnum from "../VO/WatchdogResumeEnum";
import WatchdogSetEvent from "../Event/WatchdogSetEvent";

export default class ItemWatchdogService {
	constructor(app, fancyboxService, watchdogService, ajaxItemUrlPattern, bodyWidget, internalTrackingParameters) {
		this.app = app;
		this.fancyboxService = fancyboxService;
		this.watchdogService = watchdogService;
		this.ajaxItemUrlPattern = ajaxItemUrlPattern;
		this.bodyWidget = bodyWidget;
		this.internalTrackingParameters = internalTrackingParameters;

		this.dealWidgets = {};
		this.sourceWidget = null;
		this.watchdogResume = WatchdogResumeEnum.ITEM_RESUME;

		this.watchdogService.register(this.watchdogResume, payload => this.onResume(payload));
		this.watchdogService.on(WatchdogSetEvent, ev => this.onWatchdogSet(ev));
	}

	getDealWidget(hash) {
		return this.dealWidgets[hash] || null;
	}

	getSourceWidget() {
		return this.sourceWidget;
	}

	onResume(payload) {
		if (payload && payload.deal) {
			this.openDealWatchdog(payload, widget => widget.setActive(payload.active));
		}
	}

	openDealWatchdog(data, onSuccess = () => {}) {
		if (!data.deal) {
			return false;
		}

		const srv = this;
		const widget = this.getDealWidget(data.deal);

		if (widget) {
			this.fancyboxService.openWidget(
				widget,
				{
					afterLoad: () => onSuccess.apply(srv, [widget]),
					detachOnClose: true,
				},
			);
		} else {
			const url = this.ajaxItemUrlPattern.replace("{hash}", data.deal);
			const ajaxData = $.extend({}, data); // Kopie objektu, protoze dole mazeme
			delete ajaxData.deal;

			this.fancyboxService.openAjax(
				url,
				{
					ajax: {
						settings: {
							data: ajaxData,
						},
					},
					afterLoad: (instance, current) => {
						const currentWidget = current.widget;
						srv.dealWidgets[data.deal] = currentWidget;
						onSuccess.apply(srv, [currentWidget]);
					},
					detachOnClose: true,
				},
			);
		}
		return true;
	}

	onWatchdogSet(ev) {
		if (ev.watchdogId !== this.watchdogResume) {
			return;
		}

		if (ev.data.deal) {
			this.openDealWatchdog(ev.data);
		}
	}

	setItemWatchdog(data, forceLogin) {
		const payload = data;

		this.watchdogService.setWatchdogId(this.watchdogResume);
		this.watchdogService.setSourceWidget(this.sourceWidget);
		this.watchdogService.setInternalTrackingParameters(this.internalTrackingParameters);
		this.watchdogService.setPayload(payload);
		this.watchdogService.setWatchdog(forceLogin);

		return true;
	}

	setInternalTrackingParameters(internalTrackingParameters) {
		this.internalTrackingParameters = internalTrackingParameters;
		return this;
	}

	setSourceWidget(sourceWidget) {
		this.sourceWidget = sourceWidget;
		return this;
	}
}
