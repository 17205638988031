import $ from "jquery";
import ResponsiveWidget from "../ResponsiveWidget";
import WatchdogSetEvent from "../../Event/WatchdogSetEvent";
import WatchdogRequestEvent from "../../Event/WatchdogRequestEvent";
import { trans } from "../../Service/I18NService";

/* global dry */
/* global __ */

export default class AbstractWatchdogSubscribeWidget extends ResponsiveWidget {
	constructor(el, model, children, flashMessageService, watchdogService, userService, app) {
		super(el, model, children);

		this.flashMessageService = flashMessageService;
		this.watchdogService = watchdogService;
		this.userService = userService;
		this.app = app;
		this.watchdogResume = null;
	}

	bind() {
		super.bind();
		const $btn = $(".j-btn", this.el);
		this.btn = $btn.length ? $btn : this.el;
		this.btnText = $(".j-text", this.btn);
		this.bellIcon = $(".j-bell-icon", this.btn);
		this.noBellIcon = $(".j-nobell-icon", this.btn);
		this.dropdown = $(".j-dropdown", this.el);
		this.spinner = $(".j-spinner", this.el);

		this.model.dropdownHover = false;
		this.model.hover = false;
		this.model.loading = false;

		this.btn.click(ev => this.onBtnClick(ev));
		this.btn.mouseenter(ev => this.onBtnMouseEnter(ev));
		this.btn.mouseleave(ev => this.onBtnMouseLeave(ev));
		this.dropdown.mouseenter(ev => this.onDropdownMouseEnter(ev));
		this.dropdown.mouseleave(ev => this.onDropdownMouseLeave(ev));
		this.el.on("click", ".j-btn-turnoff", ev => this.onTurnoffBtnClick(ev));
		this.el.on("click", ".j-btn-turnon", ev => this.onTurnonBtnClick(ev));

		this.watchdogService.register(this.watchdogResume, payload => this.onResume(payload));
		this.app.on(WatchdogSetEvent, ev => this.onWatchdogSet(ev));
		this.app.on(WatchdogRequestEvent, ev => this.onWatchdogRequest(ev));
	}

	alert() {
		const text = this.getAlertText();
		this.flashMessageService.showMessage(text, "success");
	}

	getAlertText() {
		let text = this.model.active ? this.model.activeAlertText : this.model.inactiveAlertText;
		if (this.model.active
			&& this.userService.isLoggedIn()
			&& !this.userService.isAnonymous()
			&& this.userService.getEmail()
		) {
			// eslint-disable-next-line max-len
			text += `\n${trans(__("Nabídky Vám pošleme na e-mail %email%."), { "%email%": this.userService.getEmail() })}`;
		}
		return text;
	}

	getCampaign() {
		return dry.str.undescorize(this.constructor.name);
	}

	// eslint-disable-next-line class-methods-use-this
	logBtnClick() {
	}

	// eslint-disable-next-line class-methods-use-this
	logWatchdogSet() {
	}

	onBtnMouseEnter() {
		this.model.hover = true;
		this.refresh();
	}

	onBtnMouseLeave() {
		this.model.hover = false;
		this.refresh();
	}

	onBtnClick(ev) {
		ev.preventDefault();

		this.logBtnClick();
		this.toggle();
	}

	onTurnonBtnClick(ev) {
		ev.preventDefault();

		this.logBtnClick();
		this.turnOn();
	}

	onTurnoffBtnClick(ev) {
		ev.preventDefault();

		this.logBtnClick();
		this.turnOff();
	}

	onDropdownMouseEnter() {
		this.model.dropdownHover = true;
		this.refresh();
	}

	onDropdownMouseLeave() {
		this.model.dropdownHover = false;
		this.refresh();
	}

	onResume(payload) {
		this.model.active = payload.active;
		this.refresh();

		this.logWatchdogSet(false);
	}

	onWatchdogSet(ev) {
		this.model.active = ev.active;
		this.refresh();

		// Pokud zapnuti watchdoga bylo vyvolano timto widgetem
		if (ev.watchdogId === this.watchdogResume) {
			this.logWatchdogSet(true);
		}
	}

	onWatchdogRequest(ev) {
		if (ev.state === "sent") {
			this.model.loading = true;
			this.refresh();
		} else {
			this.model.loading = false;
			this.refresh();
		}
	}

	refresh() {
		this.btn.removeClass(`${this.model.activeBtnClassName} ${this.model.inactiveBtnClassName}`);
		this.bellIcon.hide();
		this.noBellIcon.hide();

		if (this.model.active) {
			this.btn.addClass(this.model.activeBtnClassName);
			this.btnText.text(this.model.activeText);
			if (!this.model.loading) {
				this.bellIcon.show();
			}
		} else {
			this.btn.addClass(this.model.inactiveBtnClassName);
			if (!this.model.loading) {
				this.bellIcon.show();
			}
			this.btnText.text(this.model.inactiveText);
		}

		if (this.model.loading) {
			this.spinner.show();
		} else {
			this.spinner.hide();
		}
	}

	setWatchdog(active, forceLogin = true) {
		const data = $.extend({}, this.model.entity, {
			active: Number(active),
		});

		this.watchdogService.setWatchdogId(this.watchdogResume);
		this.watchdogService.setSourceWidget(this);
		this.watchdogService.setInternalTrackingParameters(this.getInternalTrackingParameters());
		this.watchdogService.setPayload(data, { silentLogin: true });
		this.watchdogService.setWatchdog(forceLogin);
	}

	turnOn(forceLogin = true) {
		this.setWatchdog(true, forceLogin);
	}

	turnOff(forceLogin = true) {
		this.setWatchdog(false, forceLogin);
	}

	toggle(forceLogin = true) {
		this.setWatchdog(!this.model.active, forceLogin);
	}
}
