const UserAuthMixin = {
	getShowLoginComplete() {
		return this.showLoginComplete;
	},

	setShowLoginComplete(showLoginComplete) {
		this.showLoginComplete = showLoginComplete;
	},
};

export default UserAuthMixin;
