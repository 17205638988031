import $ from "jquery";
import SingletonWidget from "../../../Inlined/SingletonWidget";
import FlashMessageWidget from "./FlashMessageWidget";
import FlashMessageHideEvent from "../Event/Widget/FlashMessageHideEvent";
import closeIcon from "../Styles/def/img/icons/close.svg";
import { getIconSpriteUrl } from "../utils";
/* global window  */

export default class FlashMessagesWidget extends SingletonWidget {
	constructor(el, model, children, app, flashMessageService, flashMessageFactory) {
		super(el, model, children);
		this.app = app;
		this.flashMessageService = flashMessageService;
		this.flashMessageFactory = flashMessageFactory;

		this.defaults = {
			btnText: "OK",
			modal: true,
		};
		this.margin = 20;
		this.minWidth = 300;
		this.queueDelay = 100;
	}

	bind() {
		super.bind();

		this.current = null;
		this.disabled = false;
		this.messages = [];

		this.$overlay = $("<div class=\"flashmessages-overlay overlay fade\"></div>");

		this.flashMessageService.register(this);
		$(window).on("beforeunload", ev => this.onBeforeUnload(ev));
		$(window).resize(ev => this.onWindowResize(ev));

		setTimeout(() => this.prepare(), 0);
	}

	createMessage(text, type = "info", messageOptions = {}) {
		const options = $.extend({}, this.defaults, messageOptions);
		const btnText = options.btnText || "OK";
		const closeButtonHtml = `
			<a class='j-close close' href='#'>
				<svg class='icon' aria-hidden='true'>
					<use xlink:href='${getIconSpriteUrl(closeIcon)}'></use>
				</svg>
			</a>`;

		let altButtonHtml = "";
		if (options.altBtn) {
			const { title, url } = options.altBtn;
			altButtonHtml = `<a href="${url}" class="btn btn-smaller btn-alt strong">${title}</a>`;
		}

		const mainButtonHtml = `
			<p class='text-center'>
				${altButtonHtml}
				<button class='j-close btn btn-smaller strong' type='button'>${btnText}</button>
			</p>`;

		const html = `
			<div class='alert alert-block flashmessage flashmessage-${type}${options.modal ? " s-modal" : ""} fade' data-cy='flashmessage-${type}'>
				${!options.modal ? closeButtonHtml : ""}
				${options.title ? `<h4>${options.title}</h4>` : ""}
				<p>${text}</p>
				${options.modal ? mainButtonHtml : ""}
			</div>`;

		const $message = $(html);
		const model = $.extend({}, options, { text, type });
		const message = this.flashMessageFactory($message[0], model, []);
		message.setParent(this);
		message.bind();

		return message;
	}

	/**
	 * Rezim DND se pouziva pri pageunload, protože neexistuje spolehlivy zpusob,
	 * jak rozlisit AJAX error vznikly pri odnacteni stranky
	 * (http://stackoverflow.com/questions/1370322/jquery-ajax-fires-error-callback-on-window-unload-how-do-i-filter-out-unload-a#comment17196063_1370383)
	 */
	doNotDisturb(timeout) {
		clearTimeout(this.dndT);

		if (timeout === false) {
			this.disabled = false;
			return;
		}

		this.disabled = true;

		this.dndT = setTimeout(() => { this.disabled = false; }, timeout);
	}

	findMessageByText(text) {
		const message = this.messages.find(m => m.getText() === text);
		return message || null;
	}

	isNextMessage() {
		return this.messages.length > 0;
	}

	nextMessage() {
		if (this.current) {
			return false;
		}

		if (!this.messages.length) {
			return false;
		}

		const message = this.messages[0];
		this.showMessage(message);
		return message;
	}

	onBeforeUnload() {
		this.doNotDisturb(5000);
	}

	onMessage(text, type, options) {
		this.push(text, type, options);
	}

	onMessageHide(ev) {
		return this.removeMessage(ev.sourceWidget);
	}

	onWindowResize() {
		return this.redrawMessage();
	}

	prepare() {
		if (this.disabled) {
			return;
		}

		const messages = this.findByType(FlashMessageWidget);
		messages.forEach(message => message.on(FlashMessageHideEvent, ev => this.onMessageHide(ev)));

		this.messages = messages;

		this.nextMessage();
	}

	push(text, type, options) {
		if (this.disabled) {
			return false;
		}

		let message = this.findMessageByText(text);

		// zprava s danym textem se zobrazi jen 1x
		if (message) {
			return message;
		}

		message = this.createMessage(text, type, options);

		if (!message) {
			return false;
		}

		message.on(FlashMessageHideEvent, ev => this.onMessageHide(ev));
		this.messages.push(message);

		return this.nextMessage();
	}

	redrawMessage(message = this.current) {
		if (!message) {
			return false;
		}

		const goldenRatio = 1 / 1.618;
		const viewportHeight = $(window).height();
		const viewportWidth = $(window).width();

		const maxWidth = viewportWidth - (2 * this.margin);
		const minWidth = this.minWidth < maxWidth ? this.minWidth : 0;
		message.el.css({
			maxWidth: `${maxWidth}px`,
			minWidth: `${minWidth}px`,
		});

		const height = message.el.outerHeight();
		const width = message.el.outerWidth();

		// v ose x centrovat stred zpravy na stred viewportu
		const left = this.margin + Math.max(0, ((viewportWidth - (2 * this.margin)) / 2) - (width / 2));
		// v ose y centrovat stred zpravy na zlaty rez
		const top = this.margin + Math.max(
			0,
			((viewportHeight - (2 * this.margin)) * (1 - goldenRatio)) - (height / 2),
		);

		message.el.css({
			left: `${left}px`,
			top: `${top}px`,
		});

		return true;
	}

	removeMessage(message) {
		const index = this.messages.indexOf(message);
		if (index === -1) {
			return false;
		}

		this.messages.splice(index, 1);
		this.current = null;

		this.$overlay
			.removeClass("in")
			.addClass("out");
		$.scrollLock(false);

		if (this.isNextMessage()) {
			setTimeout(() => this.nextMessage(), this.queueDelay);
		}

		setTimeout(() => {
			if (!this.current) {
				this.$overlay.detach();
			}
		}, 150);

		return message;
	}

	showMessage(message) {
		if (message.isModal()) {
			$.scrollLock(true);
			this.$overlay
				.append(message.el)
				.appendTo(this.el)
				.removeClass("out")
				.addClass("in");
		} else {
			this.el.append(message.el);
		}

		this.redrawMessage(message);
		message.show();

		this.current = message;
	}
}
