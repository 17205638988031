import $ from "jquery";
import Widget from "./../../../Inlined/Widget";
/* global document */

export default class KristalovkaWidget extends Widget {
	bind() {
		super.bind();

		this.btnVote = $(".j-vote", this.el);
		this.btnVote.click((ev) => { this.onBtnVoteClick(ev); });
	}

	onBtnVoteClick() {
		// Sklik konverze
		// (AdWords konverzni kod v iframu nefunguje, jako URL vezme window.top.location)
		$(document.body).append(`<iframe class='hide-out' width='1' height='1' frameborder='0' scrolling='no' src='${this.model.voteDotUrl}'></iframe>`);
	}
}
