
import $ from "jquery";

export default class ImportItemService {

	constructor(domain, userService) {
		this.domain = domain;
		this.userService = userService;
	}

	logClick(importItem, pageUrl) {
		// block duplicate click logging (one user, one import item, one minute)
		var clickCookieName = `clk_i_${importItem.id}`;
		var clickCookieExpireDT = new Date((new Date()).getTime() + 60 * 1000);

		if (typeof Cookies.get(clickCookieName) === "string") {
			return false
		}

		// ochrana proti nasobnym proklikum
		Cookies.set(
			clickCookieName,
			"1",
			{
				domain: "." + this.domain,
				path: "/",
				secure: true,
				expires: clickCookieExpireDT
			}
		);

		// generate random-enough transaction ID
		var logData = importItem.logData;
		logData.transactionId = (100000 * new Date()) + Math.round(Math.random() * 100000);
	}

	shouldLogClickInSameWindow() {
		// Zalogovat click v opener okne, pokud lze vytvorit uzivatele a exit presmeruje bez Javascriptu
		return this.userService.isVerifiedHuman();
	}

}
