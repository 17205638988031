import $ from "jquery";
import Widget from "../../../Inlined/Widget";
import UserRetargetingWidget from "./UserRetargetingWidget";

export default class UserRetargetingItemWidget extends Widget {
	bind() {
		super.bind();

		this.retargetingWidget = this.findParentByType(UserRetargetingWidget);
		if (!this.retargetingWidget) {
			throw new Error("UserRetargetingItemWidget has to have UserRetargetingWidget parent.");
		}

		this.openBtn = $(".j-open", this.el);

		this.openBtn.tooltip({
			placement: "bottom",
			container: "body",
		});
		this.openBtn.click(ev => this.onOpenBtnClick(ev));
	}

	onOpenBtnClick(ev) {
		ev.preventDefault();
		this.retargetingWidget.open(this);
	}

	getId() {
		return this.model.id;
	}

	getPopoverUrl() {
		return this.model.popoverUrl;
	}
}
