import $ from "jquery";
import ResponsiveWidget from "../ResponsiveWidget";
import AbstractFilterWidget from "./AbstractFilterWidget";
import PageRedirectEvent from "../../Event/PageRedirectEvent";

export default class AbstractFilterFormWidget extends ResponsiveWidget {
	constructor(el, model, children, app, filterService) {
		super(el, model, children);
		this.app = app;
		this.filterService = filterService;
	}

	bind() {
		super.bind();

		this.filters = this.findByType(AbstractFilterWidget);
		this.labelAnchors = $("label > a", this.el);
		this.spinner = $(".j-filterform-spinner", this.el);

		this.el.submit(ev => this.onSubmit(ev));
		this.labelAnchors.click(ev => this.onLabelAnchorClick(this, ev));
	}

	filter() {
		this.filterService.filter(this.model.filterUrlPattern);
		this.app.dispatch(new PageRedirectEvent());
		this.spinner.show();
	}

	// znefunkcnit <a> uvnitr <label>
	// (<a> je tam z duvodu SEO optimalizace)
	// eslint-disable-next-line class-methods-use-this
	onLabelAnchorClick(anchor, ev) {
		ev.preventDefault();
		$(anchor).parent().click();
	}

	onSubmit(ev) {
		ev.preventDefault();
		this.filter();
	}
}
