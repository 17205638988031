
import $ from "jquery";
import Widget from "./../../../../Inlined/Widget";
import TabChangeEvent from "../../Event/TabChangeEvent";
import * as browser from "../../../../../dry/browser";

export default class ProductDetailTabsWidget extends Widget {

	constructor(el, model, children, app) {
		super(el, model, children);

		this.app = app;
	}

	bind() {
		var $tab;

		super.bind();

		this.tabs = $('.j-tab', this.el);

		this.tabs.click((ev) => { this.onTabClick(ev); });

		// tab podle URL
		if (window.location.hash && ($tab = this.findTabById(window.location.hash.replace("#","")))) {
			this.showTab($tab);
		// tab urceny backendem
		} else if (this.model.tabId && ($tab = this.findTabById(this.model.tabId))) {
			this.showTab($tab);
		// vychozi tab
		} else {
			this.showTab(this.tabs.eq(0));
		}
	}

	findTabById(id) {
		// odlisny location.hash a tab ID zabranuje scrolovani na obsah
		var $tab = this.tabs.filter(`[data-target='#tab-${id}']`);

		return $tab.length ? $tab : null;
	}

	onTabClick(ev) {
		ev.preventDefault();

		this.showTab($(ev.currentTarget), true);
	}

	showTab($tab, scroll = false) {
		var tabId;

		if (!$tab.length) {
			return;
		}

		this.tabs.addClass("btn-info").removeClass("btn-danger");
		$tab.removeClass("btn-info").addClass("btn-danger");
		$tab.tab("show");

		// odlisny location.hash a tab ID zabranuje scrolovani na obsah
		tabId = $tab.data("target").replace("#","").replace("tab-","");
		this.app.dispatch(new TabChangeEvent(tabId));

		window.location.hash = tabId;

		if (scroll) {
			var scrollPosition = this.el.offset().top - 60;

			$("html, body").stop().animate(
				{
					scrollTop: scrollPosition
				}, {
					duration: 400
				}
			);
		}
	}

	showTabById(id, scroll = false) {
		var $tab = this.findTabById(id);

		if ($tab) {
			this.showTab($tab, scroll);
		}
	}

}