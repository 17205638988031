import UserVerifiedEvent from '../Event/UserVerifiedEvent';

/* global Sentry */

export default class ConsentManagementLogService {
	static CONSENT_MANAGEMENT_SHOW_BAR_EVENT = 'show_bar';

	static CONSENT_MANAGEMENT_ALLOW_ALL_EVENT = 'allow_all';

	static CONSENT_MANAGEMENT_ALLOW_MARKETING_EVENT = 'allow_marketing';

	static CONSENT_MANAGEMENT_DISALLOW_MARKETING_EVENT = 'disallow_marketing';

	static CONSENT_MANAGEMENT_ALLOW_ANALYTICS_EVENT = 'allow_analytics';

	static CONSENT_MANAGEMENT_DISALLOW_ANALYTICS_EVENT = 'disallow_analytics';

	static CONSENT_MANAGEMENT_BAR_PRIVACY_SETTINGS_EVENT = 'bar_privacy_settings';

	static FOOTER_PRIVACY_SETTINGS = 'footer_privacy_settings';

	constructor(app, ajaxService, userService, consentManagementLogUrl) {
		this.app = app;
		this.ajaxService = ajaxService;
		this.userService = userService;
		this.consentManagementLogUrl = consentManagementLogUrl;
	}

	logEvent(type) {
		const logEvent = (eventType) => {
			const visitId = this.userService.getVisitId();

			this.ajaxService.ajax({
				url: this.consentManagementLogUrl,
				data: JSON.stringify({ visitId, eventType }),
				method: 'POST',
			}).error((response) => {
				Sentry.captureMessage(
					`Log consent-management event failed with response: ${response.responseText}`,
					'error',
				);
			});
		};

		// log event after user is verified, because it initializes visitId
		if (this.userService.getVisitId() === null) {
			this.app.on(UserVerifiedEvent, () => logEvent(type));
		} else {
			logEvent(type);
		}
	}
}
