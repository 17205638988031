import $ from "jquery";
import AbstractFilterFormWidget from "./AbstractFilterFormWidget";
import AbstractFilterWidget from "./AbstractFilterWidget";

const PARAM_SEPARATOR = ",";

export default class MerchantParametersFilterWidget extends AbstractFilterWidget {
	bind() {
		super.bind();

		this.btnSubmit = $(".j-merchant-parameters-submit", this.el);
		this.inputs = $(".j-check", this.el);
		this.links = $(".j-link", this.el);
		this.form = this.findParentByType(AbstractFilterFormWidget) || null;

		if (!this.form) {
			throw new Error("I need AbstractFilterFormWidget parent!");
		}

		this.btnSubmit.click(ev => this.onBtnSubmitClick(ev));
		this.inputs.on("change", ev => this.onChange(ev));
		this.links.on("click", ev => MerchantParametersFilterWidget.onLinkClick(ev));
	}

	onBtnSubmitClick(ev) {
		ev.preventDefault();
		this.filter();
	}

	onChange() {
		const emptyValueSlug = "emptyValue";
		const filter = this.filterService.getFilter(this.getId());
		const slugs = filter && filter.value ? filter.value.split(PARAM_SEPARATOR) : [];
		const slugsMap = slugs.reduce(
			(map, slug) => ({ ...map, [slug]: true }),
			{},
		);

		this.inputs
			.each((i) => {
				const $input = $(this.inputs[i]);
				slugsMap[$input.val()] = $input.is(":checked");
			});

		this.model.slugs = Object
			.keys(slugsMap)
			.filter(slug => slug !== emptyValueSlug && slugsMap[slug] === true)
			.sort();

		if (this.model.slugs.length) {
			this.btnSubmit
				.removeClass("btn-info")
				.addClass("btn-success");
		} else {
			this.btnSubmit
				.addClass("btn-info")
				.removeClass("btn-success");
		}

		this.filterService.setFilter(this.getId(), this.getName(), this.serialize());
	}

	serialize() {
		return this.model.slugs && this.model.slugs.length
			? this.model.slugs.join(PARAM_SEPARATOR)
			: null;
	}
}

MerchantParametersFilterWidget.onLinkClick = function onLinkClick(ev) {
	ev.preventDefault();
	const $link = $(ev.currentTarget);

	// Make checkbox checked on link click
	const checked = $link.prevAll(":checkbox,:radio").prop("checked");
	$link
		.prevAll(":checkbox,:radio")
		.prop("checked", !checked)
		.change();
	$link.blur();
};
