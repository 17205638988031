import $ from "jquery";
import lang from "../Locale";

/* global window */

export default class MapService {
	constructor() {
		this.apiCallbackName = `MapService${Math.round(Math.random() * 1000000)}_ApiLoaded`;
		this.loading = false;
		this.listeners = [];

		window[this.apiCallbackName] = () => this.check();
	}

	check() {
		if (!this.isLoaded()) {
			return false;
		}

		for (const cb of this.listeners) {
			cb();
		}
		this.listeners = [];
		return true;
	}

	// eslint-disable-next-line class-methods-use-this
	isLoaded() {
		return window.google !== undefined
			&& window.google.maps !== undefined
			&& window.google.maps.Map !== undefined;
	}

	load() {
		if (!this.isLoaded() && !this.loading) {
			this.loading = true;
			// eslint-disable-next-line max-len
			$.getScript(`//maps.googleapis.com/maps/api/js?v=3.5&key=AIzaSyBrbuJeG1NxS23x4k4XjviJU0VK1Q5tlUc&sensor=false&libraries=places&language=${lang}&callback=${this.apiCallbackName}`).fail(() => {
				this.loading = false;
			});
		}

		return this;
	}

	onLoad(cb) {
		if (this.isLoaded()) {
			cb();
			return this;
		}

		this.listeners.push(cb);

		return this;
	}
}
