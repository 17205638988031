import $ from 'jquery';
import Widget from '../../../../Inlined/Widget';

export default class RecentFiltersWidget extends Widget {
	constructor(el, model, children) {
		super(el, model, children);
		this.$valueFilters = $('.j-filter-value', el);
		this.$valueFilters.change(ev => this.onValueFilterChange(ev));
		this.$links = $('.j-link', this.el);

		this.$sideFilterForm = $(el).closest('aside').find('#filtry');
		this.$links.click(ev => RecentFiltersWidget.onLinkClick(ev));
	}

	onValueFilterChange(ev) {
		ev.preventDefault();
		const $target = $(ev.target);
		const value = $target.data('value');
		const dealCount = $target.data('deal-count');
		const $checkboxToChange = $(`[value=${value}]`, this.$sideFilterForm);

		if ($checkboxToChange.length > 1) {
			// more checkboxes has same value -> need search also by dealCount
			$(`[value=${value}][data-deal-count=${dealCount}]`, this.$sideFilterForm)
				.prop('checked', $target.prop('checked')).change();
		} else {
			$checkboxToChange.prop('checked', $target.prop('checked')).change();
		}

		this.$sideFilterForm.change();
	}

	static onLinkClick(ev) {
		ev.preventDefault();
		const $this = $(ev.currentTarget);

		// Zaskrtnuti, protoze nepouzivame <label> element kvuli zjednoduseni markupu a stylu
		const checked = $this.prevAll(':checkbox').prop('checked');
		$this.prevAll(':checkbox')
			.prop('checked', !checked)
			.change();
		$this.blur();
	}
}
