import $ from 'jquery';
import Widget from '../../../../../Inlined/Widget';
/* global window */
/* global Cookies */

export default class CarsContestHeaderWidget extends Widget {
	bind() {
		super.bind();
		this.closeBannerBtn = $('.j-close-banner', this.el);
		this.closeBannerBtn.click(ev => this.onCloseBannerClick(ev));

		// Redraw sticky elements
		$(window).trigger('resize.floating');

		this.header = $('.f-headerend');
		if (!this.model.banner) {
			this.el.insertAfter(this.header);
		}
	}

	onCloseBannerClick(ev) {
		ev.preventDefault();
		const HIDE_BANNER_COOKIE_NAME = 'hdconbnr';
		Cookies.set(
			HIDE_BANNER_COOKIE_NAME,
			1,
			{
				domain: this.domain,
				path: '/',
				secure: true,
			},
		);
		this.el.fadeOut();
	}
}
