import $ from "jquery";
import UserAuthStateEvent from "../Event/UserAuthStateEvent";
import UserAuthStateEnum from "../VO/UserAuthStateEnum";
import UserAuthResumeEnum from "../VO/UserAuthResumeEnum";
// eslint-disable-next-line import/named
/* global window */
/* global Sentry */
/* global Cookies */


export default class DealService {
	constructor(
		domain,
		hitService,
		userService,
		app,
		ajaxService,
		ajaxDealsUrl,
		bodyWidget,
		membersService,
		cookiesAllowedMarketing,
		cookiesAllowedAnalytics,
	) {
		this.domain = domain;
		this.hitService = hitService;
		this.userService = userService;
		this.app = app;
		this.ajaxService = ajaxService;
		this.ajaxDealsUrl = ajaxDealsUrl;
		this.bodyWidget = bodyWidget;
		this.membersService = membersService;
		this.cookiesAllowedMarketing = cookiesAllowedMarketing;
		this.cookiesAllowedAnalytics = cookiesAllowedAnalytics;

		this.listeners = [];
		this.reloadT = null;
		this.reloadWidgets = [];

		this.userAuthResume = UserAuthResumeEnum.DEAL_RESUME;
		this.userService.register(this.userAuthResume, () => this.onUserAuthResume());
		this.userService.on(UserAuthStateEvent, ev => this.onUserAuthState(ev));
	}

	doAuth(internalTrackingParameters = {}) {
		this.userService.setLoginId(this.userAuthResume);
		this.userService.setPayload({
			silentLogin: true,
		});
		this.userService.setInternalTrackingParameters(internalTrackingParameters);
		this.userService.doLogin();
	}

	shouldLogClickInSameWindow() {
		// Zalogovat click v opener okne, pokud lze vytvorit uzivatele a exit presmeruje bez Javascriptu
		return this.userService.isVerifiedHuman();
	}

	// eslint-disable-next-line class-methods-use-this
	logView() {
	}

	logClick(dealData, pageUrl) {
		// block duplicate click logging (one user, one deal, one minute)
		const clickCookieName = `clk_d_${dealData.hash}`;
		const clickCookieExpireDT = new Date((new Date()).getTime() + (60 * 1000));

		if (dealData.published === false) {
			return false;
		}

		if (typeof Cookies.get(clickCookieName) === "string") {
			return false;
		}

		// - ochrana proti nasobnym proklikum
		Cookies.set(
			clickCookieName,
			"1",
			{
				domain: `.${this.domain}`,
				path: "/",
				secure: true,
				expires: clickCookieExpireDT,
			},
		);

		// - co se bude logovat
		let { logData } = dealData || {};
		if (!logData) {
			const sku = `D${typeof dealData.id !== 'undefined' ? dealData.id : ''}`;
			logData = {
				// nazev serveru
				affiliation: typeof dealData.serverTitle !== 'undefined' ? dealData.serverTitle : '',
				// kategorie => nejhlubsi kategorie
				categoryName: typeof dealData.firstCategoryTitle !== 'undefined' ? dealData.firstCategoryTitle : '',
				// mesto (nepouzivame)
				cityName: '',
				// produkt => top kategorie
				productName: typeof dealData.topCategoryTitle !== 'undefined' ? dealData.topCategoryTitle : '',
				// hodnota prokliku / zisk
				revenue: typeof dealData.revenue !== 'undefined' ? dealData.revenue : '',
				// ID slevy
				sku,
				// dan (nepouzivame)
				tax: 0,
				// ID transakce
				transactionId: typeof dealData.clickId !== 'undefined' ? dealData.clickId : '',
				// stat => nepouzivame
				state: '',
				// cena za dopravu (nepouzivame)
				shipping: 0,
			};
		}

		// generate random-enough transaction ID
		logData.transactionId = (100000 * new Date()) + Math.round(Math.random() * 100000);

		return true;
	}

	// Pri prihlaseni pres email neni potreba dale nic delat
	// eslint-disable-next-line class-methods-use-this
	onUserAuthResume() {}

	onUserAuthState(ev) {
		if (ev.newState === UserAuthStateEnum.COMPLETE_STATE && ev.loginId === this.userAuthResume) {
			// Refreshnout stranku, zobrazit dealy pro prihlaseneho uzivatele
			window.location.reload();
		}
	}

	reload(widget) {
		const key = widget.getKey();
		this.reloadWidgets[key] = widget;

		if (this.reloadT !== null) {
			return false;
		}

		this.reloadT = setTimeout(() => this.makeReload(), 3000);
		return true;
	}

	makeReload() {
		const dealIdsByDisplayTypes = {};

		Object.keys(this.reloadWidgets).forEach((key) => {
			const widget = this.reloadWidgets[key];
			const displayType = widget.getDisplayType();
			dealIdsByDisplayTypes[displayType] = dealIdsByDisplayTypes[displayType] || [];
			dealIdsByDisplayTypes[displayType].push(widget.getId());
		});

		const { reloadWidgets } = this;

		this.reloadT = null;
		this.reloadWidgets = {};

		try {
			this.ajaxService.ajax({
				data: { ids: dealIdsByDisplayTypes },
				url: this.ajaxDealsUrl,
			}).done((response) => {
				if (response.ok) {
					const renderedHtml = this.app.render(response.html, this.bodyWidget);
					Object.values(renderedHtml.widgets).forEach((widget) => {
						const key = widget.getKey();
						if (key === null || !reloadWidgets[key]) {
							return;
						}
						reloadWidgets[key].replaceWith(widget);
					});
				}
			});
		} catch (error) {
			Sentry.captureException(error);
		}
	}

	register(listener) {
		this.listeners.push(listener);
	}

	// eslint-disable-next-line class-methods-use-this
	trackAdwords(logData) {
		if (this.cookiesAllowedMarketing === true) {
			// cannot be merged into one call: `google_remarketing_only: false` hits googleadservices.com/pagead/conversion
			// which redirects to `googleads.g.doubleclick.net/pagead/viewthroughconversion`;
			// `google_remarketing_only: true` hits only `googleads.g.doubleclick.net/pagead/viewthroughconversion`
			try {
				// conversion
				window.google_trackConversion({
					google_conversion_id: 990589388,
					google_conversion_language: "cs",
					google_conversion_format: "2",
					google_conversion_color: "ffffff",
					google_conversion_label: "21lRCMXEglYQzOOs2AM",
					google_conversion_value: logData.revenue,
					google_conversion_currency: "CZK",
					google_remarketing_only: false,
				});

				// dynamic remarketing
				window.google_trackConversion({
					google_conversion_id: 990589388,
					google_custom_params: {
						local_id: logData.sku,
						local_pagetype: "conversion",
					},
					google_remarketing_only: true,
				});
			} catch (error) {
				Sentry.captureException(error);
			}
		}
	}
}
