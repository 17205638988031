import $ from "jquery";
import Widget from "../../../../Inlined/Widget";

export default class DealVariationsWidget extends Widget {
	constructor(el, model, children, itemPreviewService) {
		super(el, model, children);

		this.itemPreviewService = itemPreviewService;
	}

	bind() {
		super.bind();

		this.all = $(".j-vars-all", this.el);
		this.more = $(".j-vars-more", this.el);
		this.table = $(".j-vars-table", this.el);
		this.varItem = $(".dealvars-var", this.el);
		this.all.click(ev => this.onAllClick(ev));
		this.more.click(ev => this.onMoreClick(ev));
	}

	onAllClick(ev) {
		ev.preventDefault();
		this.itemPreviewService.preview(this.model.hash, true);
	}

	onMoreClick(ev) {
		ev.preventDefault();

		this.table.removeClass("s-limit");

		this.more.hide();
		this.all.show();
	}
}
