import $ from "jquery";
import AbstractFilterFormWidget from "./AbstractFilterFormWidget";
import AbstractFilterWidget from "./AbstractFilterWidget";
import PageRedirectEvent from "../../Event/PageRedirectEvent";

export default class SearchFilterWidget extends AbstractFilterWidget {
	bind() {
		super.bind();

		this.form = this.findParentByType(AbstractFilterFormWidget) || null;

		if (!this.form) {
			throw new Error("I need AbstractFilterFormWidget parent!");
		}

		this.btnSubmit = $(".j-search-submit", this.el);
		this.btnDelete = $(".j-delete", this.el);
		this.term = $(".j-term", this.el);
		this.search = $(".j-search", this.el);

		this.btnSubmit.click(ev => this.onBtnSubmitClick(ev));
		this.term.change(ev => this.onChange(ev));
		this.term.one("focus", ev => this.onFocus(ev));
		this.btnDelete.on("click", () => this.app.dispatch(new PageRedirectEvent()));
	}

	onBtnSubmitClick(ev) {
		ev.preventDefault();
		this.filter();
	}

	onChange() {
		this.model.term = $.trim(this.term.val()) || null;

		this.filterService.setFilter(this.getId(), this.getName(), this.serialize());
	}

	// Trida kvuli slozitejsimu stylovani (misto pseudotridy :focus)
	onFocus = () => this.el.addClass("s-focused-once");

	serialize = () => this.model.term || null;
}
