import $ from "jquery";
import AES from "crypto-js/aes";
import MD5 from "crypto-js/md5";
import Utf8 from "crypto-js/enc-utf8";
import moment from "moment";
import AbstractListingWidget from "../AbstractListingWidget";
import ResponsiveWidget from "../ResponsiveWidget";
import UserHistoryCreateEvent from "../../Event/UserHistoryCreateEvent";
import WishCreateEvent from "../../Event/WishCreateEvent";
import WishDeleteEvent from "../../Event/WishDeleteEvent";
import ItemThumbWidget from "./ItemThumbWidget";
import TopicDayRegistrationWidget from "../Topicday/TopicDayRegistrationWidget";
import {
	VOUCHER_EXPIRATION_FILTER,
	TRAVEL_DAYS_FILTER,
} from "../Filter/FiltersEnum";
/* global document */
/* global window */
/* global dry */

export default class AbstractItemWidget extends ResponsiveWidget {
	constructor(
		el,
		model,
		children,
		app,
		hitService,
		dealService,
		itemWatchdogService,
		membersService,
		ajaxService,
		itemPreviewService,
		mobileDetectService,
		flashMessageService,
		itemShareService,
		reservationService,
		filterService,
	) {
		super(el, model, children);
		this.app = app;
		this.hitService = hitService;
		this.dealService = dealService;
		this.itemWatchdogService = itemWatchdogService;
		this.membersService = membersService;
		this.ajaxService = ajaxService;
		this.itemPreviewService = itemPreviewService;
		this.mobileDetectService = mobileDetectService;
		this.flashMessageService = flashMessageService;
		this.itemShareService = itemShareService;
		this.reservationService = reservationService;
		this.filterService = filterService;
	}

	bind() {
		super.bind();

		this.hitParamsAttached = false;
		this.model.exitCount = 0;

		this.body = $(document.body);
		this.btnShare = $(".j-share", this.el);
		this.btnPreview = $(".j-preview", this.el);
		this.go = $(".j-go", this.el);
		this.listing = this.findParentByType(AbstractListingWidget);
		this.tags = $(".j-tags", this.el)[0] || null;
		this.tagsBtn = $(".j-tags-button", this.el);
		this.thumb = this.findByType(ItemThumbWidget)[0] || null;
		this.variants = $(".j-var", this.el);
		this.variantAll = $(".j-vars-all", this.el);
		this.tooltips = $("[data-toggle='tooltip']", this.el);
		this.watchdog = $(".j-watchdog", this.el);
		this.dealIn = $(".j-deal-in", this.el);

		this.app.on(WishCreateEvent, this.wishCreateFn = (ev) => { this.onWishCreate(ev); });
		this.app.on(WishDeleteEvent, this.wishDeleteFn = (ev) => { this.onWishDelete(ev); });
		this.tagsBtn.click(() => this.onBtnTagsClick());
		this.btnShare.click(ev => this.onShareButtonClick(this, ev));
		this.btnPreview.click(ev => this.onPreviewBtnClick(ev));
		this.go.on("mousedown contextmenu", ev => this.onBeforeGo(ev));
		this.go.on("click", ev => this.onGo(ev));
		this.variants.click(ev => this.onVariationClick(ev));
		this.watchdog.click(ev => this.onWatchdogButtonClick(ev));

		this.tooltips.tooltip({
			container: "body",
		});
		this.registerTracking();

		this.model.topDeal = this.el.is(".s-top");

		if (this.model.logData) {
			this.model.logData = JSON.parse(AES.decrypt(
				this.model.logData.substring(8),
				MD5(this.model.logData.substring(0, 4)),
				{
					iv: MD5(this.model.logData.substring(4, 8)),
				},
			).toString(Utf8));
		}
	}

	registerTracking() {
		if (this.model.trackCtr !== false) {
			this.hitService.register(this);
		}
	}

	unbind() {
		super.unbind();

		this.app.off(WishCreateEvent, this.wishCreateFn);
		this.app.off(WishDeleteEvent, this.wishDeleteFn);

		if (this.model.trackCtr !== false) {
			this.hitService.unregister(this);
		}
	}

	getDisplayType() {
		return this.model.displayType || null;
	}

	getKey() {
		let key = this.constructor.name;
		key = key.concat(":").concat(this.model.type);
		key = key.concat(":").concat(this.model.id);
		key = key.concat(":").concat(this.model.displayType);
		return key;
	}

	getPreviewImage() {
		return this.model.previewImageUrl || null;
	}

	getId() {
		return this.model.id || null;
	}

	getHash() {
		return this.model.hash || null;
	}

	getSlug() {
		return this.model.slug || null;
	}

	getTitle() {
		return this.model.title || null;
	}

	getType() {
		return this.model.type || null;
	}

	getThreshold() {
		if (this.getDeviceSize() === "xs") {
			return $(window).height() * 3;
		}

		if (this.getDeviceSize() === "sm") {
			return 1600;
		}

		return 800;
	}

	hide(cb) {
		// eslint-disable-next-line
		this.el.fadeOut("slow", (cb || function() {}));
	}

	onBtnTagsClick() {
		const visibleClass = "deal-tags-switch--is-visible";
		const iconMinus = "glyphicon-minus-sign";
		const iconPlus = "glyphicon-plus-sign";
		const btn = this.tagsBtn[0] || null;
		const svg = $("use", this.tagsBtn)[0] || null;
		const hideClass = "bye";

		btn.classList.toggle(visibleClass);
		if (btn.classList.contains(visibleClass)) {
			svg.setAttribute("xlink:href", `/assets/def/img/icons-sprite.svg?v=5#${iconMinus}`);
			this.tags.classList.remove(hideClass);
		} else {
			svg.setAttribute("xlink:href", `/assets/def/img/icons-sprite.svg?v=5#${iconPlus}`);
			this.tags.classList.add(hideClass);
		}
	}

	onMouseIn() {
		if (this.thumb) {
			if (this.isDesktop() && this.body.hasClass("s-dealspaginationimages")) {
				this.thumb.preloadAll("pagination");
			}
			this.thumb.play();
		}
		this.attachHitParams();
	}

	onMouseOut() {
		if (this.thumb) {
			this.thumb.pause();
		}
	}

	onBeforeGo(ev) {
		const $btn = $(ev.currentTarget);
		const variantId = $btn.data("variant");
		const trackContent = $btn.data("content");
		const travelTerm = $btn.data("term");

		this.exitCancelled = false;

		this.model.source = this.getSource();
		this.model.medium = this.getMedium();
		this.model.position = this.getPosition();
		this.model.term = this.getTerm();
		this.model.variant = this.getVariant();
		this.model.rank = this.getRank();
		const campaign = this.getCampaign();

		if (campaign === "topdeal" && this.model.topDeal === 0) {
			this.model.campaign = "topdeal_mlt";
		} else {
			this.model.campaign = campaign;
		}

		if (this.model.topicDayRegistration) {
			// Neprovest exit: registracni fancybox na topicday detailu
			const topicDayRegistrationWidget = this.app.findByType(TopicDayRegistrationWidget)[0] || null;
			if (topicDayRegistrationWidget) {
				topicDayRegistrationWidget.openDialog(true);
				this.exitCancelled = true;
			}
		} else if (!this.membersService.isExitApproved(this.model)) {
			// Neprovest exit: deal je jen pro cleny, vyzadovat registraci
			this.membersService.openDialog(this.model);
			this.exitCancelled = true;
		}

		if (this.model.exitUrl && !this.exitCancelled) {
			// Otevreni noveho okna zpusobi, ze se nevyvola udalost onMouseOut a nezastavi se rotace obrazku
			if (this.thumb !== null) {
				this.thumb.pause();
			}

			// Propsani s_content parametru do exit URL.
			// Propisujeme jen u odkazu <a>, jinak se parametr doplni v metode onGo()
			if ($btn[0].tagName === "A") {
				$btn.attr("href", this.createRealExitUrl(
					trackContent,
					variantId,
					travelTerm,
					this.filterService.filters,
				));
			}
		}
	}

	onGo(ev) {
		const $btn = $(ev.currentTarget);
		const variantId = $btn.data("variant");
		const trackContent = $btn.data("content");
		const travelTerm = $btn.data("term");

		if (this.exitCancelled) {
			// Pro elementy typu <a>
			ev.preventDefault();
			// Pro ostatni elementy nic
		}

		// Udelat proklik! (pokud element neni <a>)
		let exitUrl = "";
		if ($btn[0].tagName !== "A") {
			exitUrl = this.createRealExitUrl(trackContent, variantId, travelTerm, this.filterService.filters);
			window.open(exitUrl, this.model.sameWindow ? "_self" : "_blank");
		} else {
			exitUrl = $btn.attr("href");
		}

		this.model.exitCount += 1;
		$(window).one("focus", () => { this.onNavigatedBack(); });

		// Bude exit: zobrazit delayed item preview
		if (this.model.type === "deal"
			&& (this.model.delayedPreview || (this.listing && this.listing.isDelayedPreview()))
		) {
			setTimeout(
				() => {
					this.itemPreviewService.preview(this.model.hash, true, this, this.listing, true);
				},
				0,
			);
		}

		if (this.model.trackCtr !== false) {
			this.hitService.trackClick(
				this.model.id,
				this.model.type,
				this.model.source,
				this.model.medium,
				this.model.campaign,
				this.model.position,
				this.model.term,
				this.model.variant,
				this.model.rank,
				trackContent,
			);
		}

		// log click tracking (remarketing, conversion tracking etc.)
		if (this.model.type === "deal" && this.dealService.shouldLogClickInSameWindow()) {
			setTimeout(() => this.dealService.logClick(this.model, exitUrl, variantId), 0);
		}
	}

	createRealExitUrl(trackContent, variantId, travelTerm, filters) {
		let params = [];

		if (trackContent) {
			params.push("s_content=".concat(trackContent));
		}

		if (variantId) {
			params.push("dv=".concat(variantId));
		} else if (this.model.currentVariantId) {
			params.push("dv=".concat(this.model.currentVariantId));
		}

		if (travelTerm) {
			params.push("dt=".concat(travelTerm));
		}

		if (this.hitService.getPageviewId()) {
			params.push("pv=".concat(this.hitService.getPageviewId()));
		}

		if (filters && filters[VOUCHER_EXPIRATION_FILTER] && filters[VOUCHER_EXPIRATION_FILTER].value) {
			let [from, to] = filters[VOUCHER_EXPIRATION_FILTER].value.split("-");
			const [fromDays, toDays] = filters[TRAVEL_DAYS_FILTER]
				? filters[TRAVEL_DAYS_FILTER].value.split("-").map(value => parseInt(value, 10))
				: [null, null];
			from = moment(from, "DD.MM.YYYY");
			to = moment(to, "DD.MM.YYYY");
			const fromToDiffInDays = to.diff(from, "days") + 1;

			// only exact date
			if (fromDays === toDays && fromToDiffInDays === fromDays) {
				params.push("from=".concat(from.format("YYYY-MM-DD")));
				params.push("to=".concat(to.format("YYYY-MM-DD")));
			}
		}

		params = params.join("&");

		const url = this.model.exitUrl.split("#")[0];
		const hash = this.model.exitUrl.split("#")[1];

		let realExitUrl;
		if (this.model.exitUrl.indexOf("?") === -1) {
			realExitUrl = url.concat("?").concat(params);
		} else {
			realExitUrl = url.concat("&").concat(params);
		}

		if (hash) {
			realExitUrl = realExitUrl.concat("#").concat(hash);
		}

		return realExitUrl;
	}

	onNavigatedBack() {
		if (this.model.published) {
			this.app.dispatch(new UserHistoryCreateEvent(this.model.id, this.model.type));
		}
	}

	onPreviewBtnClick(ev) {
		// Otevirat deal preview v modal okne (vsude krome mobilu)
		if (this.getDeviceSize() !== "xs") {
			ev.preventDefault();

			if (this.model.type === "deal") {
				this.itemPreviewService.preview(this.model.hash, true, this, this.listing, false, this.model.term);
			}
		}
	}

	onShareButtonClick(btn, ev) {
		ev.preventDefault();

		this.itemShareService.openDialog(this.model);
	}

	onVariationClick() {

		const category = this.model.topCategory || null;
		const categoryTitle = category !== null ? category.title : null;
	}

	onWatchdogButtonClick(ev) {
		ev.preventDefault();

		const watchdog = {
			deal: this.model.hash,
		};

		if (this.listing && this.listing.getTerm) {
			watchdog.search = this.listing.getTerm();
		}

		this.itemWatchdogService.openDealWatchdog(watchdog);
	}

	// eslint-disable-next-line
	onWishCreate() {}

	// eslint-disable-next-line
	onWishDelete() {}

	// Naskroluje polozku do stredu viewportu
	scrollIntoView() {
		const doc = (this.el[0].ownerDocument ? this.el[0].ownerDocument : document).documentElement;

		// Element neni pripojen k dokumentu
		if (!$.contains(doc, this.el)) {
			return;
		}

		const { top } = this.el.offset();
		const height = this.el.outerHeight();
		const windowHeight = $(window).height();

		const y = top - ((windowHeight - height) / 2);
		$(window).scrollTop(y);
	}

	setDelayedPreview(delayedPreview) {
		this.delayedPreview = delayedPreview;
	}

	setPreview(preview) {
		this.preview = preview;
	}

	track() {
		if (this.model.trackCtr === false) {
			return;
		}

		if (!this.model.trackedView) {
			const rect = this.el[0].getBoundingClientRect();
			const windowWidth = (window.innerWidth || document.documentElement.clientWidth);
			const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
			const visible = rect.left > 0 && rect.right < windowWidth
				&& rect.top > 0
				&& rect.top + (rect.height * (3 / 4)) < windowHeight;

			if (visible) {
				if (!this.model.visibleStartTime) {
					this.model.visibleStartTime = new Date();
				} else {
					const visibleFor = (new Date()) - this.model.visibleStartTime;
					if (visibleFor > 1000) {
						const visibleHeight = rect.height
							- Math.max(0, -rect.top)
							- Math.max(0, rect.bottom - windowHeight);
						const visibleWidth = rect.width
							- Math.max(0, -rect.left)
							- Math.max(0, rect.right - windowWidth);
						const impressionPoints = (visibleWidth * visibleHeight) / (windowWidth * windowHeight);

						this.model.source = this.getSource();
						this.model.medium = this.getMedium();
						this.model.position = this.getPosition();
						this.model.term = this.getTerm();
						this.model.variant = this.getVariant();
						this.model.rank = this.getRank();
						const campaign = this.getCampaign();

						if (campaign === "topdeal" && this.model.topDeal === 0) {
							this.model.campaign = "topdeal_mlt";
						} else {
							this.campaign = campaign;
						}


						this.hitService.trackView(
							this.model.id,
							this.model.type,
							this.model.source,
							this.model.medium,
							this.model.campaign,
							this.model.position,
							this.model.term,
							this.model.variant,
							this.model.rank,
							impressionPoints,
						);
						if (this.model.type === "deal") {
							this.dealService.logView(this.model);
						}
						this.model.trackedView = true;
					}
				}
			} else {
				delete this.model.visibleStartTime;
			}
		}
	}

	attachHitParams() {
		if (this.hitParamsAttached) {
			return;
		}

		this.hitParamsAttached = true;

		this.model.source = this.getSource();
		this.model.medium = this.getMedium();
		this.model.position = this.getPosition();
		this.model.term = this.getTerm();
		this.model.variant = this.getVariant();
		this.model.rank = this.getRank();
		const campaign = this.getCampaign();

		if (campaign === "topdeal" && this.model.topDeal === 0) {
			this.model.campaign = "topdeal_mlt";
		} else {
			this.model.campaign = campaign;
		}

		let params = [];
		if (this.model.source) {
			params.push("s_source=".concat(this.model.source));
		}
		if (this.model.medium) {
			params.push("s_medium=".concat(this.model.medium));
		}
		if (this.model.campaign) {
			params.push("s_campaign=".concat(this.model.campaign));
		}
		if (this.model.term) {
			params.push("s_term=".concat(this.model.term));
		}
		if (this.model.position) {
			params.push("s_position=".concat(this.model.position));
		}
		if (this.model.variant) {
			params.push("s_variant=".concat(this.model.variant));
		}
		if (this.model.rank) {
			params.push("s_rank=".concat(this.model.rank));
		}

		params = params.join("&");

		const url = this.model.exitUrl.split("#")[0];
		const hash = this.model.exitUrl.split("#")[1];

		if (this.model.exitUrl.indexOf("?") === -1) {
			this.model.exitUrl = url.concat("?").concat(params);
		} else {
			this.model.exitUrl = url.concat("&").concat(params);
		}

		if (hash) {
			this.model.exitUrl = this.model.exitUrl.concat("#").concat(hash);
		}
	}

	addStats(statsWidget) {
		if (this.stats) {
			this.stats.remove();
		}

		this.stats = statsWidget;
		this.children.push(statsWidget);
		statsWidget.setParent(this);
		$(".j-thumb", this.el).append(statsWidget.el);
	}

	hasStats() {
		return !!this.stats;
	}
}
