import AbstractListingWidget from "./AbstractListingWidget";
import ListingWidget from "./ListingWidget";
import VerifiedTermListingWidget from "./VerifiedTermListingWidget";
// eslint-disable-next-line import/named

export default class RelatedDealsListingWidget extends AbstractListingWidget {
	bind() {
		this.listingWidget = this.parent ? (this.parent.findByType(ListingWidget)[0] || null) : null;
		this.verifiedTermListingWidget = this.parent
			? (this.parent.findByType(VerifiedTermListingWidget)[0] || null)
			: null;
		super.bind();
	}

	getPreviousListingPage() {
		return this.listingWidget ? this.listingWidget.getPage() : 0;
	}

	isEnabled() {
		// Enabled after all listings on pages has ended
		return (this.listingWidget === null || this.listingWidget.hasMore() === false)
			&& (this.verifiedTermListingWidget === null || this.verifiedTermListingWidget.hasMore() === false);
	}

	onScroll() {
		if (!this.isEnabled()) {
			return;
		}
		super.onScroll();
	}

	track() {

	}
}
