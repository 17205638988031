import $ from "jquery";

const ResponsiveMenuMixin = {
	bindResponsiveMenu(mobilePlacement) {
		this.mobilePlacement = mobilePlacement;
		this.origPlacement = null;
	},
	placeResponsiveMenu(placement) {
		if (this.mobilePlacement === null || !this.mobilePlacement.length) {
			return;
		}

		if (this.origPlacement == null) {
			this.origPlacement = $("<span></span>").insertAfter(this.el);
		}

		if (placement === "mobile") {
			this.el.appendTo(this.mobilePlacement);
		} else {
			this.el.insertBefore(this.origPlacement);
		}
	},
};

export default ResponsiveMenuMixin;
