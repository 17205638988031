import $ from "jquery";
import AbstractFilterFormWidget from "./AbstractFilterFormWidget";
import AbstractFilterWidget from "./AbstractFilterWidget";

export default class LocalZoneFilterWidget extends AbstractFilterWidget {
	bind() {
		super.bind();

		this.form = this.findParentByType(AbstractFilterFormWidget) || null;

		if (!this.form) {
			throw new Error("I need AbstractFilterFormWidget parent!");
		}

		this.el.on("click", ".j-link", (ev) => { this.onLinkClick(ev); });
		this.el.on("click", ".j-more", (ev) => { LocalZoneFilterWidget.onMoreClick(ev); });
	}

	onLinkClick(ev) {
		const val = $(ev.currentTarget).data("val");
	}

	// eslint-disable-next-line class-methods-use-this
	serialize() {
		// Nepridava se do URL jako filtr, ale jako slug trail
		return null;
	}
}

LocalZoneFilterWidget.onMoreClick = function onMoreClick(ev) {
	ev.preventDefault();
	ev.stopPropagation();

	const $button = $(ev.currentTarget);
	const $list = $button.closest(".j-list");

	$(".hide", $list).removeClass("hide");
	$button.hide();
};
