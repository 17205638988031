import Widget from "./../../../../../../Inlined/Widget";
import ReactLoader from "../../../ReactLoader";

export default class ChildrenCountTypeWidget extends Widget {
	bind() {
		super.bind();
		this.render();
	}

	render() {
		ReactLoader().then(({ React, ReactDOM, ChildrenCountType }) => {
			ReactDOM.render(<ChildrenCountType {...this.model} />, this.el[0]);
		});
	}
}
