import $ from "jquery";
import RenderedHtmlVO from "./VO/RenderedHtmlVO";
import EventDispatcherMixin from "./EventDispatcherMixin";
import Widget from "./Widget";
import RenderEvent from "./Event/RenderEvent";
/* global skrz */

class ApplicationError extends Error { }

export default class Application {
	constructor(container) {
		this.container = container;
		this.stack = [];
		this.widgetChildren = [];
		this.children = [];
		this.listeners = [];

		this.dispatch = EventDispatcherMixin.dispatch;
		this.on = EventDispatcherMixin.on;
		this.off = EventDispatcherMixin.off;
	}

	begin() {
		this.stack.push([]);
	}

	end() {
		this.widgetChildren = this.stack.pop();
	}

	widget(name, el, model) {
		const widget = this.container.create(`${name}Widget`, el, model, this.widgetChildren);

		if (this.stack.length) {
			this.stack[this.stack.length - 1].push(widget);
		} else {
			this.children.push(widget);
		}

		this.widgetChildren = [];
	}

	processQueue(queue, parent, doBind = true) {
		for (const args of queue) {
			const method = args.shift();
			this[method](...args);
		}

		if (doBind) {
			this.children.map((child) => {
				if (parent) {
					child.setParent(parent);
				}
				child.bind();
				if (!child.__bound__) {
					throw new ApplicationError("You have to call parent's bind!");
				}
				return child;
			});
		}
	}

	render(html, parent, doBind = true, dispatchEvent = true) {
		if (!parent || !(parent instanceof Widget)) {
			throw (new Error("You have to supply parent widget."));
		}

		const savedQueue = skrz.queue;
		const savedStack = this.stack;
		const savedWidgetChildren = this.widgetChildren;
		const savedChildren = this.children;

		skrz.queue = [];
		this.stack = [];
		this.widgetChildren = [];
		this.children = [];

		const $body = $("body");
		const $container = $("<div>");
		$body.append($container);
		skrz.open();
		$container.html(html);
		skrz.close();
		this.processQueue(skrz.queue, parent, doBind);
		const $children = $container.children();
		const ret = new RenderedHtmlVO($children, this.children);

		for (const w of ret.widgets) {
			w.setParent(parent);
			parent.children.push(w);
		}

		// Widget elementy nemaji zadneho rodice.
		// Prevence proti memory leakum ve Fancyboxu (udrzuje udalosti na elementech majici rodice).
		$children.detach();
		$container.remove();

		this.children = savedChildren;
		this.widgetChildren = savedWidgetChildren;
		this.stack = savedStack;
		skrz.queue = savedQueue;

		if (dispatchEvent) {
			setTimeout(() => this.dispatch(new RenderEvent(ret)), 0);
		}

		return ret;
	}

	findByType(clazz) {
		let byType = this.children
			.map(it => (it.findByType(clazz)))
			.reduce((total, currentValue) => (total.concat(currentValue)), []);
		if (this instanceof clazz) {
			byType = [this].concat(byType);
		}
		return byType;
	}
}
