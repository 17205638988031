import $ from "jquery";
import Widget from "./../../../Inlined/Widget";
import UserAuthResumeEnum from "../VO/UserAuthResumeEnum";
import UserAuthStateEnum from "../VO/UserAuthStateEnum";
import UserAuthStateEvent from "../Event/UserAuthStateEvent";
import { getIconSpriteUrl } from "../utils";
import checkIcon from "../Styles/def/img/icons/check.svg";
/* global Image */
/* global dry */
/* global document */
/* global window */

export default class TopicMailSubscribeWidget extends Widget {
	constructor(el, model, children, userService, flashMessageService, subscribeMessageService) {
		super(el, model, children);

		this.userService = userService;
		this.flashMessageService = flashMessageService;
		this.subscribeMessageService = subscribeMessageService;
	}

	bind() {
		super.bind();

		this.model.userAuthResume = this.model.isHpSubscribe
			? UserAuthResumeEnum.HOMEPAGE_SUBSCRIBE
			: UserAuthResumeEnum.TOPIC_MAIL_SUBSCRIBE_RESUME;
		this.model.waitingForAuth = false;

		this.img = $(".j-img", this.el);
		this.spinner = $(".j-spinner", this.el);
		this.subscribe = $(".j-subscribe", this.el);
		this.subscribe.click(ev => this.onSubscribeClick(ev));
		this.userService.register(this.model.userAuthResume, payload => this.onUserAuthResume(payload));
		this.userService.on(UserAuthStateEvent, ev => this.onUserAuth(ev));
		$(document.body).on("click", ".j-topicmailsubscribe-retry", this.onRetryFn = ev => this.onRetry(ev));

		$(window).on("scroll customScroll", (this.onScrollFn = dry.fn.throttle((() => this.onScroll()), 200)));
		setTimeout(() => this.onScroll(), 0);
	}

	unbind() {
		$(window).off("scroll customScroll", this.onScrollFn);
		$(document.body).off(".j-topicmailsubscribe-retry", this.onRetryFn);
	}

	onRetry(ev) {
		ev.preventDefault();
		this.setTopicMailing();
	}

	onUserAuth(ev) {
		if (this.model.waitingForAuth && ev.newState === UserAuthStateEnum.COMPLETE_STATE) {
			this.model.waitingForAuth = false;
			this.setTopicMailing();
		}
	}

	onUserAuthResume() {
		this.model.waitingForAuth = false;
		this.setTopicMailing();
	}

	onSubscribeClick(ev) {
		ev.preventDefault();
		this.userService.setLoginId(this.model.userAuthResume);
		this.userService.setPayload({
			silentLogin: true,
		});
		this.userService.setInternalTrackingParameters(this.getInternalTrackingParameters());
		this.userService.doLogin();
		this.model.waitingForAuth = true;
	}

	onScroll() {
		TopicMailSubscribeWidget.lazyloadImage(this.img);
	}

	setTopicMailing() {
		this.userService
			.setSettings({
				emailing_push_email_frequency: "medium",
				only_up: true,
			})
			.then(
				() => {
					if (!this.userService.isNewUser()) {
						this.subscribeMessageService.showExistingUserSuccessMessage(this.userService.getEmail());
					}
					this.subscribe
						.html(`
							<svg class="icon" aria-hidden="true">
								<use xlink:href='${getIconSpriteUrl(checkIcon)}'></use>
							</svg>
							Odebírání tipů bylo zapnuto
						`)
						.prop("disabled", true);
				},
				() => {
					this.flashMessageService.showMessage(
						"Došlo k chybě. <a class=\"j-topicmailsubscribe-retry j-close\" href=\"#\">Zkusit znovu</a>",
						"warning",
						{
							modal: true,
						},
					);
				},
			);
	}
}

// @TODO Oddelit lazyloading do service, aby byl obecne pouzitelny
// https://skrzcz.atlassian.net/browse/DEV-4067
TopicMailSubscribeWidget.lazyloadImage = function lazyloadImage($img) {
	// Neni co nacitat
	if (!$img.length || !$img.data("src") || $img.data("lazyloading")) {
		return;
	}

	const rect = $img[0].getBoundingClientRect();
	const windowWidth = (window.innerWidth || document.documentElement.clientWidth);
	const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
	const thresholdX = windowWidth / 2;
	// @TODO Threshold dynamicky podle velikosti viewportu
	const thresholdY = 800;
	const visible = rect.left >= -thresholdX
		&& rect.right <= windowWidth + thresholdX
		&& rect.top >= -thresholdY
		&& rect.top <= windowHeight + thresholdY;

	// Neni ve viewportu
	if (!visible) {
		return;
	}

	// Nacist obrazek
	const src = $img.data("src");
	const image = new Image();
	image.onload = () => {
		$img
			.attr("src", src)
			.removeData("src")
			.removeAttr("data-src");
	};
	image.src = src;
	$img.data("lazyloading", image);
};
