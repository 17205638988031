import $ from "jquery";
import Widget from "./../../../../Inlined/Widget";
import ValidationMixin from "../ValidationMixin";
import suggestEmail from "./SuggestEmail";
/* global window */

export default class UserForgottenPasswordWidget extends Widget {
	constructor(el, model, children, validateSettings) {
		super(el, model, children);

		this.validateSettings = validateSettings;

		this.bindValidation = ValidationMixin.bindValidation;
		this.getEmailMessage = ValidationMixin.getEmailMessage;
		this.onInputFix = ValidationMixin.onInputFix;
		this.onValidationSuccess = ValidationMixin.onValidationSuccess;
	}

	bind() {
		super.bind();

		this.emailInput = $(".j-email", this.el);

		this.bindForm();
		this.bindValidation();
	}

	bindForm() {
		const formSettings = {
			rules: {
				email: {
					required: true,
					email: false,
					emailHtml5: true,
				},
			},
			messages: {
				email: {
					required: "Zadej e-mail.",
					emailHtml5: (...args) => this.getEmailMessage(...args),
				},
			},
			submitHandler: (form, ev) => this.onSubmit(ev),
			success: ($label, element) => {
				this.onValidationSuccess($label, element);
			},
		};

		this.el.validate($.extend({}, this.validateSettings, formSettings));
	}

	onSubmit() {
		let email = this.emailInput.val();

		if (email) {
			const suggestedEmail = suggestEmail(email);

			if (suggestedEmail) {
				// eslint-disable-next-line no-alert
				const replace = window.confirm(`Zdá se nám, že v emailové adrese je překlep. 
Není adresa správně takto? ${suggestedEmail}`);
				if (replace) {
					email = suggestedEmail;
					this.emailInput.val(email);
				}
			}
		}

		return true;
	}
}
