import $ from "jquery";
import Widget from "../../../Inlined/Widget";
import FixHeaderShowEvent from "../Event/FixHeaderShowEvent";
import FixHeaderHideEvent from "../Event/FixHeaderHideEvent";
import TrackingMixin from "./TrackingMixin";

/* global document */

export default class FloatingWidget extends Widget {
	constructor(el, model, children, app, domain) {
		super(el, model, children);
		this.app = app;
		this.domain = domain;

		this.bindTracking = TrackingMixin.bindTracking;
		this.attachHitParams = TrackingMixin.attachHitParams;
		this.onMouseIn = TrackingMixin.onMouseIn;
	}

	bind() {
		super.bind();

		this.btnClose = $(".j-close", this.el);
		this.btnGo = $(".j-go", this.el);
		if (this.model.container) {
			this.floatingContainer = $(this.model.container);
		} else {
			this.floatingContainer = $(".f-side").parent();
		}
		this.fixHeaderEl = null;
		this.marginTop = 20;

		this.app.on(FixHeaderShowEvent, ev => this.onFixHeaderShow(ev));
		this.app.on(FixHeaderHideEvent, ev => this.onFixHeaderHide(ev));
		this.btnClose.click(ev => this.onBtnCloseClick(ev));
		this.btnGo.click(ev => this.onBtnGoClick(ev));

		if (this.model.floating !== false) {
			this.bindFloating();
		}

		this.remainingPageCounter = null;

		$(document.body).one("mousemove touchstart scroll", () => this.pushPageView());
		this.bindTracking(this.btnGo);
	}

	bindFloating() {
		this.el.floating({
			marginTop: () => {
				const spacing = this.fixHeaderEl ? this.fixHeaderEl.outerHeight() : 0;
				return this.marginTop + spacing;
			},
			limit: () => this.floatingContainer.offset().top + this.floatingContainer.outerHeight(),
			zIndex: 30,
		});
	}

	dismiss() {
		this.el.hide();

		// Po dobu X dni nezobrazit
		if (this.model.limitPageViews || this.model.dismissDays) {
			const dismissDays = this.model.dismissDays || 7;
			this.setRemainingPageCounter(0, dismissDays);
		}
	}

	getRemainingPageCounter() {
		if (this.remainingPageCounter === null) {
			this.remainingPageCounter = Number(Cookies.get(this.model.cookieName));

			if (Number.isNaN(this.remainingPageCounter)) {
				this.remainingPageCounter = this.model.limitPageViews;
			}
		}

		return this.remainingPageCounter;
	}

	go() {
		// Jiz nezobrazit, nanejvys 1x za 10 let ;-)
		if (this.model.limitPageViews) {
			this.setRemainingPageCounter(0, 10 * 365);
		}
	}

	onBtnCloseClick(ev) {
		ev.preventDefault();
		this.dismiss();
	}

	onBtnGoClick() {
		this.go();
	}

	onFixHeaderShow(ev) {
		this.fixHeaderEl = ev.header.el;
	}

	onFixHeaderHide() {
		this.fixHeaderEl = null;
	}

	pushPageView() {
		if (this.model.limitPageViews) {
			this.setRemainingPageCounter(this.getRemainingPageCounter() - 1, 10 * 365);
		}
	}

	setRemainingPageCounter(count, expires) {
		if (count < 0) {
			return;
		}
		Cookies.set(
			this.model.cookieName,
			count,
			{
				domain: `.${this.domain}`,
				path: "/",
				secure: true,
				expires,
			},
		);
		this.remainingPageCounter = count;
	}
}
