import Application from "../../../Inlined/Application";

const RerenderMixin = {
	rerender(html) {
		if (!this.app || !(this.app instanceof Application)) {
			throw new Error("RerenderMixin needs this.app to be instance of Application");
		}

		const renderedHtml = this.app.render(html, this.parent, false);
		if (renderedHtml.elements.length !== 1) {
			throw new Error(`Got ${renderedHtml.elements.length} elements, expected one.`);
		}
		const el = renderedHtml.elements[0];
		if (renderedHtml.widgets.length !== 1) {
			throw new Error(`Got ${renderedHtml.widgets.length} widgets, expected one.`);
		}
		const w = renderedHtml.widgets[0];

		this.unbind();
		const oldEl = this.el;

		// copy properties
		Object.keys(w).forEach((key) => {
			this[key] = w[key];
		});
		oldEl.replaceWith(el);
		this.bind();
	},
};

export default RerenderMixin;
