export default class TypeaheadRequestVO {
	static SEND_AFTER_DELAY = 200;

	constructor() {
		this.callbacksByDatasets = {};
		this.query = null;
		this.sent = false;
		this.timeout = null;
	}

	getQuery() {
		return this.query;
	}

	setQuery(query) {
		this.query = query;
	}

	getCallbacks() {
		return this.callbacksByDatasets;
	}

	setCallback(datasetName, callback) {
		this.callbacksByDatasets[datasetName] = callback;
	}

	isSent() {
		return this.sent;
	}

	setSent(sent) {
		this.sent = sent;
	}

	getTimeout() {
		return this.timeout;
	}

	setTimeout(timeout) {
		this.timeout = timeout;
	}
}
