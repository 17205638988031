import $ from "jquery";
import MapWidget from "./MapWidget";
import ListingMapButtonWidget from "./ListingMapButtonWidget";
/* global __ */

export default class ListingMapWidget extends MapWidget {
	constructor(el, model, children, ajaxService, flashMessageService, app, bodyWidget) {
		super(el, model, children);
		this.ajaxService = ajaxService;
		this.flashMessageService = flashMessageService;
		this.app = app;
		this.bodyWidget = bodyWidget;
	}

	bind() {
		super.bind();
		this.btnClose = $(".j-close", this.el);
		this.btnShowMore = $(".j-showmore-deals", this.el);
		this.listingMapButton = this.bodyWidget ? this.bodyWidget.findByType(ListingMapButtonWidget)[0] : null;
		this.popups = {};
		this.btnClose.click(ev => this.onBtnCloseClick(ev));
		this.btnShowMore.click(() => this.showNearbyDeals());
		this.points = this.model.topDealInfo && this.model.topDealInfo.length
			? this.model.topDealInfo
			: this.model.points;
	}

	showNearbyDeals() {
		this.points = this.model.points;
		this.refresh();
		this.btnShowMore.hide();
	}

	getPopupContent(marker) {
		const { point } = marker;

		if (!point) {
			this.flashMessageService.showMessage(__("Nepodařilo se načíst slevu"));
			return $.Deferred().reject();
		}

		// Async content cache
		if (this.popups[point.hash]) {
			return $.Deferred().resolve(this.popups[point.hash]);
		}

		// Async content
		return this.ajaxService
			.ajax({
				url: this.model.dealWindowUrlPattern.replace("{hash}", point.hash),
			})
			.then(
				(response) => {
					const renderedHtml = this.app.render(response.html, this);

					// Popup is widget so impressions and clicks work properly
					if (renderedHtml.widgets.length) {
						const popupWidget = renderedHtml.widgets[0];
						const element = popupWidget.el[0];
						// Async content cache
						this.popups[point.hash] = element;
						return element;
					}

					this.flashMessageService.showMessage(__("Nepodařilo se načíst slevu"));
					return $.Deferred().reject("No widget found");
				},
				(jqXHR, textStatus) => {
					this.flashMessageService.showMessage(__("Nepodařilo se načíst slevu"));
					return $.Deferred().reject(textStatus);
				},
			);
	}

	// eslint-disable-next-line class-methods-use-this
	hasPopup(marker) {
		const { point } = marker;
		return point && point.hash;
	}

	onBtnCloseClick(ev) {
		ev.preventDefault();

		// Pokud je na strance tlacitko, zavrit mapu tlacitkem, aby se zmenil jeho popisek. Nesnasim jQuery!
		if (this.listingMapButton) {
			this.listingMapButton.hideMap();
		} else {
			this.hide();
		}
	}
}
