import Widget from "../../../../Inlined/Widget";
import ExitLocationDialogWidget from "./ExitLocationDialogWidget";
import BeforeExitEvent from "../../Event/BeforeExitEvent";

export default class ExitLocationWidget extends Widget {
	constructor(el, model, children, app, fancyboxService) {
		super(el, model, children);
		this.app = app;
		this.fancyboxService = fancyboxService;

		this.dialog = this.findByType(ExitLocationDialogWidget)[0] || null;

		this.app.on(BeforeExitEvent, ev => this.onBeforeExit(ev));
	}

	onBeforeExit(ev) {
		if (!ev.exitUrl || !ev.zone) {
			return;
		}
		this.dialog.setExit(ev.exitUrl, ev.zone);
		this.fancyboxService.openWidget(this.dialog);
	}
}
