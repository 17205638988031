import $ from "jquery";
import Widget from "../../../../Inlined/Widget";

export default class SideFilterWidget extends Widget {
	bind() {
		super.bind();
		this.delete = $(".j-delete", this.el);
		this.delete.on("click", ev => ev.stopPropagation());
	}
}
