import $ from "jquery";
import Widget from "../../../../Inlined/Widget";

export default class LargeCitiesZonesWidget extends Widget {
	bind() {
		super.bind();

		this.btnMore = $(".j-more", this.el);

		this.btnMore.click(ev => this.onBtnMoreClick(ev));
	}

	onBtnMoreClick(ev) {
		ev.preventDefault();

		const $btn = $(ev.currentTarget);
		const $items = $btn.closest(".j-items");
		const $hidden = $(".hide", $items);

		$hidden.removeClass("hide");
		$btn.hide();

		return this;
	}
}
