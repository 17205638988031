import $ from "jquery";
import AbstractFilterFormWidget from "./AbstractFilterFormWidget";
import AbstractFilterWidget from "./AbstractFilterWidget";

export default class CategoryFilterWidget extends AbstractFilterWidget {
	bind() {
		super.bind();

		this.form = this.findParentByType(AbstractFilterFormWidget) || null;

		if (!this.form) {
			throw new Error("I need AbstractFilterFormWidget parent!");
		}

		this.el.on("click", ".j-link", (ev) => { this.onLinkClick(ev); });
		this.el.on("change", ".j-check", () => this.onChange());
	}

	// eslint-disable-next-line class-methods-use-this
	onLinkClick(ev) {
		ev.preventDefault();
		const $this = $(ev.currentTarget);

		// Zaskrtnuti, protoze nepouzivame <label> element kvuli zjednoduseni markupu a stylu
		const checked = $this.prevAll(":radio").prop("checked");
		$this.prevAll(":radio")
			.prop("checked", !checked)
			.change();
		$this.blur();
	}

	onChange() {
		const inputs = $(".j-check", this.el);

		this.model.categorySlug = inputs.filter(":checked").val();

		this.filterService.setFilter(this.getId(), this.getName(), this.serialize());
	}

	serialize = () => (this.model.categorySlug || null);
}
