/* global __ */
import $ from "jquery";
import AbstractFilterFormWidget from "./AbstractFilterFormWidget";
import AbstractFilterWidget from "./AbstractFilterWidget";
import {
	ID_DATA_SEPARATOR,
	FILTER_DATA_SEPARATOR,
} from "../../Service/FilterService";

const TRANSPORT_BY_PLANE_SLUG = __("letecky");

export default class TravelTransportFilterWidget extends AbstractFilterWidget {
	bind() {
		super.bind();

		this.form = this.findParentByType(AbstractFilterFormWidget) || null;

		if (!this.form) {
			throw new Error("I need AbstractFilterFormWidget parent!");
		}

		this.btnSubmit = $(".j-transport-submit", this.el);
		this.links = $(".j-link", this.el);
		this.checkboxes = $(".j-check", this.el);
		this.deptPlacesCheckboxes = $(".j-check-subitem", this.el);
		this.deptPlacesParentCheckboxes = $(".j-check-subitem[data-level='2']", this.el);
		this.btnSubmit.click(ev => this.onBtnSubmitClick(ev));
		this.checkboxes.change(ev => this.onChange(ev));
		this.deptPlacesCheckboxes.change(ev => this.onChange(ev));
		this.links.click(ev => this.onLinkClick(ev));
	}

	onBtnSubmitClick(ev) {
		ev.preventDefault();
		this.filter();
	}

	onChange() {
		this.model.dealTransportSlugs = this.checkboxes.filter(":checked")
			.get()
			.map(checkbox => $(checkbox).val())
			.sort();
		this.model.deptPlaceSlugs = this.deptPlacesCheckboxes.filter(":checked")
			.get()
			.map(checkbox => $(checkbox).val());

		if (this.model.dealTransportSlugs.length) {
			this.btnSubmit
				.removeClass("btn-info")
				.addClass("btn-success");
		} else {
			this.btnSubmit
				.addClass("btn-info")
				.removeClass("btn-success");
		}

		this.filterService.setFilter(this.getId(), this.getName(), this.serialize());
	}

	// eslint-disable-next-line class-methods-use-this
	onLinkClick(ev) {
		ev.preventDefault();
		const $this = $(ev.currentTarget);

		// Zaskrtnuti, protoze nepouzivame <label> element kvuli zjednoduseni markupu a stylu
		const checked = $this.prevAll(":checkbox").prop("checked");
		$this.prevAll(":checkbox")
			.prop("checked", !checked)
			.change();
		$this.blur();
	}

	serialize() {
		let transportSlugsSerialized = this.model.dealTransportSlugs
			? this.model.dealTransportSlugs.join(FILTER_DATA_SEPARATOR)
			: "";

		if (this.model.deptPlaceSlugs.length > 0) {
			// fill plane transport if not set
			if (transportSlugsSerialized.includes(TRANSPORT_BY_PLANE_SLUG) === false) {
				transportSlugsSerialized = transportSlugsSerialized.length > 1
					? TRANSPORT_BY_PLANE_SLUG + FILTER_DATA_SEPARATOR + transportSlugsSerialized
					: TRANSPORT_BY_PLANE_SLUG;
			}

			// remove child items from url when parent is checked
			const deptPlacesSlugsToRemove = [];
			let parentsChecked = 0;
			let parentsCount = 0;
			this.deptPlacesCheckboxes.each((key, checkbox) => {
				const parentId = $(checkbox).data("parent-id");

				if (parentId) {
					parentsCount += 1;
					const parentChecked = this.deptPlacesParentCheckboxes.filter($(`#${parentId}`)).filter(":checked");

					if (parentChecked.length > 0) {
						deptPlacesSlugsToRemove.push($(checkbox).val());
						parentsChecked += 1;
					}
				}
			});

			// fill dept places only when some parent is not checked
			if (parentsCount !== parentsChecked) {
				const deptPlacesSerialized = this.model.deptPlaceSlugs
					.filter(slug => deptPlacesSlugsToRemove.includes(slug) === false)
					.join(`${FILTER_DATA_SEPARATOR}${TRANSPORT_BY_PLANE_SLUG}${ID_DATA_SEPARATOR}`);
				transportSlugsSerialized = transportSlugsSerialized
					.replace(
						TRANSPORT_BY_PLANE_SLUG,
						`${TRANSPORT_BY_PLANE_SLUG}${ID_DATA_SEPARATOR}${deptPlacesSerialized}`,
					);
			}
		}

		return transportSlugsSerialized;
	}
}
