import $ from "jquery";
import Widget from "./../../../../Inlined/Widget";
import UserBiscuitEnum from "./../../VO/UserBiscuitEnum";
/* global document */

export default class TermCheckFormWidget extends Widget {
	constructor(el, model, children, userService) {
		super(el, model, children);
		this.userService = userService;
	}

	bind() {
		super.bind();

		this.button = $(".j-submit", this.el);
		this.arrivalDate = $(".j-arrival", this.el);
		this.departureDate = $(".j-departure", this.el);
		this.phone = $(".j-phone", this.el);
		if (!$.fn.datepicker) {
			const widget = this;
			require.ensure([], () => {
				// eslint-disable-next-line
				require("bootstrap-datepicker");
				// eslint-disable-next-line
				require("bootstrap-datepicker/dist/locales/bootstrap-datepicker.cs.min.js");
				return widget.bindDatepicker();
			}, "bundle/bootstrap-datepicker");
		} else {
			this.bindDatepicker();
		}
		this.button.on("click", ev => this.onSubmit(ev));
	}

	bindDatepicker() {
		$(".input-daterange", this.el).datepicker({
			autoclose: false, format: "dd.mm.yyyy", language: "cs", startDate: "0d",
		});
	}

	onSubmit(ev) {
		ev.preventDefault();
		let stop = false;
		if (!this.arrivalDate.val()) {
			stop = true;
			this.arrivalDate.addClass("error");
		} else {
			this.arrivalDate.removeClass("error");
		}
		if (!this.departureDate.val()) {
			stop = true;
			this.departureDate.addClass("error");
		} else {
			this.departureDate.removeClass("error");
		}

		if (!stop) {
			// there we want first term
			// VoucherFilterTypeEnum::EXACT
			this.userService.setBiscuit(
				UserBiscuitEnum.VOUCHER_EXPIRATION,
				`${this.arrivalDate.val().replace(" ", "")}-${this.departureDate.val().replace(" ", "")}-exact`,
				30,
			);
			$.ajax({
				type: "POST",
				url: this.model.ajaxSaveFormUrl,
				data: {
					arrivalDate: this.arrivalDate.val(),
					departureDate: this.departureDate.val(),
					phone: this.phone.val(),
					pageviewId: this.hitService.getPageviewId(),
				},
			}).done((response) => {
				if (response.ok && response.url) {
					document.location.href = response.url;
				}
			});
		}
	}
}
