import $ from "jquery";
import Widget from "../../../../Inlined/Widget";

export default class MobileSideNavigation extends Widget {
	bind() {
		super.bind();
		this.btnExpand = $(".j-btn-expand", this.el);
		this.container = $(".j-container", this.el);
		this.triangleDown = $(".j-triangle-down", this.btnExpand);
		this.triangleUp = $(".j-triangle-up", this.btnExpand);

		this.container.removeClass("hide").hide();

		this.btnExpand.click(ev => this.onBtnExpandClick(ev));
	}

	onBtnExpandClick(ev) {
		ev.preventDefault();

		this.container.toggle();
		this.triangleDown.toggle();
		this.triangleUp.toggle();
	}
}
