import $ from "jquery";

/* global document */
/* global window */
/* global FB */

export default class FacebookService {
	constructor() {
		this.$body = $(document.body);
		this.listeners = [];
		this.onLoad(() => {
			this.setStyleByLoginStatus();
		});
	}

	// eslint-disable-next-line class-methods-use-this
	isLoaded() {
		return window.FB !== undefined && window.FB.login !== undefined;
	}

	loadCheck() {
		if (!this.isLoaded()) {
			return false;
		}
		this.listeners.forEach(cb => cb());
		this.listeners = [];
		return true;
	}

	onLoad(cb) {
		if (this.isLoaded()) {
			cb();
			return;
		}
		this.listeners.push(cb);
	}

	setStyleByLoginStatus() {
		FB.getLoginStatus((response) => {
			this.$body.removeClass("s-fb-connected s-fb-notconnected s-fb-unknown");
			if (response.status === "connected") {
				this.$body.addClass("s-fb-connected");
			} else if (response.status === "not_authorized") {
				this.$body.addClass("s-fb-notconnected");
			} else {
				this.$body.addClass("s-fb-unknown");
			}
		});
	}
}
