import UserAuthResumeEnum from "../VO/UserAuthResumeEnum";
import UserAuthStateEnum from "../VO/UserAuthStateEnum";
import UserAuthStateEvent from "../Event/UserAuthStateEvent";

export default class MembersService {
	constructor(app, userService, fancyboxService, ajaxItemUrlPattern, bodyWidget, internalTrackingParameters) {
		this.app = app;
		this.userService = userService;
		this.fancyboxService = fancyboxService;
		this.ajaxItemUrlPattern = ajaxItemUrlPattern;
		this.bodyWidget = bodyWidget;
		this.internalTrackingParameters = internalTrackingParameters;
		this.itemWidgets = {};
		this.userAuthResume = UserAuthResumeEnum.MEMBERS_ITEM_RESUME;

		this.payload = null;
		this.userService.register(this.userAuthResume, loginPayload => this.onUserAuthResume(loginPayload));
		this.userService.on(UserAuthStateEvent, ev => this.onUserAuth(ev));
	}

	isLoginClickFromItem(item) {
		const payload = this.userService.getPayload();
		return this.userService.getLoginId() === this.userAuthResume
			&& payload.membersItem
			&& payload.membersItem.deal === item.hash;
	}

	getExitApprovement(item) {
		if (this.isExitApproved(item)) {
			return true;
		}

		this.payload = {
			deal: item.hash,
		};
		this.userService.setLoginId(this.userAuthResume);
		this.userService.setPayload({
			membersItem: this.payload,
			silentLogin: true,
		});
		this.userService.setInternalTrackingParameters(this.internalTrackingParameters);
		this.userService.doLogin();
		this.waitingForAuth = true;

		return false;
	}

	getItemWidget(item) {
		return this.itemWidgets[item.hash] || null;
	}

	isExitApproved(item) {
		return !this.isMembersOnly(item) || this.userService.isRegistered();
	}

	// eslint-disable-next-line class-methods-use-this
	isMembersOnly(item) {
		return item.type === "deal" && item.membersOnly;
	}

	openDialog(item, onSuccess = () => {}) {
		if (!item.hash) {
			return false;
		}

		const srv = this;
		const widget = this.getItemWidget(item);

		if (widget) {
			this.fancyboxService.openWidget(
				widget,
				{
					slideClass: "s-edge-to-edge",
					afterLoad: () => onSuccess.apply(srv, [widget]),
					detachOnClose: true,
				},
			);
		} else {
			const url = this.ajaxItemUrlPattern.replace("{hash}", item.hash);
			this.fancyboxService.openAjax(
				url,
				{
					slideClass: "s-edge-to-edge",
					afterLoad: (instance, current) => {
						const currentWidget = current.widget;
						srv.itemWidgets[item.hash] = currentWidget;
						onSuccess.apply(srv, [currentWidget]);
					},
					detachOnClose: true,
				},
			);
		}
		return true;
	}

	onUserAuth(ev) {
		if (this.waitingForAuth && ev.newState === UserAuthStateEnum.COMPLETE_STATE) {
			this.waitingForAuth = false;
			if (this.payload) {
				this.openDialog({ hash: this.payload.deal }, widget => widget.setExitApproved(true));
			}
		}
	}

	onUserAuthResume(loginPayload) {
		if (loginPayload && loginPayload.membersItem) {
			this.waitingForAuth = false;
			this.openDialog({ hash: loginPayload.membersItem.deal }, widget => widget.setExitApproved(true));
		}
	}

	setInternalTrackingParameters(internalTrackingParameters) {
		this.internalTrackingParameters = internalTrackingParameters;
		return this;
	}
}
