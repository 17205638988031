import Widget from "./../../../../../../Inlined/Widget";
import ReactLoader from "../../../ReactLoader";

export default class MobileNumberTypeWidget extends Widget {
	bind() {
		super.bind();
		this.render();
	}

	render() {
		ReactLoader().then(({ React, ReactDOM, MobileTypeNumber }) => {
			ReactDOM.render(<MobileTypeNumber {...this.model} />, this.el[0]);
		});
	}
}
