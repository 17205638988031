import $ from "jquery";
import Widget from "./../../../Inlined/Widget";
import FixHeaderShowEvent from "../Event/FixHeaderShowEvent";
import FixHeaderHideEvent from "../Event/FixHeaderHideEvent";
import TopFilterFormWidget from "./Filter/TopFilterFormWidget";
import "../../../../dry/fn";
/* global __ */
/* global dry */
/* global window */

/**
 * Toggle tlacitko pro nacteni/skryti listing mapy
 */
export default class ListingMapButtonWidget extends Widget {
	constructor(el, model, children, app, ajaxService, scrollService, flashMessageService) {
		super(el, model, children);

		this.app = app;
		this.ajaxService = ajaxService;
		this.scrollService = scrollService;
		this.flashMessageService = flashMessageService;
	}

	bind() {
		super.bind();

		this.btn = $(".j-btn-listingmap", this.el);
		this.btnText = $(".j-btn-listingmap-text", this.el);
		this.filter = this.app.findByType(TopFilterFormWidget)[0] || null;
		this.mapContainer = $(".j-listing-map").eq(0); // Zamerne globalni kontext uzlu
		this.map = null;
		this.staticBanner = $(".f-side .j-listingmapbanner-static");

		this.app.on(FixHeaderShowEvent, ev => this.onFixHeaderShow(ev));
		this.app.on(FixHeaderHideEvent, ev => this.onFixHeaderHide(ev));
		this.btn.click(ev => this.onBtnClick(ev));
		$(window).scroll(dry.fn.throttle(() => this.onScroll(), 150));
		this.onScroll();

		if (this.model.isFloating) {
			this.bindFloating();
		}
	}

	bindFloating() {
		this.floatingContainer = $(".f-side").parent();

		this.el.floating({
			marginTop: () => {
				const spacing = this.fixHeaderEl
					? this.fixHeaderEl.outerHeight()
					: 0;
				return 20 + spacing;
			},
			limit: () => (
				this.floatingContainer.offset().top + this.floatingContainer.outerHeight()
			),
			zIndex: 2,
		});
	}

	onScroll() {
		if (!this.model.isFloating) {
			return;
		}

		if (!this.staticBanner.length) {
			return;
		}

		const rect = this.staticBanner[0].getBoundingClientRect();

		// Prepinat viditelnost sticky banneru podle viditelnosti statickeho banneru
		if (rect.bottom >= 0) {
			if (!this.model.isInvisible) {
				this.el.addClass("s-invisible");
				this.model.isInvisible = true;
			}
		} else if (this.model.isInvisible) {
			this.el.removeClass("s-invisible");
			this.model.isInvisible = false;
		}
	}

	hideMap() {
		this.model.isMapVisible = false;
		this.mapContainer.hide();
		if (this.model.isToggle) {
			this.btnText.text(__("Zobrazit mapu"));
		}
	}

	onBtnClick(ev) {
		ev.preventDefault();

		if (this.model.isMapVisible && this.model.isToggle) {
			this.hideMap();
		} else {
			this.showMap();
		}

		if (this.model.hideOnClick) {
			this.el.hide();
		}
	}

	onFixHeaderShow(ev) {
		this.fixHeaderEl = ev.header.el;
	}

	onFixHeaderHide() {
		this.fixHeaderEl = null;
	}

	showMap() {
		this.model.isMapVisible = true;
		this.mapContainer.show();
		if (this.model.isToggle) {
			this.btnText.text(__("Skrýt mapu"));
		}

		const scrollElement = this.filter ? this.filter.el : this.mapContainer;
		this.scrollService.scrollToElement(scrollElement, -60);

		if (this.model.isMapLoaded) {
			if (this.map) {
				this.map.show(); // Refreshnout mapu
			}
		} else {
			this.ajaxService
				.ajax({
					url: this.model.ajaxMapUrl,
				})
				.done((response) => {
					const renderedHtml = this.app.render(response.html, this);
					if (renderedHtml.widgets.length) {
						[this.map] = renderedHtml.widgets;
						this.mapContainer
							.empty()
							.append(this.map.el);
						this.model.isMapLoaded = true;
					} else {
						this.mapContainer.hide();
						this.flashMessageService.showMessage(__("Nepodařilo se načíst mapu"));
					}
				})
				.then(() => {
					this.scrollService.scrollToElement(scrollElement, -60);
				})
				.fail(() => {
					this.mapContainer.hide();
					this.flashMessageService.showMessage(__("Nepodařilo se načíst mapu"));
				});
		}
	}
}
