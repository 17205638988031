import $ from 'jquery';
import Widget from '../../../Inlined/Widget';
import UserBiscuitEnum from '../VO/UserBiscuitEnum';
import ConsentManagementLogService from '../Service/ConsentManagementLogService';

const COOKIE_EXPIRE_DAYS = 365;
const COOKIE_VALUE_ALLOWED = '1';
const COOKIE_VALUE_DISALLOWED = '0';
const CONSENT_DETAIL_MODAL_SELECTOR = '.consent-management-detail';
const ON_KEY = 'on';
const MODAL_OPENED_CLASS = 'modal-opened';

const HIDDEN_CLASS_KEY = 'hidden';

const CONSENT_MANAGEMENT_COOKIES = [
	UserBiscuitEnum.COOKIES_ALLOWED_MARKETING,
	UserBiscuitEnum.COOKIES_ALLOWED_ANALYTICS,
];

export default class ConsentManagementWidget extends Widget {
	constructor(
		el,
		model,
		children,
		userService,
		consentManagementLogService,
		domain,
		cookiesAllowedMarketing,
		cookiesAllowedAnalytics,
	) {
		super(el, model, children);
		this.domain = domain;
		this.userService = userService;
		this.consentManagementLogService = consentManagementLogService;
		this.cookiesAllowedMarketing = cookiesAllowedMarketing;
		this.cookiesAllowedAnalytics = cookiesAllowedAnalytics;
		this.openedFromBtnDetail = false;
		this.confirmed = false;
		this.isBarVisible = this.model.isBarVisible;
	}

	bind() {
		super.bind();

		this.selectedOptions = {
			[UserBiscuitEnum.COOKIES_ALLOWED_MARKETING]: this.cookiesAllowedMarketing || false,
			[UserBiscuitEnum.COOKIES_ALLOWED_ANALYTICS]: this.cookiesAllowedAnalytics || false,
		};

		this.cmBar = $('.consent-management', this.el);
		this.cmModal = $(CONSENT_DETAIL_MODAL_SELECTOR, this.el);
		this.toggle = $('.cm-toggle', this.el);
		this.btnConfirm = $('.j-cm-confirm', this.el);
		this.btnConfirmAll = $('.j-cm-confirm-all', this.el);
		this.btnDetail = $('.j-cm-detail', this.el);
		this.optionMarketing = $('#cm-option-marketing', this.el);
		this.optionAnalytics = $('#cm-option-analytics', this.el);
		this.btnCMDetailClose = $('.j-cm-detail-close', this.el);
		this.body = $('body');

		this.cmModal.click(ev => this.onCmModalClick(ev));
		this.btnConfirm.click(ev => this.onBtnConfirmClick(ev));
		this.btnConfirmAll.click(ev => this.onBtnConfirmAllClick(ev));
		this.btnDetail.click(ev => this.onBtnDetailClick(ev));
		this.btnCMDetailClose.click(() => this.close());
		this.optionAnalytics.change(() => this.onOptionChange(UserBiscuitEnum.COOKIES_ALLOWED_ANALYTICS));
		this.optionMarketing.change(() => this.onOptionChange(UserBiscuitEnum.COOKIES_ALLOWED_MARKETING));

		this.initToggleStates();

		if (this.isBarVisible) {
			this.consentManagementLogService.logEvent(ConsentManagementLogService.CONSENT_MANAGEMENT_SHOW_BAR_EVENT);
		}
	}

	initToggleStates() {
		// Initial state is off -> we need only to init on states
		if (this.cookiesAllowedMarketing === true) this.optionMarketing.bootstrapToggle(ON_KEY);
		if (this.cookiesAllowedAnalytics === true) this.optionAnalytics.bootstrapToggle(ON_KEY);
	}

	onCmModalClick(ev) {
		if (!ev || !ev.target.matches(CONSENT_DETAIL_MODAL_SELECTOR)) return;

		this.close();
	}

	onOptionChange(cookieKey) {
		switch (cookieKey) {
			case UserBiscuitEnum.COOKIES_ALLOWED_ANALYTICS:
				this.selectedOptions[UserBiscuitEnum.COOKIES_ALLOWED_ANALYTICS] = this.optionAnalytics.is(':checked');
				break;
			case UserBiscuitEnum.COOKIES_ALLOWED_MARKETING:
				this.selectedOptions[UserBiscuitEnum.COOKIES_ALLOWED_MARKETING] = this.optionMarketing.is(':checked');
				break;
			default:
				throw new Error('Unknown cookie key');
		}
	}

	onBtnDetailClick(ev) {
		if (ev) ev.preventDefault();
		this.openedFromBtnDetail = true;

		this.body.addClass(MODAL_OPENED_CLASS);

		this.cmBar.addClass(HIDDEN_CLASS_KEY);
		this.cmModal.removeClass(HIDDEN_CLASS_KEY);
		$(this.cmModal).click(event => this.onCmModalClick(event));

		this.consentManagementLogService.logEvent(
			ConsentManagementLogService.CONSENT_MANAGEMENT_BAR_PRIVACY_SETTINGS_EVENT,
		);
	}

	onBtnConfirmAllClick(ev) {
		if (ev) ev.preventDefault();

		this.confirmAll();
	}

	onBtnConfirmClick(ev) {
		if (ev) ev.preventDefault();

		this.confirm();
	}

	confirmAll() {
		for (const key of Object.keys(this.selectedOptions)) {
			this.selectedOptions[key] = true;
		}

		this.optionMarketing.bootstrapToggle(ON_KEY);
		this.optionAnalytics.bootstrapToggle(ON_KEY);
		this.consentManagementLogService.logEvent(
			ConsentManagementLogService.CONSENT_MANAGEMENT_ALLOW_ALL_EVENT,
		);

		this.confirm(false);
	}

	confirm(logEvents = true) {
		for (const cookieName of CONSENT_MANAGEMENT_COOKIES) {
			const cookieValue = this.selectedOptions[cookieName] === true
				? COOKIE_VALUE_ALLOWED
				: COOKIE_VALUE_DISALLOWED;
			this.userService.setBiscuit(
				cookieName,
				cookieValue,
				COOKIE_EXPIRE_DAYS,
			);

			if (logEvents === true) {
				let event = null;
				if (cookieName === UserBiscuitEnum.COOKIES_ALLOWED_MARKETING) {
					event = cookieValue === COOKIE_VALUE_ALLOWED
						? ConsentManagementLogService.CONSENT_MANAGEMENT_ALLOW_MARKETING_EVENT
						: ConsentManagementLogService.CONSENT_MANAGEMENT_DISALLOW_MARKETING_EVENT;
				} else if (cookieName === UserBiscuitEnum.COOKIES_ALLOWED_ANALYTICS) {
					event = cookieValue === COOKIE_VALUE_ALLOWED
						? ConsentManagementLogService.CONSENT_MANAGEMENT_ALLOW_ANALYTICS_EVENT
						: ConsentManagementLogService.CONSENT_MANAGEMENT_DISALLOW_ANALYTICS_EVENT;
				}

				this.consentManagementLogService.logEvent(event);
			}
		}

		this.confirmed = true;
		this.close();
	}

	close() {
		this.body.removeClass(MODAL_OPENED_CLASS);
		if (this.openedFromBtnDetail === true && this.confirmed === false) {
			this.cmBar.removeClass(HIDDEN_CLASS_KEY);
		} else {
			this.cmBar.addClass(HIDDEN_CLASS_KEY);
		}

		this.cmModal.addClass(HIDDEN_CLASS_KEY);
	}
}
