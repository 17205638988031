import $ from "jquery";
import AbstractItemWidget from "./AbstractItemWidget";

/* global dry */
/* global window */

export default class MembersItemWidget extends AbstractItemWidget {
	constructor(
		el,
		model,
		children,
		app,
		hitService,
		dealService,
		itemWatchdogService,
		membersService,
		ajaxService,
		itemPreviewService,
		mobileDetectService,
		flashMessageService,
		itemShareService,
		reservationService,
		userService,
		fancyboxService,
	) {
		super(
			el,
			model,
			children,
			app,
			hitService,
			dealService,
			itemWatchdogService,
			membersService,
			ajaxService,
			itemPreviewService,
			mobileDetectService,
			flashMessageService,
			itemShareService,
			reservationService,
		);
		this.userService = userService;
		this.fancyboxService = fancyboxService;
	}

	bind() {
		super.bind();

		this.done = $(".j-done", this.el);
		this.item = $(".j-item", this.el);
		this.prepare = $(".j-prepare", this.el);
		this.spinner = $(".j-spinner", this.el);
		this.successBtn = $(".j-success-btn", this.el);
		this.item.mouseenter(ev => this.onMouseIn(ev));
		this.item.mouseover(ev => this.onMouseIn(ev));
		this.item.mouseleave(ev => this.onMouseOut(ev));
	}

	begin() {
		// Hotfix, aby fungoval lazyloading po nacteni MembersItemWidget do Fancyboxu
		// Lazyloading po nacteni nefunguje, protoze elementy se v metode
		// this.app.render vykresluji mimo viewport
		$(window).scroll();
	}

	close() {
		if (this.fancyboxService.isOpened(this)) {
			this.fancyboxService.close();
		}
	}

	end() {
		this.spinner.hide();
	}

	getCampaign() {
		return dry.str.undescorize(this.constructor.name);
	}

	onBeforeGo() {
		const approved = this.membersService.isExitApproved(this.model);
		if (!approved) {
			return;
		}
		super.onBeforeGo();
	}

	onGo(ev) {
		const approved = this.membersService.isExitApproved(this.model);
		this.setExitApproved(approved);

		if (!approved) {
			ev.preventDefault();

			// Registrace
			this.membersService.setInternalTrackingParameters(this.getInternalTrackingParameters());
			this.membersService.getExitApprovement(this.model);
			this.spinner.show();
			return;
		}

		super.onGo();

		this.close();
	}

	setExitApproved(approved) {
		this.spinner.hide();

		if (approved) {
			this.prepare.hide();
			this.done.show();
		} else {
			this.prepare.show();
			this.done.hide();
		}
	}
}
