import $ from "jquery";
import { trans } from "../../Service/I18NService";
import AbstractItemWidget from "./AbstractItemWidget";
import UserHistoryDeleteEvent from "../../Event/UserHistoryDeleteEvent";
import CountdownWidget from "../CountdownWidget";
import CountdownEndEvent from "../../Event/CountdownEndEvent";
import WishlistStickerWidget from "../Wishlist/WishlistStickerWidget";
import ArrowRightIcon from "../../Styles/def/img/icons/arrow-right.svg";
import { getIconSpriteUrl } from "../../utils";
import ItemReservationWidget from "../Reservation/ItemReservationWidget";
import {
	EXACT_KEY,
	AVAILABLE_KEY,
	UNAVAILABLE_KEY,
	ON_DEMAND_KEY,
} from "../Filter/FiltersEnum";
import locales from "../../Locale/LocaleEnum";
import ListingLoadedEvent from "../../Event/ListingLoadedEvent";

/* global __ */
/* global dry */
/* global window */
/* global IntersectionObserver */
/* global __LOCALE__ */

export default class ItemWidget extends AbstractItemWidget {
	constructor(
		el,
		model,
		children,
		app,
		hitService,
		dealService,
		itemWatchdogService,
		membersService,
		ajaxService,
		itemPreviewService,
		mobileDetectService,
		flashMessageService,
		itemShareService,
		reservationService,
		itemMapService,
		fancyboxService,
		bodyWidget,
		filterService,
	) {
		super(
			el,
			model,
			children,
			app,
			hitService,
			dealService,
			itemWatchdogService,
			membersService,
			ajaxService,
			itemPreviewService,
			mobileDetectService,
			flashMessageService,
			itemShareService,
			reservationService,
		);
		this.itemMapService = itemMapService;
		this.fancyboxService = fancyboxService;
		this.bodyWidget = bodyWidget;
		this.filterService = filterService;
	}


	bind() {
		super.bind();
		this.countdown = this.findByType(CountdownWidget)[0] || null;
		this.btnMap = $(".j-btn-map", this.el);
		this.heart = $(".j-heart", this.el);
		this.pricing = $(".j-pricing", this.el);
		this.variantsShow = $(".j-vars-show", this.el);
		this.variantsShowLink = $(".j-vars-show-link", this.el);
		this.showTable = $(".j-show-table", this.el);
		this.variantsContainer = $(".j-vars-container", this.el);
		this.userHistoryDeleteButton = $(".j-delete", this.el);
		this.breadcrumbs = $(".j-breadcrumbs", this.el);
		this.variantsToggleBtn = $(".j-variant-toggle-btn", this.el);
		this.variantsToggleContainer = $(".j-variant-container", this.el) || null;
		this.wishlistSticker = this.findByType(WishlistStickerWidget)[0] || null;
		this.app.on(CountdownEndEvent, this.onCountdownEndFn = ev => this.onCountdownEnd(ev));
		this.model.zoomLock = false;

		this.itemThumImageLink = $(".deal-image-link", this.el);
		this.itemTitleLink = $(".j-title .j-go", this.el);
		this.itemCtaBtn = $(".deal-cta.j-go", this.el);
		this.btnTermCheck = $(".j-term-check", this.el);

		// Booking.com capacities check
		this.bookingComCapacitiesLoaded = false;
		this.attachBookingComAvailability();

		this.btnTermCheck.click(ev => this.onTermCheckBtnClick(ev));
		this.btnMap.click(ev => this.onBtnMapClick(ev));
		this.el.click(ev => this.onClick(ev));
		this.el.mouseenter(ev => this.onMouseIn(ev));
		this.el.mouseenter(ev => this.onMouseEnter(ev));
		this.el.mouseover(ev => this.onMouseIn(ev));
		this.el.mouseleave(ev => this.onMouseOut(ev));

		this.variantsToggleBtn.click(ev => this.variantsContainerToggle(ev));
		this.variantsShow.one("mouseenter", () => this.loadVariants())
			.on("click", ev => this.onVariantsShowClick(ev));
		this.variantsShowLink.one("mouseenter", () => this.loadVariants())
			.on("click", ev => this.onVariantsShowClick(ev, false));

		if (this.model && this.model.server && this.model.server.id
			&& this.model.server.id !== ItemReservationWidget.BOOKING_COM_SERVER_ID
			&& this.model.isDealFromRivalFeed
			&& this.model.isDealFromRivalFeed === true
		) {
			// Show calendar for Rival deals
			this.showTable.on("click", ev => this.onTermCheckBtnClick(ev));
		} else {
			this.showTable.on("click", ev => this.onVariantsShowClick(ev))
				.one("mouseenter", () => this.loadVariants());
		}

		this.userHistoryDeleteButton.click(ev => this.onUserHistoryDeleteClick(ev));
		this.termCheckInterval = null;
	}

	unbind() {
		super.unbind();
		this.app.off(CountdownEndEvent, this.onCountdownEndFn);
	}

	getOffset() {
		const offset = this.heart.offset();
		return {
			left: offset.left,
			top: offset.top,
		};
	}

	getZoomType() {
		if (this.mobileDetectService.isDesktop()) {
			return "overlay";
		}
		return "inline";
	}

	onBtnMapClick(ev) {
		ev.preventDefault();

		this.itemMapService.open(this.model, this);
	}

	onClick() {
		// Na Androidu mouseover nerusi click. Proto zoom zapiname na 1. klik,
		// disablujeme odkazy dokud neni aktivni zoom. 2. klik je jiz povolen.
		if (dry.browser.isAndroid()) {
			this.el.removeClass("s-mouse s-touch");
			this.el.addClass(this.mobileDetectService.isDesktop() ? "s-mouse" : "s-touch");
			this.setZoom(true, this.getZoomType());
		}
	}

	onCountdownEnd(ev) {
		if (this.countdown === ev.countdown) {
			this.reload();
		}
	}

	onBeforeGo(ev) {
		super.onBeforeGo(ev);
	}

	onGo(ev) {
		super.onGo(ev);
	}

	onNavigatedBack() {
		super.onNavigatedBack();

		if (this.listing) {
			this.listing.onNavigatedBack(this);
		}
	}

	onMouseEnter() {
		// Na Androidu mouseover nerusi click. Proto zoom zapiname na 1. klik,
		// disablujeme odkazy dokud neni aktivni zoom. 2. klik je jiz povolen.
		if (!dry.browser.isAndroid()) {
			this.el.removeClass("s-mouse s-touch");
			this.el.addClass(this.mobileDetectService.isDesktop() ? "s-mouse" : "s-touch");
			this.setZoom(true, this.getZoomType());
		}
	}

	onMouseOut() {
		super.onMouseOut();

		this.el.removeClass("s-mouse");

		// Pri sjeti mysi zrusit jen zoom typu "overlay"
		if (this.getZoomType() === "overlay") {
			this.setZoom(false);
		}
	}

	onVariantsShowClick(ev, withTitleChange = true) {
		ev.preventDefault();
		let variantsShowIcon;
		let variantsShowText;
		if (withTitleChange) {
			variantsShowIcon = `
				<svg class="icon font-xs" aria-hidden="true">
					<use xlink:href="${getIconSpriteUrl(ArrowRightIcon)}"></use>
				</svg>
			`;
			variantsShowText = __("Zobrazit více variant");
			this.variantsShow.text(__("Načítám").concat("..."));
		}

		this.loadVariants()
			.then((response) => {
				const { type, html } = response;

				if (type === "variations") {
					this.pricing.hide(0);
					if (withTitleChange) {
						this.variantsShow.html(`${variantsShowText} ${variantsShowIcon}`);
					}

					const renderedHtml = this.app.render(html, this);
					this.variantsContainer
						.empty()
						.append(renderedHtml.elements);

					this.setZoom(true, "inline");
					this.model.zoomLock = true;
				} else if (type === "terms") {
					if (withTitleChange) {
						this.variantsShow.html(`${variantsShowText} ${variantsShowIcon}`);
					}
					const renderedHtml = this.app.render(html, this.bodyWidget);
					const widget = renderedHtml.widgets[0];
					this.fancyboxService.openWidget(widget, {
						baseClass: "terms-table-modal",
					});
				} else {
					throw new Error("Unhandled response type");
				}
			})
			.catch(() => {
				if (withTitleChange) {
					this.variantsShow.html(`${variantsShowText} ${variantsShowIcon}`);
				}
				this.flashMessageService.showMessage(__("Při načítání došlo k chybě"), "error");
			});
	}

	loadVariants() {
		if (this.variantsLoading) {
			return this.variantsLoading;
		}

		this.variantsLoading = this.ajaxService
			.ajax({
				url: `/sleva/${this.model.hash}/variations.json`,
			})
			.then(response => $.Deferred().resolve(response))
			.catch(() => {
				this.variantsLoading = null;
				return $.Deferred().reject();
			});
		return this.variantsLoading;
	}

	onWishCreate(ev) {
		if (ev.itemId === this.model.id && ev.itemType === this.model.type && this.model.isWishlistDetailPosition) {
			this.dealIn.show();
			// Refreshnout zoom kvuli zmene vysky polozky
			this.setZoom(this.model.zoom, this.getZoomType());
		}
	}

	onWishDelete(ev) {
		if (ev.itemId === this.model.id && ev.itemType === this.model.type && this.model.isWishlistDetailPosition) {
			this.dealIn.hide();
			// Refreshnout zoom kvuli zmene vysky polozky
			this.setZoom(this.model.zoom, this.getZoomType());
		}
	}

	onUserHistoryDeleteClick(ev) {
		ev.preventDefault();

		// Historie funguje jen pro dealy a letaky
		if (this.model.userHistoryItemUrl) {
			this.hide(() => {
				this.ajaxService.ajax({
					type: "DELETE",
					url: this.model.userHistoryItemUrl,
				}).done(() => {
					this.remove();
					this.app.dispatch(new UserHistoryDeleteEvent(this.model.id, this.model.type));
				});
			});
		}
	}


	reload() {
		if (this.model.type === "deal") {
			this.dealService.reload(this);
		}
	}

	scrollToBottom() {
		const $htmlBody = $("html, body");
		const height = this.el.outerHeight();
		const windowHeight = $(window).height();

		$htmlBody.stop().animate({
			scrollTop: this.el.offset().top - (windowHeight - height - 5),
		}, {
			duration: 400,
		});
	}

	scrollToTitle() {
		const $title = $(".j-title", this.el);
		const $htmlBody = $("html, body");

		$htmlBody.stop().animate({
			scrollTop: ($title.offset().top - ($(window).height() / 2)) + 20,
		}, {
			duration: 400,
		});
	}

	setDistance(distance) {
		if (this.model.distance === distance) {
			return;
		}

		this.model.distance = distance;
		let $distance = $(".j-distance", this.el);

		if (!$distance.length) {
			$distance = $("<p class='deal-distance j-distance'></p>").prependTo(this.el);
		}

		const formattedDistance = dry.num.format(distance, 1, ",", " ");
		$distance.text(trans(__("%distance% km od této lokality"), { "%distance%": formattedDistance }));
	}

	setZoom(active, zoomType) {
		if (this.model.zoomLock) {
			return;
		}
		this.el.removeClass("s-zoom s-zoom-overlay s-zoom-inline");

		if (this.model.zoomEnabled && active && zoomType === "overlay") {
			// Zoomovana polozka prekryje polozku pod ni
			const rect = this.el[0].getBoundingClientRect();
			this.model.origHeight = rect.height ? rect.height : rect.bottom - rect.top;

			this.el.addClass("s-zoom s-zoom-overlay");
			this.el.height(this.model.origHeight);

			this.model.zoom = true;
		} else if (this.model.zoomEnabled && active && zoomType === "inline") {
			// Zoomovana polozka odsune polozku pod ni
			this.el.addClass("s-zoom s-zoom-inline");
			this.el.height("auto");

			// Na malem viewportu odsunout polozku k hornimu nebo dolnimu okraji okna
			if (!this.model.zoom && !this.mobileDetectService.isDesktop()) {
				const rect = this.el[0].getBoundingClientRect();
				const height = this.el.outerHeight();
				const windowHeight = $(window).height();

				// Kdyz polozka presahuje dolni okraj o vice nez 15px
				if (rect.bottom >= windowHeight + 15) {
					if (height > windowHeight) {
						this.scrollToTitle();
					} else {
						this.scrollToBottom();
					}
				}
			}
			this.model.zoom = true;
		} else if (!active) {
			this.el.height("auto");
			this.model.zoom = false;
		}
	}

	variantsContainerToggle() {
		if (this.variantsToggleContainer) {
			const el = this.el[0];
			const toggleBtnTextEl = $(".j-variant-toggle-btn-text", this.variantsToggleBtn)[0];
			const toggleBtnIconEl = $("use", this.variantsToggleBtn)[0];
			const iconBottom = "arrow-bottom";
			const iconTop = "arrow-top";
			const visibleClass = "variant-item--collapsed";
			const toggleBtnText = __("Zobrazit více variant");
			const toggleBtnTextCollapased = __("Skrýt více variant");

			el.classList.toggle(visibleClass);
			if (el.classList.contains(visibleClass)) {
				this.variantsToggleContainer.show();
				if (toggleBtnIconEl && toggleBtnTextEl) {
					toggleBtnTextEl.textContent = toggleBtnTextCollapased;
					toggleBtnIconEl.setAttribute("xlink:href", `/assets/def/img/icons-sprite.svg?v=5#${iconTop}`);
				}
			} else {
				this.variantsToggleContainer.hide();
				if (toggleBtnIconEl && toggleBtnTextEl) {
					toggleBtnTextEl.textContent = toggleBtnText;
					toggleBtnIconEl.setAttribute("xlink:href", `/assets/def/img/icons-sprite.svg?v=5#${iconBottom}`);
				}
			}
		}
	}

	onTermCheckBtnClick(ev) {
		ev.preventDefault();

		const date = $(ev.currentTarget).data("date");
		this.reservationService.createTermRequestForDeal(this.model, date);
	}

	attachBookingComAvailability() {
		// 1. disable if we already have results for this deal
		// 2. term check is available only for cs_CZ locale
		// 3. check for IntersectionObserver
		// 4.-5. check only for booking.com deals
		// 6. check if we have valid element
		// 7. check for admins only (is going to be removed later)
		if (this.bookingComCapacitiesLoaded === false
			&& __LOCALE__ === locales.csCz
			&& !!window.IntersectionObserver
			&& this.model && this.model.server && this.model.server.id
			&& this.model.server.id === ItemReservationWidget.BOOKING_COM_SERVER_ID
			&& this.model.bookingComLoadedTermUrl
			&& this.el && this.el[0]
		) {
			this.checkInterval = null;
			this.checkIntervalCounter = 0;
			this.checkBookingComDealAvailability = this.checkBookingComDealAvailability.bind(this);
			this.createIntersectionObserver();

			// Takes care for infinit loaded lists and IntersectionObserver reattachment
			this.app.on(ListingLoadedEvent, () => {
				if (this.bookingComCapacitiesLoaded === false) {
					this.reattachIntersectionObserver();
				}
			});
		}
	}

	createIntersectionObserver() {
		this.observer = new IntersectionObserver(
			this.checkBookingComDealAvailability,
			{
				// move box bottom margin for faster interaction for user
				rootMargin: "0px 0px 700px 0px",
			},
		);
		this.observer.observe(this.el[0]);
	}

	reattachIntersectionObserver() {
		this.observer.unobserve(this.el[0]);
		this.observer.observe(this.el[0]);
	}

	checkBookingComDealAvailability = async (element) => {
		// Check if element is near root box margin and intersecting with user view
		const isIntersecting = element && element[0] && element[0].isIntersecting && element[0].isIntersecting === true;
		if (isIntersecting) {
			const termFilterType = this.filterService.getTermFilterTypeAndValues();
			if (termFilterType && termFilterType.type && termFilterType.type === EXACT_KEY
				&& termFilterType.dateFrom && termFilterType.dateTo
			) {
				this.termAvailableElement = $(".j-term-available", this.el);
				this.termUnavailableElement = $(".j-term-unavailable", this.el);
				this.termStatusLoader = $(".j-term-loader", this.el);
				this.availableTermsCheckButton = $(".booking-com-term .deal-term", this.el);
				const url = this.model.bookingComLoadedTermUrl
					.replace("{dateFrom}", termFilterType.dateFrom)
					.replace("{dateTo}", termFilterType.dateTo);

				const success = await this.processBookingComAjaxRequest(url);
				if (!success) {
					let retryCounter = 0;
					const retryLimit = 3;
					const tryBookingComAjaxRequest = async () => {
						retryCounter += 1;
						const innerSuccess = await this.processBookingComAjaxRequest(url);
						if (innerSuccess || retryCounter >= retryLimit) {
							this.bookingComCapacitiesLoaded = true;
							this.termStatusLoader.hide();
							this.app.off(ListingLoadedEvent);
							if (!innerSuccess) this.availableTermsCheckButton.hide();
						} else {
							setTimeout(tryBookingComAjaxRequest, 400);
						}
					};
					setTimeout(tryBookingComAjaxRequest, 400);
				} else {
					this.bookingComCapacitiesLoaded = true;
					this.app.off(ListingLoadedEvent);
				}
			}
			// Disconnect attached observer event from DOM node
			this.observer.disconnect();
		}
	}

	processBookingComAjaxRequest = async (url) => {
		const response = await this.ajaxService.ajax({ method: "GET", url });

		this.termStatusLoader.hide();
		if (response && response.ok && response.result) {
			if (response.result === AVAILABLE_KEY) {
				this.termAvailableElement.show();
			} else if (response.result === UNAVAILABLE_KEY || response.result === ON_DEMAND_KEY) {
				this.termUnavailableElement.show();
			}
			return true;
		}

		return false;
	}
}
