import $ from "jquery";
import Widget from "../../../Inlined/Widget";
import TourZoneFilterSubmitEvent from "../Event/TourZoneFilterSubmitEvent";

export default class HeroImageWidget extends Widget {
	constructor(el, model, children, app) {
		super(el, model, children);
		this.app = app;
	}

	bind() {
		super.bind();
		this.link = $(".j-link", this.el);

		this.submitButton = $(".j-tour-zone-filter-submit");
		this.submitButton.click(ev => this.submitTourZoneFilter(ev));
	}

	submitTourZoneFilter(ev) {
		ev.preventDefault();
		this.app.dispatch(new TourZoneFilterSubmitEvent());
	}
}
