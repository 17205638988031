import $ from "jquery";
import Widget from "../../../Inlined/Widget";
import ItemWidget from "./Item/ItemWidget";

export default class FlaggedDealsBoxWidget extends Widget {
	constructor(el, model, children, app) {
		super(el, model, children);
		this.app = app;
	}

	bind() {
		super.bind();

		this.model.hitParamsAttached = false;
		this.items = this.app.findByType(ItemWidget);

		this.showMoreBtn = $(".j-more", this.el);
		this.showHiddenBtn = $(".j-hidden", this.el);
		this.btns = this.showMoreBtn.add(this.showHiddenBtn);

		this.showHiddenBtn.click(ev => this.onShowHiddenBtnClick(ev));

		this.el.on("mouseenter mouseover touchstart", ev => this.onMouseIn(ev));
	}

	attachHitParams() {
		if (this.model.hitParamsAttach) {
			return;
		}

		this.model.hitParamsAttach = true;

		this.model.source = this.getSource();
		this.model.campaign = this.getCampaign();
		this.model.medium = this.getMedium();

		let params = [];
		if (this.model.source) {
			params.push(`s_source=${this.model.source}`);
		}
		if (this.model.medium) {
			params.push(`s_medium=${this.model.medium}`);
		}
		if (this.model.campaign) {
			params.push(`s_campaign=${this.model.campaign}`);
		}
		params = params.join("&");

		this.btns.each(function () {
			let url = $(this).attr("href");
			url = `${url}${url.indexOf("?") === -1 ? "?" : "&"}${params}`;
			$(this).attr("href", url);
		});
	}

	onMouseIn() {
		this.attachHitParams();
	}

	onShowHiddenBtnClick(ev) {
		ev.preventDefault();

		this.items.forEach((item) => {
			item.el.removeClass("bye");
		});

		this.showHiddenBtn.addClass("bye");
		this.showMoreBtn.removeClass("bye");
	}
}
