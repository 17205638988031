import $ from "jquery";
import moment from "moment";
import CarsContestHeaderWidget from "./CarsContestHeaderWidget";
/* global dry */
/* global window */
/* global FB */

export default class CarsContestDrawHeaderWidget extends CarsContestHeaderWidget {
	constructor(el, model, children, ajaxService, flashMessageService, facebookService) {
		super(el, model, children);
		this.ajaxService = ajaxService;
		this.flashMessageService = flashMessageService;
		this.facebookService = facebookService;
	}

	bind() {
		super.bind();

		this.answer = $(".j-answer", this.el);
		this.announcementDatetime = $(".j-announcement-datetime", this.el);
		this.validUntilDatetime = $(".j-validuntil-datetime", this.el);
		this.btnDraw = $(".j-btn-draw", this.el);
		this.btnAnswer = $(".j-btn-answer", this.el);
		this.question = $(".j-question", this.el);
		this.pieceContainer = $(".j-piece-container");
		this.spinnerAnswer = $(".j-spinner-answer", this.el);
		this.spinnerDraw = $(".j-spinner-draw", this.el);
		this.wipingArea = $(".j-wipingarea", this.el);

		this.btnDraw.click(ev => this.onBtnDrawClick(ev));
		this.btnAnswer.click(ev => this.onBtnAnswerClick(ev));
	}

	onBtnDrawClick(ev) {
		ev.preventDefault();
		this.btnDraw.off("click");
		this.btnDraw.prop("disabled", true);
		this.makeDraw();
	}

	onBtnAnswerClick(ev) {
		ev.preventDefault();
		this.makeAnswer();
	}

	makeAnswer() {
		let answer = this.answer.val();
		answer = answer.replace(/ /g, "");

		if (!answer) {
			this.flashMessageService.showMessage("Vyplňte odpověď", "warning");
			return false;
		}
		if (!dry.num.isNumeric(answer)) {
			this.flashMessageService.showMessage("Zadejte prosím číslici, např. 123", "warning");
			return false;
		}

		this.spinnerAnswer.show();
		this.ajaxService.ajax({
			method: "POST",
			url: this.model.ajaxSendAnswer,
			data: {
				answer,
				question_id: this.model.questionId,
			},
		})
			.done((response) => {
				this.spinnerAnswer.hide();
				if (!response.ok) {
					if (response.message) {
						this.flashMessageService.showMessage(response.message, "error");
					} else {
						this.flashMessageService.showMessage("Při komunikaci se serverem došlo k chybě.", "error");
					}
				}
				this.el.addClass("s-has-answer-today");
			})
			.fail(() => {
				this.spinnerAnswer.hide();
				this.flashMessageService.showMessage("Při komunikaci se serverem došlo k chybě.", "error");
			});
		return true;
	}

	makeDraw() {
		this.spinnerDraw.show();
		this.ajaxService.ajax({
			method: "POST",
			url: this.model.ajaxMakeDrawUrl,
			data: {},
		})
			.done((response) => {
				this.spinnerDraw.hide();
				if (!response.ok) {
					if (response.message) {
						this.flashMessageService.showMessage(response.message, "error");
					} else {
						this.flashMessageService.showMessage("Při komunikaci se serverem došlo k chybě.", "error");
					}
					return;
				}
				if (response.invalid) {
					this.flashMessageService.showMessage("Chybná odpověď!", "warning");
					window.location.reload();
					return;
				}
				this.setWin(response);
			})
			.fail(() => {
				this.btnDraw.click(ev => this.onBtnDrawClick(ev));
				this.btnDraw.prop("disabled", false);
				this.spinnerDraw.hide();
				this.flashMessageService.showMessage("Při komunikaci se serverem došlo k chybě.", "error");
			});

		return true;
	}

	setWin(data) {
		this.wipingArea.addClass("s-wiped");
		const { win } = data;

		if (data.ask) {
			this.model.question = data.question;
			this.model.questionId = data.questionId;
			this.model.questionValidUntilDatetime = moment(data.validUntilDatetime);
			this.model.questionAnnouncementDatetime = moment(data.announcementDatetime);

			$(".carscontestdraw-col-r .carscontestdraw-win-puzzle").remove();
			this.el
				.removeClass("s-before")
				.addClass("s-win s-win-question");

			this.question.text(this.model.question);
			// eslint-disable-next-line max-len
			this.announcementDatetime.text(`${this.model.questionAnnouncementDatetime.format("D. M. YYYY")} v ${this.model.questionAnnouncementDatetime.format("H:mm")}`);
			// eslint-disable-next-line max-len
			this.validUntilDatetime.text(`${this.model.questionValidUntilDatetime.format("D. M. YYYY")}, ${this.model.questionValidUntilDatetime.format("H:mm")}`);
		}

		this.el
			.removeClass("s-before")
			.addClass(`s-win s-win-${win}`);

		try {
			this.facebookService.onLoad(() => FB.XFBML.parse());
		} catch (e) {
			// Intentionally without handling -> milions of Sentry errors
		}

		if (data.pieceUrl) {
			const $pieceImg = $(`<img src='${data.pieceUrl}' />`);
			this.pieceContainer
				.empty()
				.append($pieceImg);
		}
	}
}
