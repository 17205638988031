import $ from "jquery";
import BoxWidget from "../BoxWidget";

export default class TopicDayListingBoxWidget extends BoxWidget {
	constructor(el, model, children, app, ajaxService, domain) {
		super(el, model, children);
		this.app = app;
		this.ajaxService = ajaxService;
		this.domain = domain;
	}

	bind() {
		super.bind();

		this.itemList = $(".j-item-list", this.el);
		this.links = $(".j-link", this.el);

		this.el.mouseenter(ev => this.onMouseIn(ev));
		this.el.mouseover(ev => this.onMouseIn(ev));
	}

	attachHitParams() {
		if (this.hitParamsAttached) {
			return;
		}

		this.hitParamsAttached = true;

		this.model.source = this.getSource();
		this.model.campaign = this.getCampaign();
		this.model.medium = this.getMedium();

		let params = [];
		if (this.model.source) {
			params.push(`s_source=${this.model.source}`);
		}
		if (this.model.medium) {
			params.push(`s_medium=${this.model.medium}`);
		}
		if (this.model.campaign) {
			params.push(`s_campaign=${this.model.campaign}`);
		}
		params = params.join("&");

		this.links.each(function () {
			let url = $(this).attr("href");
			url = `${url}${url.indexOf("?") === -1 ? "?" : "&"}${params}`;
			url = `${url}${url.indexOf("?") === -1 ? "?" : "&"}s_content=box_link`;
			$(this).attr("href", url);
		});
	}

	getTopicDayBoxId() {
		return this.model.topicDayBox ? this.model.topicDayBox.id : null;
	}

	onBtnMoreClick(ev) {
		ev.preventDefault();
		this.ajaxService.ajax({ url: this.model.boxUrl })
			.done((response) => {
				if (response.ok === true) {
					const renderedHtml = this.app.render(response.html, this);
					this.btnMore.hide();
					for (const el of renderedHtml.elements) {
						this.itemList.append(el);
					}
					for (const child of renderedHtml.widgets) {
						this.children.push(child);
					}
				}
			});
	}

	onMouseIn() {
		this.attachHitParams();
	}
}
