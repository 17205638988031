import $ from "jquery";
import FormStepperWidget from "../FormStepperWidget";
import ValidationMixin from "../ValidationMixin";
import UserAuthStateEvent from "../../Event/UserAuthStateEvent";
import UserAuthStateEnum from "../../VO/UserAuthStateEnum";

/* global dry */

export default class UserUniversalLoginWidget extends FormStepperWidget {
	constructor(el, model, children, app, ajaxService, flashMessageService, validateSettings, userService) {
		super(el, model, children, app);
		this.ajaxService = ajaxService;
		this.flashMessageService = flashMessageService;
		this.validateSettings = validateSettings;
		this.userService = userService;

		this.bindValidation = ValidationMixin.bindValidation;
		this.getEmailMessage = ValidationMixin.getEmailMessage;
		this.onInputFix = ValidationMixin.onInputFix;
		this.onValidationSuccess = ValidationMixin.onValidationSuccess;
	}

	bind() {
		super.bind();

		this.model.pwdFilled = false;

		this.btnGo = $(".j-go", this.el);
		this.inputPwd = $(":input[name=\"password\"]", this.el);
		this.groupPwd = this.inputPwd.closest(".control-group");

		this.bindForm();
		this.bindValidation();
		this.btnGo.click(ev => this.onBtnGoClick(ev));

		this.userService.on(UserAuthStateEvent, (...args) => this.onUserAuth(...args));
	}

	bindForm() {
		const formSettings = {
			rules: {
				email: {
					required: true,
					email: false,
					emailHtml5: true,
				},
				password: {
					required: true,
				},
			},
			messages: {
				email: {
					required: "Bez zadání e-mailu to nepůjde",
					emailHtml5: (...args) => this.getEmailMessage(...args),
				},
			},
			success: ($label, element) => {
				this.onValidationSuccess($label, element);
			},
			submitHandler: () => {
				this.nextStep();
				return false;
			},
		};
		this.el.validate($.extend({}, this.validateSettings, formSettings));
	}

	getCampaign() {
		return dry.str.undescorize(this.constructor.name);
	}

	onUserAuth(ev) {
		if (!this.waitingForAuth) {
			return;
		}

		this.waitingForAuth = false;
		this.setStepLoading(false);

		if (ev.newState === UserAuthStateEnum.EMAIL_EXISTS_STATE) {
			this.setPasswordRequired(true);
		} else if (ev.newState === UserAuthStateEnum.WRONG_PASSWORD_STATE) {
			this.flashMessageService.showMessage("Chybné heslo. Zkus to znovu.");
		} else if (ev.newState === UserAuthStateEnum.COMPLETE_STATE) {
			const step = this.getStep();
			step.done = true;
			this.goToStep("done");
		}
	}

	onBtnGoClick(ev) {
		ev.preventDefault();
		this.nextStep();
	}

	onStepLoginOut(step) {
		if (step.done) {
			return true;
		}

		const data = this.serializeArrayStep();

		this.setStepLoading(true);

		this.userService.setState(UserAuthStateEnum.NEW_STATE);
		this.userService.setLoginId(null);
		this.userService.setPayload(null);

		if (this.model.pwdRequired) {
			this.userService.setInternalTrackingParameters(this.getInternalTrackingParameters());
			this.userService.doAuth({ email: data.email, password: data.password });
			this.waitingForAuth = true;
		} else {
			this.userService.setInternalTrackingParameters(this.getInternalTrackingParameters());
			this.userService.doAuth({}, () => {
				this.userService.setState(UserAuthStateEnum.EMAIL_STATE);
				this.userService.doAuth({ email: data.email });
				this.waitingForAuth = true;
			});
		}

		return false;
	}

	setPasswordRequired(required) {
		this.model.pwdRequired = required;

		if (required) {
			this.inputPwd.prop("disabled", false);
			this.groupPwd.slideDown(300, () => {
				this.inputPwd.not("[placeholder]").focus();
			});
		} else {
			this.inputPwd.prop("disabled", true);
			this.groupPwd.slideUp(100);
		}
	}
}
