import $ from "jquery";

export default class AjaxService {
	constructor(domain) {
		this.domain = domain;
		$.ajaxSetup({
			headers: {
				"X-Skrz-Ajax": "Skrz",
			},
		});
	}

	getSameDomainUrl(url) {
		if (!AjaxService.isCrossDomainUrl(url)) {
			return url;
		}
		const urlObj = dry.net.parseUri(url);
		const origin = `${location.protocol}//${location.hostname}` + (location.port ? `:${location.port}` : ``);

		const subDomainPart = urlObj.host
			.replace(this.domain, "")
			.replace(/\.$/, "");
		const subDomains = subDomainPart.split(".");
		const subDomainsPath = subDomains.join("/") || "www";

		return `${origin}/cross-domain/${subDomainsPath}${urlObj.relative}`;
	}

	static isCrossDomainUrl(url) {
		const urlObj = dry.net.parseUri(url);
		return urlObj.host && urlObj.host !== location.host;
	}

	ajax(settings) {
		if (settings.url) {
			settings.url = this.getSameDomainUrl(settings.url);
		}
		return $.ajax(settings);
	}
}
