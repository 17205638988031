import $ from "jquery";
import AbstractFilterFormWidget from "./AbstractFilterFormWidget";
import AbstractFilterWidget from "./AbstractFilterWidget";

export default class ToursZoneFilterWidget extends AbstractFilterWidget {
	bind() {
		super.bind();

		this.form = this.findParentByType(AbstractFilterFormWidget) || null;

		if (!this.form) {
			throw new Error("I need AbstractFilterFormWidget parent!");
		}

		this.el.on("click", ".j-link", ev => this.onLinkClick(ev));
		this.el.on("click", ".j-more", ev => this.onMoreClick(ev));
	}

	hasCountrySlugs = () => this.model.countrySlugs !== null && this.model.countrySlugs.length;

	hasZoneSlugs = () => this.model.zoneSlugs !== null && this.model.zoneSlugs.length;

	onLinkClick(ev) {
		const val = $(ev.currentTarget).data("val");

		let source = null;
		if (this.model.searchItems !== null) {
			// Click event occurred after search field was used
			source = "search";
		}
	}

	// eslint-disable-next-line class-methods-use-this
	onMoreClick(ev) {
		ev.preventDefault();
		ev.stopPropagation();

		const $button = $(ev.currentTarget);
		const $list = $button.closest(".j-list");

		const $alphabeticalList = $list.siblings(".j-alphabetical-list");
		$list.hide();
		$button.hide();
		$alphabeticalList.removeClass("bye").show();
	}

	onSearch(ev) {
		super.onSearch(ev);

		if (this.model.searchItems !== null) {
			this.el.removeClass(this.model.columnsClass);
		} else {
			this.el.addClass(this.model.columnsClass);
		}
	}

	serialize() {
		if (this.hasCountrySlugs() && this.hasZoneSlugs()) {
			return [
				this.model.countrySlugs.join(","),
				this.model.zoneSlugs.join(","),
			].join(":").replace(/:+$/, "");
		} else if (this.hasCountrySlugs()) {
			return this.model.countrySlugs.join(",");
		} else if (this.hasZoneSlugs()) {
			return this.model.zoneSlugs.join(",");
		}
		return null;
	}
}
