export default class UserAuthStateEvent {
	constructor(newState, oldState, userId, visitId, loggedIn, anonymous, loginId, shouldTrackRegistration) {
		this.newState = newState;
		this.oldState = oldState;
		this.userId = userId;
		this.visitId = visitId;
		this.loggedIn = loggedIn;
		this.anonymous = anonymous;
		this.loginId = loginId;
		this.shouldTrackRegistration = shouldTrackRegistration;
	}
}
