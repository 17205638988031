import $ from "jquery";
import throttle from "lodash/throttle";
import Widget from "../../../Inlined/Widget";
import CountFilterBarOffsetEvent from "../Event/CountFilterbarOffsetEvent";
/* global window */
/* global document */

export default class TravelMobileFilterBarWidget extends Widget {
	constructor(el, model, children, app) {
		super(el, model, children, app);
		this.app = app;
		this.window = $(window);
	}

	bind() {
		super.bind();
		this.setParentHeight();
		this.app.on(CountFilterBarOffsetEvent, () => {
			this.bindFloating();
		});
	}

	setParentHeight() {
		const elHeight = this.el.outerHeight();
		const parent = this.el.parent();
		parent.height(elHeight);
	}

	bindFloating() {
		let lastScroll = 0;
		const scrollingUpClass = "travel-mobile-filterbar--visible";
		const fixedClass = "travel-mobile-filterbar--fixed";
		const elOffsetTop = this.el.offset().top;

		this.window.on("scroll", throttle(() => {
			const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

			if ((elOffsetTop + this.el.outerHeight()) <= currentScroll) {
				this.el.addClass(fixedClass);
			} else if (elOffsetTop >= currentScroll) {
				this.el.removeClass(fixedClass);
			}

			if (currentScroll > 0 && lastScroll < currentScroll) {
				lastScroll = currentScroll;
				this.el.removeClass(scrollingUpClass);
			} else {
				lastScroll = currentScroll;
				this.el.addClass(scrollingUpClass);
			}
		}, 100));
	}
}
