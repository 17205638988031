import $ from "jquery";
import Widget from "../../../../Inlined/Widget";
import RerenderMixin from "../RerenderMixin";
import ValidationMixin from "../ValidationMixin";
import "../../../../../dry/str";
/* global dry */

const STEP_FORM = "form";
const STEP_DONE = "done";

export default class LeadgenWidget extends Widget {
	constructor(
		el,
		model,
		children,
		app,
		ajaxService,
		flashMessageService,
		hitService,
		userService,
		fancyboxService,
		validateSettings,
	) {
		super(el, model, children);

		this.app = app;
		this.ajaxService = ajaxService;
		this.flashMessageService = flashMessageService;
		this.hitService = hitService;
		this.rerender = RerenderMixin.rerender;
		this.userService = userService;
		this.fancyboxService = fancyboxService;
		this.validateSettings = validateSettings;

		this.bindValidation = ValidationMixin.bindValidation;
		this.getEmailMessage = ValidationMixin.getEmailMessage;
		this.onInputFix = ValidationMixin.onInputFix;
		this.onValidationSuccess = ValidationMixin.onValidationSuccess;
	}

	bind() {
		super.bind();

		this.btnSubmit = $(".j-submit", this.el);

		this.done = $(".j-leadgen-done", this.el);
		this.form = $(".j-leadgen-form form", this.el);
		this.pageviewId = $(".j-pageview", this.el);
		this.selectedDeal = $(".j-selected-deal", this.el);

		this.pageviewId.val(this.hitService.getPageviewId() || "");

		this.bindForm();
		this.bindValidation();
	}

	unbind() {
		super.unbind();
	}

	bindForm() {
		const formSettings = {
			rules: {
				"term_request_form[buyerEmail]": {
					required: true,
					email: false,
					emailHtml5: true,
				},
			},
			messages: {
				"term_request_form[buyerEmail]": {
					required: "Bez e-mailu to nepůjde",
					emailHtml5: (...args) => this.getEmailMessage(...args),
				},
			},
			submitHandler: (form, ev) => this.onSubmit(ev),
			success: ($label, element) => {
				this.onValidationSuccess($label, element);
			},
		};
		this.form.validate($.extend({}, this.validateSettings, formSettings));
	}

	getCampaign() {
		return dry.str.undescorize(this.constructor.name);
	}

	goTo(step) {
		this.form.parent().hide();
		this.done.hide();

		switch (step) {
			case STEP_DONE:
				this.done.show();
				break;
			case STEP_FORM:
			default:
				this.form.parent().show();
				break;
		}
	}

	onSubmit(ev) {
		ev.preventDefault();

		const deal = this.model.deals[this.selectedDeal.val()];
		const btnText = this.btnSubmit.text();
		const url = this.model.ajaxUrlPattern.replace("{hash}", deal.hash);

		this.btnSubmit
			.prop("disabled", true)
			.text("Odesílám...");

		const formData = this.form.serializeObject();
		const trackingParameters = this.getInternalTrackingParameters();
		const data = {
			...formData,
			...trackingParameters,
		};

		this.ajaxService
			.ajax({
				data,
				method: "POST",
				url,
			})
			.then((response) => {
				if (response.ok) {
					this.rerender(response.html);
					if (response.saved) {
						this.goTo(STEP_DONE);
					} else {
						this.goTo(STEP_FORM);
					}
					if (response.shouldTrackRegistration) {
						this.hitService.trackRegistration();
					}
					return $.Deferred().resolve();
				}

				return $.Deferred().reject();
			}).fail(() => {
				this.flashMessageService.showMessage("Nepodařilo se odeslat rezervaci");
			}).always(() => {
				this.btnSubmit
					.prop("disabled", false)
					.text(btnText);
			});
	}
}
