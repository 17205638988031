import $ from "jquery";

const TrackingMixin = {
	bindTracking(links) {
		this.hitParamsLinks = links;
		this.el.on("mouseenter mouseover touchstart", ev => this.onMouseIn(ev));
	},

	attachHitParams() {
		if (this.model.hitParamsAttach) {
			return;
		}
		if (!this.hitParamsLinks) {
			return;
		}

		this.model.hitParamsAttach = true;

		this.model.source = this.getSource();
		this.model.campaign = this.getCampaign();
		this.model.medium = this.getMedium();

		let params = [];
		if (this.model.source) {
			params.push(`s_source=${this.model.source}`);
		}
		if (this.model.medium) {
			params.push(`s_medium=${this.model.medium}`);
		}
		if (this.model.campaign) {
			params.push(`s_campaign=${this.model.campaign}`);
		}

		params = params.join("&");

		this.hitParamsLinks.each(function () {
			let url = $(this).attr("href");
			url = `${url}${url.indexOf("?") === -1 ? "?" : "&"}${params}`;
			$(this).attr("href", url);
		});
	},

	onMouseIn() {
		this.attachHitParams();
	},
};

export default TrackingMixin;
