import $ from "jquery";
import AbstractFilterWidget from "./AbstractFilterWidget";

const CHECKED_KEY = "checked";
const AIRPORTS_PARENT_KEY = "airports_parent";
const INPUT_TYPE_CHECKBOX_SELECTOR_KEY = "input[type='checkbox']";
const INDETERMINATE_KEY = "indeterminate";

export default class AirportsFilterWidget extends AbstractFilterWidget {
	constructor(props) {
		super(props);
		this.checkbox = $("input[type='checkbox']", this.el);
		this.showBtn = $(".j-show", this.el);
		this.showContent = $(".j-show-content", this.el);
		this.topCheckbox = $(`#${AIRPORTS_PARENT_KEY}`);
	}

	bind() {
		super.bind();
		this.bindIntermediateState();
		this.initCheckboxStates();
		this.showBtn.click(ev => this.toggleContent(ev));
	}

	toggleContent(ev) {
		ev.preventDefault();
		this.showContent.toggle();
	}

	bindIntermediateState() {
		this.checkbox.change((e) => {
			const checked = $(e.target).prop(CHECKED_KEY);
			const container = $(e.target).parent();

			container.find("input[type='checkbox']").prop({
				[INDETERMINATE_KEY]: false,
				[CHECKED_KEY]: checked,
			});
			this.checkSiblings(container, checked);
			this.setCheckboxStateByChildrenValues(this.topCheckbox);
		});
	}

	initCheckboxStates() {
		const activeSubCheckboxes = this.checkbox.filter(":checked").not(this.topCheckbox);

		if (this.topCheckbox.prop(CHECKED_KEY) === true && activeSubCheckboxes.length === 0) {
			this.checkbox.each((key, checkbox) => {
				$(checkbox).prop({
					[INDETERMINATE_KEY]: false,
					[CHECKED_KEY]: true,
				});
			});
		} else {
			activeSubCheckboxes.each((key, checkbox) => {
				if ($(checkbox).data("level") === 2) {
					const children = $(checkbox).parent("li").find(INPUT_TYPE_CHECKBOX_SELECTOR_KEY);
					$(children).prop(CHECKED_KEY, true);
				} else if ($(checkbox).data("level") === 3) {
					const checked = $(checkbox).prop(CHECKED_KEY);
					const container = $(checkbox).parent();

					container.find("input[type='checkbox']").prop({
						[INDETERMINATE_KEY]: false,
						[CHECKED_KEY]: checked,
					});
					this.checkSiblings(container, checked);
				}
			});

			this.setCheckboxStateByChildrenValues(this.topCheckbox);
		}
	}

	setCheckboxStateByChildrenValues(checkbox) {
		const checkboxChildren = $(checkbox)
			.siblings("ul")
			.find("li.checkbox")
			.children("input[type='checkbox']");
		const checkboxShouldBeChecked = Array.from(checkboxChildren)
			.every(elem => $(elem).prop(CHECKED_KEY) === true || $(elem).prop(INDETERMINATE_KEY) === true);
		const checkboxShouldBeIndeterminate = Array.from(checkboxChildren)
			.some(elem => $(elem).prop(CHECKED_KEY) === true || $(elem).prop(INDETERMINATE_KEY) === true);

		this.topCheckbox.prop({
			[CHECKED_KEY]: checkboxShouldBeChecked,
			[INDETERMINATE_KEY]: checkboxShouldBeIndeterminate && checkboxShouldBeChecked === false,
		});
	}

	checkSiblings(el, parentIsChecked) {
		const parent = el.parent().parent();
		const allSiblings = Array.from(el.siblings()).concat(el);
		const allSiblingsAreChecked = allSiblings
			.every(elem => $(elem).children(INPUT_TYPE_CHECKBOX_SELECTOR_KEY).prop(CHECKED_KEY) === true);
		const someSiblingsAreChecked = allSiblings
			.some(elem => $(elem).children(INPUT_TYPE_CHECKBOX_SELECTOR_KEY).prop(CHECKED_KEY) === true);

		if (allSiblingsAreChecked && parentIsChecked) {
			parent.children(INPUT_TYPE_CHECKBOX_SELECTOR_KEY).prop({
				[INDETERMINATE_KEY]: false,
				[CHECKED_KEY]: true,
			});
			this.checkSiblings(parent, parentIsChecked);
		} else if (allSiblingsAreChecked && !parentIsChecked) {
			parent.children(INPUT_TYPE_CHECKBOX_SELECTOR_KEY).prop(CHECKED_KEY, parentIsChecked);
			parent.children(INPUT_TYPE_CHECKBOX_SELECTOR_KEY)
				.prop("indeterminate", (parent.find(INPUT_TYPE_CHECKBOX_SELECTOR_KEY).length > 0));
			this.checkSiblings(parent, parentIsChecked);
		} else if (someSiblingsAreChecked === false && parentIsChecked === false) {
			const elem = el.parents("li").children(INPUT_TYPE_CHECKBOX_SELECTOR_KEY);
			elem.prop({
				[INDETERMINATE_KEY]: false,
				[CHECKED_KEY]: false,
			});
		} else {
			const elem = el.parents("li").children(INPUT_TYPE_CHECKBOX_SELECTOR_KEY);
			let props = {
				[INDETERMINATE_KEY]: true,
				[CHECKED_KEY]: false,
			};
			if (elem.attr("id") === AIRPORTS_PARENT_KEY) {
				props = {
					[INDETERMINATE_KEY]: false,
					[CHECKED_KEY]: true,
				};
			}
			elem.prop(props);
		}
	}
}
