import $ from "jquery";
import TopFilterFormWidget from "./TopFilterFormWidget";
import ListingFooterWidget from "../ListingFooterWidget";

export default class FilterFooterWidget extends ListingFooterWidget {
	constructor(el, model, children, app) {
		super(el, model, children, app);

		this.app = app;
	}

	bind() {
		super.bind();

		this.btnFilters = $(".j-btn-filters", this.el);
		this.btnFilters.click((ev) => { this.onBtnFiltersClick(ev); });
		this.filters = this.app.findByType(TopFilterFormWidget)[0] || null;
	}

	onBtnFiltersClick(ev) {
		ev.preventDefault();

		if (!this.filters && !this.filters.el[0]) {
			return;
		}

		this.filters.el[0].scrollIntoView(true);
	}
}
