import ItemThumbWidget from "../Item/ItemThumbWidget";

export default class TopicDayThumbWidget extends ItemThumbWidget {
	bind() {
		super.bind();

		this.delay = 5000;
		this.firstDelay = null;
		this.preloadCount = 0;

		// var autoplayDelay = Math.round((Math.random() * this.delay));
		const autoplayDelay = (this.model.pos - 1) * 2000;

		setTimeout(() => this.play(), autoplayDelay);
	}

	// eslint-disable-next-line class-methods-use-this
	getThreshold() {
		return 500;
	}
}
