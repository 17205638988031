import $ from 'jquery';
import Widget from '../../../Inlined/Widget';

export default class HeaderWidget extends Widget {
	constructor(el, model, children, app) {
		super(el, model, children);
		this.app = app;
		this.opened = false;
	}

	bind() {
		super.bind();

		this.topNavigation = $('.navigation-parent');
		this.topNavigationContent = $('#main-submenu');
		this.burger = $('#burger');
		this.salad = $('#salad');
		this.patty = $('#patty');
		this.mayo = $('#mayo');

		this.mobileArrow = '.mobile-nav-arrow';
		this.mobileNavItem = '.mobile-nav-item';

		this.burger.click((ev) => { this.handleBurgerClick(ev); });
		$('.mobile-nav-link').click((ev) => { this.handleMobileMenuClick(ev); });
		this.topNavigation.mouseover((ev) => { this.openDesktopNavigation(ev); });
		$('.navigation-item').mouseover((ev) => { this.closeDesktopNavigation(ev); });
		this.topNavigationContent.mouseleave((ev) => { this.closeDesktopNavigation(ev); });
	}

	handleMobileMenuClick(el) {
		$(el.currentTarget).find(this.mobileArrow).toggleClass('rotate-0');
		$(el.currentTarget).find(this.mobileArrow).toggleClass('rotate-180');
		$(el.currentTarget).parent().find(this.mobileNavItem).toggleClass('opacity-0 h-0');
		$(el.currentTarget).parent().find(this.mobileNavItem).toggleClass('opacity-100');
	}

	openBurger() {
		this.salad.addClass('rotate-45 -translate-x-1 translate-y-[5px]');
		this.patty.addClass('translate-x-6 opacity-0');
		this.mayo.addClass('-rotate-45 -translate-x-1 -translate-y-[5px]');
	}

	closeBurger() {
		this.salad.removeClass('rotate-45 -translate-x-1 translate-y-[5px]');
		this.patty.removeClass('translate-x-6 opacity-0');
		this.mayo.removeClass('-rotate-45 -translate-x-1 -translate-y-[5px]');
	}

	handleBurgerClick() {
		if (this.opened) {
			this.closeMobileNavigation();
		} else {
			this.openMobileNavigation();
		}

		$('#mobile-nav').toggleClass('-translate-y-full');
		$('#mobile-nav').toggleClass('translate-y-0');
	}

	openMobileNavigation() {
		this.opened = true;
		this.openBurger();
	}

	closeMobileNavigation() {
		this.opened = false;
		this.closeBurger();
	}

	closeDesktopNavigation() {
		// set color
		this.topNavigation.removeClass('text-blue');
		this.topNavigation.addClass('text-black-100');

		// rotate triangle
		this.topNavigation.find('div').removeClass('rotate-180');

		// slide submenu up
		this.topNavigationContent.addClass('-translate-y-full');
		this.topNavigationContent.removeClass('translate-y-0');

		// hide backdrop
		$('#backdrop').addClass('hidden');
		$('#backdrop').addClass('opacity-0');
		$('#backdrop').removeClass('opacity-100');
	}

	openDesktopNavigation(e) {
		// set blue color
		this.topNavigation.removeClass('text-blue');
		this.topNavigation.addClass('text-black-100');
		$(e.currentTarget).addClass('text-blue');
		$(e.currentTarget).removeClass('text-black-100');

		// rotate triangle
		this.topNavigation.find('div').removeClass('rotate-180');
		$(e.currentTarget).find('div').addClass('rotate-180');

		const id = $(e.currentTarget).attr('id');

		// show correct submenu
		this.topNavigationContent.children('div').addClass('hidden');
		$(`.${id}`).removeClass('hidden');

		// slide submenu down
		this.topNavigationContent.removeClass('-translate-y-full');

		// open
		this.topNavigationContent.addClass('translate-y-0');

		// show backdrop
		$('#backdrop').removeClass('hidden');
		$('#backdrop').removeClass('opacity-0');
		$('#backdrop').addClass('opacity-100');
	}
}
