import $ from "jquery";
import ResponsiveWidget from "../ResponsiveWidget";

/* global window */

export default class TopicDayWidget extends ResponsiveWidget {
	bind() {
		super.bind();

		this.btnGo = $(".j-go", this.el);
		this.img = $(".j-img", this.el);
		this.thumb = $(".j-thumb", this.el);
		this.hitParamsAttached = false;

		this.btnGo.click(ev => this.onGo(this, ev));
		this.el.mouseenter(ev => this.onMouseIn(ev));
		this.el.mouseover(ev => this.onMouseIn(ev));
	}

	attachHitParams() {
		if (this.model.hitParamsAttach === false) {
			return;
		}
		if (this.hitParamsAttached) {
			return;
		}

		this.hitParamsAttached = true;

		this.model.source = this.getSource();
		this.model.campaign = this.getCampaign();
		this.model.medium = this.getMedium();

		let params = [];
		if (this.model.source) {
			params.push(`s_source=${this.model.source}`);
		}
		if (this.model.medium) {
			params.push(`s_medium=${this.model.medium}`);
		}
		if (this.model.campaign) {
			params.push(`s_campaign=${this.model.campaign}`);
		}
		params = params.join("&");

		if (this.model.exitUrl.indexOf("?") === -1) {
			this.model.exitUrl = `${this.model.exitUrl}?${params}`;
		} else {
			this.model.exitUrl = `${this.model.exitUrl}&${params}`;
		}

		this.btnGo.attr("href", this.model.exitUrl);
	}

	getColor() {
		return this.model.color;
	}

	onGo(btn) {
		// Udelat proklik! (pokud element neni <a>)
		if (btn.tagName !== "A") {
			window.open(this.model.exitUrl, "_self");
		}
	}

	onMouseIn() {
		this.attachHitParams();
	}
}
