import $ from "jquery";
import Widget from "../../../Inlined/Widget";

export default class BoxWidget extends Widget {
	bind() {
		super.bind();

		this.btnMore = $(".j-more", this.el);
		this.title = $(".j-box-title", this.el);

		this.btnMore.click(ev => this.onBtnMoreClick(ev));
		this.title.click(ev => this.onTitleClick(ev));
	}

	getCategoryId() {
		return this.model.categoryId || null;
	}

	getIsVisible() {
		return this.el.visible(true, true);
	}

	getServerId() {
		return this.model.serverId || null;
	}

	onBtnMoreClick() {

	}

	onTitleClick() {

	}

	getCampaign() {
		if (this.model.campaign) {
			return this.model.campaign;
		}
		return this.parent.getCampaign();
	}
}
