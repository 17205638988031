export default class ItemShareService {
	constructor(app, ajaxService, fancyboxService, urlPattern, ajaxUrlPattern, bodyWidget, internalTrackingParameters) {
		this.app = app;
		this.ajaxService = ajaxService;
		this.fancyboxService = fancyboxService;
		this.urlPattern = urlPattern;
		this.ajaxUrlPattern = ajaxUrlPattern;
		this.bodyWidget = bodyWidget;
		this.internalTrackingParameters = internalTrackingParameters;
		this.itemWidgets = {};
	}

	getUrlPattern() {
		return this.urlPattern;
	}

	getItemWidget(item) {
		return this.itemWidgets[item.hash] || null;
	}

	openDialog(item, onSuccess = () => {}) {
		if (item.type !== "deal" || !item.hash) {
			return false;
		}
		const srv = this;
		const widget = this.getItemWidget(item);

		if (widget) {
			this.fancyboxService.openWidget(
				widget,
				{
					slideClass: "s-edge-to-edge",
					afterLoad: () => {
						onSuccess.apply(srv, [widget]);
					},
					detachOnClose: true,
				},
			);
		} else {
			const url = this.ajaxUrlPattern.replace("{hash}", item.hash);
			this.fancyboxService.openAjax(
				url,
				{
					slideClass: "s-edge-to-edge",
					afterLoad(instance, current) {
						srv.itemWidgets[item.hash] = current.widget;
						onSuccess.apply(srv, [current.widget]);
					},
					detachOnClose: true,
				},
			);
		}
	}

	setInternalTrackingParameters(internalTrackingParameters) {
		this.internalTrackingParameters = internalTrackingParameters;
		return this;
	}
}
