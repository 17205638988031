import $ from "jquery";
import Widget from "../../../../Inlined/Widget";
import WatchdogSetEvent from "../../Event/WatchdogSetEvent";
import WatchdogRequestEvent from "../../Event/WatchdogRequestEvent";
import WatchdogResumeEnum from "../../VO/WatchdogResumeEnum";
import { trans } from "../../Service/I18NService";

/* global __ */
/* global dry */

export default class TopicDaySubscribeWidget extends Widget {
	constructor(el, model, children, watchdogService, userService) {
		super(el, model, children);

		this.watchdogService = watchdogService;
		this.userService = userService;

		this.watchdogResume = WatchdogResumeEnum.TOPICDAY_RESUME;
	}

	bind() {
		super.bind();

		this.$btnSubscribe = $(".j-btn-subscribe", this.el);
		this.$btnSubscribeText = $(".j-text", this.$btnSubscribeText);
		this.$done = $(".j-done", this.el);
		this.$prepare = $(".j-prepare", this.el);
		this.$spinner = $(".j-spinner", this.$btnSubscribe);

		this.$btnSubscribe.click(ev => this.onBtnSubscribeClick(ev));
		this.watchdogService.register(this.watchdogResume, payload => this.onResume(payload));
		this.watchdogService.on(WatchdogSetEvent, ev => this.onWatchdogSet(ev));
		this.watchdogService.on(WatchdogRequestEvent, ev => this.onWatchdogRequest(ev));

		this.model.active = false;
	}

	getAlertText() {
		let text = this.model.active ? this.model.activeAlertText : this.model.inactiveAlertText;
		if (this.model.active
			&& this.userService.isLoggedIn()
			&& !this.userService.isAnonymous()
			&& this.userService.getEmail()
		) {
			// eslint-disable-next-line max-len
			text = trans(__("%text% Nabídky Vám pošleme na e-mail %email%"), { "%text%": text, "%email%": this.userService.getEmail() });
		}
		return text;
	}

	getCampaign() {
		return dry.str.undescorize(this.constructor.name);
	}

	onBtnSubscribeClick(ev) {
		ev.preventDefault();
		this.toggle();
	}

	onResume(payload) {
		this.model.active = payload.active;
		this.onUpdated();
	}

	onUpdated() {
		if (this.model.active) {
			this.$prepare.hide();
			this.$done
				.text(this.getAlertText())
				.show();
			this.$btnSubscribeText.text(this.model.activeText);
		} else {
			this.$prepare.show();
			this.$done
				.text(this.getAlertText())
				.hide();
			this.$btnSubscribeText.text(this.model.inactiveText);
		}
	}

	onWatchdogSet(ev) {
		if (ev.watchdogId !== WatchdogResumeEnum.TOPICDAY_RESUME) {
			return;
		}

		this.model.active = ev.active;
		this.onUpdated();
	}

	onWatchdogRequest(ev) {
		if (ev.watchdogId !== WatchdogResumeEnum.TOPICDAY_RESUME) {
			return;
		}

		if (ev.state === "sent") {
			this.$spinner.show();
		} else {
			this.$spinner.hide();
		}
	}

	toggle() {
		const data = {
			active: Number(!this.model.active),
			topic_newsletter: 1,
		};
		this.watchdogService.setWatchdogId(this.watchdogResume);
		this.watchdogService.setSourceWidget(this);
		this.watchdogService.setInternalTrackingParameters(this.getInternalTrackingParameters());
		this.watchdogService.setPayload(data, { silentLogin: true });
		this.watchdogService.setWatchdog(true);
		return true;
	}
}
