const EventDispatcherMixin = {
	dispatch(ev) {
		this.listeners = this.listeners || [];
		this.listeners
			.filter(listener => (ev instanceof listener.evClass))
			.map(listener => (listener.fn))
			.map(callback => callback(ev));
	},

	on(evClass, fn) {
		this.listeners = this.listeners || [];
		this.listeners.push({ evClass, fn });
	},

	// Vypnout udalosti dane typem udalosti a (volitelne) handler funkci
	// Bez parametru - odstrani vsechny udalosti
	off(evClass, fn) {
		const listeners = [];

		if (evClass !== undefined || fn !== undefined) {
			for (const listener of this.listeners) {
				if (listener.evClass !== evClass
					|| (
						fn !== undefined && listener.fn !== fn
					)
				) {
					listeners.push(listener);
				}
			}
		}

		this.listeners = listeners;
	},
};

export default EventDispatcherMixin;
