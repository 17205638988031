import $ from "jquery";
import ResponsiveWidget from "./ResponsiveWidget";
import DropdownOpenEvent from "../Event/DropdownOpenEvent";

/* global document */

export default class AbstractMenuWidget extends ResponsiveWidget {
	constructor(el, model, children, app) {
		super(el, model, children);
		this.app = app;
		this.delayT = null;
		this.dropdownCloseDelay = 500;
		// dropdown, ktery je prave otevreny a bude se zpozdenim zavren
		this.dropdownDelayed = null;
	}

	bind() {
		super.bind();

		this.dropdowns = $("> .dropdown, > .dropdown-submenu", this.el);
		this.dropdownActiveBefore = this.dropdowns.filter(".active");
		this.dropdownOpenBefore = this.dropdowns.filter(".open");

		this.dropdowns
			.mouseenter(ev => this.onDropdownMouseEnter(this, ev))
			.mouseleave(ev => this.onDropdownMouseLeave(this, ev));
		// Poslouchat otevreni dropdownu v jinem widgetu
		this.onDropdownOpenFn = ev => this.onDropdownOpen(ev);
		this.app.on(DropdownOpenEvent, this.onDropdownOpenFn);
		// Poslouchat otevreni Bootstrap dropdownu
		$(document).on(
			"click.dropdown.data-api",
			"[data-toggle=dropdown]",
			this.onDropdownOpenFn,
		);
	}

	unbind() {
		super.unbind();

		// Unbindovat vnejsi eventy
		this.app.off(DropdownOpenEvent, this.onDropdownOpenFn);
		$(document).off(
			"click.dropdown.data-api",
			"[data-toggle=dropdown]",
			this.onDropdownOpenFn,
		);
	}

	onDropdownMouseEnter(dropdown) {
		// na mobilech a tabletech jsou dropdowny vypnute
		if (this.getDeviceSize() === "xs" || this.getDeviceSize() === "sm") {
			return;
		}
		clearTimeout(this.delayT);

		// Zavrit dropdowny z Bootstrapu
		$(document).trigger("click.dropdown.data-api");
		// Zavrit dropdowny v tomto widgetu
		this.dropdowns.removeClass("active open");
		this.dropdownOpen = $(dropdown);
		this.app.dispatch(new DropdownOpenEvent(dropdown));
	}

	onDropdownMouseLeave(dropdown) {
		// na mobilech a tabletech jsou dropdowny vypnute
		if (this.getDeviceSize() === "xs" || this.getDeviceSize() === "sm") {
			return;
		}

		this.dropdownOpen = null;
		this.dropdownDelayed = $(dropdown);

		// Zavrit se zpozdenim
		$(dropdown).addClass("open");
		this.delayT = setTimeout(() => this.onDropdownTimeout(dropdown), this.dropdownCloseDelay);
	}

	onDropdownOpen() {
		// Kdyz se otevrel dropdown z jineho menu
		if (!this.dropdownOpen) {
			this.resetDropdowns();
		}
	}

	// Od mouseleave uplynul zadany timeout
	onDropdownTimeout() {
		this.resetDropdowns();
	}

	// Uvede dropdowny do stavu, ktery byl po nacteni stranky
	resetDropdowns() {
		clearTimeout(this.delayT);

		this.dropdowns.removeClass("active open");
		this.dropdownActiveBefore.addClass("active");
		this.dropdownOpenBefore.addClass("open");
		this.dropdownOpen = null;
		this.dropdownDelayed = null;
	}
}
