import $ from "jquery";
import Widget from "../../../Inlined/Widget";
import ValidationMixin from "./ValidationMixin";
import UserAuthStateEvent from "../Event/UserAuthStateEvent";
import UserAuthStateEnum from "../VO/UserAuthStateEnum";

/* global dry */

export default class DeadEmailWidget extends Widget {
	constructor(
		el,
		model,
		children,
		userService,
		flashMessageService,
		deadEmailService,
		validateSettings,
		fancyboxService,
	) {
		super(el, model, children);
		this.userService = userService;
		this.flashMessageService = flashMessageService;
		this.deadEmailService = deadEmailService;
		this.validateSettings = validateSettings;
		this.fancyboxService = fancyboxService;

		this.bindValidation = ValidationMixin.bindValidation;
		this.getEmailMessage = ValidationMixin.getEmailMessage;
		this.onInputFix = ValidationMixin.onInputFix;
		this.onValidationSuccess = ValidationMixin.onValidationSuccess;
	}

	bind() {
		super.bind();

		this.after = $(".j-after", this.el);
		this.before = $(".j-before", this.el);
		this.btnClose = $(".j-btn-close", this.el);
		this.btnChange = $(".j-btn-change", this.el);
		this.email = $(".j-email", this.el);
		this.password = $(".j-password", this.el);
		this.passwordGroup = $(".j-password-group", this.el);
		this.spinner = $(".j-spinner", this.el);

		this.btnClose.click(ev => this.onBtnCloseClick(ev));
		this.btnChange.click(ev => this.onBtnChangeClick(ev));
		this.bindForm();
		this.bindValidation();
		this.userService.on(UserAuthStateEvent, ev => this.onUserAuthState(ev));
	}

	bindForm() {
		// Obecna validacni metoda notEqualTo
		// Muzeme vyclenit a pouzit i jinde, pokud bude potreba
		$.validator.addMethod(
			"notEqualTo",
			(val, element, comp) => {
				const value = val.toLowerCase().replace(/\s/g, "");
				const compare = comp.toLowerCase().replace(/\s/g, "");
				return this.optional(element) || value !== compare;
			},
			"Zadej jinou hodnotu",
		);

		const formSettings = {
			rules: {
				email: {
					notEqualTo: this.model.deadEmail,
					required: true,
					email: false,
					emailHtml5: true,
				},
				password: {
					required: true,
				},
			},
			messages: {
				email: {
					notEqualTo: `E-mail ${this.model.deadEmail} zdá se nefunguje. Zadej, prosím, jiný.`,
					required: "Bez zadání e-mailu to nepůjde",
					emailHtml5: (...args) => this.getEmailMessage(...args),
				},
			},
			success: ($label, element) => {
				this.onValidationSuccess($label, element);
			},
			submitHandler: (form, ev) => {
				this.onSubmit(ev);
			},
		};
		this.el.validate($.extend({}, this.validateSettings, formSettings));
	}

	getCampaign() {
		return dry.str.undescorize(this.constructor.name);
	}

	close() {
		if (this.fancyboxService.isOpened(this)) {
			this.fancyboxService.close();
		}
	}

	end() {
		this.deadEmailService.disableDialog();
	}

	goTo(step) {
		if (step === "complete") {
			this.before.hide();
			this.after.show();
		} else if (step === "password") {
			this.before.show();
			this.after.hide();
			this.passwordGroup.slideDown();
			setTimeout(() => this.password.focus(), 500);
		} else {
			this.before.show();
			this.after.hide();
			this.passwordGroup.slideUp();
			setTimeout(() => this.email.focus, 500);
		}
	}

	onBtnCloseClick(ev) {
		ev.preventDefault();
		this.close();
	}

	onBtnChangeClick(ev) {
		ev.preventDefault();
		this.emailExists = false;
		this.goTo("email");
	}

	onSubmit(ev) {
		ev.preventDefault();

		const data = this.el.serializeObject();

		const state = this.emailExists ? UserAuthStateEnum.EMAIL_EXISTS_STATE : UserAuthStateEnum.EMAIL_STATE;
		this.userService.setState(state);
		this.userService.setInternalTrackingParameters(this.getInternalTrackingParameters());
		this.userService.doAuth(
			data,
			() => this.spinner.hide(),
			() => this.spinner.hide(),
		);
		this.spinner.show();
	}

	onUserAuthState(ev) {
		this.emailExists = ev.newState === UserAuthStateEnum.EMAIL_EXISTS_STATE
			|| ev.newState === UserAuthStateEnum.WRONG_PASSWORD_STATE;

		if (ev.newState === UserAuthStateEnum.COMPLETE_STATE
			&& this.userService.getEmail() !== this.model.deadEmail
		) {
			this.goTo("complete");
		} else if (ev.newState === UserAuthStateEnum.EMAIL_EXISTS_STATE) {
			this.goTo("password");
			this.flashMessageService.showMessage("Tento e-mail již existuje, zadej heslo", "warning");
		} else if (ev.newState === UserAuthStateEnum.WRONG_PASSWORD_STATE) {
			this.goTo("password");
			this.flashMessageService.showMessage("Zadané heslo je bohužel špatně, zkus to ještě jednou.", "error");
		}
	}
}
