export default class UserAuthResumeEnum {
	static WATCHDOG_RESUME = "watchdog";
	static MEMBERS_ITEM_RESUME = "members_item";
	static USER_REGISTER_RESUME = "user_register";
	static LEAFLET_DETAIL_LOGIN_RESUME = "leaflet_detail_login";
	static CONTEST_CARS = "contest_cars";
	static DEAL_RESUME = "deal";
	static DEALS_SUBSCRIBE_RESUME = "deals_subscribe";
	static TOPICDAY_LOGIN = "topicday_login";
	static TOPIC_MAIL_SUBSCRIBE_RESUME = "topic_mail_subscribe";
	static USER_SUBSCRIBE_BOX_RESUME = "user_subscribe_box";
	static USER_SUBSCRIBE_MODAL_RESUME = "user_subscribe_modal";
	static HOMEPAGE_SUBSCRIBE = "homepage_subscribe";
	static WISHLIST_SUBSCRIBE = "wishlist_subscribe";
}
