import $ from "jquery";
import Widget from "../../../../Inlined/Widget";
import UserAuthResumeEnum from "../../VO/UserAuthResumeEnum";
import UserAuthStateEnum from "../../VO/UserAuthStateEnum";
import UserAuthStateEvent from "../../Event/UserAuthStateEvent";
import UserBiscuitEnum from "../../VO/UserBiscuitEnum";
import ValidationMixin from "../ValidationMixin";
import suggestEmail from "./SuggestEmail";

/* global document */
/* global dry */
/* global __ */

export default class UserSubscribeBoxWidget extends Widget {
	constructor(
		el,
		model,
		children,
		userService,
		validateSettings,
		flashMessageService,
		flashMessagesWidget,
		fancyboxService,
		subscribeMessageService,
	) {
		super(el, model, children);
		this.userService = userService;
		this.validateSettings = validateSettings;
		this.flashMessageService = flashMessageService;
		this.flashMessagesWidget = flashMessagesWidget;
		this.fancyboxService = fancyboxService;
		this.subscribeMessageService = subscribeMessageService;

		this.bindValidation = ValidationMixin.bindValidation;
		this.getEmailMessage = ValidationMixin.getEmailMessage;
		this.onInputFix = ValidationMixin.onInputFix;
		this.onValidationSuccess = ValidationMixin.onValidationSuccess;
		this.delay = () => {
			setTimeout(
				() => {
					if (!this.userService.isRegistered()) {
						this.show();
						return;
					}
					this.userService.getSettings().then((userSettings) => {
						if (userSettings && userSettings.pushEmailFrequency !== "0") {
							return;
						}
						this.show();
					});
				},
				this.model.timeout,
			);
		};
	}

	bind() {
		super.bind();
		this.model.userAuthResume = UserAuthResumeEnum.USER_SUBSCRIBE_BOX_RESUME;
		this.model.waitingForAuth = false;
		this.btnClose = $(".j-close", this.el);
		this.emailInput = $(".j-email", this.el);
		this.emailGroup = $(".j-email-group", this.el);
		this.noEmailGroup = $(".j-no-email-group", this.el);
		this.spinner = $(".j-spinner", this.el);
		this.btnClose.click(ev => this.onBtnCloseClick(ev));
		this.el.click(ev => this.onClick(ev));
		this.userService.register(this.model.userAuthResume, () => this.onUserAuthResume());
		this.userService.on(UserAuthStateEvent, ev => this.onUserAuth(ev));
		$(document.body).on("click", ".j-usersubscribebox-retry", this.onRetryFn = ev => this.onRetry(ev));
		this.bindForm();
		this.bindValidation();
		this.delay();
		$(document.body).one("mousemove touchstart scroll", () => this.onUserActivate());
	}

	unbind() {
		super.unbind();
		$(document.body).off(".j-usersubscribebox-retry", this.onRetryFn);
		clearTimeout(this.delay);
	}

	bindForm() {
		const formSettings = {
			rules: {
				email: {
					required: () => this.isEmailRequired(),
					email: false,
					emailHtml5: () => this.isEmailRequired(),
				},
			},
			messages: {
				email: {
					required: __("Bez e-mailu to nepůjde"),
					emailHtml5: (...args) => this.getEmailMessage(...args),
				},
			},
			submitHandler: (form, ev) => this.onSubmit(ev),
			success: ($label, element) => {
				this.onValidationSuccess($label, element);
			},
		};

		this.el.validate($.extend({}, this.validateSettings, formSettings));
	}

	getCampaign() {
		return dry.str.undescorize(this.constructor.name);
	}

	hide() {
		const height = this.el.outerHeight();

		this.el
			.stop()
			.animate(
				{
					top: `${-height - 30}px`,
				},
				300,
				() => {
					this.el.hide();
				},
			);
	}

	isEmailRequired() {
		return !this.userService.isRegistered();
	}

	onBtnCloseClick(ev) {
		ev.preventDefault();

		this.hide();
		this.userService.setBiscuit(UserBiscuitEnum.USER_SUBSCRIBE_BOX, 0, 1);
	}

	onUserAuth(ev) {
		if (this.model.waitingForAuth && ev.newState === UserAuthStateEnum.COMPLETE_STATE) {
			this.model.waitingForAuth = false;
			this.setTopicMailing();
		}
	}

	onUserAuthResume() {
		this.model.waitingForAuth = false;
		this.setTopicMailing();
	}

	onRetry(ev) {
		ev.preventDefault();
		this.setTopicMailing();
	}

	onClick() {
		// Zalozit anonyma
		if (!this.userService.getUserId() && !this.model.waitingForAuth) {
			this.userService.setState(UserAuthStateEnum.NEW_STATE);
			this.userService.setLoginId(null);
			this.userService.setPayload(null);
			this.userService.setInternalTrackingParameters(this.getInternalTrackingParameters());
			this.userService.doAuth();

			this.model.waitingForAuth = true;
		}
	}

	onSubmit() {
		let email = this.emailInput.val();

		if (!this.isEmailRequired()) {
			this.setTopicMailing();
		} else {
			const suggestedEmail = suggestEmail(email);
			if (suggestedEmail) {
				email = suggestedEmail;
			}

			this.spinner.show();
			this.userService.setState(UserAuthStateEnum.EMAIL_STATE);
			this.userService.setLoginId(this.model.userAuthResume);
			this.userService.setPayload({
				silentLogin: true,
			});
			this.userService.setInternalTrackingParameters(this.getInternalTrackingParameters());
			this.userService.doAuth(
				{
					email,
				},
				() => {
					this.spinner.hide();
					this.hide();
				},
				() => {
					this.spinner.hide();
					this.hide();
				},
			);
			this.model.waitingForAuth = true;
		}

		return false;
	}

	onUserActivate() {
		if (this.model.userActive) {
			return;
		}
		this.model.userActive = true;
	}

	setTopicMailing() {
		this.spinner.show();
		this.userService
			.setSettings({
				emailing_push_email_frequency: "medium",
				only_up: true,
			})
			.then(
				() => {
					this.spinner.hide();
					this.hide();
					if (!this.userService.isNewUser()) {
						this.subscribeMessageService.showExistingUserSuccessMessage(this.userService.getEmail());
					}
				},
				() => {
					this.spinner.hide();
					this.hide();
					this.flashMessageService.showMessage(
						// TRANSLATORS: Uživatel provedl akci, která se nepovedla
						`${__("Došlo k chybě.")} <a 
								class="j-usersubscribebox-retry j-close" 
								href="#"
							>${__("Zkusit znovu")}</a>`,
						"warning",
						{
							modal: true,
						},
					);
				},
			);
	}

	show() {
		// Do not open when modal is opened
		if (this.fancyboxService.isOpened()) {
			return;
		}
		if (this.userService.isAuthDialogOpened()) {
			return;
		}
		// Require email when user is not registered. Hide otherwise.
		if (!this.isEmailRequired()) {
			this.emailGroup.hide();
			this.noEmailGroup.show();
		} else {
			this.emailGroup.show();
			this.noEmailGroup.hide();
		}
		this.el
			.stop()
			.show()
			.animate(
				{
					top: 0,
				},
				300,
			);
	}
}
