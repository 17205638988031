import $ from "jquery";
import AbstractListingWidget from "./AbstractListingWidget";
import FixHeaderShowEvent from "../Event/FixHeaderShowEvent";
import FixHeaderHideEvent from "../Event/FixHeaderHideEvent";
import VerifiedTermListingWidget from "./VerifiedTermListingWidget";
import PageRedirectEvent from "../Event/PageRedirectEvent";
/* global FB */
/* global window */

export default class ListingWidget extends AbstractListingWidget {
	bind() {
		this.verifiedTermListingWidget = this.bodyWidget.findByType(VerifiedTermListingWidget)[0] || null;
		super.bind();

		this.btnFacebook = $(".j-listing-fb", this.el);
		this.shareBtns = $(".j-listing-share", this.el);
		this.loadingOverlay = $(".j-loading-overlay", this.el);

		this.app.on(FixHeaderShowEvent, ev => this.onFixHeaderShow(ev));
		this.app.on(FixHeaderHideEvent, ev => this.onFixHeaderHide(ev));
		this.app.on(PageRedirectEvent, () => this.onPageRedirect());
		this.btnFacebook.click(ev => this.onFacebookClick(ev));

		this.shareBtns.floating({
			marginTop: 20 + (this.fixHeaderEl ? this.fixHeaderEl.outerHeight() : 0),
			limit: this.el.offset().top + this.el.outerHeight(),
			zIndex: 30,
		});
	}

	isEnabled() {
		// Povolit listing po skonceni "verified term deals" listingu
		return this.verifiedTermListingWidget === null || this.verifiedTermListingWidget.hasMore() === false;
	}

	// eslint-disable-next-line class-methods-use-this
	onFacebookClick(ev) {
		ev.preventDefault();

		FB.ui({
			// https://developers.facebook.com/docs/sharing/reference/share-dialog
			display: "popup",
			method: "share",
			href: window.location.href,
		});
	}

	onFixHeaderShow(ev) {
		this.fixHeaderEl = ev.header.el;
	}

	onFixHeaderHide() {
		this.fixHeaderEl = null;
	}

	onPageRedirect() {
		this.loadingOverlay.show();
		this.scrollService.bindFloatingInnerElement(this.loadingOverlay, this.el);
	}

	track() {
		const absoluteUrl = this.model.absolutePageUrlPattern.replace(
			"{page}",
			this.model.page,
		) + window.location.search;

		// interni analytika
		this.hitService.trackPageview(absoluteUrl + window.location.hash);
	}
}
