import $ from "jquery";
import Widget from "../../../../Inlined/Widget";
import UserBiscuitEnum from "../../VO/UserBiscuitEnum";

export default class UserEmailVideoWidget extends Widget {
	constructor(el, model, children, fancyboxService, userService, flashMessagesWidget) {
		super(el, model, children);
		this.fancyboxService = fancyboxService;
		this.userService = userService;
		this.flashMessagesWidget = flashMessagesWidget;
	}

	bind() {
		super.bind();

		this.dialog = $(".j-dialog", this.el);
		this.btnDismiss = $(".j-dismiss", this.el);

		this.btnDismiss.click(ev => this.onBtnDismissClick(ev));

		this.openDialog();
	}

	dismiss() {
		this.fancyboxService.close();
	}

	onBtnDismissClick() {
		this.dismiss();
	}

	openDialog() {
		if (!this.dialog.length) {
			return false;
		}

		// Dialog nesmi premazat jiny otevreny dialog
		if (this.fancyboxService.isOpened()) {
			return false;
		}

		// Nezobrazovat flash messsages behem videa
		this.flashMessagesWidget.doNotDisturb(5000);

		this.fancyboxService.open(
			this.dialog,
			{
				afterLoad: () => {
					this.userService.setBiscuit(
						UserBiscuitEnum.EMAIL_VIDEO_BISCUIT,
						1,
						365,
					);
				},
			},
		);
		return true;
	}
}
