
export default class DateService {

	constructor(serverTimestamp) {
		this.serverTimestamp = serverTimestamp;
		this.initialDate = new Date();
	}

	getDate() {
		var elapsed, now;

		now = new Date();
		elapsed = (now.getTime() - this.initialDate.getTime()) / 1000;

		return new Date((this.serverTimestamp + elapsed) * 1000);
	}

}