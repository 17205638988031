import $ from "jquery";
import Widget from "../../../../Inlined/Widget";
import AbstractFilterWidget from "./AbstractFilterWidget";
import PageRedirectEvent from "../../Event/PageRedirectEvent";
/* global window */

export default class FilterDropdownWidget extends Widget {
	constructor(el, model, children, filterService, app) {
		super(el, model, children);
		this.app = app;
		this.filterService = filterService;
	}

	bind() {
		super.bind();
		this.body = $("body");
		this.btnDelete = $(".j-delete", this.el);
		this.dropdownToggle = $("[data-toggle='dropdown']", this.el);
		this.dropdownCover = $(".j-cover", this.el);
		this.modalCloseBtn = $(".j-modal-close", this.el);
		[this.filter] = this.findByType(AbstractFilterWidget);

		if (!this.filter) {
			throw new Error("No filter found inside FilterDropdownWidget!");
		}

		this.btnDelete.on("click", (ev) => {
			FilterDropdownWidget.onBtnDeleteClick(ev);
			this.app.dispatch(new PageRedirectEvent());
		});
		this.dropdownCover.on("click", ev => FilterDropdownWidget.onDropdownCoverClick(ev));
		this.dropdownToggle.on("click", ev => this.onDropdownToggleClick(ev));
		this.modalCloseBtn.on("click", () => this.el.removeClass("open"));
		if (this.isVisible()) {
			this.show();
		} else {
			this.hide();
		}
	}

	hide() {
		this.el.addClass("hide");
	}

	isVisible() {
		return this.model.isVisible !== false;
	}

	onDropdownToggleClick(ev) {
		ev.preventDefault();
		const fixedClass = "body-prevent-scroll";
		const { mobileTravelFilter } = this.model;
		if (mobileTravelFilter) {
			if (this.body.hasClass(fixedClass)) {
				this.body.removeClass(fixedClass);
				this.el.css("z-index", "inherit");
			} else {
				this.body.addClass(fixedClass);
				this.el.css("z-index", 1070);
			}
		}

		setTimeout(
			() => {
				const opened = $(ev.currentTarget).parent().hasClass("open");
				if (opened) {
					this.onDropdownOpen();
					if (this.filter.onDropdownOpen) {
						this.filter.onDropdownOpen();
					}
				}
			},
			0,
		);
	}

	onDropdownOpen() {
		const rect = this.dropdownCover[0].getBoundingClientRect();
		const windowWidth = $(window).width();

		// Dropdown shifted out of the window on the left side
		if (rect.left < 0) {
			const left = parseInt(this.dropdownCover.css("left"), 10);
			this.dropdownCover.css({ left: left + rect.left });
		// Dropdown shifted out of the window on the right side.
		// Do nothing when it's wider than window.
		} else if (rect.right > windowWidth && rect.width < windowWidth) {
			const left = parseInt(this.dropdownCover.css("left"), 10);
			this.dropdownCover.css({ left: left + (windowWidth - rect.right) });
		}
	}

	show() {
		this.el.removeClass("hide");
	}
}

FilterDropdownWidget.onBtnDeleteClick = function onBtnDeleteClick(ev) {
	ev.stopPropagation();

	// When on homepage, removing last filter might lead to //order page from /{filter}/order pattern
	// Ignoring two slashes with preceding ":" so slashes in protocol (https://) are not replaced.
	window.location.href = $(ev.currentTarget).data("href").replace(/([^:])\/\//g, "$1/");
};

FilterDropdownWidget.onDropdownCoverClick = function onDropdownCoverClick(ev) {
	// Propagating click to document body element would close dropdown
	ev.stopPropagation();
};
