/* global window */

export default class UserLocationService {
	constructor(ajaxService, fancyboxService, userService, ajaxWidgetUrl, ajaxUrl) {
		this.ajaxService = ajaxService;
		this.fancyboxService = fancyboxService;
		this.userService = userService;
		this.ajaxWidgetUrl = ajaxWidgetUrl;
		this.ajaxUrl = ajaxUrl;
		this.isSaving = false;
		this.isRemoving = {};

		// Otevrit menu na hardlink s query parametrem citySettings=true
		if (window.location.search.indexOf("locationSettings=true") >= 0) {
			this.openDialog();
		}
	}

	add(data, onSuccess, onError) {
		if (this.isSaving) {
			return;
		}

		this.ajaxService.ajax({
			url: this.ajaxUrl,
			data,
			method: "POST",
		})
			.done((response) => {
				this.isSaving = false;

				if (response.ok) {
					this.userService.setLocationCount(response.locationCount);
					onSuccess(response);
				} else {
					onError(response);
				}
			})
			.fail(() => {
				this.isSaving = false;
				onError();
			});
	}

	remove(data, onSuccess, onError) {
		if (!data.id) {
			return;
		}

		if (this.isRemoving[data.id]) {
			return;
		}

		this.isRemoving[data.id] = true;

		this.ajaxService.ajax({
			url: this.ajaxUrl,
			data,
			method: "DELETE",
		})
			.done((response) => {
				this.isRemoving[data.id] = false;

				if (response.ok) {
					this.userService.setLocationCount(response.locationCount);
					onSuccess(response);
				} else {
					onError(response);
				}
			})
			.fail(() => {
				this.isRemoving[data.id] = false;
				onError();
			});
	}

	openDialog() {
		const url = `${this.ajaxWidgetUrl}${(this.ajaxWidgetUrl.indexOf("?") === -1 ? "?" : "&")}widget_position=modal`;
		this.fancyboxService.openAjax(url);
	}
}
