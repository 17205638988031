import $ from "jquery";
import AES from "crypto-js/aes";
import MD5 from "crypto-js/md5";
import Utf8 from "crypto-js/enc-utf8";
import Widget from "../../../../Inlined/Widget";
import UserVerifiedEvent from "../../Event/UserVerifiedEvent";
import ExitReadyEvent from "../../Event/ExitReadyEvent";
import ItemBannerWidget from "../Item/ItemBannerWidget";
import ItemVoucherWidget from "../Item/ItemVoucherWidget";

/* global window */
/* global document */
/* global location */

export default class AbstractExitWidget extends Widget {
	constructor(el, model, children, app, ajaxService, userService) {
		super(el, model, children);
		this.app = app;
		this.ajaxService = ajaxService;
		this.userService = userService;

		this.loggedClick = false;
		// Jestli se muze presmerovat (a vytvorit uzivatel)
		this.ready = false;
	}

	bind() {
		super.bind();

		const banners = this.getBanners();

		this.spinner = $(".j-spinner", this.el);
		this.setRedirectUrl(this.model.redirectUrl);
		this.setReady(!banners.length);

		this.app.on(ExitReadyEvent, ev => this.onExitReady(ev));
		this.app.on(UserVerifiedEvent, ev => this.onUserVerified(ev));

		if (this.model.logData) {
			const decrypted = AES.decrypt(
				this.model.logData.substring(8),
				MD5(this.model.logData.substring(0, 4)),
				{
					iv: MD5(this.model.logData.substring(4, 8)),
				},
			).toString(Utf8);
			this.model.logData = JSON.parse(decrypted);
		}

		if (this.model.verified && this.model.clickId) {
			setTimeout(() => this.prepare(), 0);
		} else if (this.model.verified) {
			this.onUserVerified();
		}

		if (this.model.clickId) {
			this.log();
		}
	}

	getBanners() {
		let banners = [];
		banners = banners.concat(this.findByType(ItemBannerWidget));
		banners = banners.concat(this.findByType(ItemVoucherWidget));
		return banners;
	}

	// eslint-disable-next-line class-methods-use-this
	getOpenerWindow() {
		let opener = null;
		try {
			if (window.opener.location.href) {
				// eslint-disable-next-line prefer-destructuring
				opener = window.opener;
			}
		} catch (e) {
			// continue anyway
		}
		return opener;
	}

	// Defaultne nic nelogujeme
	// eslint-disable-next-line class-methods-use-this
	log() {
		return 0;
	}

	setRedirectUrl(redirectUrl) {
		this.model.redirectUrl = redirectUrl;

		this.children.forEach((child) => {
			child.setRedirectUrl(redirectUrl);
		});
	}

	onExitReady(ev) {
		this.setRedirectUrl(ev.redirectUrl || this.model.redirectUrl);
		this.setReady(true);

		if (this.model.verified && this.model.clickId) {
			this.prepare();
		} else if (this.model.verified) {
			this.onUserVerified();
		}
	}

	onUserVerified() {
		this.model.verified = true;

		if (!this.ready) {
			return false;
		}

		this.ajaxService.ajax({
			type: "POST",
			url: this.model.ajaxExitUrl,
			data: {
				referrer: document.referrer,
			},
		}).done((response) => {
			if (response.ok) {
				this.model.clickId = response.clickId || this.model.clickId;
				this.model.userId = response.userId || this.model.userId;
				this.model.redirectUrl = response.redirectUrl || this.model.redirectUrl;
				this.model.conversionCookieUrl = response.conversionCookieUrl || this.model.conversionCookieUrl;
				setTimeout(() => this.prepare(), 0);

				const opener = this.getOpenerWindow();
				let openerUserService = null;
				try {
					if (opener) {
						openerUserService = opener.skrz.service.user;
					}
				} catch (e) {
					// continue anyway
				}

				this.userService.onAuthSuccess({}, response);

				if (openerUserService) {
					openerUserService.onAuthSuccess({}, response);
				}
			}
		});
		return true;
	}

	prepare() {
		if (!this.ready) {
			this.spinner.hide();
			return false;
		}

		this.spinner.show();

		// GA codes etc.
		const logDuration = this.log();
		setTimeout(() => this.go(), logDuration);
		return true;
	}

	go() {
		if (this.model.redirectUrl) {
			// eslint-disable-next-line no-restricted-globals
			location.replace(this.model.redirectUrl);
		}
	}

	setReady(ready) {
		if (ready) {
			this.spinner.show();
		} else {
			this.spinner.hide();
		}
		this.ready = ready;
	}
}
