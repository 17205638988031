import $ from "jquery";
import AbstractItemWidget from "./AbstractItemWidget";
import WishCreateEvent from "../../Event/WishCreateEvent";
import WishDeleteEvent from "../../Event/WishDeleteEvent";
/* global __ */
/* global window */

export default class ItemMapWidget extends AbstractItemWidget {
	constructor(
		el,
		model,
		children,
		app,
		hitService,
		dealService,
		itemWatchdogService,
		membersService,
		ajaxService,
		itemPreviewService,
		mobileDetectService,
		flashMessageService,
		itemShareService,
		reservationService,
		itemMapService,
		wishlistService,
	) {
		super(
			el,
			model,
			children,
			app,
			hitService,
			dealService,
			itemWatchdogService,
			membersService,
			ajaxService,
			itemPreviewService,
			mobileDetectService,
			flashMessageService,
			itemShareService,
			reservationService,
			itemMapService,
		);

		this.wishlistService = wishlistService;
	}

	bind() {
		super.bind();

		this.fullBtn = $(".j-full", this.el);
		this.wishBtn = $(".j-wish", this.el);
		this.heart = $(".j-heart", this.el);
		this.heartFull = $(".j-heart-full", this.el);
		this.wishText = $(".j-wish-text", this.el);
		this.wishBtn.click((ev) => { this.onWishBtnClick(ev); });
		this.onChangeFn = (ev) => { this.onChange(ev); };
		this.app.on(WishCreateEvent, this.onChangeFn);
		this.app.on(WishDeleteEvent, this.onChangeFn);

		this.refresh();
	}

	unbind() {
		super.unbind();

		this.app.off(WishCreateEvent, this.onChangeFn);
		this.app.off(WishDeleteEvent, this.onChangeFn);
	}

	begin() {
		// Vynutit prekresleni responzivniho widgetu pri otevreni Fancyboxu
		// Resi problem, ze responzivni widget se prekresli s timeoutem 0 v metode bind(), coz je pozde v tomto pripade
		this.onWindowResize();
	}

	onChange(ev) {
		if (ev.itemId === this.model.id && ev.itemType === this.model.type) {
			this.refresh();
		}
	}

	onShareButtonClick(btn, ev) {
		super.onShareButtonClick(btn, ev);
	}

	onWindowResize(ev) {
		super.onWindowResize(ev);

		// Fuck off Fancybox sizing!
		// Roztahnout na viewport na mobilu / tabletu
		if (this.getDeviceSize() === "xs" || this.getDeviceSize() === "sm") {
			this.el.height($(window).height() - 50);
			this.el.width($(window).width() - 50);
		// Prirozene rozmery jinde
		} else {
			this.el.height("auto");
			this.el.width(this.getDeviceSize() === "lg" ? 850 : 730);
		}
	}

	onWishBtnClick(ev) {
		ev.preventDefault();

		if (this.wishlistService.isInWishlist(this.model.id, this.model.type)) {
			this.wishlistService.removeItemFromWishlists(
				this.model.id,
				this.model.type,
			);
		} else {
			this.wishlistService.openDialog(
				this.model.id,
				this.model.type,
				null,
				this.sourceWidget,
			);
		}
	}

	refresh() {
		this.heart.hide();
		this.heartFull.hide();

		if (this.wishlistService.isInWishlist(this.model.id, this.model.type)) {
			this.heartFull.show();
			this.wishText.text(__("Odebrat ze seznamu"));
		} else {
			this.heart.show();
			this.wishText.text(__("Přidat do seznamu"));
		}
	}
}
