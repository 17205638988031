import $ from "jquery";
import Widget from "../../../../Inlined/Widget";
import UserHistoryCreateEvent from "../../Event/UserHistoryCreateEvent";
import UserHistoryDeleteEvent from "../../Event/UserHistoryDeleteEvent";
import AbstractItemWidget from "../Item/AbstractItemWidget";

export default class UserHistoryWidget extends Widget {
	constructor(el, model, children, app) {
		super(el, model, children);
		this.app = app;
	}

	bind() {
		super.bind();

		this.emptyAlert = $(".j-empty", this.el);

		this.app.on(UserHistoryDeleteEvent, ev => this.onItemsChanged(ev));
		this.app.on(UserHistoryCreateEvent, ev => this.onItemsChanged(ev));

		this.onItemsChanged();
	}

	onItemsChanged() {
		const items = this.findByType(AbstractItemWidget);

		this.emptyAlert.removeClass("hide");

		if (items.length) {
			this.emptyAlert.hide();
		} else {
			this.emptyAlert.show();
		}
	}

	// eslint-disable-next-line class-methods-use-this
	getCampaign() {
		return "history";
	}
}
