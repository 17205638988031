import AbstractListingWidget from "../AbstractListingWidget";
/* global window */

export default class SimilarDealListingWidget extends AbstractListingWidget {
	bind() {
		super.bind();
		this.model.isVisible = false;
		window.sd = this;
	}

	isVisible() {
		this.el.visible(true, true);
	}

	onScroll() {
		super.onScroll();
		const isVisible = this.isVisible();
		this.model.isVisible = isVisible;
	}
}
