import $ from "jquery";
import AbstractFilterFormWidget from "./AbstractFilterFormWidget";
import AbstractFilterWidget from "./AbstractFilterWidget";

export default class TagFilterWidget extends AbstractFilterWidget {
	bind() {
		super.bind();

		this.form = this.findParentByType(AbstractFilterFormWidget) || null;
		if (!this.form) {
			throw new Error("I need AbstractFilterFormWidget parent!");
		}

		this.el.on("click", ".j-more", ev => this.onMoreClick(ev));
	}

	// eslint-disable-next-line class-methods-use-this
	onMoreClick(ev) {
		ev.preventDefault();
		ev.stopPropagation();

		const $button = $(ev.currentTarget);
		const $list = $button.closest(".j-list");

		$(".hide", $list).removeClass("hide");
		$button.hide();
	}

	// Nepridava se do URL jako filtr, ale jako slug trail
	// eslint-disable-next-line class-methods-use-this
	serialize = () => null;
}
