import $ from "jquery";
import ReactLoader from "../ReactLoader";
import AbstractItemWidget from "../../Widget/Item/AbstractItemWidget";
import LeadgenTermSelectEvent from "../../Event/LeadgenTermSelectEvent";

export default class TermsTableWidget extends AbstractItemWidget {
	constructor(
		el,
		model,
		children,
		app,
		hitService,
		dealService,
		itemWatchdogService,
		membersService,
		ajaxService,
		itemPreviewService,
		mobileDetectService,
		flashMessageService,
		itemShareService,
		reservationService,
		filterService,
		termsTableAdmin,
	) {
		super(
			el,
			model,
			children,
			app,
			hitService,
			dealService,
			itemWatchdogService,
			membersService,
			ajaxService,
			itemPreviewService,
			mobileDetectService,
			flashMessageService,
			itemShareService,
			reservationService,
		);
		this.termsTableAdmin = termsTableAdmin;
		this.filterService = filterService;
	}

	bind() {
		super.bind();
		this.onGo = this.onGo.bind(this);
		this.onBeforeGo = this.onBeforeGo.bind(this);
		this.render();
	}

	onBeforeGo(ev) {
		ev.preventDefault();
		this.model.exitUrl = $(ev.currentTarget).data("exit-url");
		super.onBeforeGo(ev);
	}

	onGo(ev, term) {
		if (!this.model.isLeadgen) {
			super.onGo(ev);
			return;
		}
		ev.preventDefault();
		this.app.dispatch(new LeadgenTermSelectEvent(term));
	}

	render() {
		// React widget must be rendered into first sub-div. Otherwise it overwrites close button.
		ReactLoader().then(({ React, ReactDOM, TermsTable }) => {
			ReactDOM.render(
				<TermsTable
					onBeforeGo={this.onBeforeGo}
					onGo={this.onGo}
					admin={this.termsTableAdmin}
					activeFilters={this.filterService.getActiveFilters() || {}}
					{...this.model}
				/>,
				$("div", this.el[0])[0],
			);
		});
	}
}
