import $ from "jquery";
import AbstractMenuWidget from "../AbstractMenuWidget";
import ResponsiveMenuMixin from "./ResponsiveMenuMixin";

/* global dry */

export default class HeaderMenuWidget extends AbstractMenuWidget {
	constructor(el, model, children, app) {
		super(el, model, children, app);
		this.bindResponsiveMenu = ResponsiveMenuMixin.bindResponsiveMenu;
		this.placeResponsiveMenu = ResponsiveMenuMixin.placeResponsiveMenu;
	}

	bind() {
		super.bind();

		this.anchors = $("a", this.el);
		this.bindResponsiveMenu($(".f-menu-mobile"));
		this.container = this.el.closest(".j-container");
		this.dropdownLink = $(".j-item-dropdown-link", this.el);
		this.anchors.click(ev => this.onAnchorClick(ev));
		this.dropdownLink.click(ev => HeaderMenuWidget.onDropdownClick(ev));
		if (this.model.isScroll) {
			this.el.scroll(dry.fn.throttle(ev => this.onScroll(ev), 50));
			this.el.scroll();
		}
		$(".j-item-no-link", this.el).click(ev => ev.preventDefault());
	}

	// eslint-disable-next-line class-methods-use-this
	onAnchorClick(ev) {
		const name = $(ev.currentTarget).text();
		const liElement = $(ev.currentTarget).parent("li.dropdown-mobile");
		// only some li elements have .dropdown-mobile class
		if (liElement) {
			liElement.toggleClass("dropdown-mobile--open");
		}
	}

	onDropdownMouseEnter(dropdown, ev) {
		// na mobilech a tabletech jsou dropdowny vypnute
		if (this.getDeviceSize() === "xs" || this.getDeviceSize() === "sm") {
			return;
		}
		super.onDropdownMouseEnter(dropdown, ev);
	}

	onDeviceSizeChange(ev) {
		const placement = ev.deviceSize === "xs" || ev.deviceSize === "sm" ? "mobile" : "desktop";
		this.placeResponsiveMenu(placement);
	}

	onScroll(ev) {
		const prevIsScrollEnd = this.isScrollEnd;
		// Scroll end or behind the end (momentum scroll stop)
		this.isScrollEnd = ev.currentTarget.scrollWidth - ev.currentTarget.scrollLeft <= ev.currentTarget.clientWidth;

		if (this.isScrollEnd !== prevIsScrollEnd) {
			if (this.isScrollEnd) {
				this.container.addClass("s-scroll-end");
			} else {
				this.container.removeClass("s-scroll-end");
			}
		}
	}

	static onDropdownClick(ev) {
		ev.preventDefault();
		const mobileNavigationBreakpoint = window.matchMedia("(min-width: 1024px)");
		const parent = ev.target.closest(".dropdown");
		const submenu = parent.querySelector(".dropdown-menu") ? parent.querySelector(".dropdown-menu") : null;
		const button = parent.querySelector(".j-mobile-dropdown-btn");
		const btnOpenClass = "btn-dropdown-circle--open";
		const btnVisibleClass = "block";
		if (!mobileNavigationBreakpoint.matches && submenu) {
			if (submenu.classList.contains(btnVisibleClass) && button.classList.contains(btnOpenClass)) {
				submenu.classList.remove(btnVisibleClass);
				button.classList.remove(btnOpenClass);
			} else {
				submenu.classList.add(btnVisibleClass);
				button.classList.add(btnOpenClass);
			}
		}
	}
}
