import AbstractExitWidget from "./AbstractExitWidget";

/* global window */

export default class ExitWidget extends AbstractExitWidget {
	constructor(el, model, children, app, ajaxService, userService, dealService, importItemService) {
		super(el, model, children, app, ajaxService, userService);
		this.dealService = dealService;
		this.importItemService = importItemService;
	}

	log() {
		// proklik byl jiz zaznamenan, nebudeme jej logovat
		if (this.loggedClick) {
			return 0;
		}

		// proklik byl jiz zaznamenan, nebudeme jej logovat
		let loggedClick = false;
		try {
			// eslint-disable-next-line prefer-destructuring
			loggedClick = window.skrz.service.exitAccelerator.loggedClick;
		} catch (e) {
			// continue anyway
		}

		if (loggedClick) {
			return 0;
		}

		// vyhledat service pro logovani v opener window
		const opener = this.getOpenerWindow();
		let openerService = false;
		try {
			if (opener) {
				openerService = opener.skrz.service[this.model.type];
			}
		} catch (e) {
			// continue anyway
		}

		const pageUrl = `${window.location.pathname}${window.location.search}${window.location.hash}`;

		let logDuration = 3000;
		if (openerService) {
			// zalogovat proklik (pageview + e-commerce) v opener window
			logDuration = 0;
			openerService.logClick(this.model, pageUrl);
		} else {
			// zalogovat proklik (pageview + e-commerce) v tomto okne
			let service = null;
			if (this.model.type === "deal") {
				service = this.dealService;
			} else if (this.model.type === "importItem") {
				service = this.importItemService;
			}

			if (service) {
				service.logClick(this.model, pageUrl);
			}
		}

		this.loggedClick = true;

		return logDuration;
	}
}
