export default class UserBiscuitEnum {
	static EMAIL_VIDEO_BISCUIT = "email_video";
	static ITEM_VARIANT_TOOLTIP_BISCUIT = "item_variant_tooltip";
	static JOIN_US = "join_us";
	static TOPIC_DAY_LOGIN = "topic_day_login";
	static CHAT = "chat";
	static KRISTALOVKA_PROMO = "kristalovka";
	static USER_SUBSCRIBE_BOX = "user_subscribe_box";
	static VOUCHER_EXPIRATION = "voucher_expiration";
	static NOTIFICATION_SUBCRIBE = "notification_subscribe";
	static COOKIES_ALLOWED_MARKETING = "cookies_allowed_marketing";
	static COOKIES_ALLOWED_ANALYTICS = "cookies_allowed_analytics";
}
