import $ from "jquery";
import suggestEmail from "./User/SuggestEmail";
import { trans } from "../Service/I18NService";

/* global __ */

const ValidationMixin = {

	bindValidation() {
		this.el.on("mousedown", ".j-fix-input", (ev) => { this.onInputFix(ev); });
	},

	getEmailMessage(ruleParameter, element, autoReplaceSuggestEmail = false) {
		const email = element.value;

		if (email.indexOf("@") === -1) {
			return __("Nezadali jste povinný znak @. Prosím, doplňte ho.");
		}

		// Suggest fixed typo in email when email is invalid
		const suggestedEmail = suggestEmail(email);

		if (suggestedEmail && !autoReplaceSuggestEmail) {
			return trans(
				__("Nemáte na mysli %suggestedEmail%?"),
				{
					"%suggestedEmail%": `<a
						class='j-fix-input'
						href='#'
						data-name="${element.name}"
						data-value='${suggestedEmail}'
					>${suggestedEmail}</a>`,
				},
			);
		}

		return __("Prosím, zadejte platný e-mail.");
	},

	onInputFix(ev) {
		ev.preventDefault();

		const name = $(ev.currentTarget).data("name");
		const value = $(ev.currentTarget).data("value");
		const $input = $(`:input[name="${name}"]`, this.el);

		if (!$input.length) {
			return;
		}

		const validator = $($input[0].form).data("validator");

		$input
			.val(value)
			.each((i) => {
				validator.element($input[i]);
			});
	},

	onValidationSuccess($label, element, autoReplaceSuggestEmail = false) {
		const elementRules = Object.keys($(element).rules());
		const emailRules = ["email", "emailHtml5"];
		// Detect email field by matching its validation rules
		const isEmailField = elementRules.find(rule => emailRules.indexOf(rule) >= 0) !== undefined;

		// Suggest email when value is valid but contains typo
		if (isEmailField) {
			const email = element.value;
			const suggestedEmail = suggestEmail(email);

			if (suggestedEmail && !autoReplaceSuggestEmail) {
				$label.html(trans(
					__("Nemáte na mysli %suggestedEmail%?"),
					{
						"%suggestedEmail%": `<a
						class='j-fix-input'
						href='#'
						data-name="${element.name}"
						data-value='${suggestedEmail}'
					>${suggestedEmail}</a>`,
					},
				));
			}
		}
	},

};

export default ValidationMixin;
