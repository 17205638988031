import $ from "jquery";
import Widget from "../../../../../Inlined/Widget";
import UserAuthStateEnum from "../../../VO/UserAuthStateEnum";
import UserAuthStateEvent from "../../../Event/UserAuthStateEvent";
import UserAuthResumeEnum from "../../../VO/UserAuthResumeEnum";
import checkIcon from "../../../Styles/def/img/icons/check.svg";
import * as utils from "../../../utils";

/* global dry */
/* global fbq */
/* global window */

export default class CarsContestWidget extends Widget {
	constructor(el, model, children, ajaxService, flashMessageService, userService, fancyboxService) {
		super(el, model, children);
		this.userAuthResume = UserAuthResumeEnum.CONTEST_CARS;
		this.ajaxService = ajaxService;
		this.flashMessageService = flashMessageService;
		this.userService = userService;
		this.fancyboxService = fancyboxService;
	}

	bind() {
		super.bind();

		this.model.actionAfterAuth = null;

		this.btnToggleFaq = $(".toggle-faq", this.el);
		this.btnScrollToTerms = $(".j-scroll-terms", this.el);
		this.btnJoin = $(".j-btn-join", this.el);
		this.btnJoinSpinner = $(".j-spinner", this.btnJoin);
		this.btnSubscribe = $(".j-btn-subscribe", this.el);
		this.btnSubscribeSpinner = $(".j-spinner", this.btnSubscribe);
		this.btnEmailError = $(".j-emailerror", this.el);
		this.terms = $(".j-terms", this.el);
		this.termsCheckbox = $(".j-terms-check", this.el);
		this.faqBox = $("#faq", this.el);

		this.btnScrollToTerms.click(ev => this.onBtnScrollToTerms(ev));
		this.btnJoin.click(ev => this.onBtnJoinClick(ev));
		this.btnSubscribe.click(ev => this.onBtnSubscribeClick(ev));
		this.btnEmailError.click(ev => this.onBtnEmailErrorClick(ev));
		this.btnToggleFaq.click(ev => this.toggleFaq(ev));
		this.userService.register(this.userAuthResume, payload => this.onUserAuthResume(payload));
		this.userService.on(UserAuthStateEvent, ev => this.onUserAuthState(ev));
	}

	doAuth(payload) {
		this.userService.setLoginId(this.userAuthResume);
		this.userService.setPayload(payload);
		this.userService.setInternalTrackingParameters(this.getInternalTrackingParameters());
		this.userService.doLogin(() => this.btnJoinSpinner.hide());
	}

	doAuthAndJoin(btn) {
		const $join = $(btn).closest(".j-join");
		const $termCheckbox = $(".j-terms-check", $join);
		const $terms = $(".j-terms", $join);

		this.terms.removeClass("text-danger");

		if (!$termCheckbox.is(":checked")) {
			$terms.addClass("text-danger");
			$terms.siblings(".confirm-conditions").remove();
			$("<strong class=\"confirm-conditions\">&darr; Potvrďte prosím souhlas s podmínkami soutěže</strong>")
				.insertBefore($terms);
			return;
		}

		if (!this.userService.isRegistered()) {
			this.btnJoinSpinner.show();
			this.model.actionAfterAuth = "join";

			this.doAuth(
				{
					action: this.model.actionAfterAuth,
					silentLogin: true,
				},
				() => this.btnJoinSpinner.hide(),
			);
		} else {
			this.join();
		}
	}

	doAuthAndSubscribe() {
		if (!this.userService.isRegistered()) {
			this.btnSubscribe.show();
			this.model.actionAfterAuth = "subscribe";

			this.doAuth(
				{
					action: this.model.actionAfterAuth,
					silentLogin: true,
				},
				() => this.btnSubscribe.hide(),
			);
		} else {
			this.subscribe();
		}
	}

	getCampaign() {
		return dry.str.undescorize(this.constructor.name);
	}

	join() {
		this.btnJoinSpinner.show();
		this.ajaxService.ajax({
			method: "GET",
			url: this.model.ajaxStartUserProfileUrl,
		})
			.done((response) => {
				if (!response.ok) {
					this.btnJoinSpinner.hide();
					if (response.message) {
						this.flashMessageService.showMessage(response.message, "error");
					} else {
						this.flashMessageService.showMessage("Při komunikaci se serverem došlo k chybě.", "error");
					}
					return;
				}

				if (!response.competing) {
					this.btnJoinSpinner.hide();
					this.flashMessageService.showMessage("Nemohli jsme vás zapojit do soutěže.", "warning");
					return;
				}

				if (response.redirectUrl) {
					window.location.replace(response.redirectUrl);
				} else {
					window.location.replace("/stastne-losy");
				}
			})
			.fail(() => {
				this.btnJoinSpinner.hide();
				this.flashMessageService.showMessage("Při komunikaci se serverem došlo k chybě.", "error");
			});
	}

	openEmailErrorDialog() {
		this.fancyboxService.openAjax(this.model.ajaxEmailErrorUrl);
	}

	onBtnScrollToTerms(ev) {
		ev.preventDefault();
		this.scrollToTerms();
	}

	onBtnJoinClick(ev) {
		ev.preventDefault();
		this.doAuthAndJoin(ev.currentTarget);
	}

	onBtnEmailErrorClick(ev) {
		ev.preventDefault();
		this.openEmailErrorDialog();
	}

	toggleFaq(ev) {
		ev.preventDefault();
		this.faqBox.toggle();
	}

	onBtnSubscribeClick(ev) {
		ev.preventDefault();
		this.doAuthAndSubscribe();
	}

	onUserAuthResume(payload) {
		if (payload.action === "join") {
			this.join();
		} else if (payload.action === "subscribe") {
			this.subscribe();
		}
	}

	onUserAuthState(ev) {
		if (ev.newState === UserAuthStateEnum.COMPLETE_STATE) {
			if (this.model.actionAfterAuth === "join") {
				this.join();
			} else if (this.model.actionAfterAuth === "subscribe") {
				this.subscribe();
			}

			this.model.actionAfterAuth = null;
		}
	}

	// eslint-disable-next-line class-methods-use-this
	scrollToTerms() {
		const $terms = $("#terms");
		if (!$terms.length) {
			return;
		}

		const y = $terms.offset().top;
		$("body,html").animate({ scrollTop: y }, 100);
	}

	subscribe() {
		this.btnSubscribeSpinner.show();
		this.userService
			.setSettings({ emailing_push_email_frequency: "medium", only_up: true })
			.then(() => {
				this.btnSubscribeSpinner.hide();
				this.btnSubscribe
					.prop("disabled", true)
					.html(`Upozornění zapnuto <svg class='icon' aria-hidden='true'>
						<use xlink:href='${utils.getIconSpriteUrl(checkIcon)}'></use>
					</svg>`);
				this.flashMessageService.showMessage(
					"Hotovo. Dáme vám vědět, jakmile spustíme další soutěž.",
					"success",
				);
			})
			.catch(() => {
				this.btnSubscribeSpinner.hide();
				this.flashMessageService.showMessage("Při komunikaci se serverem došlo k chybě.", "error");
			});
	}
}
