import $ from "jquery";
import Widget from "../../../Inlined/Widget";
import TrackingMixin from "./TrackingMixin";

export default class LinksWidget extends Widget {
	constructor(el, model, children) {
		super(el, model, children);

		this.bindTracking = TrackingMixin.bindTracking.bind(this);
		this.attachHitParams = TrackingMixin.attachHitParams.bind(this);
		this.onMouseIn = TrackingMixin.onMouseIn.bind(this);
	}

	bind() {
		super.bind();

		this.items = $(".j-item", this.el);
		this.links = $(".j-link", this.el);
		this.moreBtn = $(".j-more", this.el);

		this.moreBtn.click(ev => this.onMoreClick(ev));

		this.items.filter(".bye")
			.removeClass("bye")
			.hide();

		this.bindTracking(this.links);
	}

	onMoreClick(ev) {
		ev.preventDefault();

		this.moreBtn.remove();
		this.items.show();
	}
}
