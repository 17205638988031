import $ from "jquery";
import AbstractWatchdogSubscribeWidget from "./AbstractWatchdogSubscribeWidget";
import WatchdogResumeEnum from "../../VO/WatchdogResumeEnum";
import FixHeaderShowEvent from "../../Event/FixHeaderShowEvent";
import FixHeaderHideEvent from "../../Event/FixHeaderHideEvent";
import TooltipEvent from "../../Event/TooltipEvent";
import { trans } from "../../Service/I18NService";
import checkIcon from "../../Styles/def/img/icons/check.svg";
import * as utils from "../../utils";

/* global window */
/* global __ */
/* global dry */

export default class WatchdogSubscribeWidget extends AbstractWatchdogSubscribeWidget {
	constructor(
		el,
		model,
		children,
		flashMessageService,
		watchdogService,
		userService,
		app,
		ajaxService,
	) {
		super(el, model, children, flashMessageService, watchdogService, userService);
		this.app = app;
		this.ajaxService = ajaxService;

		this.watchdogResume = WatchdogResumeEnum.LISTING_RESUME;
	}

	bind() {
		super.bind();

		if (this.model.recommendTeleportClass) {
			this.recommendTeleport = $(`.${this.model.recommendTeleportClass}`); // intentionally no this.el
			this.recommendTeleport.slideUp(0);
			this.recommendTeleportShown = false;
		}

		this.model.modal = false;
		this.model.tooltipContent = null;
		this.model.tooltipType = "hover";
		this.model.visible = true;

		this.isTravelTop = this.model.position === "travel_top";

		this.travelTooltip = $(".j-watchdog-btn-tooltip", this.el);

		this.el.on("click", ".j-btn-login", ev => this.onBtnLoginClick(ev));
		this.el.on("click", ".j-btn-closetooltip", ev => this.onCloseTooltipBtnClick(ev));
		this.app.on(FixHeaderShowEvent, () => this.onFixHeaderShow(true));
		this.app.on(FixHeaderHideEvent, () => this.onFixHeaderHide(false));
		this.app.on(TooltipEvent, ev => this.onTooltipEvent(ev));


		this.btn.tooltip({
			animation: false,
			html: true,
			placement: () => this.getTooltipPlacement(),
			// eslint-disable-next-line max-len
			template: "<div class=\"tooltip tooltip-dropdown tooltip-large tooltip-light\" role=\"tooltip\" data-cy=\"tooltip-dropdown\"><div class=\"tooltip-arrow\"></div><div class=\"tooltip-inner\"></div></div>",
			title: () => this.model.tooltipContent,
			trigger: "manual",
		});

		this.refresh();

		setTimeout(() => this.showAutowatchdogTooltip(), 3000);
		setTimeout(() => this.showWatchdogCountTooltip(), this.model.hasManualWatchdogs ? 30000 : 5000);
	}

	getTooltipPlacement() {
		const clientRect = this.btn[0].getBoundingClientRect();
		const placeOnRight = $(window).width() - clientRect.right;
		const tooltipWidth = 275;
		const tooltipPadding = 2 * 10;
		if (((tooltipWidth + tooltipPadding) / 2) + 10 <= (clientRect.width / 2) + placeOnRight) {
			return "bottom";
		}
		return "left";
	}

	getAutowatchdogTooltip() {
		return `<div style='width: ${this.getDeviceSize() === "md" || this.getDeviceSize() === "lg" ? 250 : 205}px;'>
			<a class='j-btn-closetooltip close' href='#'></a>
			<h5 class='text-success m0'>${__("Hlídání spuštěno!")}</h5>
			<ul class='checkmarks checkmarks-success text-left t-small mb0'>
				<li>
					<svg class='icon checkmarks-checkmark' aria-hidden='true'>
						<use xlink:href='${utils.getIconSpriteUrl(checkIcon)}'/>
					</svg>
					${__("Zapnuli jsme ti hlídání nových nabídek")}
				</li>
				<li>
					<svg class='icon checkmarks-checkmark' aria-hidden='true'>
						<use xlink:href='${utils.getIconSpriteUrl(checkIcon)}'/>
					</svg>
					${__("Pokud tě nabídky nezaujmou, hlídání se zase samo vypne")}
				</li>
				<li>
					<svg class='icon checkmarks-checkmark' aria-hidden='true'>
						<use xlink:href='${utils.getIconSpriteUrl(checkIcon)}'/>
					</svg>${trans(
		__("Hlídání můžeš %linkStart%kdykoliv vypnout%linkEnd%"),
		{
			"%linkStart%": "<a class='text-base link j-btn-turnoff' href='#'>",
			"%linkEnd%": "</a>",
		},
	)}
				</li>
			</ul>
			${!this.userService.isLoggedIn() || this.userService.isAnonymous() ? `
				<p class='mb0'>
					<button class='btn btn-small btn-info j-btn-login'>${__("Zasílat e-mailem")}</button>
				</p>` : ""}
		</div>`;
	}

	getAnonymousTurnOnTooltip() {
		// TRANSLATORS: Motivace pro uživatele zapnout si hlídání slev e-mailem
		// eslint-disable-next-line max-len
		const texts = (__("Hlídání je zapnuto.%newLine%Nelze ale zaslat upozornění o nových slevách!%newLine%Nastavte si upozornění, %tagStart%jinak Vám nové slevy utečou%tagEnd%."))
			.split("%newLine%")
			.map(text => (trans(text, { "%tagStart%": "<strong>", "%tagEnd%": "</strong>" })));

		return `<div style='width:${this.getDeviceSize() === "md" || this.getDeviceSize() === "lg" ? 250 : 205}px;'>
			<a class='j-btn-closetooltip close' href='#'></a>
			<h5 class='text-success m0'>${texts[0]}</h5>
			<p class='text-left'><strong class='text-danger'>${texts[1]}</strong> ${texts[2]}</p>
			<p class='mb0'>
				<button class='btn btn-small btn-info j-btn-login'>${__("Zasílat e-mailem")}</button>
			</p>
		</div>`;
	}

	getAnonymousTurnOffTooltip() {
		// TRANSLATORS: Motivace pro uživatele zapnout si hlídání slev e-mailem
		// eslint-disable-next-line max-len
		const texts = (__("Hlídání je zapnuto.%newLine%Nelze ale zaslat upozornění o nových slevách!%newLine%Nastavte si upozornění, %tagStart%jinak Vám nové slevy utečou%tagEnd%."))
			.split("%newLine%")
			.map(text => (trans(text, { "%tagStart%": "<strong>", "%tagEnd%": "</strong>" })));

		return `<div style='width:${this.getDeviceSize() === "md" || this.getDeviceSize() === "lg" ? 250 : 205}px;'>
			<h5 class='text-success m0'>${texts[0]}</h5>
			<p class='text-left'><strong class='text-danger'>${texts[1]}</strong> ${texts[2]}</p>
			<p class='mb0'>
				<button class='btn btn-small btn-info j-btn-login'>${__("Zasílat e-mailem")}</button>
			</p>
		</div>`;
	}

	getBeforeTurnonTooltip() {
		if (this.model.entity.topicday_id !== undefined && this.model.topicDayPublished === false) {
			// eslint-disable-next-line max-len
			const text = (__("Po zapnutí Vás upozorníme ve chvíli spuštění akcí a dále na nové nabídky, které se na této stránce objeví."));
			return `<div style='width: 200px;'>${text}</div>`;
		}
		const text = __("Po zapnutí Vás budeme upozorňovat na nové nabídky, které se objeví na této stránce.");
		return `<div style='width: 170px;'>${text}</div>`;
	}

	getTurnOnTooltip() {
		return `<div style='width:${this.getDeviceSize() === "md" || this.getDeviceSize() === "lg" ? 250 : 205}px;'>
			<a class='j-btn-closetooltip close' href='#'></a>
			<h5 class='text-success m0'>${__("Hlídání spuštěno!")}</h5>
			<ul class='checkmarks checkmarks-success text-left t-smaller mb0'>
				${this.model.title ? `
					<li>
						<svg class='icon checkmarks-checkmark' aria-hidden='true'>
							<use xlink:href='${utils.getIconSpriteUrl(checkIcon)}'/>
						</svg>
						<div class='muted'>${__("Hlídáme nové nabídky:")}</div>
						<strong>${dry.str.escapeHtml(this.model.title)}</strong>
					</li>`
		: ""}
				${this.userService.isLoggedIn() && !this.userService.isAnonymous() && this.userService.getEmail() ? `
					<li>
						<svg class='icon checkmarks-checkmark' aria-hidden='true'>
							<use xlink:href='${utils.getIconSpriteUrl(checkIcon)}'/>
						</svg>
						<div class='muted'>
							${trans(__("Upozornění zašleme na: %email%"), { "%email%": "" }).trim()}
						</div>
						<strong>${dry.str.escapeHtml(this.userService.getEmail())}</strong>
					</li>`
		: ""}
				${this.model.entity.topicday_id !== undefined && this.model.topicDayPublished === false ? `
					<li class='t-small'>
						<svg class='icon checkmarks-checkmark' aria-hidden='true'>
							<use xlink:href='${utils.getIconSpriteUrl(checkIcon)}'/>
						</svg>
						${__("Pošleme e-mail ve chvíli spuštění akcí a dále když se objeví nové nabídky")}
					</li>`
		: `
					<li class='t-small'>
						<svg class='icon checkmarks-checkmark' aria-hidden='true'>
							<use xlink:href='${utils.getIconSpriteUrl(checkIcon)}'></use>
						</svg>
						${__("Pošleme e-mail, když se objeví nové nabídky")}
					</li>
				`}
				<li class='t-small'>
					<svg class='icon checkmarks-checkmark' aria-hidden='true'>
						<use xlink:href='${utils.getIconSpriteUrl(checkIcon)}'></use>
					</svg>
					${__("Hlídání můžete kdykoliv vypnout")}
				</li>
			</ul>
		</div>`;
	}

	getTravelTooltip() {
		return `
			<h3 class='text-success mbs mt0 bold'>${__("Hlídání spuštěno!")}</h3>
				${this.model.title ? `
					<div class="mbs">
						<div>
							${__("Hlídáme nové nabídky:")}
						</div>
						<strong>${dry.str.escapeHtml(this.model.title)}</strong>
					</div>`
		: ""}
				${this.userService.isLoggedIn() && !this.userService.isAnonymous() && this.userService.getEmail() ? `
					<div class="mbs">
						<div>
							${trans(__("Upozornění zašleme na: %email%"), { "%email%": "" }).trim()}
						</div>
						<strong>${dry.str.escapeHtml(this.userService.getEmail())}</strong>
					</div>`
		: ""}
				${this.model.entity.topicday_id !== undefined && this.model.topicDayPublished === false ? `
					<p>
						${__("Pošleme e-mail ve chvíli spuštění akcí a dále když se objeví nové nabídky")}
					</p>
					`
		: ""}
				<p>
					${__("Hlídání můžete kdykoliv vypnout")}
				</p>
				<a href='#' class='btn btn-success border-box w-100 j-btn-closetooltip'>${__("OK")}</a>
			`;
	}

	// eslint-disable-next-line class-methods-use-this
	checkWatchdogId(watchdogId) {
		return watchdogId === WatchdogResumeEnum.LISTING_RESUME
			|| watchdogId === WatchdogResumeEnum.LISTING_END_RESUME;
	}

	logBtnClick() {

	}

	logWatchdogSet(interaction) {

	}

	hide() {
		if (!this.model.visible) {
			return;
		}

		this.model.visible = false;
		this.el.hide();
		this.refresh();
	}

	show() {
		if (this.model.visible) {
			return;
		}

		this.model.visible = true;
		this.el.show();
		this.refresh();
	}

	onBtnClick(ev) {
		super.onBtnClick(ev);

		this.model.isAutowatchdogTooltipShown = false;
		this.refresh();
	}

	onBtnLoginClick(ev) {
		ev.preventDefault();

		this.logBtnClick();
		this.turnOn(true);
	}

	onCloseTooltipBtnClick(ev) {
		ev.preventDefault();

		if (this.model.tooltipName === "anonymousTurnOn") {
			this.model.isAnonymousTurnOnTooltipShown = false;
		} else if (this.model.tooltipName === "turnOn") {
			this.model.isTurnOnTooltipShown = false;
		}

		if (this.isTravelTop) {
			this.travelTooltip.hide();
			this.toggleTravelTooltip(true);
		}

		this.model.dropdownHover = false;
		this.model.modal = false;
		this.refresh();
	}

	onFixHeaderShow() {
		this.model.fixedHeaderShown = true;
		this.refresh();
	}

	onFixHeaderHide() {
		this.model.fixedHeaderShown = false;
		this.refresh();
	}

	onResume(payload) {
		super.onResume(payload);

		this.alert();
	}

	onTooltipEvent(ev) {
		if (ev.tooltip === "watchdogCount" && !ev.show) {
			this.model.isWatchdogCountTooltipShown = false;
			this.model.isWatchdogCountTooltip = false;
			this.refresh();
		}
	}

	toggleTravelTooltip(isActive) {
		if (isActive) {
			this.travelTooltip.hide();
			this.travelTooltip.empty();
		} else {
			this.travelTooltip.show();
			this.travelTooltip.html(this.getTravelTooltip());
		}
	}


	onWatchdogRequest(ev) {
		if (!this.checkWatchdogId(ev.watchdogId)) {
			return;
		}
		super.onWatchdogRequest(ev);
	}

	onWatchdogSet(ev) {
		if (!this.checkWatchdogId(ev.watchdogId)) {
			return;
		}

		this.model.isTurnOnTooltipShown = false;
		this.model.isAnonymousTurnOnTooltipShown = false;

		// Pokud zapnuti watchdoga bylo vyvolano timto widgetem
		if (ev.sourceWidget === this && ev.active) {
			// Bublina: watchdog zapnut pro registrovaneho uzivatele
			if (this.userService.isLoggedIn() && !this.userService.isAnonymous()) {
				this.model.isTurnOnTooltipShown = true;
				this.model.modal = true;
				// Bublina: anonymni watchdog zapnut
			} else if (this.userService.isLoggedIn() && this.userService.isAnonymous()) {
				this.model.isAnonymousTurnOnTooltipShown = true;
				this.model.modal = true;
			}
		}

		// Pokud zapnuti watchdoga bylo vyvolano timto watchdogem
		if (ev.watchdogId === this.watchdogResume) {
			this.showRecommendations(ev);
		}

		super.onWatchdogSet(ev);
	}

	refresh() {
		super.refresh();
		const isTouchDevice = this.getDeviceSize() === "xs" || this.getDeviceSize() === "sm";
		this.model.prevTooltipContent = this.model.tooltipContent;

		if (this.isTravelTop
			&& this.model.active
			&& this.model.isTurnOnTooltipShown
		) {
			this.toggleTravelTooltip(!this.model.active);
		}

		if (this.model.visible) {
			if (this.model.modal
				&& this.model.active
				&& this.model.isTurnOnTooltipShown
				&& this.userService.isLoggedIn()
				&& !this.userService.isAnonymous()
			) {
				// Bublina: watchdog zapnut pro registrovaneho uzivatele
				this.model.tooltipName = "turnOn";
				if (!this.isTravelTop) {
					this.model.tooltipContent = this.getTurnOnTooltip();
				}
				this.model.tooltipType = "modal";
			} else if (this.model.modal
				&& this.model.active
				&& this.model.isAnonymousTurnOnTooltipShown
				&& this.userService.isLoggedIn()
				&& this.userService.isAnonymous()
			) {
				// Bublina: anonymni watchdog zapnut
				this.model.tooltipName = "anonymousTurnOn";
				this.model.tooltipContent = this.getAnonymousTurnOnTooltip();
				this.model.tooltipType = "modal";
			} else if (this.model.modal && this.model.active && this.model.isAutowatchdogTooltipShown) {
				// Bublina: byl zapnut autowatchdog
				this.model.tooltipName = "autowatchdog";
				this.model.tooltipContent = this.getAutowatchdogTooltip();
				this.model.tooltipType = "modal";
			} else if (this.model.modal
				&& !this.model.active
				&& this.model.isWatchdogCountTooltipShown
				&& (
					(!this.model.inFixedHeader && !this.model.fixedHeaderShown)
					|| (this.model.inFixedHeader && this.model.fixedHeaderShown)
				)
			) {
				// Bublina: tyto slevy si hlida X lidi
				this.model.tooltipName = "watchdogCount";
				this.model.tooltipContent = this.model.watchdogCountText;
				this.model.tooltipType = "modal";
			} else if (this.model.dropdownHover
				&& this.model.active
				&& (!this.userService.isLoggedIn()
					|| this.userService.isAnonymous()
				)
				&& !isTouchDevice
			) {
				// Bublina: pred vypnutim anonymniho watchdoga
				this.model.tooltipName = "anonymousTurnOff";
				this.model.tooltipContent = this.getAnonymousTurnOffTooltip();
				this.model.tooltipType = "dropdown";
			} else if (this.model.hover && !this.model.active && !isTouchDevice) {
				// Bublina: pred zapnutim watchdoga
				this.model.tooltipName = "beforeTurnOn";
				this.model.tooltipContent = this.getBeforeTurnonTooltip();
				this.model.tooltipType = "hover";
			} else {
				this.model.tooltipContent = null;
			}
		} else {
			this.model.tooltipContent = null;
		}

		if (!this.model.modal && this.model.tooltipType === "modal") {
			// Skryti modal tooltipu
			this.model.tooltipContent = null;
		} else if (!this.model.dropdownHover && this.model.tooltipType === "dropdown") {
			// Skryti dropdown tooltipu pri sjeti mysi z dropdownu
			this.model.tooltipContent = null;
		} else if (!this.model.hover && this.model.tooltipType === "hover") {
			// Skryti tooltipu pri sjeti mysi z tlacitka
			this.model.tooltipContent = null;
		}

		if (this.model.tooltipContent) {
			// Prekreslit tooltip jen kdyz je potreba.
			// Nevhodnym prekreslovanim se muze narusit zachytavani udalosti na prvcich uvnitr tooltipu.
			if (this.model.prevTooltipContent !== this.model.tooltipContent) {
				this.btn.tooltip("show");
			}
		} else {
			this.btn.tooltip("hide");
		}
	}


	showAutowatchdogTooltip() {
		if (!this.model.isAutowatchdogTooltip) {
			return;
		}

		this.model.isAutowatchdogTooltipShown = true;
		this.model.modal = true;
		this.refresh();
	}

	showRecommendations(ev) {
		if (ev.watchdogId === this.watchdogResume
			&& ev.active
			&& ev.filterId
			&& this.model.recommendTeleportClass
			&& !this.recommendTeleportShown
		) {
			this.recommendTeleportShown = true;

			this.ajaxService.ajax({
				type: "GET",
				url: this.model.ajaxRecommendUrl,
				data: { filterId: ev.filterId },
			})
				.done((response) => {
					if (response.ok && response.html) {
						this.recommendTeleport.empty();
						const renderedHtml = this.app.render(response.html, this);
						this.setChildren((this.children || []).concat(renderedHtml.widgets));
						this.recommendTeleport.append(renderedHtml.elements);
						this.recommendTeleport.slideDown("slow");
					}
				})
				.fail(() => {
					// do nothing
				});
		}
	}

	showWatchdogCountTooltip() {
		if (!this.model.isWatchdogCountTooltip) {
			return;
		}
		this.model.isWatchdogCountTooltipShown = true;
		this.model.wasWatchdogCountTooltipShown = true;
		this.model.modal = true;
		this.refresh();

		// Skryt po 10 sekundach
		setTimeout(() => {
			this.app.dispatch(new TooltipEvent("watchdogCount", false));
		}, 10000);
	}
}
