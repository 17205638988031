import Widget from "../../../Inlined/Widget";
import ListingLoadedEvent from "../Event/ListingLoadedEvent";
import ListingWidget from "./ListingWidget";

export default class ListingFooterWidget extends Widget {
	constructor(el, model, children, app) {
		super(el, model, children);
		this.app = app;
	}

	bind() {
		super.bind();
		this.app.on(ListingLoadedEvent, ev => this.onListingLoaded(ev));
	}

	onListingLoaded(ev) {
		if (!ev.listing) {
			return;
		}

		if (!(ev.listing instanceof ListingWidget)) {
			return;
		}

		if (!ev.listing.hasMore()) {
			this.el.show();
		} else {
			this.el.hide();
		}
	}
}
