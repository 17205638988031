import $ from "jquery";
import Widget from "../../../Inlined/Widget";

/* global window */
/* global dry */

export default class BackToTopWidget extends Widget {
	bind() {
		super.bind();

		this.btn = $(".j-btn", this.el);

		this.btn.click(ev => this.onBtnClick(ev));
		this.btn.tooltip({
			placement: "top",
			container: "body",
		});
		$(window).scroll(dry.fn.throttle(() => this.onScroll()), 50);
		setTimeout(() => this.onScroll(), 0);
	}

	backToTop() {
		this.btn.tooltip("hide");
		$("body,html").animate({ scrollTop: 0 }, 400);
	}

	hide() {
		this.el
			.stop()
			.fadeOut(100);
	}

	onBtnClick(ev) {
		ev.preventDefault();
		this.backToTop();
	}

	onScroll() {
		if ($(window).scrollTop() > 100) {
			this.show();
		} else {
			this.hide();
		}
	}

	show() {
		this.el
			.stop()
			.fadeIn(150);
	}
}
