import $ from "jquery";
import Widget from "./../../../../Inlined/Widget";

export default class ZoneMapWidget extends Widget {

	constructor(el, model, children, mobileDetectService) {
		super(el, model, children);

		this.mobileDetectService = mobileDetectService;
	}

	bind() {
		super.bind();

		this.bubbles = $(".j-bubble", this.el);
		this.flags = $(".j-flag", this.el);
		this.markers = $(".j-marker", this.el);

		this.bubbles.click((ev) => { this.onBubbleClick(ev) });
		this.markers.click((ev) => { this.onMarkerClick(ev) });
		this.markers.mouseenter((ev) => { this.onMarkerClick(ev) });
		$(document.body).click((ev) => { this.onBodyClick(ev) });
	}

	onBodyClick(ev) {
		this.markers.removeClass("s-click");
	}

	onBubbleClick(ev) {
		var $marker = $(ev.currentTarget).closest(".j-marker");

		if ($marker.length) {
			window.location.href = $marker[0].href;
		}
	}

	onMarkerClick(ev) {
		// Na desktopu lze kliknout na marker
		if (this.mobileDetectService.isDesktop()) {
			return;
		}

		// Na mobilu a tabletu zakazeme navigaci pri hoveru nebo kliku na marker
		// Navigace az pri kliku na bublinu
		ev.preventDefault();
		ev.stopPropagation();

		// Spinavy hack jak zavrit markery ze vsech map
		$(".j-marker").removeClass("s-click");
		$(ev.currentTarget).addClass("s-click");
	}
}
