import $ from "jquery";
import Widget from "../../../Inlined/Widget";
import FlashMessageHideEvent from "../Event/Widget/FlashMessageHideEvent";

export default class FlashMessageWidget extends Widget {
	constructor(el, model, children) {
		super(el, model, children);
		this.hideDelay = 4000;
	}

	bind() {
		super.bind();

		this.$btnClose = $(".j-close", this.el);
		this.hidden = true;

		this.$btnClose.click(ev => this.onBtnCloseClick(ev, this));
	}

	getText() {
		return this.model.text;
	}

	getTitle() {
		return this.model.title;
	}

	hide() {
		this.el
			.removeClass("in")
			.addClass("out");

		setTimeout(() => this.el.remove(), 150);
		this.hidden = true;
		this.dispatch(new FlashMessageHideEvent(this));
		return true;
	}

	isModal() {
		return !!this.model.modal;
	}

	onBtnCloseClick(ev) {
		ev.preventDefault();
		this.hide();
	}

	show() {
		clearTimeout(this.hideT);

		this.el
			.removeClass("out")
			.addClass("in");
		this.hidden = false;

		if (!this.model.modal) {
			this.hideT = setTimeout(() => this.hide(), this.hideDelay);
		}

		return true;
	}
}
