import $ from "jquery";
import Widget from "../../../Inlined/Widget";

/* global window */

export default class AdultWarningWidget extends Widget {
	constructor(el, model, children, fancyboxService, ajaxService, domain) {
		super(el, model, children);
		this.fancyboxService = fancyboxService;
		this.ajaxService = ajaxService;
		this.domain = domain;
	}

	bind() {
		super.bind();

		this.dialog = $(".j-dialog", this.el);
		this.btnDismiss = $(".j-dismiss", this.el);
		this.btnGo = $(".j-go", this.el);

		this.btnDismiss.click(ev => this.onBtnDismissClick(ev));
		this.btnGo.click(ev => this.onBtnGoClick(ev));

		this.openDialog();
	}

	dismiss() {
		if (this.model.redirectUrl) {
			window.location.href = this.model.redirectUrl;
		}
	}

	go() {
		this.fancyboxService.close();
		this.saveConsent();
	}

	onBtnDismissClick() {
		this.dismiss();
	}

	onBtnGoClick() {
		this.go();
	}

	openDialog() {
		if (!this.dialog.length) {
			return false;
		}

		// Dialog nesmi premazat jiny otevreny dialog
		if (this.fancyboxService.isOpened()) {
			return false;
		}

		this.fancyboxService.open(
			this.dialog,
			{
				baseClass: "s-overlay-black s-overlay-95",
				modal: true,
			},
		);
		return true;
	}

	saveConsent() {
		this.ajaxService.ajax({
			url: this.model.saveConsentUrl,
			method: "POST",
		});
	}
}
