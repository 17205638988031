import $ from "jquery";
import EventDispatcherMixin from "../../../Inlined/EventDispatcherMixin";
import UserAuthResumeEnum from "../VO/UserAuthResumeEnum";
import UserAuthStateEnum from "../VO/UserAuthStateEnum";
import UserAuthStateEvent from "../Event/UserAuthStateEvent";
import WatchdogSetEvent from "../Event/WatchdogSetEvent";
import WatchdogRequestEvent from "../Event/WatchdogRequestEvent";

/* global __ */

export default class WatchdogService {
	constructor(app, ajaxService, flashMessageService, userService, ajaxSetUrl, internalTrackingParameters) {
		this.app = app;
		this.ajaxService = ajaxService;
		this.flashMessageService = flashMessageService;
		this.userService = userService;
		this.ajaxSetUrl = ajaxSetUrl;
		this.internalTrackingParameters = internalTrackingParameters;

		this.on = EventDispatcherMixin.on;
		this.off = EventDispatcherMixin.off;
		this.dispatch = EventDispatcherMixin.dispatch;
		this.userAuthResume = UserAuthResumeEnum.WATCHDOG_RESUME;

		this.sourceWidget = null;
		this.watchdogId = null;
		this.payload = null;
		this.resumes = {};

		this.userService.register(this.userAuthResume, payload => this.onUserAuthResume(payload));
		this.userService.on(UserAuthStateEvent, ev => this.onUserAuth(ev));
	}

	getSourceWidget() {
		return this.sourceWidget;
	}

	onUserAuth(ev) {
		if (this.waitingForAuth && ev.newState === UserAuthStateEnum.COMPLETE_STATE) {
			this.waitingForAuth = false;
			const { watchdogId, payload } = this;
			this.send(watchdogId, payload);
		}
	}

	onUserAuthResume(payload) {
		if (!payload || !payload.watchdogId) {
			return;
		}

		const { watchdogId } = payload;
		const data = payload.watchdog;
		const watchdogPayload = payload.watchdog;
		this.send(
			watchdogId,
			data,
			() => this.resume(watchdogId, watchdogPayload),
		);
	}

	register(watchdogId, cb) {
		if (!watchdogId) {
			throw new Error("Watchdog ID not specified.");
		}

		this.resumes[watchdogId] = this.resumes[watchdogId] || [];
		this.resumes[watchdogId].push(cb);
	}

	resume(watchdogId, payload) {
		this.watchdogId = watchdogId;
		this.payload = payload;

		if (!this.resumes[watchdogId]) {
			throw new Error(`Could not resume '${watchdogId}' watchdog.`);
		}
		this.resumes[watchdogId].forEach(cb => cb(payload));
	}

	send(watchdogId, dataArg = {}, onSuccess = () => {}, onError = () => {}) {
		const data = $.extend({}, dataArg, this.internalTrackingParameters);

		this.ajaxService.ajax({
			data,
			method: "GET",
			url: this.ajaxSetUrl,
		})
			.done((response) => {
				this.app.dispatch(new WatchdogRequestEvent("success", watchdogId, data.active, data));
				if (response.userId !== undefined) {
					this.userService.setUserId(response.userId);
				}
				if (response.anonymous !== undefined) {
					this.userService.setAnonymous(response.anonymous);
				}

				if (response.ok) {
					// Watchdog byl ulozen
					if (response.watchdogSet && typeof response.active === "boolean") {
						this.app.dispatch(new WatchdogSetEvent(
							watchdogId,
							data.active,
							data,
							response.filterId,
							this.sourceWidget,
						));
						onSuccess(response);
					} else {
						this.flashMessageService.showMessage(__("Při komunikaci se serverem došlo k chybě."));
						onError(response);
					}
				} else {
					this.flashMessageService.showMessage(__("Při komunikaci se serverem došlo k chybě."));
					onError(response);
				}
			})
			.fail((...args) => {
				this.app.dispatch(new WatchdogRequestEvent("error", watchdogId, data.active, data));
				this.flashMessageService.showMessage(__("Při komunikaci se serverem došlo k chybě."));
				onError(args);
			});

		this.app.dispatch(new WatchdogRequestEvent("sent", watchdogId, data.active, data));

		return true;
	}

	setInternalTrackingParameters(internalTrackingParameters) {
		this.internalTrackingParameters = internalTrackingParameters;
		return this;
	}

	setPayload(payload, loginPayload = {}) {
		this.loginPayload = loginPayload;
		this.payload = payload;
		return this;
	}

	setSourceWidget(sourceWidget) {
		this.sourceWidget = sourceWidget;
	}

	setWatchdog(forceLogin = true) {
		if (forceLogin && this.userService.isLoggedIn() && !this.userService.isAnonymous()) {
			const { watchdogId } = this;
			const data = this.payload;
			this.send(watchdogId, data);
		} else if (!forceLogin && (this.userService.isLoggedIn() || this.userService.isVerifiedHuman())) {
			const { watchdogId } = this;
			const data = this.payload;
			this.send(watchdogId, data);
		} else {
			const loginPayload = $.extend({}, this.loginPayload, {
				watchdogId: this.watchdogId,
				watchdog: this.payload,
			});

			this.userService.setLoginId(this.userAuthResume);
			this.userService.setPayload(loginPayload);
			this.userService.setInternalTrackingParameters(this.internalTrackingParameters);
			this.userService.doLogin();
			this.waitingForAuth = true;
		}
	}

	setWatchdogId(watchdogId) {
		this.watchdogId = watchdogId;
		return this;
	}
}
