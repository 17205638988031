import AbstractWatchdogSubscribeWidget from "./AbstractWatchdogSubscribeWidget";
import WatchdogResumeEnum from "../../VO/WatchdogResumeEnum";

export default class RecommendWatchdogSubscribeWidget extends AbstractWatchdogSubscribeWidget {
	constructor(el, model, children, flashMessageService, watchdogService, userService, app) {
		super(el, model, children, flashMessageService, watchdogService, userService, app);
		this.watchdogResume = WatchdogResumeEnum.RECOMMEND;
	}

	bind() {
		super.bind();
		this.refresh();
	}

	logBtnClick() {
		if (this.model.active) {
			return;
		}
	}

	logWatchdogSet(interaction) {

	}

	onWatchdogRequest(ev) {
		if (ev.watchdogId !== this.watchdogResume
			|| String(ev.data.filterId) !== String(this.model.entity.filterId)
		) {
			return;
		}
		super.onWatchdogRequest(ev);
	}

	onWatchdogSet(ev) {
		if (ev.watchdogId !== this.watchdogResume
			|| String(ev.data.filterId) !== String(this.model.entity.filterId)
		) {
			return;
		}

		this.model.active = ev.active;
		this.refresh();
		this.logWatchdogSet(true);
	}
}
