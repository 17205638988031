import $ from "jquery";
import ResponsiveWidget from "../ResponsiveWidget";
import WatchdogSetEvent from "../../Event/WatchdogSetEvent";
import WatchdogRequestEvent from "../../Event/WatchdogRequestEvent";
import { getIconSpriteUrl } from "../../utils";
import checkIcon from "../../Styles/def/img/icons/check.svg";
import closeIcon from "../../Styles/def/img/icons/close.svg";

/* global window */
/* global dry */
/* global __DEV__ */
/* global Sentry */
/* global __ */

export default class ItemWatchdogSubscribeWidget extends ResponsiveWidget {
	constructor(el, model, children, flashMessageService, watchdogService, userService, itemWatchdogService) {
		super(el, model, children);

		this.flashMessageService = flashMessageService;
		this.watchdogService = watchdogService;
		this.userService = userService;
		this.itemWatchdogService = itemWatchdogService;
	}

	bind() {
		super.bind();

		const $btn = $(".j-btn", this.el);
		this.btn = $btn.length ? $btn : this.el;
		this.btnText = $(".j-text", this.btn);
		this.bellIcon = $(".j-bell-icon", this.btn);
		this.noBellIcon = $(".j-nobell-icon", this.btn);
		this.dropdown = $(".j-dropdown", this.el);
		this.spinner = $(".j-spinner", this.el);

		this.model.dropdownHover = false;
		this.model.hover = false;
		this.model.loading = false;

		this.btn.click((ev) => { this.onBtnClick(ev); });
		this.btn.mouseenter((ev) => { this.onBtnMouseEnter(ev); });
		this.btn.mouseleave((ev) => { this.onBtnMouseLeave(ev); });
		this.dropdown.mouseenter((ev) => { this.onDropdownMouseEnter(ev); });
		this.dropdown.mouseleave((ev) => { this.onDropdownMouseLeave(ev); });
		this.el.on("click", ".j-btn-closetooltip", (ev) => { this.onCloseTooltipBtnClick(ev); });

		this.btn.tooltip({
			animation: false,
			html: true,
			placement: () => this.getTooltipPlacement(),
			template: `
				<div class="tooltip tooltip-dropdown tooltip-large tooltip-light" role="tooltip">
					<div class="tooltip-arrow"></div>
					<div class="tooltip-inner"></div>
				</div>
			`,
			title: () => this.model.tooltipContent,
			trigger: "manual",
		});

		this.watchdogService.on(WatchdogSetEvent, (ev) => { this.onWatchdogSet(ev); });
		this.watchdogService.on(WatchdogRequestEvent, (ev) => { this.onWatchdogRequest(ev); });
	}

	getCampaign() {
		return dry.str.undescorize(this.constructor.name);
	}

	getBeforeTurnonTooltip() {
		if (this.model.entity.productId) {
			return `<div style="width: 170px;">${__("Upozorníme tě, až se objeví nová sleva")}</div>`;
		}
		return `<div style="width: 170px;">${__("Upozorníme tě, až tato sleva začne")}</div>`;
	}

	getTooltipPlacement() {
		const clientRect = this.btn[0].getBoundingClientRect();
		const placeOnRight = $(window).width() - clientRect.right;
		const tooltipWidth = 275;
		const tooltipPadding = 2 * 10;

		return ((tooltipWidth + tooltipPadding) / 2 + 10 <= clientRect.width / 2 + placeOnRight) ? "bottom" : "left";
	}

	getTurnOnTooltip() {
		return `<div style="width:${(this.getDeviceSize() === "md" || this.getDeviceSize() === "lg") ? 250 : 205}px;">
			<a class="j-btn-closetooltip close" href="#">
				<svg class="icon" aria-hidden="true">
					<use xlink:href="${getIconSpriteUrl(closeIcon)}"></use>
				</svg>
			</a>
			<h5 class="text-success m0">${__("Hlídání spuštěno!")}</h5>
			<ul class="checkmarks checkmarks-success text-left t-smaller mb0">
			${this.userService.isLoggedIn() && !this.userService.isAnonymous() && this.userService.getEmail()
		? `<li>
				<svg class="icon checkmarks-checkmark" aria-hidden="true">
					<use xlink:href="${getIconSpriteUrl(checkIcon)}"></use>
				</svg>
				<div class="muted">${__("Upozornění zašleme na:")}</div>
				<strong>${this.userService.getEmail()}</strong>
			</li>`
		: ""}${this.model.entity.productId
	? `
						<li class="t-small">
							<svg class="icon checkmarks-checkmark" aria-hidden="true">
								<use xlink:href="${getIconSpriteUrl(checkIcon)}"></use>
							</svg>
							${__("Pošleme e-mail, ve chvíli, kdy se objeví nová sleva na produkt")}
						</li>`
	: `<li class="t-small">
						<svg class="icon checkmarks-checkmark" aria-hidden="true">
							<use xlink:href="${getIconSpriteUrl(checkIcon)}"></use>
						</svg>
						${__("Pošleme e-mail, ve chvíli, kdy nabídka začne")}
					</li>`}
				<li class="t-small">
					<svg class="icon checkmarks-checkmark" aria-hidden="true">
						<use xlink:href="${getIconSpriteUrl(checkIcon)}"></use>
					</svg>
					${__("Hlídání můžeš kdykoliv vypnout")}
				</li>
			</ul>
		</div>`;
	}

	onBtnMouseEnter() {
		this.model.hover = true;
		this.refresh();
	}

	onBtnMouseLeave() {
		this.model.hover = false;
		this.refresh();
	}

	onBtnClick(ev) {
		ev.preventDefault();
		this.toggle();
	}

	onCloseTooltipBtnClick(ev) {
		ev.preventDefault();

		if (this.model.tooltipName === "turnOn") {
			this.model.isTurnOnTooltipShown = false;
		}

		this.model.dropdownHover = false;
		this.model.modal = false;
		this.refresh();
	}

	onDropdownMouseEnter() {
		this.model.dropdownHover = true;
		this.refresh();
	}

	onDropdownMouseLeave() {
		this.model.dropdownHover = false;
		this.refresh();
	}

	onWatchdogRequest(ev) {
		if (ev.watchdogId !== this.itemWatchdogService.watchdogResume) {
			return;
		}

		if (this.model.entity.dealId && ev.data.dealId !== this.model.entity.dealId) {
			return;
		}

		if (this.model.entity.productId && ev.data.productId !== this.model.entity.productId) {
			return;
		}

		if (ev.state === "sent") {
			this.model.loading = true;
			this.refresh();
		} else {
			this.model.loading = false;
			this.refresh();
		}
	}

	onWatchdogSet(ev) {
		if (ev.watchdogId !== this.itemWatchdogService.watchdogResume) {
			return;
		}

		if (this.model.entity.dealId && ev.data.dealId !== this.model.entity.dealId) {
			return;
		}

		if (this.model.entity.productId && ev.data.productId !== this.model.entity.productId) {
			return;
		}

		this.model.active = ev.active;
		this.model.isTurnOnTooltipShown = false;

		// Pokud zapnuti watchdoga bylo vyvolano timto widgetem
		if (ev.sourceWidget === this) {
			// Bublina: watchdog zapnut pro registrovaneho uzivatele
			if (ev.active && this.userService.isLoggedIn() && !this.userService.isAnonymous()) {
				this.model.isTurnOnTooltipShown = true;
				this.model.modal = true;
			}
		}

		this.refresh();
	}

	refresh() {
		const isTouchDevice = (this.getDeviceSize() === "xs" || this.getDeviceSize() === "sm");

		this.model.prevTooltipContent = this.model.tooltipContent;

		this.btn.removeClass("btn-gray btn-success btn-danger");
		this.bellIcon.hide();
		this.noBellIcon.hide();

		if (this.model.active) {
			if (this.model.hover && !isTouchDevice) {
				this.btn.addClass("btn-danger");
				this.btnText.text(this.model.activeHoverText);
				if (!this.model.loading) {
					this.noBellIcon.show();
				}
			} else {
				this.btn.addClass("btn-success");
				this.btnText.text(this.model.activeText);
				if (!this.model.loading) {
					this.bellIcon.hide();
				}
			}
		} else if (this.model.hover && !isTouchDevice) {
			this.btn.addClass("btn-success");
			this.btnText.text(this.model.inactiveHoverText);
			if (!this.model.loading) {
				this.bellIcon.show();
			}
		} else {
			this.btn.addClass("btn-gray");
			if (!this.model.loading) {
				this.bellIcon.show();
			}
			this.btnText.text(this.model.inactiveText);
		}

		if (this.model.loading) {
			this.spinner.show();
		} else {
			this.spinner.hide();
		}

		// Bublina: watchdog zapnut pro registrovaneho uzivatele
		if (this.model.modal
			&& this.model.active
			&& this.model.isTurnOnTooltipShown
			&& this.userService.isLoggedIn()
			&& !this.userService.isAnonymous()
		) {
			this.model.tooltipName = "turnOn";
			this.model.tooltipContent = this.getTurnOnTooltip();
			this.model.tooltipType = "modal";
		// Bublina: pred zapnutim watchdoga
		} else if (this.model.hover && !this.model.active && !isTouchDevice) {
			this.model.tooltipName = "beforeTurnOn";
			this.model.tooltipContent = this.getBeforeTurnonTooltip();
			this.model.tooltipType = "hover";
		} else {
			this.model.tooltipContent = null;
		}

		// Skryti modal tooltipu
		if (!this.model.modal && this.model.tooltipType === "modal") {
			this.model.tooltipContent = null;
			// Skryti dropdown tooltipu pri sjeti mysi z dropdownu
		} else if (!this.model.dropdownHover && this.model.tooltipType === "dropdown") {
			this.model.tooltipContent = null;
			// Skryti tooltipu pri sjeti mysi z tlacitka
		} else if (!this.model.hover && this.model.tooltipType === "hover") {
			this.model.tooltipContent = null;
		}

		if (this.model.tooltipContent) {
			// Prekreslit tooltip jen kdyz je potreba.
			// Nevhodnym prekreslovanim se muze narusit zachytavani udalosti na prvcich uvnitr tooltipu.
			if (this.model.prevTooltipContent !== this.model.tooltipContent) {
				this.btn.tooltip("show");
			}
		} else {
			this.btn.tooltip("hide");
		}
	}

	setWatchdog(active) {
		const data = $.extend({}, this.model.entity, {
			active: Number(active),
		});

		// Pridano v DEV-2392 (watchdog je podminen loginem)
		// Pokud podmineny login pro watchdoga v budoucnu zrusime,
		// nastav forceLogin = !!this.model.entity.newsletter (login jen pro newsletter)
		const forceLogin = true;

		this.itemWatchdogService.setInternalTrackingParameters(this.getInternalTrackingParameters());
		this.itemWatchdogService.setSourceWidget(this);
		this.itemWatchdogService.setItemWatchdog(data, forceLogin);
	}

	toggle(forceLogin = true) {
		this.setWatchdog(!this.model.active, forceLogin);
	}
}
