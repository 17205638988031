
export default class ItemMapService {
	constructor(fancyboxService, ajaxItemUrlPattern) {
		this.fancyboxService = fancyboxService;
		this.ajaxItemUrlPattern = ajaxItemUrlPattern;
	}

	open(item, sourceWidget = null) {
		if (!item.hash) {
			return false;
		}

		const url = this.ajaxItemUrlPattern.replace("{hash}", item.hash);

		this.fancyboxService.openAjax(
			url,
			{
				afterLoad(instance, current) {
					if (current.widget) {
						// eslint-disable-next-line no-param-reassign
						current.widget.sourceWidget = sourceWidget;
					}
				},
				slideClass: "s-edge-to-edge",
			},
		);

		return true;
	}
}
