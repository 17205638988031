import $ from "jquery";
import BoxNavigationWidget from "../BoxNavigationWidget";

export default class TopicDayNavigationWidget extends BoxNavigationWidget {
	bind() {
		super.bind();
		this.btnRestBox = $(".j-restbox-link", this.el);
		this.btnRestBox.click(ev => this.onBtnRestBoxClick(ev));
	}

	onBtnRestBoxClick(ev) {
		ev.preventDefault();
		this.listingWidget.scrollToFirstRestBox();
	}

	onBoxLinkClick(ev) {
		if (!this.listingWidget) {
			return;
		}

		const topicDayBoxId = Number.parseInt($(ev.currentTarget).data("topicdaybox-id"), 10);

		if (Number.isNaN(topicDayBoxId)) {
			return;
		}

		ev.preventDefault();
		this.listingWidget.scrollToTopicDayBoxById(topicDayBoxId);
	}
}
