import $ from "jquery";
import ResponsiveWidget from "./ResponsiveWidget";

import PageRedirectEvent from "../Event/PageRedirectEvent";
/* global __ */
/* global window  */

const listExpandClass = "children-zones--expanded";

export default class ChildrenZonesWidget extends ResponsiveWidget {
	constructor(el, model, children, app) {
		super(el, model, children);
		this.toggleButton = $(".j-toggle-button", el);
		this.items = $(".children-zones__list-item", el).not(this.toggleButton);
		this.list = $(".children-zones__list", el);
		this.window = $(window);
		this.app = app;
	}

	bind() {
		super.bind();
		this.showChildrenZonesList();
		this.initMoreButtonClickHandler();
		this.listItemsToggleHandler();
		this.onWindowResize();
		this.bindChildrenZonesClick();
	}

	showChildrenZonesList() {
		this.list.show();
	}

	listItemsToggleHandler() {
		let btnWidthTotal = 0;
		const listWidth = this.list.width();
		const itemMargin = parseInt(this.items.css("marginRight"), 10);
		for (let i = 0; i < this.items.length; i += 1) {
			btnWidthTotal += $(this.items[i]).outerWidth() + itemMargin;
			if (btnWidthTotal >= listWidth) {
				this.toggleButton.show();
				this.el.removeClass(listExpandClass);
			} else {
				this.toggleButton.hide();
				this.el.addClass(listExpandClass);
			}
		}
	}

	onWindowResize() {
		super.onWindowResize();
		if (this.getDeviceSize() !== "xs") {
			this.listItemsToggleHandler();
		} else {
			this.toggleButton.hide();
		}
	}


	initMoreButtonClickHandler() {
		const textMoreLocations = __("Více oblastí");
		const textLessLocations = __("Méně oblastí");
		this.toggleButton.click(() => {
			this.el.toggleClass(listExpandClass);
			this.toggleButton.text(this.toggleButton.text() === textMoreLocations
				? textLessLocations
				: textMoreLocations);
		});
	}

	bindChildrenZonesClick() {
		this.items.click((ev) => {
			this.app.dispatch(new PageRedirectEvent());
		});
	}
}
