import '../../../dry/net';
/* global dry */

export const getSvgSymbolUrl = (url) => {
	// Inline SVG nefunguje cross-origin
	const relativeUrl = dry.net.getRelativeUri(url);

	const [path, hash] = relativeUrl.split('#');

	// Odstranit '-usage' pro fragment URL ze spritu generovaneho pomoci svg-sprite-loaderu
	// (https://github.com/jonathantneal/svg4everybody/issues/169#issuecomment-333819670)
	return path + (hash ? `#${hash.replace(/-usage$/, '')}` : '');
};

// Keep manually in sync with IconWidget.php getSvgCacheKey method
const iconSpriteCacheKey = 4;

export const getIconSpriteUrl = (url) => {
	const [path, hash] = getSvgSymbolUrl(url)
		.split('#');

	return `${path}?v=${iconSpriteCacheKey}#${hash}`;
};

export const decodeString = string => (
	string.split('')
		.reverse()
		.join('')
);

/**
 * Replace substrings
 *
 * @param {string} str
 * @param {Object.<string, string>} replacePairs
 * @returns {string}
 *
 * example 1: strtr('hi all, I said hello', {"h": "-", "hello" : "hi, "hi": "hello"})
 * returns "hello all, I said hi"
 *
 * example 2: strtr("aa", {"a": 1,"aa": 2})
 * returns "2"
 */
export function strtr(str, replacePairs) {
	let ret = '';
	const tmpFrom = [];
	const tmpTo = [];

	Object.keys(replacePairs)
		.sort()
		.reverse()
		.forEach((key) => {
			tmpFrom.push(key);
			tmpTo.push(replacePairs[key]);
		});

	const from = tmpFrom;
	const to = tmpTo;

	// Walk through subject and replace chars when needed
	const lenStr = str.length;
	const lenFrom = from.length;

	for (let i = 0, j; i < lenStr; i += 1) {
		let match = false;
		for (j = 0; j < lenFrom; j += 1) {
			if (str.substr(i, from[j].length) === from[j]) {
				match = true;
				// Fast forward
				i = (i + from[j].length) - 1;
				break;
			}
		}
		if (match) {
			ret += to[j];
		} else {
			ret += str.charAt(i);
		}
	}

	return ret;
}

export function DOMEvalWithAsyncDefer(code) {
	const script = document.createElement('script');

	script.defer = true;
	script.async = true;
	script.type = 'text/javascript';
	script.text = code;
	document.head.appendChild(script);
}
