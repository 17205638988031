import $ from "jquery";
import Widget from "./../../../../Inlined/Widget";
import num from "../../../../../dry/num";

export default class MerchantWidget extends Widget {

	bind() {
		super.bind();

		this.distance = $(".j-distance", this.el);
	}

	getId() {
		return this.model.id;
	}

	setDistance(distance) {
		let formattedDistance;

		if (!distance || distance < 0.001) {
			this.distance.hide();
		}

		// Meters
		if (distance < 1) {
			formattedDistance = dry.num.format(distance * 1000, 0, ",", " ") + " m";
		// Kilometers
		} else {
			formattedDistance = dry.num.format(distance, 1, ",", " ") + " km";
		}
		this.distance.text(formattedDistance);
	}

}
