import AbstractItemWidget from "./AbstractItemWidget";

export default class DealProductDetailWidget extends AbstractItemWidget {

	bind() {
		super.bind();

		this.el.mouseenter((ev) => { this.onMouseIn(ev) });
		this.el.mouseover((ev) => { this.onMouseIn(ev) });
		this.el.mouseleave((ev) => { this.onMouseOut(ev) });
	}

	onMouseIn(ev) {
		this.attachHitParams();
	}

	onMouseOut(ev) {
		// Parent overide
	}

}
