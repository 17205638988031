import LazyLoad from "vanilla-lazyload";

export default class LazyLoadingService {
	constructor() {
		this.getLazyLoadInstance();
	}

	getLazyLoadInstance() {
		if (!this.lazyLoadInstance) {
			const lazyLoadOptions = {
				elements_selector: ".lazyload",
				class_loaded: "lazyloaded",
			};
			this.lazyLoadInstance = new LazyLoad(lazyLoadOptions);
		}
		return this.lazyLoadInstance;
	}

	update() {
		this.getLazyLoadInstance().update();
	}
}
