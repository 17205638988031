import $ from "jquery";
import Widget from "../../../../Inlined/Widget";

export default class TopicDayListingWidget extends Widget {
	bind() {
		super.bind();

		this.btnTopicDay = $(".j-btn-topicday", this.el);

		this.el.mouseenter(ev => this.onMouseIn(ev));
		this.el.mouseover(ev => this.onMouseIn(ev));
	}

	attachHitParams() {
		if (this.hitParamsAttached) {
			return;
		}

		this.hitParamsAttached = true;

		this.model.source = this.getSource();
		this.model.campaign = this.getCampaign();
		this.model.medium = this.getMedium();

		let params = [];
		if (this.model.source) {
			params.push(`s_source=${this.model.source}`);
		}
		if (this.model.medium) {
			params.push(`s_medium=${this.model.medium}`);
		}
		if (this.model.campaign) {
			params.push(`s_campaign=${this.model.campaign}`);
		}
		params = params.join("&");

		this.btnTopicDay.each(function () {
			let url = $(this).attr("href");
			url = `${url}${url.indexOf("?") === -1 ? "?" : "&"}${params}`;
			$(this).attr("href", url);
		});
	}

	getCampaign() {
		return this.model.campaign;
	}

	onMouseIn() {
		this.attachHitParams();
	}
}
