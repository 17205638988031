import $ from "jquery";
import AbstractFilterFormWidget from "./AbstractFilterFormWidget";
import PageRedirectEvent from "../../Event/PageRedirectEvent";

export default class SideFilterFormWidget extends AbstractFilterFormWidget {
	constructor(el, model, children, app, filterService, fancyboxService) {
		super(el, model, children, app, filterService);
		this.app = app;
		this.filterService = filterService;
		this.fancyboxService = fancyboxService;
		this.personsInputs = $(".j-persons", this.el);
		this.personsInput = this.personsInputs.length ? this.personsInputs[0] : null;
		this.personsModal = $(".j-persons-modal", this.el);
		this.btnWrapper = $(".j-btn", this.el);
		this.btn = this.btnWrapper.children("button");
		this.bodyModalClass = "body-prevent-scroll";
		this.isMobileTravelFilter = this.model.mobileTravelFilter;
		this.travelMobileModal = $(".j-travel-mobile-modal", this.el);
		this.travelMobileModalBtn = $(".j-travel-mobile-modal-btn", this.el);
		this.travelMobileModalCloseBtn = $(".j-modal-close", this.el);
		this.travelMobileActiveClass = "travel-mobile-filter--visible";
		this.clearFiltersBtn = $(".j-clear-filters", this.el);
	}

	bind() {
		super.bind();
		this.el.one("keyup change", () => this.bindStickyBtn());
		this.travelMobileModalBtn.on("click", ev => this.onTravelMobileFilterModalOpen(ev));
		this.travelMobileModalCloseBtn.on("click", ev => this.onTravelMobileFilterModalClose(ev));
		this.clearFiltersBtn.on("click", () => this.onClearAllFilters());
	}

	bindStickyBtn() {
		if (this.model.stickyBtn) {
			return;
		}

		if (!this.isMobileTravelFilter) {
			this.btnWrapper.floating({
				// Makes the button sticky after getting to given distance from bottom egde of the scrolling viewport
				marginBottom: 0,
				marginTop: null,
				// Makes the button static after meeting the edge defined by limit option
				limit: () => this.el.offset().top + 170,
				zIndex: 30,
			});
			this.model.stickyBtn = true;
		}

		if (this.btn.prop("disabled")) {
			this.btn.prop("disabled", false).addClass("btn-success").removeClass("btn-info");
		}
	}

	onTravelMobileFilterModalOpen(ev) {
		ev.preventDefault();
		$("body").addClass(this.bodyModalClass);
		this.travelMobileModal.addClass(this.travelMobileActiveClass);
	}

	onTravelMobileFilterModalClose(ev) {
		ev.preventDefault();
		$("body").removeClass(this.bodyModalClass);
		this.travelMobileModal.removeClass(this.travelMobileActiveClass);
	}

	onSubmit(ev) {
		ev.preventDefault();
		if (this.personsInput && this.personsInput.value > 20) {
			this.open();
			return;
		}
		super.onSubmit(ev);
	}

	onClearAllFilters() {
		this.app.dispatch(new PageRedirectEvent());
	}

	open() {
		this.fancyboxService.openRaw(
			this.personsModal,
			{
				modal: false,
				// Disable keyboard navigation
				keyboard: false,
				// Disable click event handlers
				clickContent: false,
				clickSlide: false,
				clickOutside: false,
				dblclickContent: false,
				dblclickSlide: false,
				dblclickOutside: false,
				smallBtn: true,
			},
		);
	}
}
