import $ from "jquery";
import FormStepperWidget from "../FormStepperWidget";
import ValidationMixin from "../ValidationMixin";
import UserAuthStateEnum from "../../VO/UserAuthStateEnum";
import UserAuthResumeEnum from "../../VO/UserAuthResumeEnum";
import UserLocationDialogWidget from "./UserLocationDialogWidget";
import { trans } from "../../Service/I18NService";
import { applicationName } from "../../Locale";
import UserBiscuitEnum from "../../VO/UserBiscuitEnum";
import UserAuthStateEvent from "../../Event/UserAuthStateEvent";
import suggestEmail from "./SuggestEmail";

/* global window */
/* global __ */
/* global dry */

export default class UserRegisterWidget extends FormStepperWidget {
	constructor(
		el,
		model,
		children,
		app,
		userService,
		validateSettings,
		emailProvidersSettings,
		flashMessagesWidget,
	) {
		super(el, model, children, app);

		this.userService = userService;
		this.validateSettings = validateSettings;
		this.emailProvidersSettings = emailProvidersSettings;
		this.flashMessagesWidget = flashMessagesWidget;
		this.userAuthResume = UserAuthResumeEnum.USER_REGISTER_RESUME;
		this.bindValidation = ValidationMixin.bindValidation;
		this.getEmailMessage = ValidationMixin.getEmailMessage;
		this.onInputFix = ValidationMixin.onInputFix;
		this.onValidationSuccess = ValidationMixin.onValidationSuccess;
	}

	bind() {
		super.bind();

		this.validator = null;

		this.$btnChangeEmail = $(".j-change-email", this.el);
		this.$btnComplete = $(".j-btn-complete", this.el);
		this.$btnFacebook = $(".j-btn-fb", this.el);
		this.$btnForgottenPwd = $(".j-forgotten-pwd", this.el);
		this.$btnGotoEmail = $(".j-goto-email", this.el);
		this.$btnSetFacebook = $(".j-btn-set-fb", this.el);
		this.$btnSetNoFacebook = $(".j-btn-set-nofb", this.el);
		this.btnShowRegisterVideo = $(".j-userregister-video-toggle", this.el);
		this.$inputAgreeFb = $("input[name='agree_fb']", this.el);
		this.$inputEmail = $(".j-email", this.el);
		this.inputEmailName = this.$inputEmail.attr("name");
		this.$spinnerFacebook = $(".j-spinner", this.$btnFacebook);
		this.$spinnerRedirect = $(".j-spinner-redir", this.el);
		this.registeredEmailEl = $(".j-registered-email", this.el);
		this.userLocationDialog = this.app
			.findByType(UserLocationDialogWidget)
			.filter(widget => (widget.model.widgetPosition === "registration"))[0] || null;

		this.$btnGotoEmail.hide();
		this.$btnChangeEmail.click(ev => this.onBtnChangeEmailClick(ev));
		this.$btnComplete.click(ev => this.onBtnCompleteClick(ev));
		this.$btnFacebook.click(ev => this.onBtnFacebookClick(ev));
		this.$btnForgottenPwd.click(ev => this.onBtnForgottenPwdClick(ev));
		this.$btnSetFacebook.click(ev => this.onBtnSetFacebookClick(ev));
		this.$btnSetNoFacebook.click(ev => this.onBtnSetNoFacebookClick(ev));
		this.bindForm();
		this.bindValidation();
		if (this.userLocationDialog) {
			this.userLocationDialog.register(() => this.onLocationComplete());
		}

		this.userService.register(this.userAuthResume, ev => this.onUserAuthResume(ev));
		this.userService.on(UserAuthStateEvent, ev => this.onUserAuthState(ev));

		this.setFacebook(true);
	}

	bindForm() {
		const formSettings = {
			rules: {
				agree_nofb: {
					required: () => (!this.facebook),
				},
				agree_fb: {
					required: () => (!!this.facebook),
				},
				password: {
					required: true,
				},
			},
			messages: {
				agree_nofb: {
					required: __("Souhlasíte s podmínkami? Je to jen formalita."),
				},
				agree_fb: {
					required: trans(
						__("Prosím zaškrtněte souhlas s podmínkami %applicationName%"),
						{ "%applicationName%": applicationName },
					),
				},
				password: {
					required: __("Je nutné zadat heslo. Neznáte ho? Řešení je níže."),
				},
			},
			success: ($label, element) => {
				this.onValidationSuccess($label, element);
			},
			submitHandler: () => {
				this.onSubmit();
				return false;
			},
		};
		formSettings.rules[this.inputEmailName] = {
			required: true,
			emailHtml5: true,
		};
		formSettings.messages[this.inputEmailName] = {
			required: __("Bez zadání e-mailu to nepůjde."),
			emailHtml5: (...args) => this.getEmailMessage(...args),
		};

		this.validator = this.el.validate($.extend(this.validateSettings, formSettings));
	}

	doAuth() {
		const data = this.serializeArrayStep();
		if (data.email) {
			this.model.suggestedEmail = suggestEmail(data.email);
			if (this.model.suggestedEmail) {
				// eslint-disable-next-line no-alert
				const replace = window.confirm(trans(
					__("Zdá se nám, že v emailové adrese je překlep. Není adresa správně takto? %suggestedEmail%"),
					{ "%suggestedEmail%": this.model.suggestedEmail },
				));
				if (replace) {
					data.email = this.model.suggestedEmail;
				}
			}
			// eslint-disable-next-line max-len, func-names
			$(":input[name='email']", this.el).each(function () {
				$(this).val(data.email);
			});
		}

		const { stepIndex } = this;
		this.setStepLoading(true, stepIndex);

		this.userService.setLoginId(this.userAuthResume);
		this.userService.setPayload({});
		this.userService.setInternalTrackingParameters(this.getInternalTrackingParameters());
		this.userService.doAuth(
			data,
			() => {
				this.setStepLoading(false, stepIndex);
			},
			(_, response) => {
				if (response.validationMessages) {
					this.validator.showErrors(response.validationMessages);
				}
				this.setStepLoading(false, stepIndex);
			},
		);
	}

	getCampaign() {
		return dry.str.undescorize(this.constructor.name);
	}

	goToStepByAuthState(state) {
		let step = state;

		if (state === UserAuthStateEnum.EMAIL_STATE && !this.facebook) {
			// For non-Facebook user we get email input in "new" step
			step = UserAuthStateEnum.NEW_STATE;
		} else if (state === UserAuthStateEnum.COMPLETE_STATE
			&& this.userService.isLocationChallenge()
			&& this.userLocationDialog
		) {
			// Registration complete, but location challenge ocurred
			step = "location";
		}
		this.goToStep(step, true);
	}

	onBtnChangeEmailClick(ev) {
		ev.preventDefault();
		this.userService.setState(UserAuthStateEnum.EMAIL_STATE);
		this.goToStepByAuthState(UserAuthStateEnum.EMAIL_STATE);
	}

	onBtnCompleteClick(ev) {
		ev.preventDefault();
		this.redirect();
	}

	onLocationComplete() {
		this.goToStepByAuthState(this.userService.getState());
		this.userLocationDialog.hide();
		this.el.show();
	}

	onBtnFacebookClick(ev) {
		ev.preventDefault();

		const agreement = this.el.validate().element(this.$inputAgreeFb[0]);
		if (!agreement) {
			return;
		}

		this.$spinnerFacebook.show();

		this.userService.setLoginId(this.userAuthResume);
		this.userService.setPayload({});
		this.userService.setInternalTrackingParameters(this.getInternalTrackingParameters());
		this.userService.doFacebookLogin(() => this.$spinnerFacebook.hide());
	}

	onBtnForgottenPwdClick(ev) {
		ev.preventDefault();
		const data = this.serializeArrayStep();
		data.send = true;

		this.userService.setLoginId(this.userAuthResume);
		this.userService.setPayload({});
		this.userService.setInternalTrackingParameters(this.getInternalTrackingParameters());
		this.userService.doAuth(data);
	}

	onBtnSetFacebookClick(ev) {
		ev.preventDefault();
		this.setFacebook(true);
	}

	onBtnSetNoFacebookClick(ev) {
		ev.preventDefault();
		this.setFacebook(false);

		this.userService.setLoginId(this.userAuthResume);
		this.userService.setPayload({});
		this.userService.setInternalTrackingParameters(this.getInternalTrackingParameters());
		this.userService.doAuth();
	}

	onStepLocationIn() {
		this.userLocationDialog.show();
		this.el.hide();
	}

	onStepCompleteIn() {
		this.showRegisteredEmail();
		const video = this.userService.getEmailVideo();
		this.showVideo(video);
	}

	onStepForgottenPasswordIn(step) {
		const $email = $(":input[name='email']", step.el);
		const email = $email.val();

		if (email) {
			const $emailText = $(".j-email-text", step.el);
			$emailText.text(email);
			const $emailProvider = $(".j-email-provider", step.el);

			const m = email.match(/@(.+)$/);
			this.$btnGotoEmail.hide();

			if (m && this.emailProvidersSettings[m[1]]) {
				const provider = this.emailProvidersSettings[m[1]];
				$emailProvider.text(provider.name);
				this.$btnGotoEmail.attr("href", provider.href);
				this.$btnGotoEmail.show();
			}
		}
		return true;
	}

	onSubmit() {
		this.doAuth();
	}

	onUserAuthResume() {
		this.redirect();
	}

	onUserAuthState(ev) {
		// distribute email
		if (this.userService.isLoggedIn() && !this.userService.isAnonymous()) {
			$(":input[name=\"email\"]", this.el).val(this.userService.getEmail());
		}
		this.goToStepByAuthState(ev.newState);
	}

	redirect() {
		this.$spinnerRedirect.show();
		window.location = this.model.redirectUrl;
	}

	setFacebook(active) {
		this.facebook = active;

		if (active) {
			this.el
				.removeClass("s-nofb")
				.addClass("s-fb");
		} else {
			this.el
				.removeClass("s-fb")
				.addClass("s-nofb");
		}
	}

	showVideo(show) {
		if (show) {
			this.el.addClass("s-video");
			this.btnShowRegisterVideo.click(() => {
				$(".j-userregister-video-content").show();
				this.btnShowRegisterVideo.hide();
			});
			this.userService.setBiscuit(UserBiscuitEnum.EMAIL_VIDEO_BISCUIT, 1, 365);
			// Do not show flash messages during video playback
			this.flashMessagesWidget.doNotDisturb(5000);
		} else {
			this.el.removeClass("s-video");
		}
	}

	showRegisteredEmail() {
		if (this.userService.isLoggedIn() && !this.userService.isAnonymous()) {
			this.registeredEmailEl.text(this.userService.getEmail());
		}
	}
}
