import $ from "jquery";
import PageRedirectEvent from "../Event/PageRedirectEvent";
import ResponsiveWidget from "./ResponsiveWidget";
/* global __ */

const pageLinksKey = "page-links";
const listExpandClass = `${pageLinksKey}--expanded`;
const textMoreLocations = __("Více oblastí");
const textLessLocations = __("Méně oblastí");

export default class PageLinksWidget extends ResponsiveWidget {
	constructor(el, model, children, app) {
		super(el, model, children);
		const elementParent = $(el).parent();
		this.mainElement = null;
		const mainElementClass = `.${pageLinksKey}`;

		// PageLink widget is used on more places -> we need to control only those on TOP position
		if (elementParent && elementParent.children(`.${pageLinksKey}`).length > 0) {
			this.mainElement = $(mainElementClass, elementParent);
			this.toggleButton = $(".j-toggle-button", this.mainElement);
			this.list = $(`.${pageLinksKey}__list`, this.mainElement);
			this.items = $(`.${pageLinksKey}__list-item`, this.list).not(this.toggleButton);
			this.app = app;

			this.items.click((ev) => {
				if (this.app) this.app.dispatch(new PageRedirectEvent());
				const hrefAttribute = ev.target.getAttribute("href");
			});

			this.toggleButton.click(() => {
				this.mainElement.toggleClass(listExpandClass);
				this.toggleButton.text(this.toggleButton.text() === textMoreLocations
					? textLessLocations
					: textMoreLocations);
			});
		}
	}

	bind() {
		super.bind();
		this.listItemsToggleHandler();
		this.onWindowResize();
	}

	listItemsToggleHandler() {
		if (this.mainElement && this.toggleButton && this.list && this.items) {
			let btnWidthTotal = 0;
			const listWidth = this.list.width();
			const itemMargin = parseInt(this.items.css("marginRight"), 10);
			for (let i = 0; i < this.items.length; i += 1) {
				btnWidthTotal += $(this.items[i]).outerWidth() + itemMargin;
				if (btnWidthTotal >= listWidth) {
					this.toggleButton.show();
					this.mainElement.removeClass(listExpandClass);
				} else {
					this.toggleButton.hide();
					this.mainElement.addClass(listExpandClass);
				}
			}
		}
	}

	onWindowResize() {
		super.onWindowResize();
		if (this.getDeviceSize() !== "xs") {
			this.listItemsToggleHandler();
		} else if (this.toggleButton) {
			this.toggleButton.hide();
		}
	}
}
