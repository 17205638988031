import $ from "jquery";
import Widget from "../../../../Inlined/Widget";
import WatchdogSetEvent from "../../Event/WatchdogSetEvent";
import WatchdogRequestEvent from "../../Event/WatchdogRequestEvent";

/* global __ */
/* global dry */
/* global window */

export default class ItemWatchdogDialogWidget extends Widget {
	constructor(
		el,
		model,
		children,
		app,
		flashMessageService,
		itemWatchdogService,
		watchdogService,
		validateSettings,
		fancyboxService,
	) {
		super(el, model, children);
		this.app = app;
		this.flashMessageService = flashMessageService;
		this.itemWatchdogService = itemWatchdogService;
		this.watchdogService = watchdogService;
		this.validateSettings = validateSettings;
		this.fancyboxService = fancyboxService;
	}

	bind() {
		super.bind();

		this.$btn = $(".j-btn", this.el);
		this.$bellIcon = $(".j-bell", this.btn);
		this.$noBellIcon = $(".j-nobell", this.btn);
		this.$btnText = $(".j-text", this.$btn);
		this.$btnClose = $(".j-close", this.el);
		this.$prepare = $(".j-prepare", this.el);
		this.$done = $(".j-done", this.el);
		this.$spinner = $(".j-spinner", this.el);

		this.$btn.mouseenter(ev => this.onBtnMouseEnter(ev));
		this.$btnClose.on("click", ev => this.onBtnCloseClick(ev));
		this.bindForm();

		this.app.on(WatchdogSetEvent, ev => this.onWatchdogSet(ev));
		this.app.on(WatchdogRequestEvent, ev => this.onWatchdogRequest(ev));
	}

	bindForm() {
		const formSettings = {
			rules: {
				watchdog_type: {
					required: true,
				},
			},
			messages: {
				watchdog_type: {
					required: __("Jaké slevy chceš hlídat?"),
				},
			},
			submitHandler: (form, ev) => this.onSubmit(ev),
		};
		this.el.validate($.extend({}, this.validateSettings, formSettings));
	}

	onBtnMouseEnter() {
		if (this.model.active) {
			this.$btn.addClass("btn-danger");
			this.$noBellIcon.show();
			this.$bellIcon.hide();
			this.$btnText.text(__("Vypnout hlídání"));
		} else {
			this.$btn.addClass("btn-success");
			this.$noBellIcon.hide();
			this.$bellIcon.show();
			this.$btnText.text(__("Zapnout hlídání"));
		}
	}

	fetchEntity() {
		const data = this.el.serializeObject();
		const watchdogType = $.isArray(data.watchdog_type) ? data.watchdog_type : [data.watchdog_type];
		const entity = {};

		if (watchdogType.length) {
			if (watchdogType.indexOf("item_category") >= 0 && this.model.firstCategory) {
				entity.category = this.model.firstCategory.categoryId;
			}

			if (watchdogType.indexOf("item_server") >= 0 && this.model.server) {
				entity.server = this.model.server.serverId;
			}

			if (watchdogType.indexOf("item_manufacturer") >= 0 && this.model.manufacturer) {
				entity.manufacturer = this.model.manufacturer.manufacturerId;
			}

			if (watchdogType.indexOf("newsletter") >= 0) {
				entity.newsletter = 1;
			}

			if (watchdogType.indexOf("search") >= 0) {
				entity.search = this.model.search;
			}
		}
		return entity;
	}

	getCampaign() {
		return dry.str.undescorize(this.constructor.name);
	}

	onBtnCloseClick(ev) {
		ev.preventDefault();

		if (this.fancyboxService.isOpened(this)) {
			this.fancyboxService.close();
		}
	}

	onSubmit(ev) {
		ev.preventDefault();
		this.setWatchdog(!this.model.active);
	}

	onUpdated() {
		if (this.model.active) {
			this.$prepare.hide();
			this.$done.show();
		} else {
			this.$prepare.show();
			this.$done.hide();
		}
	}

	onWatchdogRequest(ev) {
		if (ev.watchdogId !== this.itemWatchdogService.watchdogResume) {
			return;
		}

		if (ev.state === "sent") {
			this.$noBellIcon.hide();
			this.$bellIcon.hide();
			this.$spinner.show();
		} else {
			if (this.model.active) {
				this.$noBellIcon.hide();
				this.$bellIcon.show();
			} else {
				this.$noBellIcon.hide();
				this.$bellIcon.show();
			}
			this.$spinner.hide();
		}
	}

	onWatchdogSet(ev) {
		if (ev.watchdogId !== this.itemWatchdogService.watchdogResume) {
			return;
		}

		this.model.active = ev.active;
		this.onUpdated();
	}

	setActive(active) {
		this.model.active = active;
		this.onUpdated();
	}

	setWatchdog(active) {
		this.model.entity = this.fetchEntity();

		const data = $.extend({}, this.model.entity, {
			active: Number(active),
			deal: this.model.deal.hash,
		});
		// Pridano v DEV-2392 (watchdog je podminen loginem)
		// Pokud podmineny login pro watchdoga v budoucnu zrusime,
		// nastav forceLogin = !!this.model.entity.newsletter (login jen pro newsletter)
		const forceLogin = true;

		this.itemWatchdogService.setInternalTrackingParameters(this.getInternalTrackingParameters());
		this.itemWatchdogService.setSourceWidget(this);
		this.itemWatchdogService.setItemWatchdog(data, forceLogin);

		return true;
	}
}
