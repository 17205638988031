
import $ from "jquery";
import Widget from "./../../../../Inlined/Widget";
import ProductDetailTabsWidget from "./ProductDetailTabsWidget";

export default class ProductDetailWidget extends Widget {

	constructor(el, model, children, app) {
		super(el, model, children);

		this.app = app;
	}

	bind() {
		super.bind();

		this.btnDeals = $(".j-btn-deals", this.el);
		this.tabs = this.app.findByType(ProductDetailTabsWidget)[0] || null;
		this.tooltips = $("[data-toggle='tooltip']", this.el);

		this.btnDeals.click((ev) => this.onBtnDealsClick(ev));
		this.tooltips.tooltip();
	}

	onBtnDealsClick(ev) {
		ev.preventDefault();

		if (this.tabs) {
			this.tabs.showTabById(ev.currentTarget.hash.replace("#",""), true);
		}
	}

}