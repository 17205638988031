import $ from "jquery";
import Widget from "../../../../Inlined/Widget";
/* global window */

export default class ExitLocationDialogWidget extends Widget {
	constructor(el, model, children, app, ajaxService, flashMessageService, validateSettings) {
		super(el, model, children);
		this.app = app;
		this.ajaxService = ajaxService;
		this.flashMessageService = flashMessageService;
		this.validateSettings = validateSettings;

		this.model.saved = false;

		this.btnDefault = $(".j-btn-default", this.el);
		this.btnDismiss = $(".j-btn-dismiss", this.el);
		this.btnGo = $(".j-btn-go", this.el);
		this.zoneTitle = $(".j-zone-title", this.el);
		this.spinner = $(".j-spinner", this.el);

		this.default = $(".j-default", this.el);
		this.done = $(".j-done", this.el);

		this.btnDefault.click(ev => this.onBtnDefaultClick(ev));
	}

	// uvedeni dialogu do pocatecniho stavu
	begin() {
		this.btnDefault.attr("href", this.model.exitUrl);
		this.btnDismiss.attr("href", this.model.exitUrl);
		this.btnGo.attr("href", this.model.exitUrl);
		this.zoneTitle.text(this.model.zone.titleForm4);

		this.goTo("default");
	}

	end() {
		if (this.model.saved) {
			// uzivatel vybral mesto, prenacist deal listy
			this.reload();
		}
	}

	// tlacitko: ulozit nabizene mesto
	onBtnDefaultClick(ev) {
		ev.preventDefault();

		this.save(this.model.zone);
	}

	goTo(stepName) {
		if (stepName === "default") {
			this.default.show();
			this.done.hide();
		} else {
			this.default.hide();
			this.done.show();
		}
	}

	// prenacte deal listy s novym nastavenim mest
	// eslint-disable-next-line class-methods-use-this
	reload() {
		window.location.reload();
	}

	save(zone) {
		let data = {
			id: zone.locationId,
			title: zone.title,
		};
		data = $.extend({}, data, this.getInternalTrackingParameters());

		this.spinner.show();
		this.ajaxService.ajax({
			data,
			method: "POST",
			url: this.model.ajaxUrl,
		})
			.done((response) => {
				this.spinner.hide();

				if (response.ok) {
					this.model.saved = true;
					this.goTo("done");
				} else {
					this.flashMessageService.showMessage(response.message
						|| "Při komunikaci se serverem došlo k chybě.");
				}
			})
			.fail(() => {
				this.spinner.hide();
				this.flashMessageService.showMessage("Při komunikaci se serverem došlo k chybě.");
			});
	}

	setExit(exitUrl, zone) {
		this.model.exitUrl = exitUrl;
		this.model.zone = zone;
	}
}
