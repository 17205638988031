import Widget from "./../../../../Inlined/Widget";
/* global __DEV__ */

export default class DemoCsWidget extends Widget {
	bind() {
		super.bind();
		/* eslint-disable-next-line no-console */
		__DEV__ && console.log("Mluvím česky");
	}
}
