import {
	DATE_FROM,
	DATE_TO,
} from "./ReservationsEnum";
import {
	EXACT_KEY,
} from "../Widget/Filter/FiltersEnum";
import ItemReservationWidget from "../Widget/Reservation/ItemReservationWidget";

export default class ReservationService {
	constructor(
		fancyboxService,
		filterService,
		ajaxDealUrlPattern,
		ajaxMerchantUrlPattern,
		ajaxFindBookingComTermsUrlPattern,
	) {
		this.fancyboxService = fancyboxService;
		this.filterService = filterService;
		this.ajaxDealUrlPattern = ajaxDealUrlPattern;
		this.ajaxMerchantUrlPattern = ajaxMerchantUrlPattern;
		this.ajaxFindBookingComTermsUrlPattern = ajaxFindBookingComTermsUrlPattern;
	}

	createTermRequestForDeal(model, date = null) {
		let url;
		const parameters = [];

		if (model.server
			&& model.server.id
			&& model.server.id === ItemReservationWidget.BOOKING_COM_SERVER_ID
		) {
			const termFilterValues = this.filterService.getTermFilterTypeAndValues();

			if (termFilterValues && termFilterValues.type && termFilterValues.type === EXACT_KEY
				&& termFilterValues.dateFrom
				&& termFilterValues.dateTo
			) {
				parameters.push(`${DATE_FROM}=${termFilterValues.dateFrom}`);
				parameters.push(`${DATE_TO}=${termFilterValues.dateTo}`);
				url = this.ajaxFindBookingComTermsUrlPattern.replace("{hash}", model.hash);
			}
		}

		if (!url) {
			url = this.ajaxDealUrlPattern.replace("{hash}", model.hash);
			if (date) {
				parameters.push(`date=${date}`);
			}
		}

		url += `${url.indexOf("?") === -1 ? "?" : "&"}${parameters.join("&")}`;

		this.fancyboxService.openAjax(
			url,
			{
				slideClass: "s-edge-to-edge",
			},
		);
	}

	createTermRequestForMerchant(slug, date = null) {
		let url = this.ajaxMerchantUrlPattern.replace("{merchant}", slug);
		if (date) {
			url += `${url.indexOf("?") === -1 ? "?" : "&"}date=${date}`;
		}

		this.fancyboxService.openAjax(
			url,
			{
				slideClass: "s-edge-to-edge",
			},
		);
	}
}
