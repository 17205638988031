import { trans } from "./I18NService";
/* global __ */

export default class SubscribeMessageService {
	constructor(flashMessageService, changeEmailUrl) {
		this.flashMessageService = flashMessageService;
		this.changeEmailUrl = changeEmailUrl;
	}

	showExistingUserSuccessMessage(email) {
		const title = trans(
			__("Hotovo, tipy budete dostávat na váš e-mail %email%"),
			{ "%email%": `<strong>${email}</strong>` },
		);

		const changeEmailMessage = trans(
			__("Pokud výše uvedený e-mail není zadán správně, %linkStart%změňte si ho v nastavení%linkEnd%"),
			{
				"%linkStart%": `<a href="${this.changeEmailUrl}">`,
				"%linkEnd%": "</a>",
			},
		);

		this.flashMessageService.showMessage(`<p><small>${changeEmailMessage}</small></p>`, "success", {
			title,
			altBtn: {
				title: __("Upravit e-mail"),
				url: this.changeEmailUrl,
			},
		});
	}

	showNewUserSuccessMessage(email) {
		const message = trans(
			__("Hotovo, tipy budete dostávat na váš e-mail %email%"),
			{ "%email%": `<strong>${email}</strong>` },
		);

		this.flashMessageService.showMessage(message, "success");
	}
}
