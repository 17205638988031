
import $ from "jquery";
import ResponsiveWidget from "../ResponsiveWidget";
import TabChangeEvent from "../../Event/TabChangeEvent";
import browser from "../../../../../dry/browser";

export default class ProductHistoryWidget extends ResponsiveWidget {

	constructor(el, model, children, app) {
		super(el, model, children);

		this.app = app;
	}

	bind() {
		super.bind();

		this.chartContainer = $(".j-chart", this.el);

		this.app.on(TabChangeEvent, (ev) => this.onTabChange(ev));

		// Chartist nepodporuje IE8
		if (dry.browser.getIEVersion() == null || dry.browser.getIEVersion() >= 9) {
			require.ensure(
				[],
				() => {
					this.Chartist = require("chartist");
					this.ChartistTooltip = require("imports-loader?Chartist=chartist!chartist-plugin-tooltip");
					this.bindChart();
				},
				"bundle/chartist"
			);
		}
	}

	bindChart() {
		this.chart = new this.Chartist.Line(
			this.chartContainer[0],
			{
				labels: this.model.labels,
				series: this.model.series
			},
			{
				axisX: {
					labelInterpolationFnc: (value, i, labels) => {
						var date = new Date(value * 1000);
						var groupLabelsByCount = Math.max(1, Math.floor(labels.length / this.getMaxLabelsCount()));

						if (i % groupLabelsByCount === 0) {
							return date.getDate() + ". " + (date.getMonth() + 1) + ". " + date.getFullYear();
						}

						return "";
					},
					offset: 60
				},
				lineSmooth: false,
				plugins: [
					this.Chartist.plugins.tooltip({
						tooltipFnc: (x, y) => {
							var date = new Date(x * 1000);
							var dateFormatted = date.getDate() + ". " + (date.getMonth() + 1) + ". " + date.getFullYear();
							return Math.round(y) + " Kč (" + dateFormatted + ")";
						}
					})
				]
			}
		);
		this.chart.on("draw", (ctx) => {
			// rotovane popisky osy X
			if (ctx.type === "label") {
				var n = $(ctx.element._node);
				if (ctx.axis.units.dir === "horizontal") {
					var x = ctx.x;
					var y = ctx.y;

					n.css("overflow", "visible");
					n.attr("transform", `translate(-40, 42) rotate(-45 ${x} ${y})`);
				} else if (ctx.axis.units.dir === "vertical") {
					n.attr("text-anchor", "end");
				}
			}
		});
	}

	getMaxLabelsCount() {
		switch (this.getDeviceSize()) {
			case "lg":
			case "md":
				return 12;
			case "sm":
				return 8;
		}

		return 4;
	}

	onTabChange(ev) {
		if (this.model.tabId && ev.tabId === this.model.tabId) {
			this.update();
		}
	}

	isVisible() {
		return this.chartContainer.is(":visible");
	}

	update() {
		if (this.chart && this.isVisible()) {
			this.chart.update();
		}
	}

}
