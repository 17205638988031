import $ from "jquery";
import Widget from "../../../Inlined/Widget";
import UserBiscuitEnum from "../VO/UserBiscuitEnum";

/* global FB */
/* global window */
/* global dry */

export default class JoinUsWidget extends Widget {
	constructor(el, model, children, facebookService, userService) {
		super(el, model, children);
		this.facebookService = facebookService;
		this.userService = userService;
	}

	bind() {
		super.bind();

		this.closeBtn = $(".j-close", this.el);

		this.closeBtn.click(ev => this.onCloseBtnClick(ev));

		this.model.isFbConnected = false;

		this.model.disabled = false;

		this.facebookService.onLoad(() => {
			FB.getLoginStatus((response) => {
				if (response.status !== "unknown") {
					this.model.isFbConnected = true;
				}
			});
		});

		$(window).scroll(dry.fn.throttle(() => this.onScroll(), 50));
		setTimeout(() => this.onScroll(), 0);
	}

	onCloseBtnClick(ev) {
		ev.preventDefault();
		let timeStampNow = Date.now();
		timeStampNow = Math.round(timeStampNow / 1000);
		// When the social widget has been already seen, it means this is the second time he sees this widget.
		// After closing this widget the 2nd time, we don't want to show it again. Timestamp set to year ~2045.
		if (this.model.isAlreadySeen) {
			timeStampNow *= 2;
		}
		this.userService.setBiscuit(UserBiscuitEnum.JOIN_US, timeStampNow);
		this.hide();
		this.model.disabled = true;
	}

	onScroll() {
		if ($(window).scrollTop() > 2000 && this.model.isFbConnected && !this.model.disabled) {
			this.show();
		}
	}

	show() {
		this.el
			.stop()
			.animate({ bottom: 240 }, 500);
	}

	hide() {
		this.el
			.stop()
			.animate({ bottom: -100 }, 200);
	}
}
