/* global window */
import Widget from "./../../../Inlined/Widget";

export default class DownloadingEbookWidget extends Widget {
	constructor(el, model, children) {
		super(el, model, children);
	}

	bind() {
		super.bind();

		window.location.href = this.model.url;
	}
}
