
import $ from "jquery";
import Widget from "./../../../../Inlined/Widget";
import AES from "crypto-js/aes";
import MD5 from "crypto-js/md5";
import Utf8 from "crypto-js/enc-utf8";

export default class ImportItemWidget extends Widget {

	constructor(el, model, children, importItemService) {
		super(el, model, children);

		this.importItemService = importItemService;
	}

	attachHitParams() {
		if (this.model.hitParamsAttached) {
			return;
		}

		var params = this.createInternalTrackingParametersQuery();
		this.model.exitUrl += (this.model.exitUrl.indexOf("?") === -1 ? "?" : "&") + params;

		this.hitParamsLinks.each(function() {
			var url = $(this).attr("href");
			url += url.indexOf("?") === -1 ? "?" + params : "&" + params;
			$(this).attr("href", url);
		});

		this.model.hitParamsAttached = true;
	}

	bind() {
		super.bind();

		this.go = $(".j-go", this.el);
		this.hitParamsLinks = this.go.filter("a");

		this.el.on("mouseenter mouseover touchstart", (ev) => { this.onMouseIn(ev) });
		this.go.click((ev) => { this.onGoClick(ev) });

		if (this.model.logData) {
			this.model.logData = JSON.parse(AES.decrypt(this.model.logData.substring(8), MD5(this.model.logData.substring(0, 4)), {iv:MD5(this.model.logData.substring(4, 8))}).toString(Utf8));
		}
	}

	onGoClick(ev) {
		var btn = ev.currentTarget;

		// Udelat proklik! (pokud element neni <a>)
		if (btn.tagName !== "A") {
			window.open(this.model.exitUrl, this.model.sameWindow ? "_self" : "_blank");
		}

		// log click tracking (remarketing, conversion tracking etc.)
		if (this.importItemService.shouldLogClickInSameWindow()) {
			setTimeout(() => { this.importItemService.logClick(this.model, this.model.exitUrl) }, 0);
		}
	}

	onMouseIn(ev) {
		this.attachHitParams();
	}

}