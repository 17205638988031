import $ from "jquery";
import ResponsiveWidget from "./ResponsiveWidget";
import FixHeaderWidget from "./FixHeaderWidget";
import { decodeString, getIconSpriteUrl } from "../utils";
import folderIcon from "../Styles/def/img/icons/folder.svg";
import TypeaheadRequestVO from "../VO/TypeaheadRequestVO";

/* global dry */
/* global __ */
/* global window */

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

export default class SearchWidget extends ResponsiveWidget {
	constructor(el, model, children, ajaxService) {
		super(el, model, children);
		this.ajaxService = ajaxService;
	}

	bind() {
		super.bind();

		this.qInput = $("input[name='q']", this.el);
		this.qInputSource = $("input.j-source", this.el);
		this.submitButton = $("button[type='submit']", this.el);

		this.mobilePlacement = null;
		this.origPlacement = null;

		this.model.lastSuggestions = {};
		this.model.placeholder = this.qInput.attr("placeholder");
		this.model.request = null;
		this.model.userFilledQuery = ""; // Text, ktery uzivatel zadal bez pouziti naseptavace

		this.qInput.blur(ev => this.onQueryBlur(ev));
		this.qInput.focus(ev => this.onQueryFocus(ev));
		this.qInput.keyup(ev => this.onQueryKeyup(ev));
		this.el.submit(ev => this.onSubmit(ev));
		this.bindAutocompleter();
	}

	bindAutocompleter() {
		const escape = dry.str.escapeHtml;
		const widget = this;

		this.qInput.typeahead(
			{
				// Pouzivame custom highlighter nezavisly na diakritice
				highlight: false,
				minLength: 0,
			},
			// Vase posledni hledani
			{
				name: "queriesHistory",
				display: "title",
				source(query, sync, async) { return widget.getQueriesHistory(query, sync, async, this); },
				templates: {
					header: `<div class='tt-header'>${__("Vaše poslední hledání")}</div>`,
					suggestion: suggestion => `<div>${this.highlighter(suggestion.title, suggestion._query)}</div>`,
				},
			},
			// Vami navstivene nabidky
			{
				name: "dealsHistory",
				display: "title",
				source(query, sync, async) { return widget.getDealsHistory(query, sync, async, this); },
				templates: {
					header: `<div class='tt-header'>${__("Vámi navštívené nabídky")}</div>`,
					footer: `<p class='tt-footer'><a class='btn btn-info btn-small j-history' href='${escape(this.model.userHistoryUrl)}'>${__("Zobrazit další nabídky")}</a></p>`,
					suggestion: (suggestion) => {
						if (this.getDeviceSize() === "xs") {
							return `<div class='tt-smartsuggestion media oh m0'>
								${suggestion.discount > 0 ? `<span class='label label-danger pull-right'>-${escape(suggestion.discount)}&nbsp;%</span>` : ""}
								<div class='media-body'>
									<div class='tt-smartsuggestion-title'>${this.highlighter(suggestion.title, suggestion._query)}</div>
								</div>
							</div>`;
						}
						return `<div class='tt-smartsuggestion media oh m0'>
								<div class='tt-smartsuggestion-img img-magic pull-left'>
									<img src='${escape(suggestion.image)}' alt='${escape(suggestion.title)}'>
								</div>
								<div class='media-body'>
									<div class='tt-smartsuggestion-title' title='${escape(suggestion.title)}'>${this.highlighter(suggestion.title, suggestion._query)}</div>
									<div>
										${suggestion.priceFinalFormat ? `<span class='text-danger'>${escape(suggestion.priceFinalFormat)}</span>` : ""}
										${suggestion.discount > 0 ? `<span class='label label-danger mls'>-${escape(suggestion.discount)}&nbsp;%</span>` : (suggestion.discountText ? `<span class='tt-smartsuggestion-disc label label-danger elips mls'>${escape(suggestion.discountText)}</span>` : "")}
									</div>
								</div>
							</div>`;
					},
				},
			},
			// Napovidane vyrazy (podle query)
			{
				name: "queries",
				async: true,
				display: "title",
				// Temporary fix pro issue https://github.com/twitter/typeahead.js/issues/1185
				// Bude opraveno v typeahead.js, verze 0.11.2. Zatim nutno limitovat suggestions na backendu.
				limit: 10000,
				source(query, sync, async) { return widget.getQueries(query, sync, async, this); },
				templates: {
					suggestion: suggestion => `<div>${this.highlighter(suggestion.title, suggestion._query)}</div>`,
				},
			},
			// Kategorie / entity (podle query)
			{
				name: "entities",
				async: true,
				display: "title",
				// Temporary fix pro issue https://github.com/twitter/typeahead.js/issues/1185
				// Bude opraveno v typeahead.js, verze 0.11.2. Zatim nutno limitovat suggestions na backendu.
				limit: 10000,
				source(query, sync, async) { return widget.getEntities(query, sync, async, this); },
				templates: {
					header: `<div class='tt-header'>${__("Kategorie")}</div>`,
					suggestion: (suggestion) => {
						if (this.getDeviceSize() === "xs") {
							return `<div class='tt-smartsuggestion media oh m0'>
								${suggestion.itemsCount ? `<span class='badge badge-muted badge-small pull-right'>${escape(suggestion.itemsCount)}</span>` : ""}
								<div class='media-body'>
									<div class='tt-smartsuggestion-title'>
										<svg class='icon tt-smartsuggestion-icon' aria-hidden='true'>
											<use xlink:href='${getIconSpriteUrl(folderIcon)}'></use>
										</svg>
										${this.highlighter(suggestion.title, suggestion._query)}
									</div>
								</div>
							</div>`;
						}
						return `<div class='tt-smartsuggestion media oh m0'>
								<div class='tt-smartsuggestion-img img-magic pull-left'>
									<img src='${escape(suggestion.image)}' alt='${escape(suggestion.title)}'>
								</div>
								<div class='media-body'>
									<div class='tt-smartsuggestion-title'>${this.highlighter(suggestion.title, suggestion._query)}</div>
									${suggestion.itemsCount ? `<div>
										<span class='badge badge-muted badge-small'>${escape(suggestion.itemsCount)}</span>
									</div>` : ""}
								</div>
							</div>`;
					},
				},
			},
			// Navstivene nabidky (podle query)
			{
				name: "deals",
				async: true,
				display: "title",
				// Temporary fix pro issue https://github.com/twitter/typeahead.js/issues/1185
				// Bude opraveno v typeahead.js, verze 0.11.2. Zatim nutno limitovat suggestions na backendu.
				limit: 10000,
				source(query, sync, async) { return widget.getDeals(query, sync, async, this); },
				templates: {
					header: `<div class='tt-header'>${__("Nehledáte náhodou tyto nabídky?")}</div>`,
					suggestion: (suggestion) => {
						if (this.getDeviceSize() === "xs") {
							return `<div class='tt-smartsuggestion media oh m0'>
								${suggestion.discount > 0 ? `<span class='label label-danger pull-right'>-${escape(suggestion.discount)}&nbsp;%</span>` : ""}
								<div class='media-body'>
									<div class='tt-smartsuggestion-title'>${this.highlighter(suggestion.title, suggestion._query)}</div>
								</div>
							</div>`;
						}
						return `<div class='tt-smartsuggestion media oh m0'>
								<div class='tt-smartsuggestion-img img-magic pull-left'>
									<img src='${escape(suggestion.image)}' alt='${escape(suggestion.title)}'>
								</div>
								<div class='media-body'>
									<div class='tt-smartsuggestion-title' title='${escape(suggestion.title)}'>${this.highlighter(suggestion.title, suggestion._query)}</div>
									<div>
										${suggestion.priceFinalFormat ? `<span class='text-danger'>${escape(suggestion.priceFinalFormat)}</span>` : ""}
										${suggestion.discount > 0 ? `<span class='label label-danger mls'>-${escape(suggestion.discount)}&nbsp;%</span>` : (suggestion.discountText ? `<span class='tt-smartsuggestion-disc label label-danger elips mls'>${escape(suggestion.discountText)}</span>` : "")}
									</div>
								</div>
							</div>`;
					},
				},
			},
		);
		this.qInput.on("typeahead:select", (ev, suggestion) => this.onTypeaheadSelect(ev, suggestion));
		this.qInput.on("typeahead:close", () => this.onTypeaheadClose());
	}

	// Vytvori TypeaheadRequestVO, ktery zapouzdruje vsechny AJAX requesty odeslane
	// v casovem okne danem promennou TypeaheadRequestVO.SEND_AFTER_DELAY
	createRequest(query, datasetName, callback) {
		let created = false;

		// Zahodit request na jinou query nez je ta aktualni
		if (this.model.request && this.model.request.getQuery() !== query && !this.model.request.isSent()) {
			clearTimeout(this.model.request.getTimeout());
		}

		if (!this.model.request || this.model.request.isSent() || this.model.request.getQuery() !== query) {
			this.model.request = new TypeaheadRequestVO();
			this.model.request.setQuery(query);
			created = true;
		}

		const request = this.model.request;
		request.setCallback(datasetName, callback);

		if (created) {
			const timeout = setTimeout(() => this.sendRequest(request), TypeaheadRequestVO.SEND_AFTER_DELAY);
			request.setTimeout(timeout);
		}
		return request;
	}

	getDeals(queryArg, sync, async, dataset) {
		const query = dry.str.trim(queryArg);

		// Nez zacne uzivatel psat, nezobrazi se zadne suggestions
		if (!query.length) {
			dataset.clear();
			return sync([]);
		}

		// Resi issue https://github.com/twitter/typeahead.js/issues/176
		// TODO: Smazat az bude opraveno v typeahead.js
		if (this.model.lastSuggestions.deals) {
			sync(this.limitSuggestionsByDevice(this.model.lastSuggestions.deals, dataset.name));
		}

		// Kdyz uzivatel zacne psat, zobrazi se dealy
		return this.createRequest(query, dataset.name, (suggestions) => {
			this.model.lastSuggestions.deals = suggestions;
			async(this.limitSuggestionsByDevice(suggestions, dataset.name));
			// Resi issue https://github.com/twitter/typeahead.js/issues/176
			// TODO: Smazat az bude opraveno v typeahead.js
			dataset._overwrite(query, this.limitSuggestionsByDevice(suggestions, dataset.name));
		});
	}

	getDealsHistory(queryArg, sync, async, dataset) {
		const query = dry.str.trim(queryArg);

		// Nez zacne uzivatel psat, zobrazi se posledni nabidky
		if (!query.length) {
			return sync(this.limitSuggestionsByDevice(this.model.dealsHistory, dataset.name));
		}

		// Kdyz uzivatel zacne psat, nezobrazi se zadne suggestions
		return sync([]);
	}

	getEntities(queryArg, sync, async, dataset) {
		const query = dry.str.trim(queryArg);

		// Nez zacne uzivatel psat, nezobrazi se zadne suggestions
		if (!query.length) {
			dataset.clear();
			return sync([]);
		}

		// Resi issue https://github.com/twitter/typeahead.js/issues/176
		// @TODO: Smazat az bude opraveno v typeahead.js
		if (this.model.lastSuggestions.entities) {
			sync(this.limitSuggestionsByDevice(this.model.lastSuggestions.entities, dataset.name));
		}

		// Kdyz uzivatel zacne psat, zobrazi se entity
		return this.createRequest(query, dataset.name, (suggestions) => {
			this.model.lastSuggestions.entities = suggestions;
			async(this.limitSuggestionsByDevice(suggestions, dataset.name));
			// Resi issue https://github.com/twitter/typeahead.js/issues/176
			// this.TODO: Smazat az bude opraveno v typeahead.js
			dataset._overwrite(query, this.limitSuggestionsByDevice(suggestions, dataset.name));
		});
	}

	getQueries(queryArg, sync, async, dataset) {
		const query = dry.str.trim(queryArg);

		// Nez zacne uzivatel psat, nezobrazi se zadne suggestions
		if (!query.length) {
			dataset.clear();
			return sync([]);
		}

		// Resi issue https://github.com/twitter/typeahead.js/issues/176
		// TODO: Smazat az bude opraveno v typeahead.js
		if (this.model.lastSuggestions.queries) {
			sync(this.limitSuggestionsByDevice(this.model.lastSuggestions.queries, dataset.name));
		}

		// Kdyz uzivatel zacne psat, zobrazi se naseptavane fraze
		return this.createRequest(query, dataset.name, (suggestions) => {
			this.model.lastSuggestions.queries = suggestions;
			async(this.limitSuggestionsByDevice(suggestions, dataset.name));
			// Resi issue https://github.com/twitter/typeahead.js/issues/176
			// TODO: Smazat az bude opraveno v typeahead.js
			dataset._overwrite(query, this.limitSuggestionsByDevice(suggestions, dataset.name));
		});
	}

	getQueriesHistory(queryArg, sync, async, dataset) {
		const query = dry.str.trim(queryArg);

		// Nez zacne uzivatel psat, zobrazi se posledni hledani
		if (!query.length) {
			return sync(this.limitSuggestionsByDevice(this.model.queriesHistory, dataset.name));
		}

		// Kdyz uzivatel zacne psat, nezobrazi se zadne suggestions
		return sync([]);
	}

	// Zvyrazneni hledane fraze v suggestions i pres odlisnou diakritiku
	// eslint-disable-next-line class-methods-use-this
	highlighter(text, queryArg) {
		const query = dry.str.trim(queryArg);

		if (!query.length) {
			return dry.str.escapeHtml(text);
		}

		const textUndiacritic = dry.str.undiacritic(text);
		const queryUndiacritic = dry.str.undiacritic(query);

		const regex = new RegExp(`(${queryUndiacritic})`, "ig");
		const indices = [];
		let result = regex.exec(textUndiacritic);
		while (result) {
			indices.push(result.index);
			result = regex.exec(textUndiacritic);
		}

		let highlightedText = "";
		let j = 0;
		for (const i of indices) {
			highlightedText = `${highlightedText}${dry.str.escapeHtml(text.substr(j, i - j))}<strong>${dry.str.escapeHtml(text.substr(i, query.length))}</strong>`;
			j = i + query.length;
		}
		return `${highlightedText}${dry.str.escapeHtml(text.substr(j))}`;
	}

	limitSuggestionsByDevice(suggestions, datasetName) {
		let limit = 5;
		if (this.getDeviceSize() === "xs") {
			if (datasetName === "queriesHistory" || datasetName === "queries") {
				limit = 4;
			} else {
				limit = 3;
			}
		}
		return suggestions.slice(0, limit);
	}

	onDeviceSizeChange(ev) {
		if (this.model.position === "error") {
			return;
		}
		// widget nachazejici se uvnitr fix header nereaguje na responsivitu
		if (this.findParentByType(FixHeaderWidget)) {
			return;
		}

		if (this.origPlacement === null) {
			this.origPlacement = $("<div></div>").insertBefore(this.el);
		}
		if (this.mobilePlacement === null) {
			this.mobilePlacement = $(".f-search-mobile");
		}

		if (ev.deviceSize === "xs" || ev.deviceSize === "sm") {
			this.el.appendTo(this.mobilePlacement);
		} else {
			this.el.insertAfter(this.origPlacement);
		}
	}

	onQueryBlur(ev) {
		// Obnovit placeholder
		if (this.model.placeholder) {
			$(ev.currentTarget).attr("placeholder", this.model.placeholder);
		}
	}

	onQueryKeyup(ev) {
		this.model.userFilledQuery = dry.str.trim($(ev.currentTarget).val());
	}

	onQueryFocus(ev) {
		// Zmizet placeholder, aby se BFU nelekl
		this.model.placeholder = $(ev.target).attr("placeholder");
		$(ev.currentTarget).removeAttr("placeholder");

		// Na mobilu a tabletu je malo mista - odsunout input k horni hrane viewportu
		if (this.getDeviceSize() === "xs" || this.getDeviceSize() === "sm") {
			this.scrollToTop();
		}
	}

	onSubmit(ev) {
		ev.preventDefault();

		const query = dry.str.trim(this.qInput.val());

		if (!query) {
			return;
		}

		const placeholderLowerCase = this.model.placeholder ? this.model.placeholder.toLowerCase() : "";

		if (query.toLowerCase() === placeholderLowerCase) {
			return;
		}

		window.location.href = `${this.model.searchUrlTemplate
			.replace(
				/{term}/,
				encodeURIComponent(query)
					.replace(/%20/g, "+"),
			)}?${this.model.sourceTrackingParam}=${this.qInputSource.val()}`;
	}

	onTypeaheadClose() {
		this.model.lastSuggestions = {};
	}

	onTypeaheadSelect(ev, suggestion) {
		let url = null;
		let actionLabel = "";
		if (typeof suggestion === "object" && suggestion.link) {
			url = decodeString(suggestion.link);
			actionLabel = url;
		} else if (typeof suggestion === "object" && suggestion.url) {
			url = suggestion.url;
			actionLabel = url;
		} else if (typeof suggestion === "object" && suggestion.title) {
			url = `${this.model.searchUrlTemplate
				.replace(/{term}/, encodeURIComponent(suggestion.title).replace(/%20/g, "+"))}?${this.model.sourceTrackingParam}=${this.model.trackingParamSuggest}`;
			actionLabel = suggestion.title;
		} else if (typeof suggestion === "string") {
			url = `${this.model.searchUrlTemplate
				.replace(/{term}/, encodeURIComponent(suggestion).replace(/%20/g, "+"))}?${this.model.sourceTrackingParam}=${this.model.trackingParamSuggest}`;
			actionLabel = suggestion;
		}

		if (url) {
			window.location.href = url;
		}
	}

	scrollToTop() {
		const $htmlBody = $("html, body");

		$htmlBody.stop().animate({
			scrollTop: this.qInput.offset().top - 5,
		}, {
			duration: 400,
		});
	}

	sendRequest(request) {
		this.ajaxService.ajax({
			url: this.model.suggestUrlTemplate.replace("{query}", request.getQuery()),
		})
			.done((response) => {
				if (request.getQuery() !== dry.str.trim(this.qInput.val())) {
					return;
				}

				const callbacks = request.getCallbacks();

				// Zavolat vsechny callbacky zapouzdrene v requestu
				Object.keys(callbacks).forEach((datasetName) => {
					const callback = callbacks[datasetName];
					const suggestions = response[datasetName] === undefined ? [] : response[datasetName];
					callback.apply(this, [suggestions, request.getQuery(), datasetName]);
				});
			})
			.fail(() => {
				if (request.getQuery() !== dry.str.trim(this.qInput.val())) {
					return;
				}

				const callbacks = request.getCallbacks();

				// Zavolat vsechny callbacky zapouzdrene v requestu
				Object.keys(callbacks).forEach((datasetName) => {
					const callback = callbacks[datasetName];
					const suggestions = [];
					callback.apply(this, [suggestions, request.getQuery(), datasetName]);
				});
			});
		request.setSent(true);
	}
}
