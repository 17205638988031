import $ from "jquery";
import ItemPreviewWidget from "./ItemPreviewWidget";
import Widget from "../../../../Inlined/Widget";
import ListingLoadedEvent from "../../Event/ListingLoadedEvent";
/* global window */
/* global document */

export default class ItemDialogWidget extends Widget {
	constructor(el, model, children, app, itemPreviewService, fancyboxService, ajaxService) {
		super(el, model, children);
		this.app = app;
		this.itemPreviewService = itemPreviewService;
		this.fancyboxService = fancyboxService;
		this.ajaxService = ajaxService;
		this.termsBtn = $(".j-scroll-to-terms", this.el);
		this.termsTable = $(".deal-terms-table");
	}

	bind() {
		super.bind();

		this.btnNext = $(".j-next", this.el);
		this.btnPrev = $(".j-prev", this.el);
		this.imgNext = $("img", this.btnNext);
		this.imgPrev = $("img", this.btnPrev);
		this.inside = $(".j-inside", this.el);
		this.item = this.findByType(ItemPreviewWidget)[0] || null;

		this.active = false;

		this.app.on(ListingLoadedEvent, ev => this.onListingLoaded(ev));
		this.btnNext.click(ev => this.onBtnNextClick(ev));
		this.btnPrev.click(ev => this.onBtnPrevClick(ev));
		this.inside.scroll(ev => this.onScroll(ev));
		$(document).keydown(ev => this.onKeyDown(ev));
		this.termsBtn.click(() => this.onTermsBtnClick());
	}

	begin() {
		if (this.item && this.item.begin) {
			this.item.begin();
		}

		// Hotfix, aby fungoval lazyloading po nacteni ItemPreviewWidget do Fancyboxu
		// Lazyloading po nacteni nefunguje, protoze elementy se v metode
		// this.app.render vykresluji mimo viewport
		$(window).scroll();
		this.navigationUpdate();
		setTimeout(() => {
			this.active = true;
		}, 100);
	}

	end() {
		if (this.item && this.item.end) {
			this.item.end();
		}
		this.active = false;
	}


	getHash() {
		return this.item ? this.item.getHash() : null;
	}

	getSlug() {
		return this.item ? this.item.getSlug() : null;
	}

	getPageTitle() {
		return this.item ? this.item.getPageTitle() : null;
	}

	navigationUpdate() {
		const nextItemWidget = this.itemPreviewService.getNextItemWidget();
		const prevItemWidget = this.itemPreviewService.getPrevItemWidget();

		this.setNextItemWidget(nextItemWidget);
		this.setPrevItemWidget(prevItemWidget);
	}

	onBtnNextClick(ev) {
		ev.preventDefault();
		this.itemPreviewService.nextPreview();
	}

	onBtnPrevClick(ev) {
		ev.preventDefault();
		this.itemPreviewService.prevPreview();
	}

	// Pri zmene v listingu aktualizovat navigaci (predchozi / dalsi item);
	onListingLoaded(ev) {
		if (!this.active) {
			return;
		}
		if (ev.listing !== this.itemPreviewService.getListingWidget()) {
			return;
		}
		this.navigationUpdate();
	}

	onKeyDown(ev) {
		if (!this.active) {
			return;
		}
		if (document.activeElement && $(document.activeElement).is(":input")) {
			return;
		}

		if (ev.keyCode === 37) {
			// doleva
			this.itemPreviewService.prevPreview();
		} else if (ev.keyCode === 39) {
			// doprava
			this.itemPreviewService.nextPreview();
		}
	}

	onScroll() {
		// Aby fungoval lazyloading nahledu na dealech uvnitr dialogu
		// Je-li widget uvnitr fancyboxu, prenaset skrolovani Fancyboxu na window
		if (this.fancyboxService.isOpened(this)) {
			$(window).trigger("customScroll");
		}
	}

	setNextItemWidget(widget) {
		if (!widget) {
			this.btnNext.hide();
			return;
		}

		const image = widget.getPreviewImage();
		if (!image) {
			this.btnNext.hide();
			return;
		}

		this.imgNext.attr("src", image);
		this.btnNext.show();
	}

	setPrevItemWidget(widget) {
		if (!widget) {
			this.btnPrev.hide();
			return;
		}

		const image = widget.getPreviewImage();
		if (!image) {
			this.btnPrev.hide();
			return;
		}

		this.imgPrev.attr("src", image);
		this.btnPrev.show();
	}

	onTermsBtnClick() {
		const slideWrapper = $(".dealpreview", this.el);
		const termsTable = $(".deal-terms-table", this.el);
		if (slideWrapper && termsTable) {
			const offsetValue = (termsTable.offset().top - slideWrapper.offset().top);
			$(".dealdialog-in", this.el).stop().animate({ scrollTop: offsetValue }, 300);
		}
	}
}
