import $ from "jquery";
import UserVerifiedEvent from "../Event/UserVerifiedEvent";
import UserLoginEvent from "../Event/UserLoginEvent";

/* global document */
/* global dry */
/* global fbq */
/* global location */
/* global window */
/* global __LOCALE__ */
/* global __DEV__ */
/* global Cookies */
/* global Sentry */

export default class HitService {
	static instance;

	constructor(
		hitUrl,
		hvrName,
		trackingParameters,
		app,
		userService,
		ajaxService,
		ajaxTrackRegistrationUrl,
		cookiesAllowedMarketing,
		cookiesAllowedAnalytics
	) {
		if (HitService.instance) {
			HitService.instance.trackingParameters = trackingParameters;
			return HitService.instance;
		}
		this.hitUrl = hitUrl;
		this.hvrName = hvrName;
		this.trackingParameters = trackingParameters;
		this.app = app;
		this.userService = userService;
		this.ajaxService = ajaxService;
		this.ajaxTrackRegistrationUrl = ajaxTrackRegistrationUrl;
		this.listingCount = null;
		this.items = [];
		this.pageOrder = 0;
		this.pageviewId = null;
		this.pageviewHits = 0;
		this.registrationValue = 20;
		this.intervaltracking = false;
		this.userinteraction = false;
		this.cookiesAllowedMarketing = cookiesAllowedMarketing;
		this.cookiesAllowedAnalytics = cookiesAllowedAnalytics;

		$(window).on("scroll customScroll", dry.fn.throttle(() => this.onTracking(), 100));
		this.userService.on(UserLoginEvent, ev => this.onUserLogin(ev));

		if (!Cookies.get(this.hvrName)) {
			// eslint-disable-next-line no-unused-expressions,no-console
			__DEV__ && console.log("HitService: user not verified, waiting for verification");

			this.app.on(UserVerifiedEvent, (ev) => {
				this.userInteraction = true;
				// eslint-disable-next-line no-unused-expressions,no-console
				__DEV__ && console.log(`HitService: UserVerifiedEvent(userId=${ev.userId}, visitId=${ev.visitId} )`);
				if (!this.pageviewHits) { // ignore multiple calls
					// must be on next tick to use UserService's user info
					setTimeout(() => this.trackPageview(), 0);
				}
			});
		} else {
			// eslint-disable-next-line no-unused-expressions,no-console
			__DEV__ && console.log("HitService: user verified, waiting for interaction");
			const bounceFn = () => {
				this.userInteraction = true;
				if (!this.pageviewHits) { // ignore multiple calls
					this.trackPageview();
				}
			};
			$(document).one("mousemove touchstart", bounceFn);
			$(window).one("scroll", bounceFn);
		}

		if (this.userService.shouldTrackRegistration) {
			this.trackRegistration();
		}

		HitService.instance = this;
	}

	// Type string due to number precision
	getPageviewId() {
		return this.pageviewId;
	}

	getTrackingParameters() {
		return this.trackingParameters;
	}

	setListingCount(listingCount) {
		this.listingCount = listingCount;
	}

	register(item) {
		this.items.push(item);
		item.track();
	}

	unregister(item) {
		const index = this.items.indexOf(item);
		if (index !== -1) {
			this.items.splice(index);
		}
	}

	onTracking() {
		if (!this.intervalTracking) {
			this.intervalTracking = true;
			setInterval(() => this.onTracking(), 500);
		}
		this.items.forEach(item => item.track());
	}

	onUserLogin() {
		if (this.userService.shouldTrackRegistration) {
			this.trackRegistration();
		}
	}

	trackPageview(urlArgument) {
		// eslint-disable-next-line no-restricted-globals
		const url = urlArgument === undefined ? `${location.href}` : urlArgument;

		if (!this.userInteraction) {
			return;
		}
		// eslint-disable-next-line no-unused-expressions,no-console
		__DEV__ && console.log("HitService: tracking pageview");
		this.pageviewHits += 1;
		// fix (probably) too long request URI from l.facebook.com
		let { referrer } = document;
		if (referrer.indexOf("l.facebook.com") !== -1) {
			referrer = null;
		}

		// Skrz pageview hit
		this.hit(`${this.hitUrl}/pv`, { url, referrer, lc: this.listingCount }, (data) => {
			if (data.ok) {
				this.pageviewId = data.id;

				if (data.uid) {
					this.userService.setUid(data.uid);
				}

				if (data.sid) {
					this.userService.setSid(data.sid);
				}

				this.userService.logUser();
			}
		});
	}

	trackRegistration() {
		// Propingnuti serveru po odeslani konverznich kodu
		this.ajaxService.ajax({ type: "GET", url: this.ajaxTrackRegistrationUrl });
		this.userService.shouldTrackRegistration = false;
	}

	trackRegistrationAdwords() {

	}

	trackView(id, type, source, medium, campaign, position, term, variant, rank, points) {
		if (type === "deal") {
			// hit deal impression
			this.hit(
				`${this.hitUrl}/im/d/${id}`,
				{
					s_source: source,
					s_medium: medium,
					s_campaign: campaign,
					s_position: position,
					s_term: term,
					s_variant: variant,
					s_rank: rank,
					pt: points,
				},
			);
		} else {
			// do nothing, only deals have impressions
		}
	}

	trackClick(id, type, source, medium, campaign, position, term, variant, rank, content) {
		if (type === "deal") {
			// create deal bounce action
			this.pageOrder += 1;
			this.hit(
				`${this.hitUrl}/bc/d/${id}`,
				{
					o: this.pageOrder,
					s_source: source,
					s_medium: medium,
					s_campaign: campaign,
					s_position: position,
					s_term: term,
					s_variant: variant,
					s_rank: rank,
					s_content: content,
				},
				(data) => {
					if (!data.ok) {
						return;
					}
					$(window).one("focus", () => this.hit(`${this.hitUrl}/bb/${data.id}`, { sig: data.sig }));
				},
			);
		} else {
			// TODO: leaflets, products, import items
		}
	}

	hit(url, parameters, onLoad, onError) {
		const script = document.createElement("script");

		const cbName = `track${Math.round(Math.random() * 1000000)}`;
		window[cbName] = (...args) => {
			try {
				delete (window[cbName]);
			} catch (e) {
				window.cbName = undefined; // IE8 fallback
			}
			if (document && document.body) {
				document.body.removeChild(script);
			}
			if (onLoad) {
				onLoad.apply(this, args);
			}
		};

		if (onError) {
			script.onerror = (...args) => onError.apply(this, args);
		}

		let query = `${url.indexOf("?") === -1 ? "?" : "&"}callback=${cbName}`;
		Object.keys(this.trackingParameters).forEach((k) => {
			if (this.trackingParameters[k]) {
				query = `${query}&${k}=${this.trackingParameters[k]}`;
			}
		});
		if (parameters) {
			Object.keys(parameters).forEach((k) => {
				if (parameters[k]) {
					query = `${query}&${k}=${parameters[k]}`;
				}
			});
		}

		if (this.userService.getUserId()) {
			query = `${query}&u=${this.userService.getUserId()}`;
		}

		if (this.userService.getVisitId()) {
			query = `${query}&v=${this.userService.getVisitId()}`;
		}

		if (this.pageviewId) {
			query = `${query}&pv=${this.pageviewId}`;
		}
		script.src = url + query;
		document.body.appendChild(script);
	}
}
