import $ from "jquery";
import Widget from "./../../../Inlined/Widget";
import ChatWidget from "./ChatWidget";

export default class ContactWidget extends Widget {
	constructor(el, model, children, app) {
		super(el, model, children);
		this.app = app;
	}

	bind() {
		super.bind();
		this.chat = this.app.findByType(ChatWidget)[0] || null;
		this.chatBtn = $(".j-chat", this.el);
		this.chatSpinner = $(".j-chat-spinner", this.el);
		this.chatBtn.click((ev) => {
			ev.preventDefault();

			if (this.chat) {
				this.chatSpinner.show();
				this.chat.load();
				let iterator = 0;
				const interval = setInterval(() => {
					if (iterator <= 5) {
						if (this.chat.isLoaded) {
							this.chat.open();
							clearInterval(interval);
						}
					} else {
						clearInterval(interval);
					}
					iterator += 1;
				}, 500);
				setTimeout(() => { this.chatSpinner.hide(); }, 2000);
			}
		});
	}
}
