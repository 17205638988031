// This is unlocalized container.
// Modules imported here will be included by all locales.
/* global __DEV__ */
import $ from "jquery";
import Container from "../../../../Inlined/Container";
// LazyLoadingService must be required before Sentry. Don't know why, this way it works, other way it started
// to throw exception in Chrome 74...
import LazyLoadingService from "../../Service/LazyLoadingService";

import * as Sentry from "@sentry/browser";
// eslint-disable-next-line no-unused-expressions
!__DEV__ && Sentry.init({
	dsn: "https://5c11c34ed7c74efea98278547fb2d014@sentry.io/1419633",
	release: "13c08014ea85bbde95f7490002f533c979672267",
});

// services
import AjaxService from "../../Service/AjaxService";
import DeadEmailService from "../../Service/DeadEmailService";
import DealService from "../../Service/DealService";
import ImportItemService from "../../Service/ImportItemService";
import FacebookService from "../../Service/FacebookService";
import FancyboxService from "../../Service/FancyboxService";
import FlashMessageService from "../../Service/FlashMessageService";
import HitService from "../../Service/HitService";
import MembersService from "../../Service/MembersService";
import ItemPreviewService from "../../Service/ItemPreviewService";
import ItemWatchdogService from "../../Service/ItemWatchdogService";
import MapService from "../../Service/MapService";
import ScrollService from "../../Service/ScrollService";
import UserService from "../../Service/UserService";
import WatchdogService from "../../Service/WatchdogService";
import WishlistService from "../../Service/WishlistService";
import MobileDetectService from "../../Service/MobileDetectService";
import DateService from "../../Service/DateService";
import ItemShareService from "../../Service/ItemShareService";
import ItemMapService from "../../Service/ItemMapService";
import ReservationService from "../../Service/ReservationService";
import UserLocationService from "../../Service/UserLocationService";
import NotificationService from "../../Service/NotificationService";
import FilterService from "../../Service/FilterService";
import CaptchaService from "../../Service/CaptchaService";
import TravelTermsService from "../../Service/TravelTermsService";
import SubscribeMessageService from "../../Service/SubscribeMessageService";
// widgets
import AdultWarningWidget from "../../Widget/AdultWarningWidget";
import AirportsFilterWidget from "../../Widget/Filter/AirportsFilterWidget";
import BackToTopWidget from "../../Widget/BackToTopWidget";
import BodyWidget from "../../Widget/BodyWidget";
import BoxWidget from "../../Widget/BoxWidget";
import CategoryNavigationWidget from "../../Widget/CategoryNavigationWidget";
import DeadEmailWidget from "../../Widget/DeadEmailWidget";
import EmailingWidget from "../../Widget/EmailingWidget";
import ExitWidget from "../../Widget/Exit/ExitWidget";
import VykuptoExitWidget from "../../Widget/Exit/VykuptoExitWidget";
import FixHeaderWidget from "../../Widget/FixHeaderWidget";
import FlashMessageWidget from "../../Widget/FlashMessageWidget";
import FlashMessagesWidget from "../../Widget/FlashMessagesWidget";
import FloatingWidget from "../../Widget/FloatingWidget";
import HeaderWidget from "../../Widget/HeaderWidget";
import JoinUsWidget from "../../Widget/JoinUsWidget";
import ListingFooterWidget from "../../Widget/ListingFooterWidget";
import ListingWidget from "../../Widget/ListingWidget";
import MapWidget from "../../Widget/MapWidget";
import MobileNavigationWidget from "../../Widget/MobileNavigationWidget";
import PageLinksWidget from "../../Widget/PageLinksWidget";
import RestBoxWidget from "../../Widget/RestBoxWidget";
import SearchCloudWidget from "../../Widget/SearchCloudWidget";
import SearchWidget from "../../Widget/SearchWidget";
import PromoWidget from "../../Widget/Promo/PromoWidget";
import UserRetargetingItemWidget from "../../Widget/UserRetargetingItemWidget";
import UserRetargetingWidget from "../../Widget/UserRetargetingWidget";
import CategoryWidget from "../../Widget/Category/CategoryWidget";
import SearchLocalZoneFilterWidget from "../../Widget/Filter/SearchLocalZoneFilterWidget";
import CarsContestDrawHeaderWidget from "../../Widget/Contest/Cars/CarsContestDrawHeaderWidget";
import CarsContestHeaderWidget from "../../Widget/Contest/Cars/CarsContestHeaderWidget";
import CarsContestPromoWidget from "../../Widget/Contest/Cars/CarsContestPromoWidget";
import CarsContestWidget from "../../Widget/Contest/Cars/CarsContestWidget";
import CarsContestAnswerWidget from "../../Widget/Contest/Cars/CarsContestAnswerWidget";
import ExpiredDealsListWidget from "../../Widget/Deal/ExpiredDealsListWidget";
import SimilarDealListingWidget from "../../Widget/Deal/SimilarDealListingWidget";
import SimilarDealWidget from "../../Widget/Deal/SimilarDealWidget";
import TopDealListingWidget from "../../Widget/Deal/TopDealListingWidget";
import ExitLocationDialogWidget from "../../Widget/Exit/ExitLocationDialogWidget";
import ExitLocationWidget from "../../Widget/Exit/ExitLocationWidget";
import FilterDropdownWidget from "../../Widget/Filter/FilterDropdownWidget";
import SideFilterWidget from "../../Widget/Filter/SideFilterWidget";
import CategoryFilterWidget from "../../Widget/Filter/CategoryFilterWidget";
import FlagFilterWidget from "../../Widget/Filter/FlagFilterWidget";
import PriceFilterWidget from "../../Widget/Filter/PriceFilterWidget";
import ServerFilterWidget from "../../Widget/Filter/ServerFilterWidget";
import SkrzScoreFilterWidget from "../../Widget/Filter/SkrzScoreFilterWidget";
import ManufacturerFilterWidget from "../../Widget/Filter/ManufacturerFilterWidget";
import LocalZoneFilterWidget from "../../Widget/Filter/LocalZoneFilterWidget";
import SearchFilterWidget from "../../Widget/Filter/SearchFilterWidget";
import SideFilterFormWidget from "../../Widget/Filter/SideFilterFormWidget";
import TagFilterWidget from "../../Widget/Filter/TagFilterWidget";
import TopFilterFormWidget from "../../Widget/Filter/TopFilterFormWidget";
import FilterFooterWidget from "../../Widget/Filter/FilterFooterWidget";
import ToursZoneFilterWidget from "../../Widget/Filter/ToursZoneFilterWidget";
import TravelBoardFilterWidget from "../../Widget/Filter/TravelBoardFilterWidget";
import TravelPriceIncludesFilterWidget from "../../Widget/Filter/TravelPriceIncludesFilterWidget";
import TravelClassFilterWidget from "../../Widget/Filter/TravelClassFilterWidget";
import TravelPersonsFilterWidget from "../../Widget/Filter/TravelPersonsFilterWidget";
import TravelTransportFilterWidget from "../../Widget/Filter/TravelTransportFilterWidget";
import MerchantParametersFilterWidget from "../../Widget/Filter/MerchantParametersFilterWidget";
import ItemDetailWidget from "../../Widget/Item/ItemDetailWidget";
import ItemDialogWidget from "../../Widget/Item/ItemDialogWidget";
import ItemPreviewThumbWidget from "../../Widget/Item/ItemPreviewThumbWidget";
import ItemPreviewWidget from "../../Widget/Item/ItemPreviewWidget";
import ItemRetargetingWidget from "../../Widget/Item/ItemRetargetingWidget";
import ItemTagsWidget from "../../Widget/Item/ItemTagsWidget";
import ItemThumbWidget from "../../Widget/Item/ItemThumbWidget";
import MembersItemWidget from "../../Widget/Item/MembersItemWidget";
import ItemWatchdogDialogWidget from "../../Widget/Item/ItemWatchdogDialogWidget";
import AbstractItemWidget from "../../Widget/Item/AbstractItemWidget";
import ItemWidget from "../../Widget/Item/ItemWidget";
import WishlistItemAddonWidget from "../../Widget/Wishlist/WishlistItemAddonWidget";
import WishlistStickerWidget from "../../Widget/Wishlist/WishlistStickerWidget";
import WishlistModalWidget from "../../Widget/Wishlist/WishlistModalWidget";
import WishlistDetailWidget from "../../Widget/Wishlist/WishlistDetailWidget";
import WishlistListWidget from "../../Widget/Wishlist/WishlistListWidget";
import WishlistGroupListWidget from "../../Widget/Wishlist/WishlistGroupListWidget";
import WishlistGroupWidget from "../../Widget/Wishlist/WishlistGroupWidget";
import HeaderMenuWidget from "../../Widget/Navigation/HeaderMenuWidget";
import MobileSideNavigationWidget from "../../Widget/Navigation/MobileSideNavigationWidget";
import RecommendWatchdogSubscribeWidget from "../../Widget/Watchdog/RecommendWatchdogSubscribeWidget";
import ServerWidget from "../../Widget/Server/ServerWidget";
import TagWidget from "../../Widget/Tag/TagWidget";
import TopicDayListingBoxWidget from "../../Widget/Topicday/TopicDayListingBoxWidget";
import TopicDayListingWidget from "../../Widget/Topicday/TopicDayListingWidget";
import TopicDayLoginWidget from "../../Widget/Topicday/TopicDayLoginWidget";
import TopicDayRegistrationWidget from "../../Widget/Topicday/TopicDayRegistrationWidget";
import TopicDayNavigationWidget from "../../Widget/Topicday/TopicDayNavigationWidget";
import TopicDaySubscribeWidget from "../../Widget/Topicday/TopicDaySubscribeWidget";
import TopicDayWidget from "../../Widget/Topicday/TopicDayWidget";
import ContentDemoWidget from "../../Widget/Type/ContentDemoWidget";
import FixUserMenuWidget from "../../Widget/User/FixUserMenuWidget";
import MenuUserLocationWidget from "../../Widget/User/MenuUserLocationWidget";
import UserEmailVideoWidget from "../../Widget/User/UserEmailVideoWidget";
import UserHistoryWidget from "../../Widget/User/UserHistoryWidget";
import UserLoginWidget from "../../Widget/User/UserLoginWidget";
import UserRegisterWidget from "../../Widget/User/UserRegisterWidget";
import UserSubscribeWidget from "../../Widget/User/UserSubscribeWidget";
import UserUniversalLoginWidget from "../../Widget/User/UserUniversalLoginWidget";
import UserWidget from "../../Widget/User/UserWidget";
import ListingEndWatchdogSubscribeWidget from "../../Widget/Watchdog/ListingEndWatchdogSubscribeWidget";
import WatchdogSubscribeWidget from "../../Widget/Watchdog/WatchdogSubscribeWidget";
import LinksWidget from "../../Widget/LinksWidget";
import BirthdayCoverPromoWidget from "../../Widget/BirthdayCoverPromoWidget";
import FlaggedDealsPageWidget from "../../Widget/FlaggedDealsPageWidget";
import FlaggedDealsBoxWidget from "../../Widget/FlaggedDealsBoxWidget";
import RelatedDealsListingWidget from "../../Widget/RelatedDealsListingWidget";
import MerchantMoreLikeThisListingWidget from "../../Widget/Merchant/MerchantMoreLikeThisListingWidget";
import MerchantNearbyDealsWidget from "../../Widget/Merchant/MerchantNearbyDealsWidget";
import NearMerchantsListingWidget from "../../Widget/Merchant/NearMerchantsListingWidget";
import ReviewListingWidget from "../../Widget/Review/ReviewListingWidget";
import ReviewItemWidget from "../../Widget/Review/ReviewItemWidget";
import ReviewFilterWidget from "../../Widget/Review/ReviewFilterWidget";
import StarsRatingWidget from "../../Widget/Review/StarsRatingWidget";
import ReviewFormWidget from "../../Widget/Review/ReviewFormWidget";
import LeaveContactFormWidget from "../../Widget/Review/LeaveContactFormWidget";
import MerchantDetailWidget from "../../Widget/Merchant/MerchantDetailWidget";
import ItemVoucherWidget from "../../Widget/Item/ItemVoucherWidget";
import ItemBannerWidget from "../../Widget/Item/ItemBannerWidget";
import UserForgottenPasswordWidget from "../../Widget/User/UserForgottenPasswordWidget";
import TopicDayDetailWidget from "../../Widget/Topicday/TopicDayDetailWidget";
import CountdownWidget from "../../Widget/CountdownWidget";
import TopicDayCountdownWidget from "../../Widget/Topicday/TopicDayCountdownWidget";
import TopicDayWatchdogSubscribeWidget from "../../Widget/Topicday/TopicDayWatchdogSubscribeWidget";
import ItemWatchdogSubscribeWidget from "../../Widget/Watchdog/ItemWatchdogSubscribeWidget";
import ProductDetailWidget from "../../Widget/Product/ProductDetailWidget";
import ProductDetailTabsWidget from "../../Widget/Product/ProductDetailTabsWidget";
import ProductHistoryWidget from "../../Widget/Product/ProductHistoryWidget";
import BoxNavigationWidget from "../../Widget/BoxNavigationWidget";
import ItemShareWidget from "../../Widget/Item/ItemShareWidget";
import TopicDayThumbWidget from "../../Widget/Topicday/TopicDayThumbWidget";
import ImportItemWidget from "../../Widget/ImportItem/ImportItemWidget";
import DealProductDetailWidget from "../../Widget/Item/DealProductDetailWidget";
import SirensHelpWidget from "../../Widget/Topicday/SirensHelpWidget";
import ChatWidget from "../../Widget/ChatWidget";
import ItemMapWidget from "../../Widget/Item/ItemMapWidget";
import KristalovkaWidget from "../../Widget/KristalovkaWidget";
import ReviewRequestItemWidget from "../../Widget/Review/ReviewRequestItemWidget";
import ReviewPostponeFormWidget from "../../Widget/Review/ReviewPostponeFormWidget";
import KristalovkaPromoWidget from "../../Widget/Promo/KristalovkaPromoWidget";
import ZoneMapWidget from "../../Widget/Zone/ZoneMapWidget";
import TopCategoriesWidget from "../../Widget/Category/TopCategoriesWidget";
import ZoneWidget from "../../Widget/Zone/ZoneWidget";
import LargeCitiesZonesWidget from "../../Widget/Zone/LargeCitiesZonesWidget";
import UserLocationDialogWidget from "../../Widget/User/UserLocationDialogWidget";
import ReservationFormWidget from "../../Widget/Reservation/ReservationFormWidget";
import LocalBoxWidget from "../../Widget/LocalBoxWidget";
import TermCheckFormWidget from "../../Widget/Reservation/TermCheckFormWidget";
import ContactWidget from "../../Widget/ContactWidget";
import ListingMapWidget from "../../Widget/ListingMapWidget";
import ListingMapButtonWidget from "../../Widget/ListingMapButtonWidget";
import ItemMapWindowWidget from "../../Widget/Item/ItemMapWindowWidget";
import CalendarWidget from "../../Widget/Reservation/CalendarWidget";
import ItemReservationWidget from "../../Widget/Reservation/ItemReservationWidget";
import MobileNumberTypeWidget from "../../React/ReactWidget/Reservation/MobileNumberTypeWidget";
import TopicMailSubscribeWidget from "../../Widget/TopicMailSubscribeWidget";
import VerifiedTermListingWidget from "../../Widget/VerifiedTermListingWidget";
import MerchantWidget from "../../Widget/Merchant/MerchantWidget";
import UserSubscribeBoxWidget from "../../Widget/User/UserSubscribeBoxWidget";
import NotificationSubscribeWidget from "../../Widget/NotificationSubscribeWidget";
import UserSubscribeModalWidget from "../../Widget/User/UserSubscribeModalWidget";
import UserSubscribeModal4Widget from "../../Widget/User/UserSubscribeModal4Widget";
import DealsSubscribeWidget from "../../Widget/Deal/DealsSubscribeWidget";
import DealVariationsWidget from "../../Widget/Item/DealVariationsWidget";
import UserDownloadDataWidget from "../../Widget/User/UserDownloadDataWidget";
import UserDeleteWidget from "../../Widget/User/UserDeleteWidget";
import DemoWidget from "../../Widget/DemoWidget";
import ActiveAnchorIndicatorWidget from "../../Widget/ActiveAnchorIndicatorWidget";
import ExtendFilterWidget from "../../Widget/Filter/ExtendFilterWidget";
import ChildrenCountTypeWidget from "../../React/ReactWidget/Reservation/ChildrenCountTypeWidget";
import DownloadingEbookWidget from "../../Widget/DownloadingEbookWidget";
import TermsTableWidget from "../../React/ReactWidget/TermsTable";
import ZonesFilterWidget from "../../React/ReactWidget/ZonesFilter";
import CalendarFilterWidget from "../../React/ReactWidget/CalendarFilter";
import ChildrenZonesWidget from "../../Widget/ChildrenZonesWidget";
import RecentFiltersWidget from "../../Widget/Filter/RecentFiltersWidget";
import HeroImageWidget from "../../Widget/HeroImageWidget";
import BestZoneWidget from "../../Widget/BestZoneWidget";
import TravelPromoBannerWidget from "../../Widget/TravelPromoBannerWidget";
import LeadgenWidget from "../../Widget/Leadgen/LeadgenWidget";
import ListingOrderWidget from "../../Widget/ListingOrderWidget";
import TravelMobileFilterBarWidget from "../../Widget/TravelMobileFilterBarWidget";
import ActiveFiltersWidgetWidget from "../../Widget/ActiveFiltersWidget";
import FooterWidget from "../../Widget/FooterWidget";
import ConsentManagementWidget from '../../Widget/ConsentManagementWidget';
import ConsentManagementLogService from "../../Service/ConsentManagementLogService";

export default class FrontendContainer extends Container {
	getAjaxService() {
		return new AjaxService(this.options.domain);
	}

	getDeadEmailService() {
		return new DeadEmailService(this.getApplication(), this.getAjaxService(), this.getUserService(), this.getFancyboxService(), this.getBodyWidget(), this.options.isDeadEmail, this.options.deadEmailUrl, this.options.domain);
	}

	getDealService() {
		return new DealService(this.options.domain, this.getHitService(), this.getUserService(), this.getApplication(), this.getAjaxService(), this.options.ajaxDealsUrl, this.getBodyWidget(), this.getMembersService(), this.options.cookiesAllowedMarketing, this.options.cookiesAllowedAnalytics);
	}

	getImportItemService() {
		return new ImportItemService(this.options.domain, this.getUserService());
	}

	getItemPreviewService() {
		return new ItemPreviewService(this.options.itemPreviewUrl, this.options.dealDetailUrlPattern, this.getApplication(), this.getAjaxService(), this.getFancyboxService(), this.getBodyWidget(), this.getLazyLoadingService());
	}

	getFlashMessageService() {
		return new FlashMessageService();
	}

	getFacebookService() {
		return new FacebookService();
	}

	getHitService() {
		return new HitService(this.options.hitUrl.split("").reverse().join("").replace(/\/+$/, ""), this.options.hvrName, this.options.tracking, this.getApplication(), this.getUserService(), this.getAjaxService(), this.options.ajaxTrackRegistrationUrl, this.options.cookiesAllowedMarketing, this.options.cookiesAllowedAnalytics);
	}

	getUserService() {
		return new UserService(this.getApplication(), this.options.domain, this.getAjaxService(), this.getFlashMessageService(), this.getFancyboxService(), this.getBodyWidget(), this.getFacebookService(), this.options.authUrl, this.options.userId, this.options.visitId, this.options.facebookId, this.options.uid, this.options.sid, this.options.anonymous, this.options.email, this.options.registrationType, this.options.registrationFrom, this.options.userZoneTitle, this.options.internalTracking, this.options.setUserBiscuitUrl, this.options.getUserBiscuitUrl, this.options.hvrName, this.options.shouldTrackRegistration, this.options.ajaxUserSettingsUrl, this.options.newUser);
	}

	getItemWatchdogService() {
		return new ItemWatchdogService(this.getApplication(), this.getFancyboxService(), this.getWatchdogService(), this.options.itemWatchdogUrlPattern, this.getBodyWidget(), this.options.internalTracking);
	}

	getMembersService() {
		return new MembersService(this.getApplication(), this.getUserService(), this.getFancyboxService(), this.options.membersItemUrlPattern, this.getBodyWidget(), this.options.internalTracking);
	}

	getItemShareService() {
		return new ItemShareService(this.getApplication(), this.getAjaxService(), this.getFancyboxService(), this.options.itemShareUrlPattern, this.options.ajaxItemShareUrlPattern, this.getBodyWidget(), this.options.internalTracking);
	}

	getItemMapService() {
		return new ItemMapService(this.getFancyboxService(), this.options.ajaxItemMapUrlPattern);
	}

	getReservationService() {
		return new ReservationService(this.getFancyboxService(), this.getFilterService(), this.options.ajaxTermRequestUrlPattern, this.options.ajaxTermRequestMerchantUrlPattern, this.options.ajaxFindBookingComTermsUrlPattern);
	}

	getUserLocationService() {
		return new UserLocationService(this.getAjaxService(), this.getFancyboxService(), this.getUserService(), this.options.ajaxWidgetUserLocationUrl, this.options.ajaxUserLocationUrl);
	}

	getWatchdogService() {
		return new WatchdogService(this.getApplication(), this.getAjaxService(), this.getFlashMessageService(), this.getUserService(), this.options.watchdogSetUrl, this.options.internalTracking);
	}

	getWishlistService() {
		return new WishlistService(this.getApplication(), this.getBodyWidget(), this.getAjaxService(), this.getFlashMessageService(), this.getFancyboxService(), this.options.wishlists, this.options.wishlistAjaxUrls);
	}

	getScrollService() {
		return new ScrollService(this.getApplication());
	}


	getMapService() {
		return new MapService();
	}

	getFancyboxService() {
		return new FancyboxService(this.getApplication(), this.getAjaxService(), this.getBodyWidget());
	}

	getNotificationService() {
		return new NotificationService(this.options.webPushPublicKey, this.options.webPushNotificationIdentUrl, this.getAjaxService(), this.getUserService());
	}

	getFilterService() {
		return new FilterService(this.options.filters, this.options.topCategoryId);
	}

	getCaptchaService() {
		return new CaptchaService(this.getAjaxService());
	}

	getLazyLoadingService() {
		return new LazyLoadingService(this.getApplication());
	}

	getTravelTermsService() {
		return new TravelTermsService(this.getFancyboxService(), this.options.ajaxTravelTermsUrlPattern);
	}

	getConsentManagementLogService() {
		return new ConsentManagementLogService(
			this.getApplication(),
			this.getAjaxService(),
			this.getUserService(),
			this.options.consentManagementLogUrl,
		);
	}

	getEmailProvidersSettings() {
		return {
			"seznam.cz": {
				name: "Seznam.cz",
				href: "https://email.seznam.cz",
			},
			"email.cz": {
				name: "Seznam.cz",
				href: "https://email.seznam.cz",
			},
			"post.cz": {
				name: "Seznam.cz",
				href: "https://email.seznam.cz",
			},
			"firmy.cz": {
				name: "Seznam.cz",
				href: "https://email.seznam.cz",
			},
			"spoluzaci.cz": {
				name: "Seznam.cz",
				href: "https://email.seznam.cz",
			},
			"gmail.com": {
				name: "Gmail.com",
				href: "https://gmail.com",
			},
			"centrum.cz": {
				name: "Centrum.cz",
				href: "https://mail.centrum.cz",
			},
			"volny.cz": {
				name: "Volný.cz",
				href: "https://mail.volny.cz",
			},
		};
	}

	getValidateSettings() {
		return {
			errorClass: "help-block",
			errorElement: "span",
			// umisteni error message
			errorPlacement: (error, element) => {
				const holderPrototype = $("<span class=\"help-holder\"></span>");
				let pointer = null;
				if (element.is(":checkbox, :radio, [data-jv-msg=\"group\"]") && (pointer = element.closest("[data-jv-role=\"group\"], .control-group")).length) {
					// inputy uvnitr grupy
					const holder = holderPrototype.appendTo(pointer);
					error.appendTo(holder);
					// checkboxy a radia, ktere nejsou uvnitr grupy
				} else if (element.is(":radio,:checkbox")) {
					let $current = element;
					while (1) {
						if (!$current.is(`:radio[name="${element[0].name}"],:checkbox[name="${element[0].name}"],label[for="${element[0].form.id}_${element[0].id}"]`)) {
							break;
						}

						const $next = $current.next();
						if ($next.length) {
							$current = $next;
						} else {
							break;
						}
					}
					pointer = $current;
					const holder = holderPrototype.insertAfter(pointer);
					error.appendTo(holder);
					// ostatni inputy
				} else {
					pointer = element;
					const holder = holderPrototype.insertAfter(pointer);
					error.appendTo(holder);
				}
			},
			highlight: (element, errorClass, validClass) => {
				// oznacit input a jeho label jako nevalidni
				$(element).addClass(errorClass).removeClass(validClass);
				$(element.form).find(`label[for='${element.id}']`).addClass(errorClass);

				// pokud je input uvnitr control-group, oznac ji jako nevalidni
				const group = $(element).closest("[data-jv-role=\"group\"], .control-group");
				if (group.length) {
					group.addClass("error");
				}
			},
			unhighlight: (element, errorClass, validClass) => {
				// oznacit input a jeho label jako nevalidni
				$(element).removeClass(errorClass).addClass(validClass);
				$(element.form).find(`label[for='${element.id}']`).removeClass(errorClass);

				// pokud je input uvnitr control-group, odznac ji jako nevalidni
				const group = $(element).closest("[data-jv-role=\"group\"], .control-group");
				if (group.length) {
					group.removeClass("error");
				}
			},
			ignoreTitle: true,
			onkeyup: (element, ev) => {
				// Validate on every keyup when element is marked as invalid
				if (this.invalid && this.invalid.hasOwnProperty(element.name)) {
					this.element(element);
				}
			},

		};
	}

	getMobileDetectService() {
		return new MobileDetectService(this.options.isDesktop);
	}

	getDateService() {
		return new DateService(this.options.serverTimestamp);
	}

	getSubscribeMessageService() {
		return new SubscribeMessageService(this.getFlashMessageService(), this.options.changeEmailUrl);
	}

	getBodyWidget() {
		return new BodyWidget();
	}

	createBodyWidget(el, model, children) {
		const w = this.getBodyWidget();
		w.setEl($(el));
		w.setModel(model);
		w.setChildren(children);
		return w;
	}

	createFlashMessageWidget(el, model, children) {
		return new FlashMessageWidget(el, model, children);
	}

	getFlashMessagesWidget() {
		return new FlashMessagesWidget();
	}

	createFlashMessagesWidget(el, model, children) {
		const w = this.getFlashMessagesWidget();
		w.setEl($(el));
		w.setModel(model);
		w.setChildren(children);
		w.app = this.getApplication();
		w.flashMessageService = this.getFlashMessageService();
		w.flashMessageFactory = this.createFlashMessageWidget; // Bez zavorek. Predavame tovarni metodu.
		return w;
	}

	createSearchWidget(el, model, children) {
		return new SearchWidget(el, model, children, this.getAjaxService());
	}

	createHeaderMenuWidget(el, model, children) {
		return new HeaderMenuWidget(el, model, children, this.getApplication());
	}

	createFilterDropdownWidget(el, model, children) {
		return new FilterDropdownWidget(el, model, children, this.getFilterService(), this.getApplication());
	}

	createSideFilterWidget(el, model, children) {
		return new SideFilterWidget(el, model, children);
	}

	createTopFilterFormWidget(el, model, children) {
		return new TopFilterFormWidget(el, model, children, this.getApplication(), this.getFilterService());
	}

	createFilterFooterWidget(el, model, children) {
		return new FilterFooterWidget(el, model, children, this.getApplication());
	}

	createSideFilterFormWidget(el, model, children) {
		return new SideFilterFormWidget(el, model, children, this.getApplication(), this.getFilterService());
	}

	createPriceFilterWidget(el, model, children) {
		return new PriceFilterWidget(el, model, children, this.getApplication(), this.getFilterService());
	}

	createFlagFilterWidget(el, model, children) {
		return new FlagFilterWidget(el, model, children, this.getApplication(), this.getFilterService());
	}

	createToursZoneFilterWidget(el, model, children) {
		return new ToursZoneFilterWidget(el, model, children, this.getApplication(), this.getFilterService());
	}

	createMerchantParametersFilterWidget(el, model, children) {
		return new MerchantParametersFilterWidget(el, model, children, this.getApplication(), this.getFilterService());
	}

	createServerFilterWidget(el, model, children) {
		return new ServerFilterWidget(el, model, children, this.getApplication(), this.getFilterService());
	}

	createSkrzScoreFilterWidget(el, model, children) {
		return new SkrzScoreFilterWidget(el, model, children, this.getApplication(), this.getFilterService());
	}

	createManufacturerFilterWidget(el, model, children) {
		return new ManufacturerFilterWidget(el, model, children, this.getApplication(), this.getFilterService());
	}

	createLocalZoneFilterWidget(el, model, children) {
		return new LocalZoneFilterWidget(el, model, children, this.getApplication(), this.getFilterService());
	}

	createSearchFilterWidget(el, model, children) {
		return new SearchFilterWidget(el, model, children, this.getApplication(), this.getFilterService());
	}

	createTravelTransportFilterWidget(el, model, children) {
		return new TravelTransportFilterWidget(el, model, children, this.getApplication(), this.getFilterService());
	}

	createAirportsFilterWidget(el, model, children) {
		return new AirportsFilterWidget(el, model, children);
	}

	createTravelBoardFilterWidget(el, model, children) {
		return new TravelBoardFilterWidget(el, model, children, this.getApplication(), this.getFilterService());
	}

	createTravelPriceIncludesFilterWidget(el, model, children) {
		return new TravelPriceIncludesFilterWidget(el, model, children, this.getApplication(), this.getFilterService());
	}

	createTravelClassFilterWidget(el, model, children) {
		return new TravelClassFilterWidget(el, model, children, this.getApplication(), this.getFilterService());
	}

	createTravelPersonsFilterWidget(el, model, children) {
		return new TravelPersonsFilterWidget(el, model, children, this.getApplication(), this.getFilterService());
	}

	createCategoryFilterWidget(el, model, children) {
		return new CategoryFilterWidget(el, model, children, this.getApplication(), this.getFilterService());
	}

	createTagFilterWidget(el, model, children) {
		return new TagFilterWidget(el, model, children, this.getApplication(), this.getFilterService());
	}

	createListingWidget(el, model, children) {
		return new ListingWidget(el, model, children, this.getUserService(), this.getFlashMessageService(), this.getApplication(), this.getAjaxService(), AbstractItemWidget, this.getScrollService(), this.getHitService(), this.getBodyWidget(), this.getLazyLoadingService(), this.options.topCategory);
	}

	createCategoryNavigationWidget(el, model, children) {
		return new CategoryNavigationWidget(el, model, children);
	}

	createMenuUserLocationWidget(el, model, children) {
		return new MenuUserLocationWidget(el, model, children, this.getApplication(), this.getUserLocationService());
	}

	createUserWidget(el, model, children) {
		return new UserWidget(el, model, children, this.getApplication(), this.getAjaxService(), this.getUserService());
	}

	createWishlistItemAddonWidget(el, model, children) {
		return new WishlistItemAddonWidget(el, model, children, this.getWishlistService(), this.getApplication());
	}

	createWishlistStickerWidget(el, model, children) {
		return new WishlistStickerWidget(
			el,
			model,
			children,
			this.getWishlistService(),
			this.getApplication(),
			this.getUserService(),
			this.getFlashMessageService(),
			this.getSubscribeMessageService(),
		);
	}

	createItemWidget(el, model, children) {
		return new ItemWidget(el, model, children, this.getApplication(), this.getHitService(), this.getDealService(), this.getItemWatchdogService(), this.getMembersService(), this.getAjaxService(), this.getItemPreviewService(), this.getMobileDetectService(), this.getFlashMessageService(), this.getItemShareService(), this.getReservationService(), this.getItemMapService(), this.getFancyboxService(), this.getBodyWidget(), this.getFilterService());
	}

	createUserHistoryWidget(el, model, children) {
		return new UserHistoryWidget(el, model, children, this.getApplication());
	}

	createContentDemoWidget(el, model, children) {
		return new ContentDemoWidget(el, model, children);
	}

	createConsentManagementWidget(el, model, children) {
		return new ConsentManagementWidget(
			el,
			model,
			children,
			this.getUserService(),
			this.getConsentManagementLogService(),
			this.options.domain,
			this.options.cookiesAllowedMarketing,
			this.options.cookiesAllowedAnalytics,
		);
	}

	createExitWidget(el, model, children) {
		return new ExitWidget(el, model, children, this.getApplication(), this.getAjaxService(), this.getUserService(), this.getDealService(), this.getImportItemService());
	}

	createVykuptoExitWidget(el, model, children) {
		return new VykuptoExitWidget(el, model, children, this.getApplication(), this.getAjaxService(), this.getUserService());
	}

	createUserLoginWidget(el, model, children) {
		return new UserLoginWidget(el, model, children, this.getUserService(), this.getFlashMessageService(), this.getFacebookService(), this.getValidateSettings());
	}

	createExitLocationWidget(el, model, children) {
		return new ExitLocationWidget(el, model, children, this.getApplication(), this.getFancyboxService());
	}

	createExitLocationDialogWidget(el, model, children) {
		return new ExitLocationDialogWidget(el, model, children, this.getApplication(), this.getAjaxService(), this.getFlashMessageService(), this.getValidateSettings());
	}

	createBoxWidget(el, model, children) {
		return new BoxWidget(el, model, children);
	}

	createHeaderWidget(el, model, children) {
		return new HeaderWidget(el, model, children, this.getApplication());
	}

	createFixHeaderWidget(el, model, children) {
		return new FixHeaderWidget(el, model, children, this.getApplication());
	}

	createSearchCloudWidget(el, model, children) {
		return new SearchCloudWidget(el, model, children);
	}

	createUserUniversalLoginWidget(el, model, children) {
		return new UserUniversalLoginWidget(el, model, children, this.getApplication(), this.getAjaxService(), this.getFlashMessageService(), this.getValidateSettings());
	}

	createTopicDayRegistrationWidget(el, model, children) {
		return new TopicDayRegistrationWidget(el, model, children, this.getApplication(), this.getFancyboxService(), this.getUserService());
	}

	createMobileNavigationWidget(el, model, children) {
		return new MobileNavigationWidget(el, model, children);
	}

	createItemThumbWidget(el, model, children) {
		return new ItemThumbWidget(el, model, children);
	}

	createTopicDayLoginWidget(el, model, children) {
		return new TopicDayLoginWidget(el, model, children, this.getUserService(), this.getFancyboxService());
	}

	createEmailingWidget(el, model, children) {
		return new EmailingWidget(el, model, children, this.getFlashMessageService());
	}

	createWatchdogSubscribeWidget(el, model, children) {
		return new WatchdogSubscribeWidget(el, model, children, this.getFlashMessageService(), this.getWatchdogService(), this.getUserService(), this.getApplication(), this.getAjaxService());
	}

	createTopicDayWatchdogSubscribeWidget(el, model, children) {
		return new TopicDayWatchdogSubscribeWidget(el, model, children, this.getFlashMessageService(), this.getWatchdogService(), this.getUserService(), this.getApplication(), this.getAjaxService());
	}

	createUserSubscribeWidget(el, model, children) {
		return new UserSubscribeWidget(el, model, children, this.getApplication(), this.getUserService(), this.getFlashMessageService(), this.getValidateSettings(), this.getEmailProvidersSettings(), this.getFlashMessagesWidget(), this.getFancyboxService());
	}

	createTopicDayWidget(el, model, children) {
		return new TopicDayWidget(el, model, children);
	}

	createItemWatchdogDialogWidget(el, model, children) {
		return new ItemWatchdogDialogWidget(el, model, children, this.getApplication(), this.getFlashMessageService(), this.getItemWatchdogService(), this.getWatchdogService(), this.getValidateSettings(), this.getFancyboxService());
	}

	createListingEndWatchdogSubscribeWidget(el, model, children) {
		return new ListingEndWatchdogSubscribeWidget(el, model, children, this.getFlashMessageService(), this.getWatchdogService(), this.getUserService(), this.getApplication(), this.getAjaxService());
	}

	createRecommendWatchdogSubscribeWidget(el, model, children) {
		return new RecommendWatchdogSubscribeWidget(el, model, children, this.getFlashMessageService(), this.getWatchdogService(), this.getUserService(), this.getApplication());
	}

	createSimilarDealWidget(el, model, children) {
		return new SimilarDealWidget(el, model, children);
	}

	createExpiredDealsListWidget(el, model, children) {
		return new ExpiredDealsListWidget(el, model, children);
	}

	createTopDealListingWidget(el, model, children) {
		// HitService intentionally not injected(inject only to ListingWidget)
		return new TopDealListingWidget(el, model, children, this.getUserService(), this.getFlashMessageService(), this.getApplication(), this.getAjaxService(), AbstractItemWidget, this.getScrollService(), null, this.getBodyWidget(), this.getLazyLoadingService(), this.options.topCategory);
	}

	createMembersItemWidget(el, model, children) {
		return new MembersItemWidget(el, model, children, this.getApplication(), this.getHitService(), this.getDealService(), this.getItemWatchdogService(), this.getMembersService(), this.getAjaxService(), this.getItemPreviewService(), this.getMobileDetectService(), this.getFlashMessageService(), this.getItemShareService(), this.getReservationService(), this.getUserService(), this.getFancyboxService());
	}

	createItemRetargetingWidget(el, model, children) {
		return new ItemRetargetingWidget(el, model, children, this.getApplication(), this.getHitService(), this.getDealService(), this.getItemWatchdogService(), this.getMembersService(), this.getAjaxService(), this.getItemPreviewService(), this.getMobileDetectService(), this.getFlashMessageService(), this.getItemShareService(), this.getReservationService());
	}

	createUserRetargetingItemWidget(el, model, children) {
		return new UserRetargetingItemWidget(el, model, children);
	}

	createUserRetargetingWidget(el, model, children) {
		return new UserRetargetingWidget(el, model, children, this.getApplication(), this.getAjaxService(), this.getFlashMessageService());
	}

	createUserRegisterWidget(el, model, children) {
		return new UserRegisterWidget(el, model, children, this.getApplication(), this.getUserService(), this.getValidateSettings(), this.getEmailProvidersSettings(), this.getFlashMessagesWidget());
	}

	createWishlistDetailWidget(el, model, children) {
		return new WishlistDetailWidget(el, model, children, this.getWishlistService(), this.getFancyboxService());
	}

	createWishlistListWidget(el, model, children) {
		return new WishlistListWidget(el, model, children, this.getWishlistService());
	}

	createWishlistGroupListWidget(el, model, children) {
		return new WishlistGroupListWidget(el, model, children, this.getApplication(), this.getAjaxService(), this.getBodyWidget());
	}

	createWishlistGroupWidget(el, model, children) {
		return new WishlistGroupWidget(el, model, children, this.getWishlistService());
	}

	createDeadEmailWidget(el, model, children) {
		return new DeadEmailWidget(el, model, children, this.getUserService(), this.getFlashMessageService(), this.getDeadEmailService(), this.getValidateSettings(), this.getFancyboxService());
	}

	createItemPreviewWidget(el, model, children) {
		return new ItemPreviewWidget(el, model, children, this.getApplication(), this.getHitService(), this.getDealService(), this.getItemWatchdogService(), this.getMembersService(), this.getAjaxService(), this.getItemPreviewService(), this.getMobileDetectService(), this.getFlashMessageService(), this.getItemShareService(), this.getReservationService(), this.getUserService(), this.getScrollService(), this.getFilterService());
	}

	createItemDialogWidget(el, model, children) {
		return new ItemDialogWidget(el, model, children, this.getApplication(), this.getItemPreviewService(), this.getFancyboxService(), this.getAjaxService());
	}

	createFloatingWidget(el, model, children) {
		return new FloatingWidget(el, model, children, this.getApplication(), this.options.domain);
	}

	createBackToTopWidget(el, model, children) {
		return new BackToTopWidget(el, model, children);
	}

	createTopicDaySubscribeWidget(el, model, children) {
		return new TopicDaySubscribeWidget(el, model, children, this.getWatchdogService(), this.getUserService());
	}

	createCarsContestWidget(el, model, children) {
		return new CarsContestWidget(el, model, children, this.getAjaxService(), this.getFlashMessageService(), this.getUserService(), this.getFancyboxService());
	}

	createCarsContestAnswerWidget(el, model, children) {
		return new CarsContestAnswerWidget(el, model, children, this.getAjaxService(), this.getFlashMessageService());
	}

	createCarsContestHeaderWidget(el, model, children) {
		return new CarsContestHeaderWidget(el, model, children);
	}

	createCarsContestDrawHeaderWidget(el, model, children) {
		return new CarsContestDrawHeaderWidget(el, model, children, this.getAjaxService(), this.getFlashMessageService(), this.getFacebookService());
	}

	createJoinUsWidget(el, model, children) {
		return new JoinUsWidget(el, model, children, this.getFacebookService(), this.getUserService());
	}

	createListingFooterWidget(el, model, children) {
		return new ListingFooterWidget(el, model, children, this.getApplication());
	}

	createTopicDayListingWidget(el, model, children) {
		return new TopicDayListingWidget(el, model, children);
	}

	createCarsContestPromoWidget(el, model, children) {
		return new CarsContestPromoWidget(el, model, children, this.getFancyboxService(), this.options.domain);
	}

	createServerWidget(el, model, children) {
		return new ServerWidget(el, model, children);
	}

	createTagWidget(el, model, children) {
		return new TagWidget(el, model, children);
	}

	createCategoryWidget(el, model, children) {
		return new CategoryWidget(el, model, children, this.getApplication(), this.getScrollService());
	}

	createSearchLocalZoneFilterWidget(el, model, children) {
		return new SearchLocalZoneFilterWidget(el, model, children, this.getApplication(), this.getFilterService());
	}

	createAdultWarningWidget(el, model, children) {
		return new AdultWarningWidget(el, model, children, this.getFancyboxService(), this.getAjaxService(), this.options.domain);
	}

	createTopicDayListingBoxWidget(el, model, children) {
		return new TopicDayListingBoxWidget(el, model, children, this.getApplication(), this.getAjaxService(), this.options.domain);
	}

	createTopicDayNavigationWidget(el, model, children) {
		return new TopicDayNavigationWidget(el, model, children, this.getApplication(), this.getBodyWidget());
	}

	createRestBoxWidget(el, model, children) {
		return new RestBoxWidget(el, model, children, this.getApplication());
	}

	createUserEmailVideoWidget(el, model, children) {
		return new UserEmailVideoWidget(el, model, children, this.getFancyboxService(), this.getUserService(), this.getFlashMessagesWidget());
	}

	createItemTagsWidget(el, model, children) {
		return new ItemTagsWidget(el, model, children);
	}

	createMapWidget(el, model, children) {
		return new MapWidget(el, model, children);
	}

	createItemDetailWidget(el, model, children) {
		return new ItemDetailWidget(el, model, children, this.getApplication(), this.getAjaxService());
	}

	createFixUserMenuWidget(el, model, children) {
		return new FixUserMenuWidget(el, model, children, this.getApplication());
	}

	createPageLinksWidget(el, model, children) {
		return new PageLinksWidget(el, model, children, this.getApplication());
	}

	createSimilarDealListingWidget(el, model, children) {
		// HitService intentionally not injected(inject only to ListingWidget )
		return new SimilarDealListingWidget(el, model, children, this.getUserService(), this.getFlashMessageService(), this.getApplication(), this.getAjaxService(), AbstractItemWidget, this.getScrollService(), null, this.getBodyWidget(), this.getLazyLoadingService(), this.options.topCategory);
	}

	createItemPreviewThumbWidget(el, model, children) {
		return new ItemPreviewThumbWidget(el, model, children);
	}

	createMobileSideNavigationWidget(el, model, children) {
		return new MobileSideNavigationWidget(el, model, children);
	}

	createPromoWidget(el, model, children) {
		return new PromoWidget(el, model, children);
	}

	createLinksWidget(el, model, children) {
		return new LinksWidget(el, model, children);
	}

	createFlaggedDealsPageWidget(el, model, children) {
		return new FlaggedDealsPageWidget(el, model, children);
	}

	createBirthdayCoverPromoWidget(el, model, children) {
		return new BirthdayCoverPromoWidget(el, model, children, this.options.domain);
	}

	createFlaggedDealsBoxWidget(el, model, children) {
		return new FlaggedDealsBoxWidget(el, model, children, this.getApplication());
	}

	createRelatedDealsListingWidget(el, model, children) {
		// HitService intentionally not injected(inject only to ListingWidget )
		return new RelatedDealsListingWidget(el, model, children, this.getUserService(), this.getFlashMessageService(), this.getApplication(), this.getAjaxService(), AbstractItemWidget, this.getScrollService(), null, this.getBodyWidget(), this.getLazyLoadingService(), this.options.topCategory);
	}

	createMerchantMoreLikeThisListingWidget(el, model, children) {
		// HitService intentionally not injected(inject only to ListingWidget )
		return new MerchantMoreLikeThisListingWidget(el, model, children, this.getUserService(), this.getFlashMessageService(), this.getApplication(), this.getAjaxService(), AbstractItemWidget, this.getScrollService(), null, this.getBodyWidget(), this.getLazyLoadingService(), this.options.topCategory);
	}

	createMerchantNearbyDealsWidget(el, model, children) {
		// HitService intentionally not injected(inject only to ListingWidget )
		return new MerchantNearbyDealsWidget(el, model, children, this.getUserService(), this.getFlashMessageService(), this.getApplication(), this.getAjaxService(), AbstractItemWidget, this.getScrollService(), null, this.getBodyWidget(), this.getLazyLoadingService(), this.options.topCategory);
	}

	createNearMerchantsListingWidget(el, model, children) {
		// HitService intentionally not injected(inject only to ListingWidget )
		return new NearMerchantsListingWidget(el, model, children, this.getUserService(), this.getFlashMessageService(), this.getApplication(), this.getAjaxService(), AbstractItemWidget, this.getScrollService(), null, this.getBodyWidget(), this.options.topCategory);
	}

	createReviewItemWidget(el, model, children) {
		return new ReviewItemWidget(el, model, children, this.getApplication(), this.getFlashMessageService(), this.getAjaxService());
	}

	createReviewFilterWidget(el, model, children) {
		return new ReviewFilterWidget(el, model, children, this.getApplication());
	}

	createStarsRatingWidget(el, model, children) {
		return new StarsRatingWidget(el, model, children, this.getMobileDetectService());
	}

	createReviewFormWidget(el, model, children) {
		return new ReviewFormWidget(el, model, children);
	}

	createLeaveContactFormWidget(el, model, children) {
		return new LeaveContactFormWidget(el, model, children);
	}

	createReviewListingWidget(el, model, children) {
		// HitService intentionally not injected(inject only to ListingWidget )
		return new ReviewListingWidget(el, model, children, this.getUserService(), this.getFlashMessageService(), this.getApplication(), this.getAjaxService(), ReviewItemWidget, this.getScrollService(), null, this.getBodyWidget(), this.getLazyLoadingService(), this.options.topCategory);
	}

	createMerchantDetailWidget(el, model, children) {
		return new MerchantDetailWidget(el, model, children, this.getFancyboxService(), this.getReservationService());
	}

	createItemVoucherWidget(el, model, children) {
		return new ItemVoucherWidget(el, model, children, this.getApplication(), this.getFlashMessageService());
	}

	createItemBannerWidget(el, model, children) {
		return new ItemBannerWidget(el, model, children, this.getApplication());
	}

	createUserForgottenPasswordWidget(el, model, children) {
		return new UserForgottenPasswordWidget(el, model, children, this.getValidateSettings());
	}

	createTopicDayDetailWidget(el, model, children) {
		return new TopicDayDetailWidget(el, model, children, this.getApplication());
	}

	createCountdownWidget(el, model, children) {
		return new CountdownWidget(el, model, children, this.getApplication(), this.getDateService());
	}

	createTopicDayCountdownWidget(el, model, children) {
		return new TopicDayCountdownWidget(el, model, children, this.getApplication(), this.getDateService(), this.getDealService());
	}

	createItemWatchdogSubscribeWidget(el, model, children) {
		return new ItemWatchdogSubscribeWidget(el, model, children, this.getFlashMessageService(), this.getWatchdogService(), this.getUserService(), this.getItemWatchdogService());
	}

	createProductDetailWidget(el, model, children) {
		return new ProductDetailWidget(el, model, children, this.getApplication());
	}

	createProductDetailTabsWidget(el, model, children) {
		return new ProductDetailTabsWidget(el, model, children, this.getApplication());
	}

	createProductHistoryWidget(el, model, children) {
		return new ProductHistoryWidget(el, model, children, this.getApplication());
	}

	createBoxNavigationWidget(el, model, children) {
		return new BoxNavigationWidget(el, model, children, this.getApplication(), this.getBodyWidget());
	}

	createItemShareWidget(el, model, children) {
		return new ItemShareWidget(el, model, children, this.getItemShareService(), this.getFlashMessageService());
	}

	createWishlistModalWidget(el, model, children) {
		return new WishlistModalWidget(el, model, children, this.getApplication(), this.getWishlistService(), this.getFlashMessageService(), this.getFancyboxService());
	}

	createTopicDayThumbWidget(el, model, children) {
		return new TopicDayThumbWidget(el, model, children);
	}

	createImportItemWidget(el, model, children) {
		return new ImportItemWidget(el, model, children, this.getImportItemService());
	}

	createDealProductDetailWidget(el, model, children) {
		return new DealProductDetailWidget(el, model, children, this.getApplication(), this.getHitService(), this.getDealService(), this.getItemWatchdogService(), this.getMembersService(), this.getAjaxService(), this.getItemPreviewService(), this.getMobileDetectService(), this.getFlashMessageService(), this.getItemShareService(), this.getReservationService());
	}

	createSirensHelpWidget(el, model, children) {
		return new SirensHelpWidget(el, model, children);
	}

	createChatWidget(el, model, children) {
		return new ChatWidget(el, model, children, this.getUserService());
	}

	createItemMapWidget(el, model, children) {
		return new ItemMapWidget(el, model, children, this.getApplication(), this.getHitService(), this.getDealService(), this.getItemWatchdogService(), this.getMembersService(), this.getAjaxService(), this.getItemPreviewService(), this.getMobileDetectService(), this.getFlashMessageService(), this.getItemShareService(), this.getReservationService(), this.getItemMapService(), this.getWishlistService());
	}

	createKristalovkaWidget(el, model, children) {
		return new KristalovkaWidget(el, model, children);
	}

	createReviewRequestItemWidget(el, model, children) {
		return new ReviewRequestItemWidget(el, model, children, this.getAjaxService());
	}

	createReviewPostponeFormWidget(el, model, children) {
		return new ReviewPostponeFormWidget(el, model, children, this.getAjaxService(), this.getFlashMessageService());
	}

	createKristalovkaPromoWidget(el, model, children) {
		return new KristalovkaPromoWidget(el, model, children, this.getUserService());
	}

	createZoneMapWidget(el, model, children) {
		return new ZoneMapWidget(el, model, children, this.getMobileDetectService());
	}

	createTopCategoriesWidget(el, model, children) {
		return new TopCategoriesWidget(el, model, children);
	}

	createZoneWidget(el, model, children) {
		return new ZoneWidget(el, model, children);
	}

	createLargeCitiesZonesWidget(el, model, children) {
		return new LargeCitiesZonesWidget(el, model, children);
	}

	createUserLocationDialogWidget(el, model, children) {
		return new UserLocationDialogWidget(el, model, children, this.getMapService(), this.getValidateSettings(), this.getFlashMessageService(), this.getUserService(), this.getUserLocationService(), this.getFancyboxService());
	}

	createReservationFormWidget(el, model, children) {
		return new ReservationFormWidget(el, model, children);
	}

	createLocalBoxWidget(el, model, children) {
		return new LocalBoxWidget(el, model, children, this.getApplication(), this.getUserLocationService());
	}

	createTermCheckFormWidget(el, model, children) {
		return new TermCheckFormWidget(el, model, children, this.getUserService());
	}

	createDirectTermCheckWidget(el, model, children) {
		return new DirectTermCheckWidget(el, model, children);
	}

	createContactWidget(el, model, children) {
		return new ContactWidget(el, model, children, this.getApplication());
	}

	createListingMapWidget(el, model, children) {
		return new ListingMapWidget(el, model, children, this.getAjaxService(), this.getFlashMessageService(), this.getApplication(), this.getBodyWidget());
	}

	createListingMapButtonWidget(el, model, children) {
		return new ListingMapButtonWidget(el, model, children, this.getApplication(), this.getAjaxService(), this.getScrollService(), this.getFlashMessageService());
	}

	createItemMapWindowWidget(el, model, children) {
		return new ItemMapWindowWidget(el, model, children, this.getApplication(), this.getHitService(), this.getDealService(), this.getItemWatchdogService(), this.getMembersService(), this.getAjaxService(), this.getItemPreviewService(), this.getMobileDetectService(), this.getFlashMessageService(), this.getItemShareService(), this.getBodyWidget(), this.getFilterService());
	}

	createCalendarWidget(el, model, children) {
		return new CalendarWidget(el, model, children, this.getApplication(), this.getFlashMessageService(), this.getHitService(), this.getAjaxService(), this.options.cookiesAllowedMarketing);
	}

	createItemReservationWidget(el, model, children) {
		return new ItemReservationWidget(el, model, children, this.getApplication(), this.getAjaxService(), this.getFlashMessageService(), this.getHitService(), this.getUserService(), this.getFancyboxService(), this.getValidateSettings());
	}

	createMobileNumberTypeWidget(el, model, children) {
		return new MobileNumberTypeWidget(el, model, children);
	}

	createChildrenCountTypeWidget(el, model, children) {
		return new ChildrenCountTypeWidget(el, model, children);
	}

	createTopicMailSubscribeWidget(el, model, children) {
		return new TopicMailSubscribeWidget(el, model, children, this.getUserService(), this.getFlashMessageService(), this.getSubscribeMessageService());
	}

	createVerifiedTermListingWidget(el, model, children) {
		// HitService intentionally not injected(inject only to ListingWidget )
		return new VerifiedTermListingWidget(el, model, children, this.getUserService(), this.getFlashMessageService(), this.getApplication(), this.getAjaxService(), AbstractItemWidget, this.getScrollService(), null, this.getBodyWidget(), this.getLazyLoadingService(), this.options.topCategory);
	}

	createMerchantWidget(el, model, children) {
		return new MerchantWidget(el, model, children);
	}

	createUserSubscribeBoxWidget(el, model, children) {
		return new UserSubscribeBoxWidget(el, model, children, this.getUserService(), this.getValidateSettings(), this.getFlashMessageService(), this.getFlashMessagesWidget(), this.getFancyboxService(), this.getSubscribeMessageService());
	}

	createNotificationSubscribeWidget(el, model, children) {
		return new NotificationSubscribeWidget(el, model, children, this.getNotificationService(), this.getUserService(), this.getFlashMessageService());
	}

	createUserSubscribeModalWidget(el, model, children) {
		return new UserSubscribeModalWidget(el, model, children, this.getApplication(), this.getUserService());
	}

	createUserSubscribeModal4Widget(el, model, children) {
		return new UserSubscribeModal4Widget(el, model, children, this.getApplication(), this.getUserService(), this.getFancyboxService(), this.getValidateSettings(), this.getWatchdogService(), this.getItemWatchdogService(), this.getFlashMessageService(), this.getFlashMessagesWidget());
	}

	createDealsSubscribeWidget(el, model, children) {
		return new DealsSubscribeWidget(el, model, children, this.getUserService(), this.getFlashMessageService(), this.getSubscribeMessageService());
	}

	createDealVariationsWidget(el, model, children) {
		return new DealVariationsWidget(el, model, children, this.getItemPreviewService());
	}

	createUserDownloadDataWidget(el, model, children) {
		return new UserDownloadDataWidget(el, model, children);
	}

	createUserDeleteWidget(el, model, children) {
		return new UserDeleteWidget(el, model, children);
	}

	createExtendFilterWidget(el, model, children) {
		return new ExtendFilterWidget(el, model, children);
	}

	createDemoWidget(el, model, children) {
		return new DemoWidget(el, model, children);
	}

	createActiveAnchorIndicatorWidget(el, model, children) {
		return new ActiveAnchorIndicatorWidget(el, model, children, this.getApplication(), this.getScrollService());
	}

	createDownloadingEbookWidget(el, model, children) {
		return new DownloadingEbookWidget(el, model, children);
	}

	createTermsTableWidget(el, model, children) {
		return new TermsTableWidget(el, model, children, this.getApplication(), this.getHitService(), this.getDealService(), this.getItemWatchdogService(), this.getMembersService(), this.getAjaxService(), this.getItemPreviewService(), this.getMobileDetectService(), this.getFlashMessageService(), this.getItemShareService(), this.getReservationService(), this.getFilterService());
	}

	createZonesFilterWidget(el, model, children) {
		return new ZonesFilterWidget(el, model, children, this.getAjaxService(), this.getFilterService(), this.getApplication());
	}

	createCalendarFilterWidget(el, model, children) {
		return new CalendarFilterWidget(el, model, children, this.getFilterService(), this.getApplication());
	}

	createChildrenZonesWidget(el, model, children) {
		return new ChildrenZonesWidget(el, model, children, this.getApplication());
	}

	createRecentFiltersWidget(el, model, children) {
		return new RecentFiltersWidget(el, model, children);
	}

	createHeroImageWidget(el, model, children) {
		return new HeroImageWidget(el, model, children, this.getApplication());
	}

	createBestZoneWidget(el, model, children) {
		return new BestZoneWidget(el, model, children, this.getScrollService());
	}

	createTravelPromoBannerWidget(el, model, children) {
		return new TravelPromoBannerWidget(el, model, children);
	}

	createLeadgenWidget(el, model, children) {
		return new LeadgenWidget(
			el,
			model,
			children,
			this.getApplication(),
			this.getAjaxService(),
			this.getFlashMessageService(),
			this.getHitService(),
			this.getUserService(),
			this.getFancyboxService(),
			this.getValidateSettings(),
		);
	}

	createListingOrderWidget(el, model, children) {
		return new ListingOrderWidget(el, model, children, this.getApplication());
	}

	createTravelMobileFilterBarWidget(el, model, children) {
		return new TravelMobileFilterBarWidget(el, model, children, this.getApplication());
	}

	createActiveFiltersWidget(el, model, children) {
		return new ActiveFiltersWidgetWidget(el, model, children, this.getApplication());
	}

	createFooterWidget(el, model, children) {
		return new FooterWidget(el, model, children, this.getConsentManagementLogService());
	}
}
