import $ from "jquery";
import cx from "classnames";
import moment from "moment";
import Widget from "../../../../Inlined/Widget";
import "../../../../../dry/str";
import CalendarSelectEvent from "../../Event/CalendarSelectEvent";
import { getIconSpriteUrl } from "../../utils";
import triangleLeftIcon from "../../Styles/def/img/icons/triangle-left.svg";
import triangleRightIcon from "../../Styles/def/img/icons/triangle-right.svg";
// eslint-disable-next-line import/named
import { lang } from "../../Locale";
// eslint-disable-next-line import/named
/* global document */
/* global dry */
/* global window */

export default class CalendarWidget extends Widget {
	constructor(el, model, children, app, flashMessageService, hitService, ajaxService, cookiesAllowedMarketing) {
		super(el, model, children);

		this.app = app;
		this.flashMessageService = flashMessageService;
		this.hitService = hitService;
		this.ajaxService = ajaxService;
		this.cookiesAllowedMarketing = cookiesAllowedMarketing;
	}

	bind() {
		super.bind();
		moment.locale(lang);

		this.logCapacityRequest(
			null,
			this.model.clickedDealId,
			null,
			this.model.clickedMerchantId,
			"opened",
		);

		this.model.dropdownDateString = null;
		this.model.today = moment().startOf("day");
		this.model.selectedDate = null;
		this.model.selectedDaysLength = null;
		this.model.selectedDealId = null; // deal pro vybrany interval
		this.model.selectedVariantId = null; // varianta pro vybrany interval

		// deal / varianta zobrazena v kalendari
		if (this.model.dealsAndVariants.length === 1) {
			this.model.dealId = this.model.dealsAndVariants[0].dealId;
			this.model.variantId = this.model.dealsAndVariants[0].variantId || null;
		} else {
			this.model.dealId = null;
			this.model.variantId = null;
		}

		// Kurzor se musi pocitat az kdyz je znama dostupnost a zvolena varianta
		this.model.cursor = typeof this.model.cursor === "string"
			? moment.min(moment.max(this.getStartDate(), moment(this.model.cursor)), this.getEndDate())
			: this.getStartDate().clone();

		this.el.on("click", ".j-day", ev => this.onDayClick(ev));
		this.el.on("click", ".j-day-variant", ev => this.onDayVariantClick(ev));
		this.el.on("click", ".j-prevmonth", ev => this.onBtnMonthClick(ev, -1));
		this.el.on("click", ".j-nextmonth", ev => this.onBtnMonthClick(ev, 1));
		$(document.body).on("click", this.onOutsideClickFn = ev => this.onOutsideClick(ev));

		this.render();
	}

	unbind() {
		super.unbind();
		$(document.body).off("click", this.onOutsideClickFn);
	}

	/**
	 * Omezi konec kalendare podle dostupnosti variant, max. + 1 rok
	 * @returns {moment|null}
	 */
	getEndDate() {
		const lastAvailableDay = this.getLastAvailableDay();

		return lastAvailableDay
			// Zobrazit posledni dostupny den + nasledujici noc (= 1 den navic). Max. 1 rok od ted.
			? moment.min(lastAvailableDay.clone().add(1, "days"), this.model.today.clone().add(1, "years"))
			: this.model.today.clone();
	}

	isVariantShown() {
		return this.model.dealId !== null;
	}

	/**
	 * Vrati prvni dostupny den pro prave zobrazeny kalendar
	 * (kalendar muze zobrazovat vsechny varianty nebo jednu konkretni variantu).
	 * Den je povazovan za dostupny, pokud neni nedostupny.
	 * @returns {moment|null}
	 */
	getFirstAvailableDay() {
		// Prvni dostupny den pro vsechny varianty dohromady
		if (!this.isVariantShown()) {
			// Cachujeme
			if (this.model.firstDayAvailable !== undefined) {
				return this.model.firstDayAvailable.clone();
			}

			const firstDaysStrings = Object
				.keys(this.model.capacities)
				.map((key) => {
					const variantCapacity = this.model.capacities[key];
					return this.getFirstAvailableDayForVariant(variantCapacity.dealId, variantCapacity.variantId);
				})
				.filter(firstDay => firstDay !== null)
				.map(firstDay => firstDay.format("YYYY-MM-DD"));
			const firstDayString = firstDaysStrings.sort()[0] || null;
			this.model.firstDayAvailable = firstDayString ? moment(firstDayString) : null;
			return this.model.firstDayAvailable.clone();
		}

		// Prvni dostupny den pro konkretni variantu
		return this.getFirstAvailableDayForVariant(this.model.dealId, this.model.variantId);
	}

	/**
	 * Vrati prvni dostupny den pro danou variantu
	 * Den je povazovan za dostupny, pokud neni nedostupny.
	 * @param {number} dealId
	 * @param {number|null} variantId
	 * @returns {moment|null}
	 */
	getFirstAvailableDayForVariant(dealId, variantId = null) {
		let firstDayAvailable = null;
		const variantCapacity = this.getVariantCapacity(dealId, variantId);

		// Cachujeme
		if (variantCapacity && variantCapacity.firstDayAvailable) {
			return variantCapacity.firstDayAvailable;
		}

		if (variantCapacity !== null) {
			const dateStart = moment(variantCapacity.dateStart);
			const dateEnd = moment(variantCapacity.dateEnd);

			// Hledame prvni dostupny den v rozsahu dateStart <= den < dateEnd
			for (let day = dateStart.clone(), isAvailable; day.isBefore(dateEnd); day.add(1, "days")) {
				isAvailable = this.isDayAvailableForVariant(day, dealId, variantId);
				// Den je povazovan za dostupny, pokud neni nedostupny
				if (isAvailable !== false) {
					firstDayAvailable = day;
					break;
				}
			}

			// Cachovat vysledek
			variantCapacity.firstDayAvailable = firstDayAvailable;
		}

		return firstDayAvailable;
	}

	/**
	 * Vrati posledni dostupny den pro prave zobrazeny kalendar
	 * (kalendar muze zobrazovat vsechny varianty nebo jednu konkretni variantu).
	 * Den je povazovan za dostupny, pokud neni nedostupny.
	 * @returns {moment|null}
	 */
	getLastAvailableDay() {
		// Posledni dostupny den pro vsechny varianty dohromady
		if (!this.isVariantShown()) {
			// Cachujeme
			if (this.model.lastDayAvailable !== undefined) {
				return this.model.lastDayAvailable;
			}

			const lastDaysStrings = Object
				.keys(this.model.capacities)
				.map((key) => {
					const variantCapacity = this.model.capacities[key];
					return this.getLastAvailableDayForVariant(variantCapacity.dealId, variantCapacity.variantId);
				})
				.filter(firstDay => firstDay !== null)
				.map(firstDay => firstDay.format("YYYY-MM-DD"));
			const lastDayString = lastDaysStrings.sort()[lastDaysStrings.length - 1] || null;
			this.model.lastDayAvailable = lastDayString ? moment(lastDayString) : null;
			return this.model.lastDayAvailable;
		}

		// Posledni dostupny den pro konkretni variantu
		return this.getLastAvailableDayForVariant(this.model.dealId, this.model.variantId);
	}

	/**
	 * Vrati posledni dostupny den pro danou variantu
	 * Den je povazovan za dostupny, pokud neni nedostupny.
	 * @param {number} dealId
	 * @param {number|null} variantId
	 * @returns {moment|null}
	 */
	getLastAvailableDayForVariant(dealId, variantId = null) {
		let lastDayAvailable = null;
		const variantCapacity = this.getVariantCapacity(dealId, variantId);

		// Cachujeme
		if (variantCapacity && variantCapacity.lastDayAvailable) {
			return variantCapacity.lastDayAvailable;
		}

		if (variantCapacity !== null) {
			const dateStart = moment(variantCapacity.dateStart);
			const dateEnd = moment(variantCapacity.dateEnd);

			// Hledame posledni dostupny den v rozsahu dateStart <= den < dateEnd
			for (let day = dateEnd.clone().subtract(1, "days"),
				     isAvailable; day.isSameOrAfter(dateStart);
			     day.subtract(1, "days")
			) {
				isAvailable = this.isDayAvailableForVariant(day, dealId, variantId);
				if (isAvailable === null || isAvailable === true) {
					lastDayAvailable = day;
					break;
				}
			}

			// Cachovat vysledek
			variantCapacity.lastDayAvailable = lastDayAvailable;
		}

		return lastDayAvailable;
	}

	/**
	 * Vrati dny v mesici rozdelene podle tydnu
	 * @returns [[moment]]
	 */
	getMonthWeeks() {
		const startWeek = this.model.cursor.clone().startOf("month").startOf("week");
		const endWeek = this.model.cursor.clone().endOf("month").startOf("week");

		const weeks = [];
		for (let week = startWeek.clone(); !week.isAfter(endWeek); week.add(1, "week")) {
			weeks.push(new Array(7)
				.fill(0)
				.map((n, i) => (
					week.clone().add(n + i, "day")
				)));
		}

		return weeks;
	}

	/**
	 * Omezi zacatek kalendare podle dostupnosti variant nebo podle dneska
	 * @returns {moment|null}
	 */
	getStartDate() {
		const firstAvailableDay = this.getFirstAvailableDay();

		return firstAvailableDay
			? moment.max(firstAvailableDay, this.model.today.clone())
			: this.model.today.clone();
	}

	/**
	 * Vrati termin od-do vybrany v kalendari a odpovidajici dealId a variantId
	 * @returns {dateFrom, dateTo, dealId, variantId, verified}|null
	 */
	getSelected() {
		// K terminu musi byt zvolen deal (nove variantove dealy). Muze byt zvolena legacy deal varianta
		if (this.model.selectedDealId && this.model.selectedDate && this.model.selectedDaysLength) {
			const selectedNightsCount = Math.max(0, this.model.selectedDaysLength - 1);
			return {
				dateFrom: this.model.selectedDate.clone(),
				dateTo: this.model.selectedDate.clone().add(this.model.selectedDaysLength - 1, "days"),
				dealId: this.model.selectedDealId,
				variantId: this.model.selectedVariantId,
				verified: this.isRangeVerifiedForVariant(
					this.model.selectedDate,
					selectedNightsCount,
					this.model.selectedDealId,
					this.model.selectedVariantId,
				),
			};
		}

		return null;
	}

	/**
	 * Vrati kapacitu pro vybranou variantu
	 * @returns {Object}
	 */
	getSelectedVariantCapacity() {
		if (!this.getSelected()) {
			return null;
		}

		return this.getVariantCapacity(this.model.selectedDealId, this.model.selectedVariantId);
	}

	/**
	 * Vrati kapacitu pro vybranou variantu
	 * @param {number} dealId
	 * @param {number|null} variantId
	 * @returns {Object|null}
	 */
	getVariantCapacity(dealId, variantId = null) {
		return this.model.capacities[`${dealId}:${variantId !== null ? variantId : ""}`] || null;
	}

	/**
	 * Vrati, jestli je varianta dostupna pro dany den. Pokud je dealId / variantId null, overi, jestli je dostupna
	 * alespon 1 varianta.
	 * @param {moment} date
	 * @param {number|null} dealId
	 * @param {number|null} variantId
	 * @param {boolean} checkInDay Jestli dany den ma byt nastupni
	 * @returns {boolean|null} true = dostupna, false = nedostupna, null = dostupnost neznama
	 */
	isDayAvailable(date, dealId = null, variantId = null, checkInDay = false) {
		// Dostupnost pro vsechny varianty najednou (neni dane dealId)
		if (dealId === null) {
			const isDayAvailableForVariants = this.model.dealsAndVariants
				.map(dealOrVariant => this.isDayAvailableForVariant(
					date,
					dealOrVariant.dealId,
					dealOrVariant.variantId,
					checkInDay,
				));

			// Den je dostupny, kdyz je alespon 1 varianta pro den dostupna
			if (isDayAvailableForVariants.some(variantAvailable => variantAvailable === true)) {
				return true;
			}
			// Den je k overeni, kdyz je alespon 1 varianta pro den k overeni
			if (isDayAvailableForVariants.some(variantAvailable => variantAvailable === null)) {
				return null;
			}

			// Jinak je den nedostupny
			return false;
		}

		// Dostupnost pro konkretni variantu
		return this.isDayAvailableForVariant(date, dealId, variantId, checkInDay);
	}

	/**
	 * Vrati, jestli je varianta dostupna pro dany den
	 * @param {moment} date
	 * @param {number} dealId
	 * @param {number|null} variantId
	 * @param {boolean} checkInDay Jestli dany den ma byt nastupni
	 * @returns {boolean|null} true = dostupna, false = nedostupna, null = dostupnost neznama
	 */
	isDayAvailableForVariant(date, dealId, variantId = null, checkInDay = false) {
		const dateString = date.format("YYYY-MM-DD");

		// Den v minulosti
		if (date.isBefore(this.model.today)) {
			return false;
		}

		const variantCapacity = this.getVariantCapacity(dealId, variantId);

		// Neni info o dostupnosti dane varianty = neznama dostupnost
		if (variantCapacity === null || variantCapacity.days === undefined) {
			return null;
		}

		// Den pred zacatkem platnosti varianty = nedostupne
		if (variantCapacity.dateStart) {
			if (date.isBefore(variantCapacity.dateStart)) {
				return false;
			}
		}
		// Den po konci platnosti varianty = nedostupne
		if (variantCapacity.dateEnd) {
			if (date.isAfter(variantCapacity.dateEnd)) {
				return false;
			}
		}

		// Neznamy den = neznama dostupnost
		if (variantCapacity.days[dateString] === undefined) {
			return null;
		}

		// Zkontrolovat, jestli v dany den muze byt check-in
		if (checkInDay) {
			return variantCapacity.days[dateString].checkinEnabled;
		}

		return variantCapacity.days[dateString].status;
	}

	/**
	 * Vrati, jestli je dostupnost varianty overena pro dany den
	 * @param {moment} date
	 * @param {number} dealId
	 * @param {number|null} variantId
	 * @returns {boolean|null} true = dostupna, false = nedostupna, null = dostupnost neznama
	 */
	isDayVerifiedForVariant(date, dealId, variantId = null) {
		const dateString = date.format("YYYY-MM-DD");
		const variantCapacity = this.getVariantCapacity(dealId, variantId);

		// Neni info o dostupnosti dane varianty = neznama dostupnost
		if (variantCapacity === null || variantCapacity.days === undefined) {
			return null;
		}

		// Neznamy den
		if (variantCapacity.days[dateString] === undefined) {
			return null;
		}

		return variantCapacity.days[dateString].verified;
	}

	/**
	 * Vrati, jestli je den soucasti vyberu
	 * @param {moment} date
	 * @returns {boolean}
	 */
	isDaySelected(date) {
		return this.model.selectedDate
			&& date.isSameOrAfter(this.model.selectedDate)
			&& this.model.selectedDaysLength
			&& date.isBefore(this.model.selectedDate.clone().add(this.model.selectedDaysLength, "days"))
			// Neoznacovat den jako vybrany kdyz je zobrazena jina varianta nez varianta vybrana
			&& (
				!this.isVariantShown()
				|| (
					this.model.selectedDealId === this.model.dealId
					&& this.model.selectedVariantId === this.model.variantId
				)
			);
	}

	/**
	 * Vrati, jestli je den prvnim dnem ve vyberu
	 * @param {moment} date
	 * @returns {boolean}
	 */
	isDayFirstSelected(date) {
		return this.isDaySelected(date)
			&& this.model.selectedDate && date.isSame(this.model.selectedDate);
	}

	/**
	 * Vrati, jestli je den poslednim dnem ve vyberu
	 * @param {moment} date
	 * @returns {boolean}
	 */
	isDayLastSelected(date) {
		return this.isDaySelected(date)
			&& this.model.selectedDate
			&& this.model.selectedDaysLength
			&& date.isSame(this.model.selectedDate.clone().add(this.model.selectedDaysLength - 1, "days"));
	}

	/**
	 * Vrati, jestli se den nachazi ve viditelnem rozsahu kalendare
	 * (nekdy chceme zobrazit napr. jen rok dopredu)
	 * @param {moment} date
	 * @returns {boolean}
	 */
	isDayVisible(date) {
		return date.isSameOrAfter(this.getStartDate())
			&& date.isSameOrBefore(this.getEndDate());
	}

	/**
	 * Vrati jestli se navigace v kalendari nachazi na konci
	 */
	isEnd() {
		return this.getEndDate()
			.isSameOrBefore(this.model.cursor.clone().endOf("month"));
	}

	/**
	 * Vrati dostupnost pro dane dny a danou variantu
	 * @param {moment} dateFrom Nastupni den
	 * @param {number} nightsCount Pocet noci
	 * @param {number} dealId Variantovy deal
	 * @param {number|null} variantId Varianta
	 * @returns {boolean|null}
	 */
	isRangeAvailableForVariant(dateFrom, nightsCount, dealId, variantId) {
		const daysAvailable = [];

		// Zjistit dostupnost jednotlivych dni v danem rozsahu
		// Nekontrolujeme dostupnost posledniho dne (check-out)
		for (let day = 0, date, isCheckInDay; day < nightsCount; day += 1) {
			date = dateFrom.clone().add(day, "days");
			isCheckInDay = day === 0;
			daysAvailable.push(this.isDayAvailableForVariant(date, dealId, variantId, isCheckInDay));
		}

		// Rozsah je dostupny, kdyz jsou vsechny dny dostupne
		if (daysAvailable.every(dayAvailable => dayAvailable === true)) {
			return true;
		}
		// Rozsah je k overeni, kdyz zadny den neni obsazeny
		if (daysAvailable.indexOf(false) === -1) {
			return null;
		}

		// Jinak je rozsah nedostupny
		return false;
	}

	isRangeVerifiedForVariant(dateFrom, nightsCount, dealId, variantId) {
		const daysVerified = [];

		// Zjistit overeni jednotlivych dni v danem rozsahu
		// Nekontrolujeme dostupnost posledniho dne (check-out)
		for (let day = 0, date; day < nightsCount; day += 1) {
			date = dateFrom.clone().add(day, "days");
			daysVerified.push(this.isDayVerifiedForVariant(date, dealId, variantId));
		}

		// Rozsah je overeny, kdyz jsou vsechny dny overene
		if (daysVerified.every(dayVerified => dayVerified === true)) {
			return true;
		}
		// Rozsah nelze overit, kdy nektery den nelze overit
		if (daysVerified.indexOf(null) >= 0) {
			return null;
		}

		// Jinak je rozsah neovereny
		return false;
	}

	/**
	 * Vrati jestli se navigace v kalendari nachazi na zacatku
	 */
	isStart() {
		return this.getStartDate()
			.isSameOrAfter(this.model.cursor.clone().startOf("month"));
	}

	/**
	 * Vrati, jestli je varianta od daneho nastupniho dne volna
	 * @param {moment} dateFrom
	 * @param {number} dealId
	 * @param {number|null} variantId
	 * @returns {boolean|null}
	 */
	isVariantAvailable(dateFrom, dealId, variantId = null) {
		const variantCapacity = this.getVariantCapacity(dealId, variantId);

		// Neznama varianta = neznama dostupnost
		if (variantCapacity === null) {
			return null;
		}

		const nightsCount = Math.max(0, variantCapacity.daysLength - 1);
		return this.isRangeAvailableForVariant(dateFrom, nightsCount, dealId, variantId);
	}

	/**
	 * Vrati nejblizsi check-in day od zadaneho dne a varianty. Hleda se v intervalu +-14 dni od daneho dne.
	 * @param {moment} date Den, od ktereho se hleda
	 * @param {number|null} dealId Omezit hledani jen na danou variantu
	 * @param {number|null} variantId Omezit hledani jen na danou variantu
	 * @returns {moment|null}
	 */
	getClosestCheckinDay(date, dealId = null, variantId = null) {
		let checkInDay = null;

		for (let diff = 0; diff < 14; diff += 1) {
			const dayBefore = date.clone().add(diff, "days");

			if (this.isDayAvailable(dayBefore, dealId, variantId, true)) {
				checkInDay = dayBefore;
				break;
			}

			const dayAfter = date.clone().subtract(diff, "days");

			if (this.isDayAvailable(dayAfter, dealId, variantId, true)) {
				checkInDay = dayAfter;
				break;
			}
		}

		return checkInDay;
	}

	/**
	 * Zaloguje
	 * @param {moment|null} dateFrom
	 * @param {number|null} dealId
	 * @param {number|null} variantId
	 * @param {number|null} merchantId
	 * @param {string} type
	 */
	logCapacityRequest(dateFrom, dealId, variantId, merchantId, type) {
		this.ajaxService.ajax({
			data: {
				dateFrom: dateFrom !== null ? dateFrom.format("YYYY-MM-DD") : null,
				dealId,
				merchantId,
				pageviewId: this.hitService.getPageviewId(),
				type,
				variantId,
			},
			method: "post",
			url: this.model.ajaxSaveCapacityRequestUrl,
		});
	}

	onBtnMonthClick(ev, addMonths) {
		ev.preventDefault();
		this.model.cursor.add(addMonths, "months");
		this.render();
	}

	onDayClick(ev) {
		ev.preventDefault();
		ev.stopPropagation();

		const dateString = $(ev.currentTarget).data("date");
		const { dealId, variantId } = this.model;
		const date = moment(dateString);

		if (dealId === null) {
			if (this.model.dropdownDateString !== dateString) {
				this.model.dropdownDateString = dateString;
			} else {
				this.model.dropdownDateString = null;
			}
			this.render();
		} else {
			this.model.dropdownDateString = null;
			this.select(date, dealId, variantId);
			this.render();
		}
	}

	onDayVariantClick(ev) {
		ev.preventDefault();

		const dateString = $(ev.currentTarget).data("date");
		const dealId = +$(ev.currentTarget).data("dealId");
		let variantId = +$(ev.currentTarget).data("variantId");
		const date = moment(dateString);

		if (Number.isNaN(variantId)) {
			variantId = null;
		}

		if (!Number.isNaN(dealId)) {
			this.select(date, dealId, variantId);
			this.render();
		}
	}

	onOutsideClick() {
		if (this.model.dropdownDateString) {
			this.model.dropdownDateString = null;
			this.render();
		}
	}

	/**
	 * Vybere variantu od daneho data
	 * @param {moment} dateFrom
	 * @param {number} dealId
	 * @param {number} variantId
	 */
	select(dateFrom, dealId, variantId) {
		const variantCapacity = this.getVariantCapacity(dealId, variantId);
		const { daysLength } = variantCapacity;
		const isVariantAvailable = this.isVariantAvailable(dateFrom, dealId, variantId);

		if (isVariantAvailable === false) {
			const closestCheckInDay = this.getClosestCheckinDay(dateFrom, dealId, variantId);
			this.flashMessageService.showMessage(
				`Od ${dateFrom.format("D. M.")} nelze rezervovat ${daysLength}denní pobyt. 
				${closestCheckInDay && !closestCheckInDay.isSame(dateFrom)
					? `Zkute termín od ${closestCheckInDay.format("D. M.")}`
					: "Vyberte jiný nástupní den."
				}`,
				"warning",
				{
					modal: true,
				},
			);
			return;
		}

		this.model.selectedDealId = dealId;
		this.model.selectedVariantId = variantId;
		// Vybere rozsah, pokud je dostupny nebo neni znama dostupnost
		this.selectRange(dateFrom, daysLength);

		const selected = this.getSelected();
		this.app.dispatch(new CalendarSelectEvent(selected));

		// Trackujeme deal podle variantId, ne podle dealId
		this.logCapacityRequest(dateFrom, dealId, variantId, this.model.clickedMerchantId, "date_chosen");
	}

	track(selected) {

	}

	unselect() {
		this.model.selectedDate = null;
		this.model.selectedDaysLength = null;
		this.model.selectedDealId = null;
		this.model.selectedVariantId = null;

		this.app.dispatch(new CalendarSelectEvent());
	}

	renderDropdown(date) {
		const availableAndUnknownDealsAndVariants = [];
		const unavailableDealsAndVariants = [];

		// Vypsat dealy a varianty pro dany den podle dostupnosti
		this.model.dealsAndVariants.forEach((dealOrVariant) => {
			const isAvailable = this.isVariantAvailable(
				date,
				dealOrVariant.dealId,
				dealOrVariant.variantId,
			);

			switch (isAvailable) {
				case true:
				case null:
					availableAndUnknownDealsAndVariants.push(dealOrVariant);
					break;
				case false:
					unavailableDealsAndVariants.push(dealOrVariant);
					break;
				default:
			}
		});

		return `
			<ul class="dropdown-menu pull-right">
				${availableAndUnknownDealsAndVariants.length
			? `
						<li class="dealvars-dropdown-header disabled">
							<strong class="a">Vyberte variantu:</strong>
						</li>
						${CalendarWidget.renderDayDealsAndVariants(availableAndUnknownDealsAndVariants, date)}
					`
			: `<li class="disabled">
							<div class="alert mbs">Vyberte jiný nastupní den</div>
					</li>`
		}
				${unavailableDealsAndVariants.length
			? `<li class="dealvars-dropdown-header disabled">
							<strong class="a">Pro tento den nelze vybrat:</strong>
						</li>
						${CalendarWidget.renderDayDealsAndVariants(unavailableDealsAndVariants, date, true)}
					`
			: ""
		}
			</ul>
		`;
	}

	render() {
		let html;
		const weeks = this.getMonthWeeks();
		const prevMonth = !this.isStart()
			? this.model.cursor
				.clone()
				.subtract(1, "months")
				.startOf("month")
			: null;
		const nextMonth = !this.isEnd()
			? this.model.cursor
				.clone()
				.add(1, "months")
				.startOf("month")
			: null;

		html = "<div class='calendar'>";
		html += "<p class='calendar-navigation'>";
		html += prevMonth
			? `
				<a class='calendar-prev btn btn-mini btn-info j-prevmonth' href='#'>
					<svg class='icon' aria-hidden='true'>
						<use xlink:href='${getIconSpriteUrl(triangleLeftIcon)}'></use>
					</svg>
					${dry.str.ucfirst(prevMonth.format("MMMM"))}
				</a>
			`
			: "";
		html += `<strong>${dry.str.ucfirst(this.model.cursor.format("MMMM YYYY"))}</strong>`;
		html += nextMonth
			? `
				<a class='calendar-next btn btn-mini btn-info j-nextmonth' href='#'>
					${dry.str.ucfirst(nextMonth.format("MMMM"))}
					<svg class='icon' aria-hidden='true'>
						<use xlink:href='${getIconSpriteUrl(triangleRightIcon)}'></use>
					</svg>
				</a>
			`
			: "";
		html += "</p>";
		html += "<table class='calendar-table table'>";
		html += `<tr>
			<th>Po</th>
			<th>Út</th>
			<th>St</th>
			<th>Čt</th>
			<th>Pá</th>
			<th>So</th>
			<th>Ne</th>
		</tr>`;
		html += weeks
			.map(week => (
				`<tr>${
					week
						.map((day) => {
							const isVisible = this.isDayVisible(day);
							const isAvailable = this.isDayAvailable(day, this.model.dealId, this.model.variantId);
							const isCheckIn = this.isDayAvailable(day, this.model.dealId, this.model.variantId, true);
							const isSelected = this.isDaySelected(day);
							const isFirstSelected = this.isDayFirstSelected(day);
							const isLastSelected = this.isDayLastSelected(day);
							const isDisabled = !isVisible || isAvailable === false;
							const isToday = day.isSame(this.model.today, "day");
							const dateString = day.format("YYYY-MM-DD");
							const isOpened = this.model.dropdownDateString === dateString;

							const yesterday = day.clone().subtract(1, "days");
							const isYesterdayVisible = this.isDayVisible(yesterday);
							const isYesterdayAvailable = this.isDayAvailable(
								yesterday,
								this.model.dealId,
								this.model.variantId,
							);

							const className = cx({
								"s-disabled": isDisabled,
								"s-available": isAvailable,
								"s-checkin": isCheckIn && isAvailable,
								"s-checkin-ondemand": isCheckIn && isAvailable === null,
								"s-selected": isSelected && !isFirstSelected && !isLastSelected,
								"s-first-selected": isFirstSelected,
								"s-last-selected": isLastSelected,
								"s-opened": isOpened,
								"s-today": isToday,
								// Propsat predchozi dostupny den na nasledujici
								// den k overeni / nedostupny den (check-out den)
								"s-yesterday-available": isYesterdayVisible && isYesterdayAvailable && !isAvailable,
								// Propsat predchozi den k overeni na nasledujici nedostupny den (check-out den)
								"s-yesterday-ondemand": isYesterdayVisible
									&& isYesterdayAvailable === null
									&& isAvailable === false,
							});

							return `
								<td class="${className}">
				${!isDisabled || (isYesterdayVisible && isYesterdayAvailable !== false)
								? `${dateString === this.model.dropdownDateString
									? `<span class="dealvars-dropdown btn-group open">
							<a class="calendar-day j-day" href="#" data-date="${dateString}">
								<span class="calendar-daytext">${day.date()}.</span>
								<span class="calendar-dayarrow dropdown-arrow"></span>
							</a>
							${this.renderDropdown(day)}
						</span>`
									: `<a class="calendar-day j-day" href="#" data-date="${dateString}">
							<span class="calendar-daytext">${day.date()}.</span>
						</a>`
								}`
								: `<span class="calendar-day">
						<span class="calendar-daytext">${day.date()}.</span>
					</span>`
							}
								</td>
							`;
						})
						.join("")
				}</tr>`
			))
			.join("");
		html += "</table>";
		html += "</div>";

		this.el.html(html);
	}

	selectRange(dateFrom, daysLength) {
		this.model.selectedDate = dateFrom;
		this.model.selectedDaysLength = daysLength;
		this.model.cursor = this.model.selectedDate
			.clone()
			.add(this.model.selectedDaysLength - 1, "days");
	}

	/**
	 * Nastavi zobrazenou variantu
	 * @param {number} dealId
	 * @param {number} variantId
	 */
	setVariant(dealId, variantId) {
		this.model.dealId = dealId;
		this.model.variantId = variantId;

		// Posunout kurzor, aby se nedostal mimo interval startDate/endDate prave zvolene varianty
		const startDate = this.getStartDate();
		const endDate = this.getEndDate();
		this.model.cursor = moment.min(moment.max(startDate, this.model.cursor), endDate).clone();
	}
}

CalendarWidget.renderDayDealsAndVariants = (dealsAndVariants, date, disabled = false) => (
	dealsAndVariants
		.map((dealOrVariant) => {
			const dateString = date.format("YYYY-MM-DD");

			return `
				<li class="${cx({ disabled })}">
					<div class="${cx("media", "oh", "m0", "a", { "j-day-variant": !disabled })}"
						data-deal-id="${dealOrVariant.dealId}"
						data-variant-id="${dealOrVariant.variantId}"
						data-date="${dateString}"
					>
						<div class="pull-right text-right">
							${dealOrVariant.priceFinalFormat
				? `<span class="${cx({ "text-danger": !disabled })}">${dealOrVariant.priceFinalFormat}</span>`
				: ""
			}
							<br>
							${dealOrVariant.discount
				? `<span class="${cx("label", { "label-danger": !disabled })}">${dealOrVariant.discount}&nbsp;%</span>`
				: ""
			}
						</div>
						<div class="media-body">
							${dealOrVariant.title}
						</div>
					</div>
				</li>`;
		}).join("")
);
