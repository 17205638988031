import camelCase from "lodash/camelCase";
import $ from "jquery";
import Widget from "../../../Inlined/Widget";

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

export default class StepperWidget extends Widget {
	constructor(el, model, children, app) {
		super(el, model, children);
		this.app = app;
		this.prevStepIndex = null;
		this.stepIndex = null;
	}

	bind() {
		super.bind();
		this.steps = this.findSteps();
		this.nextStep();
	}

	getStep(indexOrNameArg) {
		const indexOrName = (indexOrNameArg === undefined) ? this.stepIndex : indexOrNameArg;
		const i = (typeof indexOrName === "number") ? indexOrName : this.getStepIndex(indexOrName);

		if (i === null) {
			return null;
		}
		return this.steps[i] || null;
	}

	// vrati cislo kroku s danym nazvem
	getStepIndex(name) {
		let found = null;
		for (let i = 0; i < this.steps.length; i += 1) {
			if (this.steps[i].name === name) {
				found = i;
				break;
			}
		}
		return found;
	}

	// najde elementy "kroku" v DOMu
	findSteps() {
		const steps = $(".j-step", this.el);
		const namedSteps = [];

		steps.each(function () {
			const name = $(this).data("step");
			if (name) {
				namedSteps.push({
					name,
					el: $(this),
				});
			}
		});
		return namedSteps;
	}

	// prejde na dany krok (cislo nebo nazev)
	goToStep(indexOrName, dismissPrevStep = false) {
		const step = this.getStep(indexOrName);

		if (step === null) {
			return false;
		}

		const i = this.getStepIndex(step.name);

		// cilovy krok je soucasny
		if (i === this.stepIndex) {
			return step;
		}

		// in a out callback pri zmene kroku
		const prevStep = this.stepIndex === null ? null : (this.steps[this.stepIndex] || null);
		let retStepOut = null;
		let retStepNameOut = null;
		let retStepIn = null;
		let retStepNameIn = null;
		if (prevStep !== null) {
			prevStep.dismiss = dismissPrevStep;
			if (typeof this.onStepOut === "function") {
				retStepOut = this.onStepOut(prevStep, step);
			}
			if (typeof this[`onStep${capitalize(camelCase(prevStep.name))}Out`] === "function") {
				retStepNameOut = this[`onStep${capitalize(camelCase(prevStep.name))}Out`](prevStep, step);
			}
		}
		if (typeof this.onStepIn === "function") {
			retStepIn = this.onStepIn(step, prevStep);
		}
		if (typeof this[`onStep${capitalize(camelCase(step.name))}In`] === "function") {
			retStepNameIn = this[`onStep${capitalize(camelCase(step.name))}In`](step, prevStep);
		}

		// pokud callback vrati false, neprejde se na dalsi krok
		if (retStepOut === false
			|| retStepNameOut === false
			|| retStepIn === false
			|| retStepNameIn === false
		) {
			return false;
		}

		// prechod
		this.prevStepIndex = this.stepIndex;
		this.stepIndex = i;
		if (prevStep !== null) {
			prevStep.el.hide();
		}
		step.el
			.show()
			.removeClass("hide");

		// udalost: zobrazen krok step.name
		if (typeof this.onStep === "function") {
			this.onStep(step, prevStep);
		}
		if (typeof this[`onStep${capitalize(camelCase(step.name))}`] === "function") {
			this[`onStep${capitalize(camelCase(step.name))}`](step, prevStep);
		}

		if (prevStep !== null) {
			prevStep.dismiss = false;
		}

		return step;
	}

	nextStep(dismissPrevStep) {
		let i = this.stepIndex + 1;
		if (this.stepIndex === null) {
			i = 0;
		}
		this.goToStep(i, dismissPrevStep);
	}

	prevStep(dismissPrevStep) {
		let i = this.stepIndex - 1;
		if (this.stepIndex == null) {
			i = 0;
		}
		this.goToStep(i, dismissPrevStep);
	}

	setStepLoading(loading, indexOrNameArg) {
		const indexOrName = indexOrNameArg === undefined ? this.stepIndex : indexOrNameArg;
		const step = this.getStep(indexOrName);

		if (step === null) {
			return false;
		}

		const spinner = $(".j-spinner", step.el);
		step.loading = loading;

		if (loading) {
			spinner.show();
		} else {
			spinner.hide();
		}
		return true;
	}
}
