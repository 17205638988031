import $ from "jquery";
import AbstractFilterFormWidget from "./AbstractFilterFormWidget";
import FilterDropdownWidget from "./FilterDropdownWidget";


export default class TopFilterFormWidget extends AbstractFilterFormWidget {
	bind() {
		super.bind();

		this.btnHideHidden = $(".j-hidden-hide", this.el);
		this.btnShowHidden = $(".j-hidden-show", this.el);
		this.btnToggleMobile = $(".j-toggle-mobile", this.el);
		this.filtersBar = $(".j-filters-bar", this.el);
		this.filtersDropdowns = this.findByType(FilterDropdownWidget);
		this.filterDropdownToggles = $(".filter [data-toggle='dropdown']", this.el);
		this.desktopContainer = $(".j-desktop", this.el);
		this.mobileContainer = $(".j-mobile", this.el);

		this.btnHideHidden.click(ev => this.onBtnHideHiddenClick(ev));
		this.btnShowHidden.click(ev => this.onBtnShowHiddenClick(ev));
		this.btnHideHidden.addClass("hide");
		this.btnToggleMobile.click(ev => this.onBtnToggleMobileClick(ev));
		this.btnShowHidden.removeClass("hide");
		if (this.model.position !== "travel_top") {
			this.mobileContainer.removeClass("hide").hide();
		}
	}

	getHiddenFilters() {
		return this.filtersDropdowns.filter(filterDropdown => !filterDropdown.isVisible());
	}

	hideHiddenFilters() {
		const hiddenFilters = this.getHiddenFilters();
		hiddenFilters.forEach((filterDropdown) => { filterDropdown.hide(); });

		this.el.removeClass("s-expanded");
	}

	onBtnHideHiddenClick(ev) {
		ev.preventDefault();

		this.hideHiddenFilters();

		this.btnHideHidden.addClass("hide");
		this.btnShowHidden.removeClass("hide");
	}

	onBtnShowHiddenClick(ev) {
		ev.preventDefault();

		this.showHiddenFilters();

		this.btnHideHidden.removeClass("hide");
		this.btnShowHidden.addClass("hide");
	}

	onBtnToggleMobileClick(ev) {
		ev.preventDefault();

		const $parent = $(ev.currentTarget).parent();
		const isOpen = $parent.hasClass("open");

		if (isOpen) {
			this.mobileContainer.slideUp();
			$parent.removeClass("open");
		} else {
			this.mobileContainer.slideDown();
			$parent.addClass("open");
		}
	}

	onDeviceSizeChange(ev) {
		if (this.model && this.model.position && this.model.position === "homepage") {
			// Do not change anything on homepage
			return;
		}

		// Zmenit chovani dropdownu. Na mobilu jsou inlinovane a nezaviraji se pri kliku mimo dropdown
		if (ev.deviceSize === "xs" || ev.deviceSize === "sm") {
			this.filterDropdownToggles.addClass("dropdown-noclose");
		} else {
			this.filterDropdownToggles.removeClass("dropdown-noclose");
			this.filterDropdownToggles.parent().removeClass("open");
		}
		if (!this.model.preventMobileFilters) {
			this.placeFilters();
		}
	}

	placeFilters() {
		const deviceSize = this.getDeviceSize();
		if (deviceSize === "xs" || deviceSize === "sm") {
			this.filtersBar.appendTo(this.mobileContainer).removeClass("hidden-xs hidden-sm");
		} else {
			this.filtersBar.appendTo(this.desktopContainer).removeClass("hidden-xs hidden-sm");
		}
	}

	showHiddenFilters() {
		const hiddenFilters = this.getHiddenFilters();
		hiddenFilters.forEach((filter) => { filter.show(); });

		this.el.addClass("s-expanded");
	}
}
