import $ from "jquery";

/* global __ */
/* global document */
/* global history */
/* global window */
/* global Image */

export default class ItemPreviewService {
	static instance;

	constructor(
		ajaxPreviewUrl,
		dealDetailUrlPattern,
		app,
		ajaxService,
		fancyboxService,
		bodyWidget,
		lazyLoadingService,
	) {
		if (ItemPreviewService.instance) {
			return ItemPreviewService.instance;
		}

		this.ajaxPreviewUrl = ajaxPreviewUrl;
		this.dealDetailUrlPattern = dealDetailUrlPattern;
		this.app = app;
		this.ajaxService = ajaxService;
		this.fancyboxService = fancyboxService;
		this.bodyWidget = bodyWidget;
		this.lazyLoadingService = lazyLoadingService;

		this.closeTitle = document.title;
		this.closeUrl = window.location.href;
		this.coming = false;
		this.loadXhr = null;
		this.loadingShown = false;
		this.opened = false;
		this.delayed = false;

		// Reference na polozku v listingu
		this.itemWidget = null;
		// Reference na listing
		this.listingWidget = null;
		// Aktualni preview widget
		this.previewWidget = null;
		// Cachovane preview widgety
		this.previewWidgets = {};
		this.preparedWidgets = [];

		$(window).on("popstate", ev => this.onPopState(ev));

		ItemPreviewService.instance = this;
	}

	abortLoading() {
		if (this.loadXhr) {
			this.loadXhr.abort();
		}
		this.hideLoading();
	}

	createUrl(slug, hash) {
		return this.dealDetailUrlPattern
			.replace("{slug}", slug)
			.replace("{hash}", hash);
	}

	getListingWidget() {
		return this.listingWidget;
	}

	getNextItemWidget() {
		if (!this.itemWidget) {
			return null;
		}
		if (!this.listingWidget) {
			return null;
		}
		return this.listingWidget.getNextUniqueDeal(this.itemWidget);
	}

	getPrevItemWidget() {
		if (!this.itemWidget) {
			return null;
		}
		if (!this.listingWidget) {
			return null;
		}
		return this.listingWidget.getPrevUniqueDeal(this.itemWidget);
	}

	getPreviewWidgetByHash(hash) {
		return this.previewWidgets[hash] || null;
	}

	getPreviewWidgetsFromResponse(response) {
		const widgets = {};

		if (response.html) {
			const renderedHtml = this.app.render(response.html, this.bodyWidget);
			if (renderedHtml.widgets.length) {
				[widgets.current] = renderedHtml.widgets;
			}
		}
		if (response.next) {
			const renderedHtml = this.app.render(response.next, this.bodyWidget);
			if (renderedHtml.widgets.length) {
				[widgets.next] = renderedHtml.widgets;
			}
		}
		if (response.prev) {
			const renderedHtml = this.app.render(response.prev, this.bodyWidget);
			if (renderedHtml.widgets.length) {
				[widgets.prev] = renderedHtml.widgets;
			}
		}
		return widgets;
	}

	getUrl() {
		if (this.previewWidget === null || !this.previewWidget.getHash()) {
			return null;
		}

		const slug = this.previewWidget.getSlug();
		const hash = this.previewWidget.getHash();
		return this.createUrl(slug, hash);
	}

	hideLoading() {
		if (!this.loadingShown) {
			return;
		}

		this.loadingShown = false;
		this.fancyboxService.close();
	}

	load(data, onSuccess = () => {}, onError = () => {}) {
		if (!data.current && !data.prev && !data.next) {
			return;
		}

		const url = this.ajaxService.getSameDomainUrl(this.ajaxPreviewUrl);

		this.loadXhr = this.ajaxService.ajax({ data, url })
			.done((response) => {
				this.preloadImages();
				const widgets = this.getPreviewWidgetsFromResponse(response);
				if (widgets.current) {
					this.previewWidgets[widgets.current.getHash()] = widgets.current;
					this.prepareWidget(widgets.current);
				}
				if (widgets.next) {
					this.previewWidgets[widgets.next.getHash()] = widgets.next;
					this.prepareWidget(widgets.next);
				}
				if (widgets.prev) {
					this.previewWidgets[widgets.prev.getHash()] = widgets.prev;
					this.prepareWidget(widgets.prev);
				}
				onSuccess();
				this.lazyLoadingService.update();
			})
			.fail((jqXHR, textStatus) => {
				if (this.coming && textStatus !== "abort") {
					onError(this);
				}
			});
	}

	nextPreview() {
		const nextItemWidget = this.getNextItemWidget();

		if (!nextItemWidget) {
			return false;
		}

		this.preview(nextItemWidget.model.hash, true, nextItemWidget);
		return true;
	}

	onHistoryChange(state) {
		if (!state || !state.itemPreview) {
			return;
		}

		const { hash } = state.itemPreview;
		const itemWidget = this.listingWidget ? this.listingWidget.getItemByHash(hash) : null;

		this.preview(hash, false, itemWidget);
	}

	onPopState(ev) {
		const state = ev.originalEvent.state || null;
		this.onHistoryChange(state);
	}

	// Uzavren fancybox
	onPreviewClose() {
		this.setItemWidget(null);
		this.setListingWidget(null);
		this.removePreparedWidgets();
		this.delayed = false;

		// eslint-disable-next-line no-restricted-globals
		history.pushState({}, this.closeTitle, this.closeUrl);

		document.title = this.closeTitle;
	}

	// Zmenil se obsah fancyboxu
	onPreviewChange() {
		if (!this.itemWidget) {
			return;
		}

		const $body = $(document.body);
		const instance = this.fancyboxService.getInstance();

		// Naskrolovat na aktivni polozku v listingu
		if (instance) {
			$body.removeClass("fancybox-active");
		}
		this.itemWidget.scrollIntoView();
		if (instance) {
			$body.addClass("fancybox-active");
		}
	}

	// Otevrel se fancybox (jen pokud predtim byl uzavren)
	onPreviewOpen() {
	}

	preloadImages() {
		const nextItemWidget = this.getNextItemWidget();
		const prevItemWidget = this.getPrevItemWidget();

		const nextImageUrl = nextItemWidget ? nextItemWidget.getPreviewImage() : null;
		const prevImageUrl = prevItemWidget ? prevItemWidget.getPreviewImage() : null;

		if (nextImageUrl) {
			const nextImage = new Image();
			nextImage.src = nextImageUrl;
		}
		if (prevImageUrl) {
			const prevImage = new Image();
			prevImage.src = prevImageUrl;
		}
	}

	// Vlozi widget do DOMu, aby se prednacetly jeho obrazky jeste predtim nez jej zobrazime
	prepareWidget(widget) {
		widget.el.appendTo($(document.body)).hide();
		this.preparedWidgets.push(widget);
	}

	preview(
		hash,
		pushState = true,
		itemWidgetArg,
		listingWidgetArg,
		delayedArg,
		term = null,
	) {
		const itemWidget = typeof itemWidgetArg === "undefined" ? this.itemWidget : itemWidgetArg;
		const listingWidget = typeof listingWidgetArg === "undefined" ? this.listingWidget : listingWidgetArg;
		const delayed = typeof delayedArg === "undefined" ? this.delayed : delayedArg;

		this.fancyboxService.create(() => {
			this.itemWidget = itemWidget;
			this.listingWidget = listingWidget;
			this.delayed = delayed;

			const widget = this.getPreviewWidgetByHash(hash);
			const prevItemWidget = this.getPrevItemWidget();
			const prevItem = prevItemWidget ? prevItemWidget.model : null;
			const prevPreviewWidget = prevItem ? this.getPreviewWidgetByHash(prevItem.hash) : null;
			const nextItemWidget = this.getNextItemWidget();
			const nextItem = nextItemWidget ? nextItemWidget.model : null;
			const nextPreviewWidget = nextItem ? this.getPreviewWidgetByHash(nextItem.hash) : null;

			const data = {
				delayed: Number(this.delayed),
				widget_position: "modal",
			};
			// Nacist aktualni preview, pokud jiz nebylo nacteno
			if (!widget) {
				data.current = hash;
				if (term !== null) {
					data.dt = term;
				}
			}
			// Stahnout AJAXem predchozi a nasledujici preview, pokud jiz nebylo stazeno
			if (prevItem && !prevPreviewWidget) {
				data.prev = prevItem.hash;
			}
			if (nextItem && !nextPreviewWidget) {
				data.next = nextItem.hash;
			}

			this.removePreparedWidgets();
			this.coming = true;

			if (widget) {
				this.preloadImages();
				this.show(widget, pushState);
				this.load(data);
			} else {
				const load = () => {
					this.showLoading();
					this.load(
						data,
						() => {
							const previewWidget = this.getPreviewWidgetByHash(hash);
							this.hideLoading();
							if (previewWidget) {
								this.show(previewWidget, pushState);
							}
						},
						() => {
							this.hideLoading();
							this.showError(load);
						},
					);
				};
				load();
			}

			if (prevPreviewWidget) {
				this.prepareWidget(prevPreviewWidget);
			}
			if (nextPreviewWidget) {
				this.prepareWidget(nextPreviewWidget);
			}
		});

		return true;
	}

	prevPreview() {
		const prevItemWidget = this.getPrevItemWidget();

		if (!prevItemWidget) {
			return false;
		}

		this.preview(prevItemWidget.model.hash, true, prevItemWidget);
		return true;
	}

	removePreparedWidgets() {
		this.preparedWidgets.forEach((widget) => {
			widget.el.detach().show();
		});
		this.preparedWidgets = [];
	}

	setItemWidget(itemWidget) {
		this.itemWidget = itemWidget;
		return this;
	}

	setListingWidget(listingWidget) {
		this.listingWidget = listingWidget;
		return this;
	}


	show(widget, pushState = true) {
		const srv = this;
		const open = !this.opened;

		this.fancyboxService.openWidget(
			widget,
			{
				slideClass: "s-edge-to-edge s-no-slide-overflow s-overlay-75",
				afterLoad: () => {
					srv.coming = false;
					srv.opened = true;
					srv.previewWidget = widget;
					srv.onPreviewChange();
					if (pushState) {
						// eslint-disable-next-line no-restricted-globals
						history.pushState(
							{
								itemPreview: {
									hash: widget.getHash(),
								},
							},
							widget.getPageTitle(),
							srv.createUrl(widget.getSlug(), widget.getHash()),
						);
					}
					document.title = widget.getPageTitle();
				},
				beforeClose: () => {
					if (!srv.coming) {
						srv.onPreviewClose();
					}
					srv.previewWidget = null;
					srv.opened = false;
				},
				detachOnClose: true,
			},
		);
	}

	showError(retry) {
		// eslint-disable-next-line max-len
		const $tpl = $(`<div><div class='alert nowrap m0'>${__("Bohužel se nepodařilo načíst nabídku.")} <a class='j-btn-preview-refresh' href='#'>${__("Načíst znovu")}!</a></div></div>`);
		$(".j-btn-preview-refresh", $tpl).on(
			"click",
			(ev) => {
				ev.preventDefault();
				retry();
			},
		);

		this.fancyboxService.open(
			$tpl,
			{
				slideClass: "s-overlay-75",
			},
		);
	}

	showLoading() {
		const height = $(window).height() - (2 * 44);
		const $el = $(`<div class='dealdialog' style='height:${height}px;'>
			<p class='dealdialog-loading'><span class='spinner'></span> ${__("Načítám")}...</p>
		</div>`);

		this.loadingShown = true;

		this.fancyboxService.open(
			$el,
			{
				slideClass: "s-edge-to-edge s-overlay-75",
				beforeClose: () => this.abortLoading(),
			},
		);
	}
}
