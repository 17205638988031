import Translator from "gettext-translator";
import { strtr } from "../utils";
import { translations } from "../Locale";

/**
 * Usage:
 *
 * - For gettext() calls use __() which is not exported here but is globally defined and processed in build-time
 *   using Webpack's i18n-webpack-plugin (https://github.com/webpack-contrib/i18n-webpack-plugin).
 * - For ngettext() calls use n__() exported here, ngettext() calls are processed in runtime.
 * - For pgettext() calls use p__() exported here, pgettext() calls are processed in runtime.
 * - For npgettext() calls use np__() exported here, npgettext() calls are processed in runtime.
 * - For parameters replacement use trans() function
 *
 * Design decisions:
 *
 * – Right now i18n-webpack-plugin support only singular translations with no context.
 *   (see issue https://github.com/webpack-contrib/i18n-webpack-plugin/pull/58)
 *   For plurals or context-aware translations we use functions from gettext-translator runtime.
 * - __(), n__, p__ and np__() function do not support translation parameters to keep compatibility
 *   with i18n-webpack-plugin
 *   Use composition with trans() function instead, e.g. trans(__("Hello %name%"), { "%name%": "Kitty" })
 */

const translator = new Translator(translations);

// eslint-disable-next-line no-underscore-dangle
const n__ = (original, plural, value) => translator.ngettext(original, plural, value);

// eslint-disable-next-line no-underscore-dangle
const p__ = (context, original) => translator.pgettext(context, original);

// eslint-disable-next-line no-underscore-dangle
const np__ = (context, original, plural, value) => translator.npgettext(context, original, plural, value);

const defaultParameters = {
	"%nbsp%": "\u00A0",
};

/**
 * Translates string params with given values. Params use format like ["%name%" => $value].
 *
 * @param {string} str
 * @param {Object.<string, string>} parameters
 * @returns {string}
 */
const trans = (str, parameters = {}) => {
	const replacePairs = {
		...defaultParameters,
		...parameters,
	};

	return strtr(str, replacePairs);
};

export default translator;

export { n__, p__, np__, trans };
